import React from "react"
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import Figure from '../img/Figure_ColorTemperature.png';
import FigureNormal from '../img/Figure_Normal.png';
import FigureCool from '../img/Figure_TempCool.png';
import FigureWarm from '../img/Figure_TempWarm.png';
import FigureDark from '../img/Figure_ColorTemperature_Dark.png';
import PageHeader from '../components/PageHeader';
import ImageSlider from "../components/ImageSlider";

import { Link } from "react-router-dom";
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";

function ColorTemperature(props) {
  const mq = window.matchMedia('(prefers-color-scheme: dark)');
  const IMG = (mq.matches) ? FigureDark : Figure;

  const Slider = <ImageSlider 
    id="Temperature" 
    images={ [FigureWarm, FigureNormal, FigureCool] } 
    labelLeft="Warm" 
    labelRight="Cool"
    height="226px"
    defaultValue="50"
  />

  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      key="color-temperature"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Color temperature"
        description="A way to describe appearance of light, measured in degrees of Kelvin (K). Color temperature relates to warm and cool colors."
      />

      <PageHeader
        category="Measurement &amp; formulas"
        title="Color temperature"
        // subtitle="(Delta E)"
        />

      <Section
        figure={IMG}
      >
        <p className="body4">A way to describe appearance of light, measured in degrees of Kelvin (K). Color temperature relates to <Link to="/warm-and-cool-colors">warm and cool colors</Link>.</p>
      </Section>

      <Section title="See how it looks" level="2">
        <Section
          splitView
          playground={Slider}
        >
          <p>Use the slider to see how adjusting color temperature affects an image.</p>
        </Section>
      </Section>

      <Section title="How it affects UI design" level="4" className="elements">
        <p><Link to="/complimentary-colors">Complimentary colors</Link> tend to be of opposing temperatures. Using <Link to="warm-and-cool-colors">warm and cool colors</Link> together creates dynamic color contrast.</p>
        <p>Warm colors can communicate excitement, friendliness, and positivity.</p>
        <p>Cool colors can be used to communicate calmness, peace, approachability, and sadness.</p>
        <p>Certain combinations of warm and cool colors are not distinguishable to people with <Link to="/color-vision-deficiency">color vision deficiencies</Link>.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/hue'> Hue </Link></li>
          <li><Link to='/chroma'> Chroma </Link></li>
          <li><Link to='/lightness'> Lightness </Link></li>
          <li><Link to='/warm-and-cool-colors'> Warm and cool colors </Link></li>
          <li><Link to='/complimentary-colors'> Complimentary colors </Link></li>
          <li><Link to='/color-vision-deficiency'> Color vision deficiency </Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="Measuring color" pages="437" />
        <Citation src="Wikipedia" url="https://en.wikipedia.org/wiki/Color_temperature" title="Color temperature" />

      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default ColorTemperature;