import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';
import Citation from "../components/Citation";
import CitationList from "../components/CitationList";

// import Figure from '../img/Figure_Retina.png';
// import FigureDark from '../img/Figure_Retina_Dark.png';
import PageHeader from '../components/PageHeader';

import Figure from '../img/Figure_retina.png';
import FigureDark from '../img/Figure_retina_dark.png';
import Cones from '../img/Figure_cones.png';
import ConesDark from '../img/Figure_cones_dark.png';
import Rods from '../img/Figure_rods.png';
import RodsDark from '../img/Figure_rods_dark.png';
// import Figure from '../img/Figure_optic-nerve.png';
import Pigment from '../img/Figure_pigment.png';
import PigmentDark from '../img/Figure_pigment_dark.png';
import Fovea from '../img/Figure_fovea.png';
import FoveaDark from '../img/Figure_fovea_dark.png';


function Retina(props) {
  const mq = window.matchMedia('(prefers-color-scheme: dark)');
  const IMG_retina = (mq.matches) ? FigureDark : Figure;
  const IMG_cones = (mq.matches) ? ConesDark : Cones;
  const IMG_rods = (mq.matches) ? RodsDark : Rods;
  const IMG_pigment = (mq.matches) ? PigmentDark : Pigment;
  const IMG_fovea = (mq.matches) ? FoveaDark : Fovea;

  return (
    <motion.div
      style={pageStyle}
      key="trichromacy"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Retina"
        description=""
      />

      <PageHeader
        category="Color vision"
        title="Retina"
        // subtitle="(Anatomy)"
        />

      <Section
        figure={IMG_retina}
        splitView
      >
        <p className="body4">The retina is a thin layer at the backside of the eye comprised of photoreceptors and cells for signal processing.</p>
        
      </Section>

      <Section 
        title="Parts of the retina"
        level="2"
      >
        <Section 
          title="Cones"
          splitView
          figure={IMG_cones}
          level="4"
        >
          <p>Cones have photopigments that are sensitive to <Link to="trichromacy">three distinct wavelengths</Link> of light. The retina is comprised of approximately <strong>7 million cones</strong>. </p>
          <p>When only cones are functioning (sunlight) it is called <em>photopic vision</em>. When both cones and rods are functioning (twilight), it is called <em>mesopic vision</em>. People with <Link to="color-vision-deficiency">color vision deficiencies</Link> may have missing or abnormally functioning cones.</p>
        </Section>

        <Section 
          title="Rods"
          splitView
          figure={IMG_rods}
          level="4"
        >
          <p>Rods are a type of photoreceptor that works at lower levels of light. The retina is comprised of approximately <strong>120 million rods</strong>.</p>
          <p>When only the rods are functioning, it is called <em>scotopic vision</em>. Rod-only vision is <Link to="monochromacy">monochromatic</Link> and can be attributed to <Link to="color-vision-deficiency">color vision deficiencies</Link>.</p>
        </Section>

        <Section 
          title="Fovea"
          splitView
          figure={IMG_fovea}
          level="4"
        >
          <p>The fovea is the area of the retina that has the best <Link to="visual-acuity">visual acuity</Link> and color vision. The fovea has the highest concentration of <strong>cones</strong> and the lowest concentration of <strong>rods</strong>.</p>
          <p>The fovea is protected from intense exposure to short-wavelength energy by a yellow filter called the macula.</p>
        </Section>

        <Section 
          title="Pigmented epithelium"
          splitView
          figure={IMG_pigment}
          level="4"
        >
          <p>The pigmented epithelium is the bottom layer of the retina, and is pigmented in order to absorb any remaining light that was not processed by the cones and rods.</p>
        </Section>

      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/trichromacy'> Trichromacy </Link></li>
          <li><Link to='/color-opponent-process'> Color opponent process </Link></li>
          <li><Link to='/color-vision-deficiency'> Color vision deficiency </Link></li>
          <li><Link to='/anomalous-trichromacy'> Anomalous richromacy </Link></li>
          <li><Link to='/dichromacy'> Dichromacy </Link></li>
          <li><Link to='/monochromacy'> Monochromacy </Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="CAMS" pages="5-13" />
        <Citation src="Measuring color" pages="448" />
        <Citation src="NIH NEI" url="https://www.nei.nih.gov/learn-about-eye-health/healthy-vision/how-eyes-work" title="How eyes work" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default Retina;