
import { useLocation, useParams } from 'react-router-dom';
import './Footer.scss';
import Pagination from './Pagination';

const Footer = () => {
  const page = useLocation().pathname;
  const pagination = (page === '/') ? '' : <Pagination page={page} /> ;
  return (
    <footer className="App-footer">
      {pagination}
      <div className="App-footer-content">
        <p className="App-footer-copyright">Copyright {(new Date().getFullYear())} &nbsp; <a href="https://natebaldw.in" target="_blank" rel="noopener noreferrer">Nate Baldwin</a>.</p>
        <a className="button button--buymecoffee" href="https://www.buymeacoffee.com/natebaldwiw" target="_blank">Buy me a coffee</a>
      </div>
    </footer>
  );
};

export default Footer;