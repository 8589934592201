import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import Figure from '../img/Figure_ColorTheme.png';
// import Example from '../img/Figure_ColorTheme-Example.png';
// import Example2 from '../img/Figure_ColorModel-Example.png';
import PageHeader from '../components/PageHeader';


function ColorTheme(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      key="color-theme"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Color theme"
        description="A collection of colors applied to a design system. Color themes can be used to support light mode, dark mode, increase contrast mode, or different brands."
      />

      <PageHeader
        category="Colors in use"
        title="Color theme"
        // subtitle=""
        />

      <Section
        figure={Figure}
        // alt="" 
        caption="Examples of color themes orange (light), orange (dark), blue (light), and blue (dark)."
      >
        {/* <p className="body4">A color space is the total collection of possible colors within a particular set of primaries. Color spaces are specific implimentations of a <Link to="/color/color-model">color model</Link>.</p> */}
        <p className="body4">A collection of <Link to="color-scale">color scales</Link> applied to an user interface design system.</p>
        <p className="body4">Color themes can be used to support <Link to="light-mode">light mode</Link>, <Link to="dark-mode">dark mode</Link>, <Link to="increase-contrast-mode">increase contrast mode</Link>, or different brands.</p>
        
      </Section>


      <Section title="How it affects UI design" level="4" className="elements">
        <p>A light theme and dark theme can be helpful for supporting <Link to="light-mode">light</Link> and <Link to="dark-mode">dark</Link> modes using the <a href="https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-color-scheme" target="_blank" rel="noopener noreferrer"><code>prefers-color-scheme</code> media query</a>.</p>
        <p>A unique theme can be used to support <Link to="increase-contrast-mode">increase contrast mode</Link> using the <a href="https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-contrast" target="_blank" rel="noopener noreferrer"><code>prefers-contrast</code> media query</a>.</p>
        <p><strong>Do not create custom themes</strong> for windows <Link to="high-contrast-mode">hight contrast mode</Link>. This mode is intended to provide end-user control over color.</p>
        <p>Not all people have the same contrast sensitivity. WCAG <a href="https://www.w3.org/TR/WCAG21/#contrast-minimum" target="_blank" rel="noopener noreferrer">1.4.3 (contrast minimum)</a> provides a general threshold for making text &amp; components percievable to most sighted users.</p>
        <p>In some cases, users may benefit from higher contrast than recommended minimums, whereas other users <strong>may be negatively impacted by high contrast interfaces</strong>.  Consider personalization settings to give users the ability to adjust contrast to meet their needs.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to="light-sensitivity">Light sensitivity</Link></li>
          <li><Link to="light-and-dark-adaptation">Light &amp; dark adaptation</Link></li>
          <li><Link to="color-vision-deficiency">Color vision deficiency</Link></li>
          <li><Link to="bezold-effect">Bezold effect</Link></li>
          <li><Link to="chromatic-adaptation">Chromatic adaptation</Link></li>
          <li><Link to="helmholtz-kohlrausch-effect">Helmholtz–Kohlrausch effect</Link></li>
          <li><Link to="color-constancy">Color constancy</Link></li>
          <li><Link to="contrast-sensitivity">Contrast sensitivity</Link></li>
          <li><Link to="contrast-discrimination">Contrast discrimination</Link></li>
          <li><Link to="simultaneous-contrast">Simultaneous contrast</Link></li>
          <li><Link to="/polygonal-colors">Polygonal colors</Link></li>
          <li><Link to="relative-luminance">Relative luminance</Link></li>
          <li><Link to="apca">APCA</Link></li>
          <li><Link to="light-mode">Light mode</Link></li>
          <li><Link to="dark-mode">Dark mode</Link></li>
          <li><Link to="increase-contrast-mode">Increase contrast mode</Link></li>
          <li><Link to="high-contrast-mode">High contrast mode</Link></li>
          <li><Link to="color-scale">Color scale</Link></li>
        </ul>
      </Section>

      <Footer />

    </motion.div>
  )
}
 
export default ColorTheme;