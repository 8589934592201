import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import Figure from '../img/Figure_LightMode.png';
// import Example from '../img/Figure_LightMode-Example.png';
// import Example2 from '../img/Figure_ColorModel-Example.png';
import PageHeader from '../components/PageHeader';
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";


function LightMode(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      key="light-mode"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Light mode"
        description="Light mode is an device-specific color mode. It effects the colors of interfaces at an operating system level and displays whites and light grays for most applications."
      />

      <PageHeader
        category="Color for design systems"
        title="Light mode"
        // subtitle=""
        />

      <Section
        figure={Figure}
        alt="Gradient of color from yellow to orange to dark purple"       
      >
        {/* <p className="body4">A color space is the total collection of possible colors within a particular set of primaries. Color spaces are specific implimentations of a <Link to="/color/color-model">color model</Link>.</p> */}
        <p className="body4">Light mode is an device-specific color mode. It effects the colors of interfaces at an operating system level and displays whites and light grays for most applications.</p>

      </Section>


      <Section title="How it affects UI design" level="4" className="elements">
        <p>In web applications, light mode can be given unique styling using the <a href="https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-color-scheme" target="_blank" rel="noopener noreferrer"><code>prefers-color-scheme</code> media query</a>.</p>
        <p>Light mode can cause adverse effects for certain users (such as eye strain or migraines) when <Link to='/contrast-sensitivity'>contrast is too high</Link> or if they have <Link to="light-sensitivity">light sensitivity</Link>.</p>
        <p>Colors on a light background may <Link to='/bezold-effect'>appear differently</Link> than when they are placed on a light background.</p>
        <p>The lightness or contrast of a colors on a light background may <Link to='/lateral-inhibition'>appear differently</Link> than when they are placed on a light background.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/contrast-sensitivity'> Contrast sensitivity </Link></li>
          <li><Link to='/light-and-dark-adaptation'> Light and dark adaptation </Link></li>
          <li><Link to='/dark-mode'> Dark mode </Link></li>
          <li><Link to='/color-theme'> Color theme </Link></li>
          <li><Link to='/bezold-effect'>Bezold effect</Link></li>
          <li><Link to='/lateral-inhibition'>Lateral inhibition</Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation url="https://support.apple.com/guide/mac-help/use-a-light-or-dark-appearance-mchl52e1c2d2/mac" publisher="Apple Support" title="Using a light or dark appearance on your mac"/>
        <Citation url="https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-color-scheme" publisher="Mozilla" title="prefers-color-scheme" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default LightMode;