import React from "react"
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';
import getColorDifference from '../utils/getColorDifference';

import PageHeader from '../components/PageHeader';

import { Link } from "react-router-dom";
import '../components/ColorPicker.scss';
// import Meter from "../components/Meter";
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";
// import { Table, TableRow } from "../components/Table";

const Round = (value) => {
  return Math.round( value * 100 ) / 100;
}


class DeltaEPicker extends React.Component {
  constructor(props) {
    super(props)
    // this.id = props.id;
    this.defaultColor1 = '#f26322'
    this.defaultColor2 = '#119ede';

    this.color1 = this.defaultColor1;
    this.color2 = this.defaultColor2;

    this.difference = 52.63

    this.update = () => {
      const diff = getColorDifference(this.color1, this.color2);
      const rounded = Round(diff)
      document.getElementById('differenceReport').innerHTML = rounded;
      this.difference = rounded;
      // document.getElementById('deltaEmeter').innerHTML = <Meter />
    }
  }

  render() {
    return (
      <div>
        <div>
          <span className="body4">Delta-E: <span id='differenceReport' style={{fontWeight: 'Bold'}}>{this.difference}</span></span>
          {/* <div id="deltaEmeter"></div> */}
          {/* <Meter 
            key={this.difference} 
            value={this.difference} 
            min={0}
            max={100}
            minSafe={11}
            minWarn={8}
            /> */}
        </div>
        <div className="ColorPicker colorPicker--row">
          <div className="ColorPicker_swatch " id="colorDiff1swatch" style={{backgroundColor: this.defaultColor1}}>
            <input type="color" id="color1" defaultValue={this.defaultColor1} onInput={ (e) => {document.getElementById('colorDiff1swatch').style.backgroundColor = e.target.value; this.color1 = e.target.value; this.update()}}></input>
          </div>
          <div className="ColorPicker_swatch " id="colorDiff2swatch" style={{backgroundColor: this.defaultColor2}}>
            <input type="color" id="color2" defaultValue={this.defaultColor2} onInput={ (e) => {document.getElementById('colorDiff2swatch').style.backgroundColor = e.target.value; this.color2 = e.target.value; this.update()}}></input>
          </div>
        </div>
      </div>
    )
  }
}

function ColorDifference(props) {
  const Playground = <DeltaEPicker/>;

  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      key="color-difference"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Color difference"
        description="(Delta E) The scientific measurement of percieved difference or distance between two colors."
      />

      <PageHeader
        category="Measurement &amp; formulas"
        title="Color difference"
        subtitle="(Delta E)"
        />

      <Section
        splitView
        // className="smallSplitView"
        playground={Playground}
      >
        <p className="body4">The scientific measurement of percieved difference or distance between two colors.</p>
        <p className="body4">The latest color difference formula is Delta E 2000. The minimum value of 1 stands for a "just noticeable difference" between two colors.</p>

      </Section>

      <Section title="How it affects UI design" level="4" className="elements">
        <p>Color difference can be used to measure the difference of colors for a variety of sighted users, however it is not a substitute for <Link to="apca"> contrast evaluation</Link>. </p>
        <p>Color vision deficiency "safe" colors must pass a certain threshold of color difference for their simulated counterparts.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/color-contrast'> Color contrast </Link></li>
          <li><Link to='/color-vision-deficiency'> Color vision deficiency </Link></li>
          <li><Link to='/cielab'> CIELAB </Link></li>
        </ul>
      </Section>


      <CitationList>
        <Citation src="CAMS" pages="414-415"/>
        <Citation src="Reproduction of color" pages="110, 112, 670-671" />
        <Citation src="Measuring color" pages="61-62" />
        <Citation src="Wikipedia" url="https://en.wikipedia.org/wiki/Color_difference" title="Color difference" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default ColorDifference;