import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import Figure from '../img/Figure_RYB.png';
import PageHeader from '../components/PageHeader';
// import ColorPicker from "../components/ColorPicker";
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";

function RYBModel(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      key="ryb-model"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="RYB model"
        description="A color model defined by three primaries; red, yellow, and blue. The RYB model is based on subtractive color mixing."
      />

      <PageHeader
        category="Color models"
        title="RYB model"
        // subtitle=""
        />

      <Section
        splitView
        figure={Figure}
      >
        <p className="body4">A <Link to="/color-model">color model</Link> defined by three primaries; red, yellow, and blue. The RYB model is based on <Link to="/subtractive">subtractive color mixing</Link>.</p>
      </Section>


      <Section title="How it affects UI design" level="4" className="elements">
        <p>Digital displays do not use RYB or <Link to="subtractive">subtractive color mixing</Link>.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to="color-model">Color model</Link></li>
          <li><Link to="subtractive">Subtractive color mixing</Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="Wikipedia" url="https://en.wikipedia.org/wiki/Color_model" title="Color model"/>
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default RYBModel;