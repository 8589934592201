import React from "react"
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import PageHeader from '../components/PageHeader';

import { Link } from "react-router-dom";
import './SimultaneousContrast.scss';
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";

class SimultaneousContrastSlider extends React.Component {
  constructor(props) {
    super(props)
    this.id = props.id; // Must be either "Hue", "Lightness", or "Saturation"
    let acceptedIds = ['Hue', 'Lightness', 'Saturation']
    if(!acceptedIds.includes(this.id)) console.warn('Id must be either Hue, Saturation, or Lightness')

    this.colors = (this.props.colors) ? this.props.colors : ['red', 'blue', 'yellow', 'orange'];
    this.sliderId = `${this.id}-ImageSlider-control`;

    this.width = (this.props.width) ? this.props.width : '100%';
    this.height = (this.props.height) ? this.props.height : '170px';
    this.defaultValue = 0;
    this.defaultBlockOffset = 52;
    this.maxValue = (366 / 2) - 62; // 40 = half of gray rectangle width

    document.documentElement.style
      .setProperty(`--Sim${this.id}Position`, `${this.defaultBlockOffset}px`);


    this._resetSlider = () => {
      const range = document.getElementById(this.sliderId);
      range.value = this.defaultValue;
      this._update();
    }
    
    this._ease = (x) => {
      return x;
    }
    this._update = (e) => {
      let val = (!e) ? this.defaultBlockOffset : `calc(${e.target.value}% - ${(+e.target.value/100 * 80)}px)`;

      document.documentElement.style
        .setProperty(`--Sim${this.id}Position`, `${val}`)
    }

  }

  render() {
    return (
      <div className="ImageSlider">
        <div className="ImageSlider-container" style={{height: this.height, flexDirection: 'row'}}>
          <div className="SimultaneousContrast_wrapper" style={{backgroundColor: this.colors[0]}}>
            <div className={`SimultaneousContrastBlock Sim${this.id}Position--left`} style={{backgroundColor: this.colors[2]}}></div>
          </div>
          <div className="SimultaneousContrast_wrapper" style={{backgroundColor: this.colors[1]}}>
            <div className={`SimultaneousContrastBlock Sim${this.id}Position--right`} style={{backgroundColor: this.colors[3]}}></div>
          </div>
        </div>
        <div className="ImageSlider-labels" style={{width: this.width}}>
          <label>{this.props.labelLeft}</label>
          <label>{this.props.labelRight}</label>
        </div>
        <div className="ImageSlider-controls">
          <input 
            id={this.sliderId} 
            className="ImageSlider-control"
            type="range" 
            min="50" 
            max="100" 
            defaultValue={ this.defaultValue }
            step="0.5"
            style={{width: this.width}}
            onInput={(e) => {this._update(e)}} 
          /> 
          {/* <button className="button button--reset" onClick={() => this._resetSlider()}>Reset</button> */}
        </div>
      </div>
    )
  }
}


function SimultaneousContrast(props) {
  const SliderLightness = <SimultaneousContrastSlider 
    id="Lightness" 
    colors={['#FF8D1A', '#A85500', '#DB6E00', '#e27a09']} 
    labelLeft="Separate" 
    labelRight="Together" 
  />

  const SliderHue = <SimultaneousContrastSlider 
    id="Hue" 
    colors={['#226f66', '#c1004a', '#f09000', '#f67a02']} 
    labelLeft="Separate" 
    labelRight="Together" 
  />
  const SliderSaturation = <SimultaneousContrastSlider 
    id="Saturation" 
    colors={['#7a5c3d', '#ff8000', '#d47f2a', '#d87614']} 
    labelLeft="Separate" 
    labelRight="Together" 
  />

  return (
    <motion.div
      style={pageStyle}
      key="simultaneous-contrast"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Simultaneous contrast"
        description="Simultaneous contrast is an effect where the Color perception of a color's hue, saturation, or lightness is affected by adjacent colors."
      />

      <PageHeader
        category="Contrast perception"
        title="Simultaneous contrast"
        // subtitle="(White's illusion)"
        />
        
      <Section>
        <p className="body4">Simultaneous contrast is an illusion where the appearance of a color's <Link to="hue">hue</Link>, <Link to="chroma">saturation (chroma)</Link>, or <Link to="lightness">lightness</Link> are affected by adjacent colors.</p>
        <p className="body4">These shifts in appearance follow the dimensons of the <Link to="color-opponent-process">opponent process</Link> channels.</p>
      </Section>

      <Section title="Examples" level="2">
        <Section level="4" splitView 
          title="Simultaneous lightness contrast"
          playground={SliderLightness}
          alt="alt text"
          // caption="What you see here."
        >
          <p>The perceived <Link to="lightness">lightness</Link> of a color is effected by adjacent colors.</p>
          <p>In this example, both internal squares appear to be the same <strong>lightness</strong>. Move the slider to bring them together and reveal if that is true.</p>
        </Section>

        <Section level="4" splitView 
          title="Simultaneous hue contrast"
          playground={SliderHue}
          alt="alt text"
          // caption="What you see here."
        >
          <p>The perceived <Link to="hue">hue</Link> of a color is effected by adjacent colors.</p>
          <p>In this example, both internal squares appear to be the same <strong>hue</strong>. Move the slider to bring them together and reveal if that is true.</p>
        </Section>

        <Section level="4" splitView 
          title="Simultaneous saturation contrast"
          playground={SliderSaturation}
          alt="alt text"
          // caption="What you see here."
        >
          <p>The perceived <Link to="chroma">saturation</Link> of a color is effected by adjacent colors.</p>
          <p>In this example, both internal squares appear to be the same <strong>saturation</strong>. Move the slider to bring them together and reveal if that is true.</p>
        </Section>

      </Section>

      <Section title="How it affects UI design" level="4" className="elements">
        {/* <p>WCAG <a href="https://www.w3.org/TR/WCAG21/#contrast-minimum" target="_blank" rel="noopener noreferrer">1.4.3 (contrast minimum)</a> provides a general threshold for making text &amp; components percievable to most sighted users. </p> */}
        <p>Chromatic background colors will affect the appearance of colors in your design.</p>
        <p>Sometimes a color can be changed simply by changing its <Link to="color-theme">surrounding color</Link>. </p>
        <p>Users with <Link to="/color-vision-deficiency">color vision deficiencies</Link> may not experience these simultaneous contrast, or will not experience it for certain colors. Do not rely on simultaneous contrast as a mechanism for distinguishing UI elements.</p>
        <p>Users with a low <Link to="/contrast-sensitivity">contrast sensitivity</Link> may not experience these simultaneous contrast, or will not experience it for certain colors.</p>
        <p>The <Link to="chroma">chroma</Link> of a color can be perceived differently between light and dark modes as a result of the <Link to="/bezold-effect">Bezold effect</Link>.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to="contrast-sensitivity">Contrast sensitivity</Link></li>
          <li><Link to="color-vision-deficiency">Color vision deficiency</Link></li>
          <li><Link to="contrast-sensitivity">Contrast sensitivity</Link></li>
          <li><Link to="color-difference">Color difference</Link></li>
          <li><Link to="relative-luminance">Relative luminance</Link></li>
          <li><Link to="apca">APCA</Link></li>
          <li><Link to="/bezold-effect">Bezold effect</Link></li>
          <li><Link to="helmholtz-kohlrausch-effect">Helmholtz–Kohlrausch effect</Link></li>
          <li><Link to="/color-theme">Color theme</Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="CAMS" pages="116-118"/>
        <Citation src="Psychophysical" pages="175"/>
        <Citation src="Understanding Color" pages="96-98" />
        <Citation src="Launching the Imagination" pages="383" />
      </CitationList>


      <Footer />

    </motion.div>
  )
}
 
export default SimultaneousContrast;