import React from "react"
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';
import { Link } from "react-router-dom";

import Figure from '../img/Figure_ColorAppearanceModels.png';
// import ColorAppearanceModelsModel from '../img/Figure_ColorAppearanceModels_model.png';
import PageHeader from '../components/PageHeader';
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";

function ColorAppearanceModels(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      exit="out"
      key="color-appearance-models"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Color appearance model"
        description="A color model based on the perceptual aspects of color vision. When implemented under fixed viewing conditions, a color appearnce model becomes a uniform color space."
      />

      <PageHeader
        category="Color models"
        title="Color appearance model"
        subtitle="(Uniform color space)"
        />

      <Section
        splitView
        figure={Figure}
      >
        <p className="body4">A dynamic <Link to="color-space">color space</Link> that accounts for perceptual effects of color vision.</p>
        <p className="body4">When implemented under fixed viewing conditions, a color appearance model becomes a <strong>uniform color space</strong>. </p>
        <p className="body4">Because of their perceptual basis, they are also referred to as <Link to="/color-opponent-process">opponent color</Link> spaces.</p> 

        {/* <p className="body4">A <Link to="/color-space">color model</Link> based on the <strong>perceptual aspects of color vision</strong> including <Link to="hue">hue</Link>, <Link to="chroma">chroma</Link> and <Link to="lightness">lightness</Link>, along with <Link to="chromatic-adaptation">chromatic adaptation</Link> transformations and other <Link to="luminance">luminance</Link> effects such as <Link to="stevens-power-law">Stevens' law</Link> or the <Link to="hunt-effect">Hunt effect</Link>.</p>
        <p className="body4">When implemented under fixed viewing conditions, a color appearance model becomes a <strong>uniform color space</strong>. Cartesian models are also referred to as <Link to="/color-opponent-process">opponent color</Link> spaces.</p> */}
      </Section>

      <Section title="Implementations &amp; key differentiators" level="3">
        <p>All implementations share the same L* value for <Link to="lightness">lightness</Link>. This means the key differentiator between color appearance models relates to <Link to="chromaticity">chromaticity</Link>.</p>
        {/* Opponent color spaces and their cylindrical transformations vary in how color transitions from one to another in <Link to="color-scale">color scales</Link>. */}
        <h5>Implementations:</h5>
        <p>
          <ul>
            <li><Link to="cielab">CIELAB</Link></li>
            <li><Link to="cieluv">CIELUV </Link></li>
            <li><Link to="cam02">CAM02</Link></li>
            <li><Link to="cam16">CAM16</Link></li>
            <li><Link to="oklab">OKLAB</Link></li>
          </ul>
        </p>
      </Section>

      {/* <Section title="Opponent color spaces">
        <p>Opponent color spaces are a uniform <Link to="/color/color-space">color space</Link> defined by three channels: <strong>luminance</strong>, <strong>red-green</strong>, and <strong>blue-yellow</strong>. They are based on the <Link to='/color-opponent-process'>color opponent process</Link> of color vision </p>
        <Section level="4" splitView 
          title="LAB (CIELAB)"
          playground={<ColorPicker mode="LAB" />}
          >
          <p>The LAB color space was created in 1976 by the International Commission on Illumination.</p>
          <p>CSS annotation:</p>
          <ul>
            <li> <code>lab(40% 81 20)</code> </li>
            <li> <code>lab(40% 81 20 / 1)</code> with transparency</li>
          </ul>
        </Section>

        <Section level="4" splitView 
          title="OKLAB"
          playground={<ColorPicker mode="OKLAB" />}
          >
          <p>OKLAB is the most modern color space, based on CAM16 and TCI color spaces.</p>
          <p>CSS annotation:</p>
          <ul>
            <li> <code>oklab(40% 81 20)</code> </li>
            <li> <code>oklab(40% 81 20 / 1)</code> with transparency</li>
          </ul>
        </Section>
      </Section>

      <Section title="Cylindrical transformations">
        <p>Cylindrical transformations provide a more human-centered approach to defining color, defined by three channels: <strong>luminance</strong>, <strong>chroma</strong>, and <strong>hue</strong>.</p>
        <Section level="4" splitView 
          title="LCH (CIELCH)"
          playground={<ColorPicker mode="LCH" />}
          >
          <p>LCH is a cylindrical transformation of LAB color space. Properties are Lightness, Chroma, and Hue.</p>
          <p>CSS annotation:</p>
          <ul>
            <li> <code>lch(40% 81 350)</code> </li>
            <li> <code>lch(40% 81 350 / 1)</code> with transparency</li>
          </ul>
        </Section>

        <Section level="4" splitView 
          title="OKLCH"
          playground={<ColorPicker mode="OKLCH" />}
          >
          <p>OKLCH is a cylindrical transformation of LAB color space. Properties are Lightness, Chroma, and Hue.</p>
          <p>CSS annotation:</p>
          <ul>
            <li> <code>oklch(40% 81 350)</code> </li>
            <li> <code>oklch(40% 81 350 / 1)</code> with transparency</li>
          </ul>
        </Section>
      </Section> */}


      <Section title="How it affects UI design" level="4" className="elements">
        <p>Color appearance models are <strong>better color spaces</strong> for evaluating color and creating <Link to="color-scale">color scales</Link>.</p>
        <p>Most uses of color in user interfaces are not affected by color appearance models. You do not need to rely on these color spaces to create aesthetically appealing and accessible colors.</p>
        {/* <p>CIELAB and subsequent color appearance models are based on reflected light. Devices emit light to create color, and may behave differently than the values of these models may suggest. Additional perceptual considerations should be made.</p> */}
        <p>Uniform color spaces are linear models of human vision. However, human perception is non-linear. Do not rely on these color spaces alone to create aesthetically balanced colors. </p>
        <p>Browser support is still growing for LAB, OKLAB, LCH and OKLCH color spaces. Check <a href="https://www.w3.org/TR/css-color-4/" target="_blank" rel="noopener noreferrer">CSS Module level 4</a> for more detail.</p>
        {/* <p><Link to="/color/color-scales">Color scales</Link> can be created with color appearance models in various color tools, such as <a href="https://leonardocolor.io/scales.html" target="_blank" rel="noopener noreferrer">Leonardo</a>.</p> */}
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to="color-model">Color model</Link></li>
          <li><Link to="color-space">Color space</Link></li>
          <li><Link to="color-opponent-process">Color opponent process</Link></li>
          <li><Link to="hue">Hue</Link></li>
          <li><Link to="chroma">Chroma</Link></li>
          <li><Link to="lightness">Lightness</Link></li>
          <li><Link to="chromaticity">Chromaticity</Link></li>
          <li><Link to="luminance">Luminance</Link></li>
          <li><Link to="stevens-power-law">Stevens' power law</Link></li>
          <li><Link to="hunt-effect">Hunt effect</Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="CAMS" pages="199-200" />
        <Citation src="Reproduction of color" pages="116, 587-636" />
        <Citation src="Colorimetry" pages="446" />
        <Citation src="Measuring color" pages="129, 293" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default ColorAppearanceModels;