import {
  useState
} from 'react';
import {useTheme} from 'css-vars-hook';
// import * as Leo from '@adobe/leonardo-contrast-colors';
import React from 'react';
import { AnimatePresence } from "framer-motion";

import ScrollToTop from './components/ScrollToTop';
import Navigation from './components/Navigation';
// import Accessibility from './svg/Accessibility';
// import Popover from './components/Popover';
import Hamburger from './svg/Hamburger';

import "animate.css";
import './Common.scss';
import './App.scss';
import { 
  Link,
  Route, 
  Switch,
  useLocation
} from 'react-router-dom'; 

import Home from './Home.js'; 
import HelmholtzKohlrausch from './pages/HelmholtzKohlrausch';
import RGBModel from './pages/RGBModel';
import ColorSpace from './pages/ColorSpace';
import ColorModel from './pages/ColorModel';
import Trichromacy from './pages/Trichromacy';
import ColorOpponentProcess from './pages/ColorOpponentProcess';
import ColorVisionDeficiency from './pages/ColorVisionDeficiency';
import ColorAppearanceModels from './pages/ColorAppearanceModels';
import SimultaneousContrast from './pages/SimultaneousContrast';
import ContrastSensitivity from './pages/ContrastSensitivity';
import ColorConstancy from './pages/ColorConstancy';
import BezoldEffect from './pages/BezoldEffect';
import BezoldBruckeShift from './pages/BezoldBruckeShift';
import StevensPowerLaw from './pages/StevensPowerLaw';
import CrawfordStilesEffect from './pages/CrawfordStilesEffect';
import Chromostereopsis from './pages/Chromostereopsis';
import ChromaticAberration from './pages/ChromaticAberration';
import LightDarkAdaptation from './pages/LightDarkAdaptation';
import LateralInhibition from './pages/LateralInhibition';
import RelativeLuminance from './pages/RelativeLuminance';
import AdvancedPerceptualContrast from './pages/AdvancedPerceptualContrast';
import ColorDifference from './pages/ColorDifference';
import AnomalousTrichromacy from './pages/AnomalousTrichromacy';
import Dichromacy from './pages/Dichromacy';
import Monochromacy from './pages/Monochromacy';
import LightSensitivity from './pages/LightSensitivity';
import ColorTemperature from './pages/ColorTemperature';
import ColorProperties from './pages/ColorProperties';
import AnalogousColors from './pages/AnalogousColors';
import Tint from './pages/Tint';
import Shade from './pages/Shade';
import Tone from './pages/Tone';
import ColorScale from './pages/ColorScale';
import ComplimentaryColors from './pages/ComplimentaryColors';
import MonochromaticColors from './pages/MonochromaticColors';
import SubtractiveColorMixing from './pages/SubtractiveColorMixing';
import AdditiveColorMixing from './pages/AdditiveColorMixing';
import Lightness from './pages/Lightness';
import Chroma from './pages/Chroma';
import Hue from './pages/Hue';
import Opacity from './pages/Opacity';
import WarmAndCoolColors from './pages/WarmAndCoolColors';
import RYBModel from './pages/RYBModel';
import CMYKModel from './pages/CMYKModel';
import LightMode from './pages/LightMode';
import DarkMode from './pages/DarkMode';
import HighContrastMode from './pages/HighContrastMode';
import IncreaseContrastMode from './pages/IncreaseContrastMode';
import ChromaticAdaptation from './pages/ChromaticAdaptation';
import Chromaticity from './pages/Chromaticity';
import UsingTheGuide from './pages/UsingTheGuide';
import SplitComplimentaryColors from './pages/SplitComplimentaryColors';
import TriadicColors from './pages/TriadicColors';
import TetradicColors from './pages/TetradicColors';
import PolygonalColors from './pages/PolygonalColors';
import sRGB from './pages/sRGB';
import DisplayP3 from './pages/DisplayP3';
import LAB from './pages/CIELAB';
import OKLAB from './pages/OKLAB';
import CIELUV from './pages/CIELUV';
import CAM02 from './pages/CAM02';
import AbneyEffect from './pages/AbneyEffect';
import ContrastDiscrimination from './pages/ContrastDiscrimination';
import SuccessiveContrast from './pages/SuccessiveContrast';
import ColorTheme from './pages/ColorTheme';
import AdaptiveColorTheme from './pages/AdaptiveColorTheme';
import ColorInterpolation from './pages/ColorInterpolation';
import TutorialChoosingColor from './pages/TutorialChoosingColor';
import TutorialChoosingPalette from './pages/TutorialChoosingPalette';
import TutorialCreatingColorScale from './pages/TutorialCreatingColorScale';
import TutorialCreatingTheme from './pages/TutorialCreatingTheme';
import TutorialPersonalization from './pages/TutorialPersonalization';
import TutorialChoosingColorSpace from './pages/TutorialChoosingColorSpace';
import SpatialColorMixing from './pages/SpatialColorMixing';
import Gamut from './pages/Gamut';
import Clamping from './pages/Clamping';
import ColorProfile from './pages/ColorProfile';
import HuntEffect from './pages/HuntEffect';
import Luminance from './pages/Luminance';
import PurkinjeEffect from './pages/PurkinjeEffect';
import References from './pages/References';
import ColorMeaning from "./pages/ColorMeaning";
import SpectralColors from './pages/SpectralColors';
import NonSpectralColors from './pages/NonSpectralColors';
import VisualAcuity from './pages/VisualAcuity';
import Myopia from './pages/Myopia';
import Hyperopia from './pages/Hyperopia';
import ContrastAdaptation from './pages/ContrastAdaptation';
import CAM16 from './pages/CAM16';
// import IPT from './pages/IPT';
import XYZ from './pages/XYZ';
import LMS from './pages/LMS';
import ColorConfusionLines from './pages/ColorConfusionLines';
// import VonKries from './pages/VonKries';
// import VisualSystem from './pages/VisualSystem';
import Astigmatism from './pages/Astigmatism';
import Cornea from './pages/Cornea';
import Pupil from './pages/Pupil';
import Retina from './pages/Retina';
import Lens from './pages/Lens';
import Presbyopia from './pages/Presbyopia';
import Cataracts from './pages/Cataracts';
import './components/Button.scss';
import OpticalGlare from './pages/OpticalGlare';
import DynamicRange from './pages/DynamicRange';
import UIColorScale from './pages/UIColorScale';
import SequentialColorScale from './pages/SequentialColorScale';
import DivergingColorScale from './pages/DivergingColorScale';
import CategoricalColorScale from './pages/CategoricalColorScale';
import AdsComponent from './components/AdsComponent';
import ContrastPolarity from './pages/ContrastPolarity';

// import HelsonJudd from './pages/HelsonJudd';


function App(props) {
  // INSTALL PWA WORKFLOW
  // Initialize deferredPrompt for use later to show browser install prompt.
  // let deferredPrompt;

  // window.addEventListener('beforeinstallprompt', (e) => {
  //   // Prevent the mini-infobar from appearing on mobile
  //   e.preventDefault();
  //   // Stash the event so it can be triggered later.
  //   deferredPrompt = e;

  //   console.log(`'beforeinstallprompt' event was fired.`);
  // });

  // const colorSwatches = props.adaptiveTheme.contrastColors;
  const location = useLocation();

  // const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const mq = window.matchMedia('(prefers-color-scheme: dark)');

  // const popoverDisplay = (isPopoverOpen) ? 'block' : 'none';

  const [setLightness] = useState((mq.matches) ? 8 : 100);
  // const [contrast, setContrast] = useState(1);
  // const [saturation, setSaturation] = useState(100);
  // const [sliderMin, setSliderMin] = useState((mq.matches) ? 0 : 80);
  // const [sliderMax, setSliderMax] = useState((mq.matches) ? 30 : 100);


  mq.addEventListener('change', function (evt) {
    props.adaptiveTheme.lightness = ((mq.matches) ? 8 : 100)
    // setLightness((mq.matches) ? 8 : 100)
    // setSliderMin((mq.matches) ? 0 : 80);
    // setSliderMax((mq.matches) ? 30 : 100);
  });

  const _createThemeObject = () => {
    let themeObj = {}
    props.adaptiveTheme.contrastColors.forEach(color => {      
      if(color.name) {
        let values = color.values;
        values.forEach(instance => {
          let name = instance.name;
          let val = instance.value;
          themeObj[name] = val;
        });
      } else {
        // must be the background
        let name = 'background'
        let val = color.background;
        themeObj[name] = val;
      }
    })
    return themeObj;
  };

  const theme = useState( _createThemeObject() );

  const {setRef, setVariable} = useTheme(theme);

  function _updateColorVariables() {
    // console.log("Updating color variables");

    let themeInstance = _createThemeObject();  
    // console.log(props.adaptiveTheme) 
    // console.log(themeInstance) 
    for (const [key, value] of Object.entries( themeInstance )) {
      setVariable(key, value);
    }
  };

  props.adaptiveTheme.lightness = ((mq.matches) ? 8 : 100)

  _updateColorVariables();

  return (
      <div 
        className="App" 
        ref={setRef}
        >
        <div className="App-header">
          {/* <AnimatePresence initial={false}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            > */}
              <div className="App-header-content">
                <button 
                  type="button"
                  onClick={ e => {setIsMenuOpen(true)}}
                  className="menuButton">
                  <Hamburger 
                    width='24px' 
                    height='24px'/>
                </button>
                

                <Navigation visible={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>

                <Link to="/" className="logo" tabIndex={1} >
                  {/* <span className="logoTop">Understanding</span> */}
                  <span className="logoLarge">Color &amp; <br/>Contrast</span>
                  <span className="dotCom">.com</span>
                  {/* <span className="logoTop">The</span>
                  <span className="logoLarge">Color</span> */}
                  {/* <span className="logoBottom">A Guide for UI Designers</span> */}
                </Link>

                <div style={{ width: '0px', display: 'block'}}></div>
                <div style={{ width: '44px', display: 'block'}}></div>
              </div>
            {/* </motion.div>
          </AnimatePresence> */}
        </div>
        
        <div className="App-content">
          <AnimatePresence exitBeforeEnter initial={false}> 
          <ScrollToTop />
          <Switch location={location} key={location.pathname}> 
            <Route exact path="/" component={Home} />
            <Route path="/introduction" component={UsingTheGuide} />
            <Route path="/choosing-a-color" component={TutorialChoosingColor} />
            <Route path="/choosing-a-palette" component={TutorialChoosingPalette} />
            <Route path="/choosing-a-color-space" component={TutorialChoosingColorSpace} />
            <Route path="/creating-color-scales" component={TutorialCreatingColorScale} />
            <Route path="/creating-a-theme" component={TutorialCreatingTheme} />
            <Route path="/personalization" component={TutorialPersonalization} />
            <Route path="/trichromacy" component={Trichromacy} />
            <Route path="/dichromacy" component={Dichromacy} />
            <Route path="/monochromacy" component={Monochromacy} />
            <Route path="/anomalous-trichromacy" component={AnomalousTrichromacy} />
            <Route path="/trichromacy" component={Trichromacy} />
            <Route path="/trichromacy" component={Trichromacy} />
            <Route path="/color-opponent-process" component={ColorOpponentProcess} />
            <Route path="/color-vision-deficiency" component={ColorVisionDeficiency} />
            <Route path="/contrast-sensitivity" component={ContrastSensitivity} />
            <Route path="/light-sensitivity" component={LightSensitivity} />
            <Route path="/lateral-inhibition" component={LateralInhibition} />
            <Route path="/abney-effect" component={AbneyEffect} />
            <Route path="/spatial-color-mixing" component={SpatialColorMixing} />
            <Route path="/hunt-effect" component={HuntEffect} />
            <Route path="/purkinje-shift" component={PurkinjeEffect} />
            <Route path="/color-properties" component={ColorProperties} />
            <Route path="/hue" component={Hue} />
            <Route path="/chroma" component={Chroma} />
            <Route path="/chromaticity" component={Chromaticity} />
            <Route path="/lightness" component={Lightness} />
            <Route path="/opacity" component={Opacity} />
            <Route path="/tint" component={Tint} />
            <Route path="/shade" component={Shade} />
            <Route path="/tone" component={Tone} />
            <Route path="/analogous-colors" component={AnalogousColors} />
            <Route path="/complimentary-colors" component={ComplimentaryColors} />
            <Route path="/split-complimentary-colors" component={SplitComplimentaryColors} />
            <Route path="/triadic-colors" component={TriadicColors} />
            <Route path="/polygonal-colors" component={PolygonalColors} />
            <Route path="/tetradic-colors" component={TetradicColors} />
            <Route path="/monochromatic-colors" component={MonochromaticColors} />
            <Route path="/light-mode" component={LightMode} />
            <Route path="/dark-mode" component={DarkMode} />
            <Route path="/increase-contrast-mode" component={IncreaseContrastMode} />
            <Route path="/high-contrast-mode" component={HighContrastMode} />
            <Route path="/color-scale" component={ColorScale} />
            <Route path="/color-interpolation" component={ColorInterpolation} />
            <Route path="/color-theme" component={ColorTheme} />
            <Route path="/adaptive-color-theme" component={AdaptiveColorTheme} />
            <Route path="/additive" component={AdditiveColorMixing} />
            <Route path="/subtractive" component={SubtractiveColorMixing} />
            <Route path="/warm-and-cool-colors" component={WarmAndCoolColors} />
            <Route path="/color-space" component={ColorSpace} />
            <Route path="/color-model" component={ColorModel} />
            <Route path="/rgb-model" component={RGBModel} />
            <Route path="/ryb-model" component={RYBModel} />
            <Route path="/cmyk-model" component={CMYKModel} />
            <Route path="/color-appearance-model" component={ColorAppearanceModels} />
            <Route path="/srgb" component={sRGB} />
            <Route path="/display-p3" component={DisplayP3} />
            <Route path="/oklab" component={OKLAB} />
            <Route path="/cielab" component={LAB} />
            <Route path="/cieluv" component={CIELUV} />
            <Route path="/cam02" component={CAM02} />
            <Route path="/gamut" component={Gamut} />
            <Route path="/clamping" component={Clamping} />
            <Route path="/color-profile" component={ColorProfile} />
            <Route path="/color-temperature" component={ColorTemperature} />
            <Route path="/luminance" component={Luminance} />
            <Route path="/chromatic-adaptation" component={ChromaticAdaptation} />
            <Route path="/helmholtz-kohlrausch-effect" component={HelmholtzKohlrausch} />
            <Route path="/contrast-discrimination" component={ContrastDiscrimination} />
            <Route path="/color-constancy" component={ColorConstancy} />
            <Route path="/simultaneous-contrast" component={SimultaneousContrast} />
            <Route path="/successive-contrast" component={SuccessiveContrast} />
            <Route path="/bezold-effect" component={BezoldEffect} />
            <Route path="/bezold-brucke-shift" component={BezoldBruckeShift} />
            <Route path="/stevens-power-law" component={StevensPowerLaw} />
            <Route path="/crawford-stiles-effect" component={CrawfordStilesEffect} />
            <Route path="/chromostereopsis" component={Chromostereopsis} />
            <Route path="/chromatic-aberration" component={ChromaticAberration} />
            <Route path="/chromatic-abberration" component={ChromaticAberration} />

            <Route path="/light-and-dark-adaptation" component={LightDarkAdaptation} />
            <Route path="/lateral-inhibition" component={LateralInhibition} />
            <Route path="/relative-luminance" component={RelativeLuminance} />
            <Route path="/apca" component={AdvancedPerceptualContrast} />
            <Route path="/color-difference" component={ColorDifference} />
            <Route path="/color-psychology" component={ColorMeaning} />
            <Route path='/references' component={References} />
            <Route path='/spectral-colors' component={SpectralColors} />
            <Route path='/non-spectral-colors' component={NonSpectralColors} />
            <Route path='/visual-acuity' component={VisualAcuity} />
            <Route path="/myopia" component={Myopia} />
            <Route path="/hyperopia" component={Hyperopia} />
            <Route path="/contrast-adaptation" component={ContrastAdaptation} />
            <Route path="/cam16" component={CAM16} />
            {/* <Route path="/ipt" component={IPT} /> */}
            <Route path="/lms" component={LMS} />
            <Route path="/xyz" component={XYZ} />
            <Route path="/color-confusion-lines" component={ColorConfusionLines} />
            {/* <Route path="/von-kries" component={VonKries} /> */}
            <Route path="/astigmatism" component={Astigmatism} />
            <Route path='/cornea' component={Cornea} />
            <Route path='/pupil-and-iris' component={Pupil} />
            <Route path='/retina' component={Retina} />
            <Route path='/lens' component={Lens} />
            <Route path='/presbyopia' component={Presbyopia} />
            <Route path='/cataracts' component={Cataracts} />
            <Route path='/optical-glare' component={OpticalGlare} />
            <Route path="/dynamic-range" component={DynamicRange} />
            <Route path="/ui-color-scale" component={UIColorScale} />
            <Route path="/sequential-scale" component={SequentialColorScale} />
            <Route path="/diverging-scale" component={DivergingColorScale} />
            <Route path="/categorical-scale" component={CategoricalColorScale} />
            {/* <Route path='/helson-judd-effect' component={HelsonJudd} /> */}
            <Route path="/contrast-polarity" component={ContrastPolarity} />

          </Switch>
          </AnimatePresence>

        </div>

        {/* <Navigation visible={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/> */}

        <AdsComponent dataAdSlot='X7XXXXXX5X' />

      </div>
  );
}

export default App;
