import React from "react"
import { Link } from "react-router-dom";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

// import Figure from '../img/Figure_RGB.png';
import PageHeader from '../components/PageHeader';
// import ColorPicker from "../components/ColorPicker";
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";
// import { Table, TableRow } from "../components/Table";
import PageMeta from "../components/PageMeta";

function CAM16(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      key="cam16"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="CAM16"
        description="A uniform color space created in 2016 by the International Commission on Illumination (CIE). CAM16 is an enhancement to LAB color space for improved perceptual uniformity. CAM16 is an improved model after its predecessor, CAM02."
      />

      <PageHeader
        category="Color spaces"
        title="CAM16"
        subtitle="(CIECAM16-UCS)"
        />

      <Section>
        <p className="body4">A <Link to="/color-appearance-model">color appearance model</Link> created in 2016 by the International Commission on Illumination (CIE). CAM16 is an improved model after its predecessor, <Link to="cam02">CAM02</Link>.</p>

      </Section>

      {/* <Section title="Examples" level="2">
        <p>No color tool provides CAM16 implementation that I currently am aware of...</p>
      </Section> */}


      {/* <Section title="Analysis of CAM16" level="2" >
        <p>Knowing which <Link to="color-space">color space</Link> to use as a designer can be confusing. This analysis could help you in determining whether or not CIELAB is the right color space to try.</p>

        <h5>Methods and derivation</h5>
        <Table
          header={['Derived from', <Link to="chromatic-adaptation">Chromatic adaptation</Link>, <Link to='lightness'>Lightness</Link>, 'White point']}
        >
          <TableRow>
            <Link to="xyz">XYZ</Link>
            <>TODO</>
            <>TODO</>
            <>TODO</>
          </TableRow>

        </Table>

        <h5>Pro's and con's of CAM16</h5>
        <Table 
          splitView
          header={['Known issues & limitations', 'Benefits & strengths']}
        >
          <TableRow>
            <ul>
              <li>Stuffs</li>
            </ul>
            <ul>
              <li>Device-independant</li>
            </ul>
          </TableRow>
        </Table>

      </Section> */}

      <Section title="How it affects UI design" level="4" className="elements">
        <p>Uniform color spaces are linear models of human vision. However, human perception is non-linear. Do not rely on these color spaces alone to create aesthetically balanced colors. </p>
        <p><Link to="/color-scales">Color scales</Link> can be created by <Link to="color-interpolation">interpolation</Link> in various color spaces.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to="/trichromacy">Trichromacy</Link></li>
          <li><Link to="color-opponent-process">Color opponent process</Link></li>
          <li><Link to="/color-scale">Color scales</Link></li>
          <li><Link to="/color-interpolation">Color interpolation</Link></li>
          <li><Link to="/cam02">CAM02</Link></li>
          <li><Link to="/oklab">OKLAB</Link></li>
        </ul>
      </Section>

      <CitationList>
        {/* <Citation src="CAMS" page="TODO"/> */}
        <Citation url="https://onlinelibrary.wiley.com/doi/10.1002/col.22131" title="Comprehensive color solutions: CAM16, CAT16,and CAM16-UCS" publisher="Wiley" author="Changjun Li, Zhiqiang Li, Zhifeng Wang, Yang Xu, Ming Ronnier Luo, Guihua Cui, Manuel Melgosa, Michael H. Brill, Michael Pointer"/>
        <Citation src="Wikipedia" url="https://en.wikipedia.org/wiki/Color_appearance_model#CAM16" title="Color appearance model (CAM16)" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default CAM16;