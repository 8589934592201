import React from "react"
import PageMeta from "../components/PageMeta";
import chroma from "chroma-js";
import { 
  APCAcontrast, 
  // reverseAPCA, 
  sRGBtoY, 
  // displayP3toY, 
  // adobeRGBtoY, 
  // alphaBlend, 
  // calcAPCA, 
  // fontLookupAPCA 
} from 'apca-w3';
// import { colorParsely } from 'colorparsely';

// 
import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

// import Caption from '../components/Caption';
// import Figure from '../img/Figure_AdvancedPerceptualContrast.png';
import PageHeader from '../components/PageHeader';

import { Link } from "react-router-dom";
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";

const Round = (value) => {
  return Math.round( value * 100 ) / 100;
}

class APCAChecker extends React.Component {
  constructor(props) {
    super(props)
    // this.id = props.id;
    this.defaultcolorContrast1 = '#f26322'
    this.defaultcolorContrast2 = '#59d0ff';

    this.colorContrast1 = this.defaultcolorContrast1;
    this.colorContrast2 = this.defaultcolorContrast2;
    this.diff = APCAcontrast( sRGBtoY( chroma(this.colorContrast1).rgba() ), sRGBtoY( chroma(this.colorContrast2).rgba() ) );
    this.rounded = Round(this.diff);
    const contrast = chroma.contrast(this.defaultcolorContrast1, this.defaultcolorContrast2)
    this.wcagContrast = Round(contrast)


    this.update = () => {
      const color1 = sRGBtoY( chroma(this.colorContrast1).rgba() );
      const color2 = sRGBtoY( chroma(this.colorContrast2).rgba() );
      const diff = APCAcontrast( color1, color2  );
      const rounded = Round(diff);
      document.getElementById('APCAcontrast').innerHTML = rounded;
      const contrast = chroma.contrast(this.colorContrast1, this.colorContrast2);
      this.wcagContrast = Round(contrast)
      document.getElementById('contrastRatio').innerHTML = this.wcagContrast;

    }
  }

  render() {
    return (
      <div>
        <div>
          <span className="body4">Contrast: <span id='APCAcontrast' style={{fontWeight: 'Bold'}}>{this.rounded}</span></span>
          <p className="body5"><Link to="relative-luminance">WCAG 2</Link> equivalent: <span id="contrastRatio">{this.wcagContrast}</span>:1</p>
        </div>
        <div className="ColorPicker colorPicker--row">
          <div className="ColorPicker_swatch" id="colorAPCAContrast1swatch" style={{backgroundColor: this.defaultcolorContrast1}}>
            <input type="color" id="colorContrast1" defaultValue={this.defaultcolorContrast1} onInput={ (e) => {document.getElementById('colorAPCAContrast1swatch').style.backgroundColor = e.target.value; this.colorContrast1 = e.target.value; this.update()}}></input>
          </div>
          <div className="ColorPicker_swatch" id="colorAPCAContrast2swatch" style={{backgroundColor: this.defaultcolorContrast2}}>
            <input type="color" id="colorContrast2" defaultValue={this.defaultcolorContrast2} onInput={ (e) => {document.getElementById('colorAPCAContrast2swatch').style.backgroundColor = e.target.value; this.colorContrast2 = e.target.value; this.update()}}></input>
          </div>
        </div>
      </div>
    )
  }
}


function AdvancedPerceptualContrast(props) {
  // let contrastLc = APCAcontrast( sRGBtoY( chroma('red').rgb() ), sRGBtoY( chroma('blue').rgb() ) );
  // console.log(contrastLc)
  const playground = <APCAChecker />

  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      exit="out"
      key="apca"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="APCA"
        description="(Accessible perceptual contrast algorithm) An algorithm in progress set to replace Web Content Accessibility Guidelines' (WCAG) relative luminance method for contrast calculation."
      />

      <PageHeader
        category="Measurement &amp; formulas"
        title="APCA"
        subtitle="(Accessible perceptual contrast algorithm)"
        />

      <Section
        splitView
        playground={playground}
        // className="smallSplitView"
      >
        <p className="body4">An algorithm in progress set to replace Web Content Accessibility Guidelines' (WCAG) <Link to="relative-luminance">relative luminance</Link> method for contrast calculation.</p>
        <p className="body4">APCA takes into account various aspects of contrast &amp; color perception, such as <Link to="/contrast-sensitivity">spatial frequency</Link>, <Link to="/simultaneous-contrast">simultaneous contrast</Link>, and elements of <Link to="/light-and-dark-adaptation">light &amp; dark adaptation</Link>.</p>
      </Section>

      {/* <Section title="Try it out" level="2">
        <Section level="4"
          splitView
          className="smallSplitView"
          playground={playground}
        >
        <p>Choose two colors to check their APCA contrast.</p>
        <p>More in-depth contrast and color difference reporting can be found at <a href="https://www.myndex.com/APCA/" target="_blank" rel="noopener noreferrer">https://www.myndex.com/APCA/</a>.</p>
        </Section>
      </Section> */}

      <Section title="How it affects UI design" level="4" className="elements">
        <p className="notice"><strong>APCA is not an official standard formula and should not be used in replacement of WCAG <Link to='relative-luminance'>relative luminance</Link></strong> until it has been officially released. However, there is a <a href="https://www.myndex.com/BPCA/" target="_blank" rel="noopener noreferrer">backwards-compatible version available</a> if you want to use APCA while still meeting WCAG 2 criteria.</p>
        <p>Calculated contrast for dark on light color will be different from light on dark color. <Link to="dark-mode">Dark mode</Link> or <Link to="color-theme">themes</Link> should be independently evaluated for contrast and may require modification to pass compliance.</p>
        <p>APCA formula is based on typographic shapes, which exist in a higher <Link to="contrast-sensitivity">spatial frequency</Link>. However, there are <a href="https://www.myndex.com/APCA/" target="_blank" rel="noopener noreferrer">additional criteria</a> regarding which contrast value can be used for various combinations of font weight and size.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/contrast-sensitivity'> Contrast sensitivity </Link></li>
          <li><Link to='/color-difference'> Color difference </Link></li>
          <li><Link to='/simultaneous-contrast'> Simultaneous contrast </Link></li>
          <li><Link to='/contrast-discrimination'> Contrast discrimination </Link></li>
          {/* <li><Link to='/bezold-effect'> Bezold effect </Link></li> */}
          <li><Link to='/stevens-power-law'> Stevens' power law </Link></li>
          <li><Link to='/light-adaptation'> Light adaptation </Link></li>
          <li><Link to='/lateral-inhibition'> Lateral inhibition </Link></li>
          <li><Link to='/color-appearance-models'> Color appearance models </Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="Myndex" url="https://git.myndex.com/" title="Myndex Color &amp; Contrast Resources" />
        <Citation src="Myndex" url="https://www.myndex.com/APCA/" title="APCA contrast calculator"/>
        <Citation src="w3c" url="https://www.w3.org/WAI/GL/task-forces/silver/wiki/Visual_Contrast_of_Text_Subgroup/Whitepaper" title="Visual Contrast of Text Subgroup/Whitepaper" />
        <Citation src="w3c" url="https://www.w3.org/WAI/GL/task-forces/silver/wiki/Visual_Contrast_of_Text_Subgroup" title="Visual Contrast of Text Subgroup"/>

      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default AdvancedPerceptualContrast;