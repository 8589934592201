import React from "react"
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import Figure1 from '../img/Figure_SuccessiveContrast1.png';
import Figure2 from '../img/Figure_SuccessiveContrast2.png';
import PageHeader from '../components/PageHeader';

import { Link } from "react-router-dom";
import ImageSlider from '../components/ImageSlider';
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";

function SuccessiveContrast(props) {
  const DiscContrast = <ImageSlider 
  id="SuccessiveContrast" 
  images={ [Figure1, Figure2] } 
  label="Toggle color" 
  height="184px"
  toggle
  // width='230px'
  />

  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      key="successive-contrast"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Successive contrast"
        description="An illusion where the appearance of a color is affected by previously viewed colors. Successive contrast is a result of the afterimage effect from chromatic adaptation."
      />

      <PageHeader
        category="Contrast perception"
        title="Successive contrast"
        // subtitle="(White's illusion)"
        />
        
      <Section
        splitView
        playground={DiscContrast}
        caption="Stare at the central cross of either the red or blue color, then toggle the color. The resulting orange dots will momentarily appear to be different colors."
        >
        <p className="body4">An illusion where the appearance of a color is affected by previously viewed colors.</p>
        <p className="body4">Successive contrast is a result of the afterimage effect from <Link to="chromatic-adaptation">chromatic adaptation</Link>.</p>
      </Section>

      {/* <Section title="Example" level="2">
        <Section level="4" splitView 
          playground={DiscContrast}
          >
          <p>This illusion is created by staring at the central dot of either the red or blue color. Toggle the illusion to the orange dots, which will momentarily appear to be different colors, however they are the same.</p>
        </Section>
      </Section> */}

      <Section title="How it affects UI design" level="4" className="elements">
        <p>Component state changes or interactions that have large changes to color can create undesirable results from successive contrast. In extreme cases, this could result in legibility issues.</p>
        <p>Experiences that produce dramatic effects from successive contrast can be detrimental to some people, and could cause migraines or other symptoms.</p>
        <p>People with <Link to="color-vision-deficiency">color vision deficiencies</Link> may not experience the same effects from successive contrast.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to="chromatic-adaptation">Chromatic adaptation</Link></li>
          <li><Link to="color-opponent-process">Color opponent process</Link></li>
          <li><Link to="color-vision-deficiency">Color vision deficiency</Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="Psychophysical" pages="261, 286" />
        <Citation src="Wikipedia" url="https://en.wikipedia.org/wiki/Contrast_effect" title="Contrast effect" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default SuccessiveContrast;