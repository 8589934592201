import React from "react"
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

// import Figure from '../img/Figure_UsingTheGuide.png';
// import Example from '../img/Figure_UsingTheGuideCIE.png';
// import Example2 from '../img/Figure_ColorModel-Example.png';
import PageHeader from '../components/PageHeader';
import ImageSlider from "../components/ImageSlider";
import Image1 from '../img/Figure_ContrastConstancy1.png';
import Image2 from '../img/Figure_ContrastConstancy2.png';


function UsingTheGuide(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      key="introduction"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Introduction"
        description="Introduction to the Color &amp; Contrast: who it's for, what makes it unique, and examples of what you'll find in this website."
      />

      <PageHeader
        // category=" "
        title="Introduction"
        // subtitle=""
        />

      <Section title="Author's note" level="2">
        <p className="body4">This website is for designers to learn about color, contrast, and how it can affect experiences of a user interface. It provides quick access to relevant information at any point in the design process.</p>
        <p className="body4">The content is thorough, but concise, and provides contextual insight to assist you in making educated decisions about color and contrast.</p>
      </Section>

      {/* <Section title="Who it's for" level="4">
        <p>This guide is for the curious designer wishing to learn more about color and vision. It is a resource for educated decisions without strenuous research.</p>
      </Section> */}

      <Section title="What makes it unique" level="4">
        <p>This website combines scientific, theoretical, and practical information into a <strong>single location</strong> as <strong>digestible content</strong> with <strong>actionable insight</strong>. Each topic cross-references related topics for <strong>deeper learning</strong> if you want to know more.</p>
      </Section>
      <Section title="How it affects UI design" level="4" className="elements">
        <p>Topics have contextual insights describing how it can affect UI design.</p>
      </Section>
      <Section title="Examples" level="4">
        <p style={{marginBottom: '1.5em', marginTop: '1em'}}>Interactive examples help you to understand each topic.</p>
        <ImageSlider 
          id="ContrastConstancy" 
          images={ [Image1, Image2,] } 
          labelLeft="Illusion" 
          labelRight="Reveal" 
          height="172px"
          width="368px"
          />
      </Section>

      {/* <Section title="Why I created this guide">
        <p>This guide is for the curious designer wishing to learn more about color and vision. It is a resource for educated decisions without strenuous research.</p>
      </Section> */}

      {/* <Section title="How it affects UI design" level="4" className="elements">
        <p>The chroma of a color can be perceived differently between light and dark modes as a result of the <Link to="/color/bezold-effect">Bezold effect</Link>.</p>
        <p>The chroma of a color can be perceived differently when adjacent to other colors of varying chroma due to <Link to="/color/simultaneous-contrast">simultaneous contrast</Link>.</p>
        <p>Lower chroma colors can be more difficult to distinguish for people with <Link to='color-vision-deficiency'>color vision deficiencies</Link>.</p>
      </Section> */}

    {/* TODO: Proofread and ensure message is what it should be
      */}

      <Footer />

    </motion.div>
  )
}
 
export default UsingTheGuide;