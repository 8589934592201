import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import Figure from '../img/Figure_SplitComplimentaryColors.png';
import PageHeader from '../components/PageHeader';
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";
import ToolCitation from "../components/ToolCitation";


function SplitComplimentaryColors(props) {
  
  return (
    <motion.div
      style={pageStyle}
      key="split-complimentary-colors"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Split complimentary colors"
        description="TBD."
      />

      <PageHeader
        category="Color relationships"
        title="Split complimentary colors"
        // subtitle=""
        />

      <Section
        figure={Figure}
        alt="Color wheel with orange, yellow-green, and red circles displayed in close proximity" 
      >
        {/* <p className="body4">A color space is the total collection of possible colors within a particular set of primaries. Color spaces are specific implimentations of a <Link to="/color/color-model">color model</Link>.</p> */}
        <p className="body4">Colors positioned on the color wheel along an isosceles triangle. One of Johannes Itten's "harmonious chords": geometric points demonstrating color "notes" as a basis for composition.</p>
        
      </Section>


      <Section title="How it affects UI design" level="4" className="elements">
        <p>Split complimentary colors can be used as secondary accent colors in a UI color palette for both a dynamic and harmonious appearance.</p>
        <p>A split complimentary palette may be more prone to color conflicts for people who have a <Link to="color-vision-deficiency">color vision deficiency</Link>.</p>
        <p><Link to="hue">Hue</Link> is treated differently between <Link to="color-space">color spaces</Link>, which can effect which colors are considered to be split complimentary.</p>
      </Section>

      <CitationList tools>
        <ToolCitation tool="Adobe color" />
      </CitationList>

      <CitationList>
        <Citation src="Understanding Color" pages="140" />
        <Citation src="Launching the Imagination" pages="63" />
        <Citation src="Classical Painting" pages="81" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default SplitComplimentaryColors;