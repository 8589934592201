import React from "react"
// import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";
import Citation from "../components/Citation";
import CitationList from "../components/CitationList";

import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

// import Figure from '../img/Figure_References.png';
// import Example from '../img/Figure_ReferencesCIE.png';
// import Example2 from '../img/Figure_ColorModel-Example.png';
import PageHeader from '../components/PageHeader';
// import ImageSlider from "../components/ImageSlider";
// import Image1 from '../img/Figure_ContrastConstancy1.png';
// import Image2 from '../img/Figure_ContrastConstancy2.png';
// import { findByLabelText } from "@testing-library/react";


function References(props) {
  
  return (
    <motion.div
      style={pageStyle}
      key="references"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="References"
        description="References used in the creation of the Color & Contrast."
      />

      <PageHeader
        // category=" "
        title="References"
        // subtitle=""
        />

      <Section level="2">
        <p className="body4">The following are primary references used throughout this website on multiple topics. Additional, less frequently used sources are cited at the bottom of each page.</p>
      </Section>

      <Section title="Publications" level="4">
        {/* Author/editor. Year. Title. Edition. Place of publication: publisher. #pages. */}
        <CitationList className="sourceCitations" hideTitle>
          <Citation src="Psychophysical" />
          <Citation src="CAMS" />
          <Citation src="Visual Intelligence" />
          <Citation src="Visual Perception" />
          <Citation src="CVD" />
          <Citation src="Colorimetry" />
          <Citation src="Reproduction of color" />
          <Citation src="Measuring color" />
          <Citation src="Understanding Color"  />
          <Citation src="Color and Meaning" />
          <Citation src="Launching the Imagination"  />
          <Citation src="Classical Painting" />
          <Citation src="Vision and art" />
        </CitationList>
      </Section>

      <Section title="Websites" level="4">
        <CitationList className="sourceCitations" hideTitle>
          <Citation src="Handprint.com" />
          <Citation src="Color-blindness.com" />
          <Citation src="Fighting Blindness" />
          <Citation src="BCM Families Foundation" />
          <Citation src="NIH NIE" />
          <Citation src="NIH Rare Diseases" />
          <Citation src="NIH NCBI" />
          <Citation src="Wikipedia" />
          <Citation src="WebMD" />
          <Citation src="Mayo Clinic" />
          <Citation src="AAO.org" />
          <Citation src="2020mag" />
        </CitationList>
      </Section>

      {/* TODO: Add all cited resources here via the Citation component
      */}
      <Footer />

    </motion.div>
  )
}
 
export default References;