import React from "react"
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';
import { Link } from "react-router-dom";
import AfterimageFigure from '../img/Figure_Afterimage.gif';

import Figure from '../img/Figure_ChromaticAdaptation.png';
import PageHeader from '../components/PageHeader';

import ImageSlider from '../components/ImageSlider';
import ImageA from '../img/a.png';
import ImageB from '../img/b.png';
import ImageC from '../img/c.png';
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";

function ChromaticAdaptation(props) {
  const BlueDress = <ImageSlider id="BlueDress" images={ [ImageA, ImageB, ImageC] } labelLeft="Blue &amp; black" labelRight="White &amp; gold" height="375px"/>

  return (
    <motion.div
      style={pageStyle}
      key="chromatic-adaptation"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Chromatic adaptation"
        description="A process of the visual system that adjusts to preserve color appearance in response to colored light."
      />

      <PageHeader
        category="Color perception"
        title="Chromatic adaptation"
        />

      <Section
        figure={Figure}
        caption="Under blue-green light a blueberry appears to be blue and a strawberry appears to be red, however the actual colors of those objects differ from our Color perception of them."
      >
        <p className="body4">A process of the visual system that adjusts to preserve color appearance in response to colored light.</p>
        <p className="body4">This adaptation occurs by suppressing the <Link to="trichromacy">sensitivity of cones</Link> under prolonged exposure. This partially isolates the remaining cones to measure spectral sensitivity (or <Link to="hue">hue</Link>).</p>
      </Section>

      <Section title="Examples" level="2">
        <Section 
          level="4" 
          splitView 
          playground={BlueDress}
          title='"The dress"'
          // alt="alt text"
          // caption="Center area of checkers (A) appear to have similar Colors due to comparison with outer checkered area, as evidenced when outer checkers are removed (B)."
          >
          <p>This illusion is result of improper color identification due to chromatic adaptation, or <Link to="color-constancy">color constancy</Link>, and is known as "chromatic bias". Based on viewing conditions, or surrounding light, the dress may appear to be blue and black or white and gold.</p>
        </Section>

        <Section level="4" splitView 
          figure={AfterimageFigure}
          title="Afterimage illusion"
          // alt="alt text"
          caption="The lilac chaser afterimage illusion. Focus on the black cross in the center to see the illusion."
        >
        <p>An illusion where an image continues to appear after you stop looking at it.</p>
        <p>The illusion is caused by chromatic adaptation. Opposing (<Link to="complimentary-colors">complimentary</Link>) colors are experienced due to the <Link to="color-opponent-process">color opponent process</Link>.</p>
        </Section>

      </Section>


      <Section title="How it affects UI design" level="4" className="elements">
        <p>The appearance of some colors may be misidentified in certain environments, or more strongly affected by <Link to="/simultaneous-contrast">simultaneous contrast</Link>.</p>
        <p>Coloration of an entire interface (such as blue-grays or browns) can result in chromatic adaptation. This can cause other colors in the interface to take on a different appearance.</p>
        <p>Animations, scrolling, and page transitions may create afterimages, especially for high contrast elements. This may affect users with motion sensitivities.</p>
      </Section>

      {/* <Section title="How it affects UI design" level="4" className="elements">
        <p>Chromatic adaptation can </p>
      </Section> */}
      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/hue'> Hue </Link></li>
          <li><Link to='/color-opponent-process'> Color opponent process </Link></li>
          <li><Link to='/light-and-dark-adaptation'> Light &amp; dark adaptation </Link></li>
          <li><Link to='/color-constancy'> Color constancy </Link></li>
          <li><Link to='/simultaneous-contrast'> Simultaneous contrast </Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="Psychophysical" pages="224-225, 283" />
        <Citation src="CAMS" pages="25-26" />
        <Citation src="Understanding Color" pages="221" />
        <Citation src="Launching the Imagination" pages="377" />
        <Citation src="Wikipedia" url="https://en.wikipedia.org/wiki/Chromatic_adaptation" title="Chromatic adaptation"/>
      </CitationList>
      
      <Footer />

    </motion.div>
  )
}
 
export default ChromaticAdaptation;