import React from "react"
import { Link } from "react-router-dom";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Pagination from '../components/Pagination';
import Footer from '../components/Footer';

// import Figure from '../img/Figure_TutorialCreatingColorScale.png';
// import Example from '../img/Figure_TutorialCreatingColorScaleCIE.png';
// import Example2 from '../img/Figure_ColorModel-Example.png';
import PageHeader from '../components/PageHeader';
// import ImageSlider from "../components/ImageSlider";
// import Image1 from '../img/Figure_ContrastConstancy1.png';
// import Image2 from '../img/Figure_ContrastConstancy2.png';
// import { findByLabelText } from "@testing-library/react";


function TutorialCreatingColorScale(props) {
  
  return (
    <motion.div
      style={pageStyle}
      key="tutorial-creating-color-scale"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageHeader
        category="Tutorials"
        title="Creating color scales"
        subtitle="Tints &amp; shades of your colors"
        />

      <Section level="2">
        <p className="body4"><Link to="color-scale">Color scales</Link> provide options for various use cases of color in a digital experience, including options to meet WCAG contrast.</p>
      </Section>

      <Section 
        overline="Step 1" 
        title="Identify contrast requirements" 
        level="2"
        >
        <p>To begin, you will need to know if your product is targeting WCAG AA or AAA compliance.</p>
        <p>For AA compliance, each of your colors will need values that meet 3:1 and 4.5:1 contrast.</p>
        <p>For AAA compliance, they will need to meet 4.5:1 and 7:1.</p>
      </Section>

      <Section 
        overline="Step 2" 
        title="Choosing quantity of values" 
        level="2"
        >
        <p>The primary issue you will encounter with color scales relates to the lightness values of each color.</p>
        <p>For simple, minimalist color palettes, you may only need values that meet your contrast requirements.</p>
        <p>However, most design systems will need to provide a flexible color system. In these cases, you will want to create scales with multiple options.</p>
      </Section>

      <Section 
        overline="Step 3" 
        title="Evenly distribute values" 
        level="2"
        >
        <p>Flexible color scales conform to a perceptually uniform distribution of lightness. In other words, the change in value from one tint or shade to another appears consistent, or evenly weighted visually.</p>
        <p>Despite how it may seem, uniform color spaces do not help accomplish this balance. This is because no color space accommodates for the phenomena known as Stevens' law. This law measures how humans percieve the rate of change, which is non-linear.</p>
        <p>If you're using APCA contrast, you can evenly distribute contrast values (linearly) and it will give you the best result. This is because APCA has accounted for Steven's law within the formula itself.</p>
        <p>Note, as you evaluate the rate of change in your scales, you will experience complications from lateral inhibition.</p>
      </Section>

      <Section 
        overline="Step 4" 
        title="Generative methods" 
        level="2"
        >
        <p>Perhaps one of the more flexible, extensible, and invaluable methods of creating color scales is by using generative tools. Generative tools can help ensure you meet contrast requirements, and provide an easy way to adjust colors without having to rebuild everything from scratch.</p>
        <p>Adobe's open-source <a href="https://leonardocolor.io" target="_blank" rel="noopener noreferrer">Leonardo</a> provides this functionality, and even generates svg gradients of your color scales to use elsewhere in your design.</p>
      </Section>


      <Pagination 
        previous="Choosing a palette"
        next="Creating a theme"
      />

      <Footer />

    </motion.div>
  )
}
 
export default TutorialCreatingColorScale;