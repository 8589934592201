import React from "react"
import './Citation.scss';

class CitationList extends React.Component {
  constructor(props) {
    super(props)
    this.tools = props.tools;
    this.toolHeader = (props.hideTitle) ? '' : <li><strong>Helpful tools:</strong></li>
    this.resourceHeader = (props.hideTitle) ? '' : <span style={{marginLeft: '-1rem'}}><strong>Sources:</strong></span>
  }

  render() {
    const list = (this.tools) 
      ? <ul className={`CitationList CitationList--tools `+ this.props.className}>
          {this.toolHeader}
          {this.props.children}
        </ul>
      : <ol className={`CitationList `+ this.props.className}>
          {this.resourceHeader}
          {this.props.children}
        </ol> 

    return (
      <section style={{marginBottom: '0'}}>
        {list}
      </section>

    )
  }
}
 
export default CitationList;