import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import Figure from '../img/Figure_ComplimentaryColors.png';
import PageHeader from '../components/PageHeader';
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";
import ToolCitation from "../components/ToolCitation";


function ComplimentaryColors(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      key="complimentary-colors"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >

      <PageMeta 
        title="Complimentary colors"
        description="Complimentary colors are colors that are on opposite sides of a color wheel."
      />

      <PageHeader
        category="Color relationships"
        title="Complimentary colors"
        // subtitle=""
        />

      <Section
        figure={Figure}
        alt="Color wheel with orange, yellow-green, and red circles displayed in close proximity" 
      >
        {/* <p className="body4">A color space is the total collection of possible colors within a particular set of primaries. Color spaces are specific implimentations of a <Link to="/color/color-model">color model</Link>.</p> */}
        <p className="body4">Colors that are on opposite sides of a color wheel.</p>

      </Section>


      <Section title="How it affects UI design" level="4" className="elements">
        <p>Complimentary colors can be used to create diverging <Link to="/color-scale">color scales</Link> for data visualization.</p>
        <p>Complimentary colors can be used as secondary, accent color in a UI color palette for a dynamic appearance.</p>
        <p>A complimentary color palette may be more prone to color conflicts for people who have a <Link to="color-vision-deficiency">color vision deficiency</Link>.</p>
        <p><Link to="hue">Hue</Link> is treated differently between <Link to="color-space">color spaces</Link>, which can effect which colors are considered to be complimentary.</p>
      </Section>

      {/* <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/color-opponent-process'> Color opponent process </Link></li>
        </ul>
      </Section> */}
      <CitationList tools>
        <ToolCitation tool="Adobe color" />
      </CitationList>

      <CitationList>
        <Citation src="Understanding Color" pages="70, 225" />
        <Citation src="Launching the Imagination" pages="63" />
        <Citation src="Classical Painting" pages="80" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default ComplimentaryColors;