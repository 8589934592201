import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import Figure from '../img/Figure_AdditiveColorMixing.png';
// import Example from '../img/Figure_AdditiveColorMixing-Example.png';
// import Example2 from '../img/Figure_ColorModel-Example.png';
import PageHeader from '../components/PageHeader';
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";


function AdditiveColorMixing(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      key="additive-color-mixing"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Additive color mixing"
        description="When color is created by mixing lights of various color."
      />

      <PageHeader
        category="Color mixing"
        title="Additive"
        // subtitle=""
        />

      <Section
        figure={Figure}
      >
        {/* <p className="body4">A color space is the total collection of possible colors within a particular set of primaries. Color spaces are specific implimentations of a <Link to="/color/color-model">color model</Link>.</p> */}
        <p className="body4">When color is created by mixing lights of various color.</p>
        
      </Section>


      <Section title="How it affects UI design" level="4" className="elements">
        <p>Most digital color pickers and tools utilize additive color spaces such as <Link to="srgb">sRGB</Link>.</p>
        <p>CSS <a href="https://developer.mozilla.org/en-US/docs/Web/CSS/color_value/color-mix" target="_blank" rel="noopener noreferrer"><code>color-mix</code></a> can approximate mixing methods for various <Link to="color-space">color spaces</Link>, including those modeled after additive properties of light. Not all browsers support <code>color-mix</code>.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/hue'> Hue </Link></li>
          <li><Link to='/chroma'> Chroma </Link></li>
          <li><Link to='/lightness'> Lightness </Link></li>
          <li><Link to='/color-model'> Color model </Link></li>
          <li><Link to='/rgb-model'>RGB model</Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="Understanding Color" pages="19, 221" />
        <Citation src="Handprint.com" url="https://www.handprint.com/HP/WCL/color5.html#theoryadd" title="Additive &amp; subtractive color mixing"/>
        <Citation src="Wikipedia" url="https://en.wikipedia.org/wiki/Additive_color" title="Additive color" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default AdditiveColorMixing;