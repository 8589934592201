import React from "react"
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";
import ImageSlider from '../components/ImageSlider';

import Footer from '../components/Footer';

import Figure from '../img/Figure_LightSensitivity.png';
import FigureNormal from '../img/Figure_Normal.png';
import PageHeader from '../components/PageHeader';

import { Link } from "react-router-dom";
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";

function LightSensitivity(props) {
  const LightSensitivitySlider = <ImageSlider id="CheckerShadow" images={ [FigureNormal, Figure] } labelLeft="No sensitivity" labelRight="Photophobia" height="226px"/>

  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      key="light-sensitivity"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Light sensitivity"
        description="(Photophobia) A condition where bright lights hurt the eyes. Light sensitivity can be caused by migraines, corneal abrasion, conjuctivitis or other conditions."
      />

      <PageHeader
        category="Color vision"
        title="Light sensitivity"
        subtitle="(Photophobia)"
        />

      <Section
        splitView
        playground={LightSensitivitySlider}
        caption="Light sensitivity makes it difficult to focus on objects and may cause them to be blurry."
      >
        <p className="body4"> A condition where bright lights hurt the eyes and can cause loss of visual acuity in well lit environments. </p>
        <p className="body4"> Light sensitivity can be caused by migraines, corneal abrasion, conjuctivitis or other conditions such as <Link to="monochromacy">cerebral and blue cone monochromacy</Link>. </p>
        <p className="body4"> About 80% of people who have migraines also have light sensitivity.</p>
      </Section>

      <Section title="How it affects UI design" level="4" className="elements">
        <p>High contrast interfaces may cause physical discomfort or strain some peoples' eyes. </p>
        <p><Link to="dark-mode">Dark mode</Link>, or dark <Link to="color-theme">color themes</Link>, may help certain users by lessening the amount of light emitted on screen.</p>
        <p> Supporting <Link to="high-contrast-mode">Windows high contrast mode</Link> will help users with light sensitivity.</p>
        <p>In some cases, WCAG <a href="https://www.w3.org/TR/WCAG21/#contrast-minimum" target="_blank" rel="noopener noreferrer">1.4.3 (contrast minimum)</a> may create too high of contrast for users. Consider personalization settings to give users the ability to adjust contrast to meet their needs.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/contrast-sensitivity'> Contrast sensitivity </Link></li>
          <li><Link to='/relative-luminance'> Relative luminance </Link></li>
          <li><Link to='/apca'> Advanced perceptual contrast </Link></li>
          <li><Link to='/color-difference'> Color difference </Link></li>
          <li><Link to='/luminance-contrast'> Luminance contrast</Link></li>
          <li><Link to='/light-adaptation'> Light adaptation </Link></li>
          <li><Link to='/dark-mode'> Dark mode </Link></li>
          <li><Link to='/color-theme'> Color theme </Link></li>
          <li><Link to='/high-contrast-mode'> High contrast mode </Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation publisher="NIH NCBI" url="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3485070/" title="Shedding Light on Photophobia" />
        <Citation src="WebMD" url="https://www.webmd.com/eye-health/photophobia-facts" title="What is photophobia?" author="Stephanie Watson"/>
        <Citation src="AAO.org" url="https://www.aao.org/eyenet/article/photophobia-looking-causes-solutions/" title="Photophobia: Looking for Causes and Solutions" author="Marianne Doran" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default LightSensitivity;