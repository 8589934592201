import React from "react"
import { Link } from "react-router-dom";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

// import Figure from '../img/Figure_RGB.png';
import PageHeader from '../components/PageHeader';
import ColorPicker from "../components/ColorPicker";
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";
// import { Table, TableRow } from "../components/Table";
import PageMeta from "../components/PageMeta";

function LMS(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      key="lms"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >

      <PageMeta 
        title="LMS"
        description="A color space representing the three different cone responses of trichromatic vision. LMS is used in studying and simulating color vision deficiencies."
      />

      <PageHeader
        category="Color spaces"
        title="LMS"
        subtitle="(long, medium, short)"
        />

      <Section>
        <p className="body4">A color space representing the three different <Link to="retina">cone</Link> responses of <Link to="trichromacy">trichromatic vision</Link>.</p>
        <p className="body4">LMS is used in studying and simulating <Link to="color-vision-deficiency">color vision deficiencies</Link>.</p>

      </Section>

      <Section title="Example" level="2">
        <Section level="5"  
          splitView
          playground={<ColorPicker mode="LMS" hideValue />}
          >
          <p><strong>Note:</strong> LMS is not supported in any devices. The colors shown in this tool only depict colors that are within the sRGB <Link to="gamut">gamut</Link>.</p>

        </Section>

      </Section>

      {/* <Section title="Analysis of LMS" level="2" >
        <p>Knowing which <Link to="color-space">color space</Link> to use as a designer can be confusing. This analysis could help you in determining whether or not LMS is the right color space to try.</p>

        <h5>Methods and derivation</h5>
        <Table
          header={['Derived from', <Link to="chromatic-adaptation">Chromatic adaptation</Link>, <Link to='lightness'>Lightness</Link>, 'White point']}
        >
          <TableRow>
            <><ul>
                <li><Link to="retina">Cone</Link> responses to stimulus</li>
                <li><Link to="xyz">XYZ</Link></li>
              </ul>
            </>
            <>TODO</>
            <>TODO</>
            <>TODO</>
          </TableRow>

        </Table>

        <h5>Pro's and con's of LMS</h5>
        <Table 
          splitView
          header={['Known issues & limitations', 'Benefits & strengths']}
        >
          <TableRow>
            <ul>
              <li>Not based on color apperance</li>
              <li>Unreliable for measuring color differences</li>
            </ul>
            <ul>
              <li>Device-independant</li>
            </ul>
          </TableRow>
        </Table>

      </Section> */}

      <Section title="How it affects UI design" level="4" className="elements">
        <p>LMS is not based on color appearance and generally is not used for creating colors for user interfaces.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to="retina">Retina</Link></li>
          <li><Link to="/trichromacy">Trichromacy</Link></li>
          <li><Link to='/color-vision-deficiency'>Color vision deficiency</Link></li>
          <li><Link to="/color-confusion-lines">Color confusion lines</Link></li>
          <li><Link to="/color-confusion-lines">Color confusion lines</Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="CAMS" page="68-69, 79"/>
        <Citation src="Wikipedia" url="https://en.wikipedia.org/wiki/LMS_color_space" title="LMS color space"/>
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default LMS;