import React from "react"
import './Citation.scss';


class Citation extends React.Component {
  constructor(props) {
    super(props)
    // cite = shorthand name for previously cited material
    // BOOK:
    // Authors (Year). <i>Title</i>. Edition. Publisher.
    // WEBSITE:
    // Author. <i>Title</i>. Publication. <a>Link</a>
    this.src = props.src;

    this.ref = (props.pages) ? `Pages ${props.pages}` : ((props.url) ? <a href={props.url} target="blank">{props.url}</a> : '' )
    this.author = props.author;
    this.year = props.year;
    this.date = (this.year) ? `(${this.year})` : '';
    this.publisher = props.publisher;
    this.title = props.title;

    this.existing = {
      'Psychophysical': {
        media: 'book',
        author: 'Thomas Norton, David Corliss, James Bailey',
        year: '2020',
        title: 'The Psychophysical Measurement of Visual Function',
        edition: '1st edition',
        publisher: 'Butterworth-Heinemann'
      },
      "Visual Perception": {
        media: 'book',
        author: 'Vicki Bruce, Patrick R. Green, Mark A. Georgeson',
        year: '2003',
        title: 'Visual Perception',
        edition: '4th edition',
        publisher: 'Psychology Press'
      },
      'CAMS': {
        media: 'book',
        author: 'Mark D. Fairchild',
        year: '2013',
        title: 'Color Appearance Models',
        edition: '3rd edition',
        publisher: 'John Wiley & Sons, Ltd'
      },
      'CVD': {
        media: 'book',
        author: 'J.D. Mollon, J. Pokorny, K. Knoblauch',
        year: '2003',
        title: 'Normal & Defective Colour Vision',
        // edition: '1st edition',
        publisher: 'Oxford University Press'
      },
      'Visual Intelligence': {
        media: 'book',
        author: 'Donald D. Hoffman',
        year: '1998',
        title: 'Visual Intelligence',
        // edition: '1st edition',
        publisher: 'W. W. Norton & Company'
      },
      'Understanding Color': {
        media: 'book',
        title: 'Understanding color: an introduction for designers',
        author: 'Linda Holtzschue',
        edition: '5th edition',
        publisher: 'John Wiley & Sons, Inc',
        year: '2017'
      },
      "Launching the Imagination": {
        media: 'book',
        title: 'Launching the imagination: a comprehensive guide to basic design',
        author: 'Mary Stewart',
        edition: '2nd edition',
        publisher: 'McGraw-Hill',
        year: '2006'
      },
      "Classical Painting": {
        media: 'book',
        title: 'Classical painting atelier: a contemporary guide to traditional studio practice',
        author: 'Juliette Aristides',
        // edition: '5th edition',
        publisher: 'Watson-Guptill Publications',
        year: '2008'
      },
      "Colorimetry": {
        media: 'book',
        title: 'Colorimetry: understanding the CIE system',
        author: 'Janos Schanda',
        // edition: '5th edition',
        publisher: 'John Wiley & Sons, Inc',
        year: '2007'
      },
      "Reproduction of color": {
        media: 'book',
        title: 'The Reproduction of Colour',
        author: 'R.W.G. Hunt',
        // edition: '5th edition',
        publisher: 'John Wiley & Sons, Inc',
        year: '2004'
      },
      "Measuring color": {
        media: 'book',
        title: 'Measuring Colour',
        author: 'R.W.G. Hunt, M.R. Pointer',
        // edition: '5th edition',
        publisher: 'John Wiley & Sons, Inc',
        year: '2011'
      },
      "Vision and art": {
        media: 'book',
        title: 'Vision and art: the biology of seeing',
        author: 'Margaret Livingstone',
        // edition: '5th edition',
        publisher: 'Harry N. Abrams, Inc',
        year: '2002'
      },
      "Color and Meaning": {
        media: 'book',
        title: 'Color and Meaning: Art, Science, and Symbolism',
        author: 'John Gage',
        // edition: '5th edition',
        publisher: 'University of California Press',
        year: '1999'
      },
      // ONLINE SOURCES
      "Wikipedia": {
        media: 'website',
        publisher: 'Wikipedia',
        url: 'https://www.wikipedia.org/'
      },
      'Color-blindness.com': {
        media: 'website',
        author: 'Daniel Flück',
        publisher: 'Colblindor',
        url: 'https://www.color-blindness.com/'
      },
      'Handprint.com': {
        media: 'website',
        author: 'Bruce MacEvoy',
        publisher: 'Handprint.com',
        url: 'https://www.handprint.com'
      },
      'Myndex': {
        media: 'website',
        author: 'Andrew Somers',
        publisher: 'Myndex Research',
        url: 'https://www.myndex.com/'
      },
      'Fighting Blindness': {
        media: 'website',
        // author: 'None',
        publisher: 'Fighting Blindness',
        url: 'https://www.fightingblindness.org/'
      },
      'BCM Families Foundation': {
        media: 'website',
        // author: 'None',
        publisher: 'BCM Families Foundation',
        url: 'https://www.blueconemonochromacy.org/'
      },
      'NIH Rare Diseases': {
        media: 'website',
        // author: 'None',
        publisher: 'National Institute of Health (Genetic and Rare Diseases Information Center)',
        url: 'https://rarediseases.info.nih.gov/'
      },
      'NIH NCBI': {
        media: 'website',
        // author: 'None',
        publisher: 'National Institute of Health (National Center for Biotechnology Information)',
        url: 'https://www.ncbi.nlm.nih.gov/'
      },
      'NIH NIE': {
        media: 'website',
        // author: 'None',
        publisher: 'National Institute of Health (National Eye Institute)',
        url: 'https://www.nei.nih.gov/'
      },
      'WebMD': {
        media: 'website',
        // author: 'None',
        publisher: 'WebMD',
        url: 'https://www.webmd.com/'
      },
      "w3c": {
        media: 'website',
        // author: 'None',
        publisher: 'The World Wide Web Consortium (w3c)',
        url: 'https://www.w3c.org/'
      },
      "WCAG": {
        media: 'website',
        // author: 'None',
        publisher: 'Web Content Accessibility Guidelines (WCAG) 2.1',
        url: 'https://www.w3.org/TR/WCAG21/'
      },
      "2020mag": {
        media: 'website',
        // author: 'None',
        publisher: '20/20 Magazine',
        url: 'https://www.2020mag.com/'
      },
      "AAO.org": {
        media: 'website',
        // author: 'None',
        publisher: 'American Academy of Opthalmology',
        url: 'https://www.aao.org/'
      },
      "AOA.org": {
        media: 'website',
        // author: 'None',
        publisher: 'American Optometric Association',
        url: 'https://www.aoa.org/'
      },
      "Mayo Clinic": {
        media: 'website',
        // author: 'None',
        publisher: 'Mayo Clinic',
        url: 'https://www.mayoclinic.org/'
      },
      "Vision excellence" : {
        media: 'website',
        // author: 'None',
        publisher: 'Vision excellence: optometry & orthokeratology',
        url: 'https://www.visionexcellence.com.au/'
      }
    };

    if(this.existing.hasOwnProperty(this.src)) {
      const cite = this.existing[this.src];
      if(cite.media === 'book') {
        if(cite.edition) {
          this.citation = <>{cite.author} ({cite.year}). <i>{cite.title}</i>. {cite.edition}. {cite.publisher}. {this.ref}</>
        } else {
          this.citation = <>{cite.author} ({cite.year}). <i>{cite.title}</i>. {cite.publisher}. {this.ref}</>
        }
      } else if (cite.media === 'website') {
        let _ref = (this.ref) ? this.ref : <a href={cite.url} target="blank">{cite.url}</a>;
        this.citation = <>{(cite.author) ? cite.author+'. ' : ''} <i>{(this.title) ? this.title+". " : ((cite.title) ? cite.title+". " : '')}</i> {cite.publisher}. {_ref} </>
      }
    } else {
      if(this.year && this.author) {
        this.citation = <>{this.author} {this.date}. <i>{this.title}</i>{ this.edition}. {this.publisher}. {this.ref}</>
      } else if (this.author && !this.edition && !this.publisher) {
        this.citation = <>{this.author}. <i>{this.title}</i>. {this.ref}</>
      } else if (this.author && !this.edition) {
        this.citation = <>{this.author}. <i>{this.title}</i>. {this.publisher}. {this.ref}</>
      } else if (!this.author && !this.edition) {
        this.citation = <><i>{this.title}</i>. {this.publisher}. {this.ref}</>
      } else {
        this.citation = <><i>{this.title}</i>. {this.edition}. {this.publisher}. {this.ref}</>
      }
    }

  }
  render() {
    return (
      <li><cite className="Citation">{this.citation}</cite></li>
    )
  }
}
 
export default Citation;