import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';
import ColorDetails from "../components/ColorDetails";
import Citation from "../components/Citation";
import CitationList from "../components/CitationList";

import PageHeader from '../components/PageHeader';

function ColorMeaning(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      key="color-psychology" 
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
       <PageMeta 
        title="Color psychology"
        description="(Color moods) Colors are generally associated with emotional or psychological meaning."
      />

      <PageHeader
        category="Properties of color"
        title="Color psychology"
        subtitle="(Color moods)"
        />

      <Section
        // splitView
        // playground={<ColorDetails mode="emotions" />}
      >
        <p className="body4">Colors are generally associated with descriptive or emotional or psychological meaning.</p>
      </Section>

      <Section>
        <ColorDetails />
      </Section>



      <Section title="How it affects UI design" level="4" className="elements">
        <p>Color can be used to reinforce brand and affect user experience for sighted users by leveraging common psychological color meaning.</p>
        <p>Not all cultures interpret color in the same way. Be sure your colors convey appropriate meanings for your audience's locale.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/hue'> Hue </Link></li>
          <li><Link to='/chroma'> Chroma </Link></li>
          <li><Link to='/color-temperature'> Color temperature </Link></li>
        </ul>
      </Section>


      <CitationList>
        <Citation title="Color Description" author="[Color terms via] David Aerne (@meodai)" url="https://github.com/words/color-description"/>
        <Citation src="Wikipedia" url="https://en.wikipedia.org/wiki/Color_psychology" title="Color psychology" />
        <Citation url="https://www.colorpsychology.org/" publisher="Color Psychology" title="The Psychology of Colors and Their Meanings"/>
        <Citation url="https://www.informationisbeautiful.net/visualizations/colours-in-cultures/" title="Colours in Culture" author="David McCandness &amp; AlwaysWithHonor.com" publisher="Information is beautiful" />
        <Citation url="https://www.canva.com/colors/color-meanings/" publisher="Canva" title="Color meanings and symbolism" />
        <Citation url="https://www.thoughtco.com/visual-color-symbolism-chart-by-culture-4062177" title="Visual Color Symbolism Chart by Culture" author="Jeremy Girard"  year="2019" />
      </CitationList>


      <Footer />

    </motion.div>
  )
}
 
export default ColorMeaning;