import React from "react"
import chroma from 'chroma-js';
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import PageHeader from '../components/PageHeader';

import { Link } from "react-router-dom";
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";

const Round = (value) => {
  return Math.round( value * 100 ) / 100;
}

class ContrastChecker extends React.Component {
  constructor(props) {
    super(props)
    // this.id = props.id;
    this.defaultcolorContrast1 = '#f26322'
    this.defaultcolorContrast2 = '#59d0ff';

    this.colorContrast1 = this.defaultcolorContrast1;
    this.colorContrast2 = this.defaultcolorContrast2;
    const contrast = chroma.contrast(this.colorContrast1, this.colorContrast2);
    this.contrast = Round(contrast)

    this.update = () => {
      const diff = chroma.contrast(this.colorContrast1, this.colorContrast2);
      const rounded = Round(diff)
      document.getElementById('contrastRatio').innerHTML = rounded;
    }
  }

  render() {
    return (
      <div>
        <div>
          <span className="body4">Contrast ratio: <span id='contrastRatio' style={{fontWeight: 'Bold'}}>{this.contrast}</span>:1</span>
        </div>
        <div className="ColorPicker colorPicker--row">
          <div className="ColorPicker_swatch " id="colorContrast1swatch" style={{backgroundColor: this.defaultcolorContrast1}}>
            <input type="color" id="colorContrast1" defaultValue={this.defaultcolorContrast1} onInput={ (e) => {document.getElementById('colorContrast1swatch').style.backgroundColor = e.target.value; this.colorContrast1 = e.target.value; this.update()}}></input>
          </div>
          <div className="ColorPicker_swatch " id="colorContrast2swatch" style={{backgroundColor: this.defaultcolorContrast2}}>
            <input type="color" id="colorContrast2" defaultValue={this.defaultcolorContrast2} onInput={ (e) => {document.getElementById('colorContrast2swatch').style.backgroundColor = e.target.value; this.colorContrast2 = e.target.value; this.update()}}></input>
          </div>
        </div>
      </div>
    )
  }
}

function RelativeLuminance(props) {
  const playground = <ContrastChecker />
  return (
    <motion.div
      style={pageStyle}
      key="relative-luminance"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Relative luminance"
        description="A relative measurement of luminance used in color appearance models where adaptive light is a factor. The relative luminance formula is used by the Web Content Accessibility Guidelines (WCAG) for calculating lightness contrast, and used in the definition of their success criteria."
      />

      <PageHeader
        category="Measurement &amp; formulas"
        title="Relative luminance"
        // subtitle="(WCAG contrast ratio)"
        />

      <Section
        splitView
        playground={playground}
        >
        <p className="body4">A relative measurement of <Link to="luminance">luminance</Link>, defined by the Y value in <Link to="xyz">XYZ color space</Link>.</p>
        <p className="body4">A comparison of relative luminance is used by the Web Content Accessibility Guidelines (WCAG) for calculating <Link to="lightness">lightness</Link> contrast.</p>
      </Section>

      {/* <Section title="Try it out" level="2">
        <Section level="4" 
        splitView 
        className="smallSplitView"
        playground={playground}>
          <p>Choose two colors to check their relative luminance contrast ratio.</p>
          <p>More in-depth contrast and color difference reporting can be found at <a href="https://leonardocolor.io/tools.html" target="_blank" rel="noopener noreferrer">leonardocolor.io/tools</a>, including contrast for colors with <Link to="opacity">transparency</Link>.</p>
        </Section>
      </Section> */}

      <Section title="How it affects UI design" level="4" className="elements">
        <p>Relative luminance formula is used for contrast calculations to ensure text and components are distinguishable. Check contrast for colors to ensure you are in compliance with WCAG <a href="https://www.w3.org/TR/WCAG21/#contrast-minimum" target="_blank" rel="noopener noreferrer">1.4.3 (contrast minimum)</a>.</p>
        <p>Contrast is a complex perceptual element of sighted user experiences. Learn more about <Link to="/color/contrast-sensitivity">contrast sensitivity</Link> when evaluating contrast for your user interface.</p>
        <p>Relative luminance contrast performs poorly for evaluating contrast in dark <Link to="color-theme">color themes</Link> or <Link to="dark-mode">dark mode</Link>. Light text colors on dark backgrounds need increased relative luminance contrast.</p>
        <p>A more perceptually accurate formula for measuring contrast is currently <strong>in develpoment</strong> for WCAG 3 called <Link to="apca">APCA (accessible perceptual contrast algorithm)</Link> which uses an alternative to relative luminance when calculating contrast.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/contrast-sensitivity'> Contrast sensitivity </Link></li>
          <li><Link to='/apca'> Advanced perceptual contrast </Link></li>
          <li><Link to='/color-difference'> Color difference </Link></li>
          <li><Link to='/luminance-contrast'> Luminance contrast</Link></li>
          <li><Link to='/whites-illusion'> Munker-White's illusion</Link></li>
          <li><Link to='/simultaneous-contrast'> Simultaneous contrast </Link></li>
          <li><Link to='/contrast-discrimination'> Contrast discrimination </Link></li>
          <li><Link to='/bezold-effect'> Bezold effect </Link></li>
          <li><Link to='/stevens-power-law'> Stevens' power law </Link></li>
          <li><Link to='/light-adaptation'> Light adaptation </Link></li>
          <li><Link to='/lateral-inhibition'> Lateral inhibition </Link></li>
          <li><Link to='/cielab'> CIELAB </Link></li>
          <li><Link to='/apca'> APCA </Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation url="http://www.brucelindbloom.com/index.html?Eqn_RGB_XYZ_Matrix.html" title="RGB/XYZ Matrices" publisher="Bruce Lindbloom" />
        <Citation url="https://www.image-engineering.de/library/technotes/958-how-to-convert-between-srgb-and-ciexyz" title="How to convert between sRGB and CIEXYZ" publisher="Image Engineering" />
        <Citation url="https://www.w3.org/WAI/GL/wiki/Relative_luminance" title="Relative luminance" publisher="w3c.org" />
        <Citation src="Wikipedia" url="https://en.wikipedia.org/wiki/Relative_luminance" title="Relative luminance" />
      </CitationList>


      <Footer />

    </motion.div>
  )
}
 
export default RelativeLuminance;