import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

// import Figure from '../img/Figure_RGB.png';
import PageHeader from '../components/PageHeader';
import ColorPicker from "../components/ColorPicker";
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";
import ToolCitation from "../components/ToolCitation";
// import { Table, TableRow } from "../components/Table";

function CIELUV(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      exit="out"
      key="cieluv"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="CIELUV"
        description="A uniform color space created in 1976. CIELUV is an opponent color space."
      />

      <PageHeader
        category="Color spaces"
        title="CIELUV"
        // subtitle="(CIECIELUV)"
        />

      <Section>
        <p className="body4">A <Link to="/color-appearance-model">uniform color space</Link> created in 1976 by the International Commission on Illumination (CIE). CIELUV is an <Link to="color-opponent-process">opponent color</Link> space.</p>
        <p className="body4"><Link to="cielab">CIELAB</Link> and CIELUV were both recommended as interim solutions for solving <Link to="color-difference">color difference</Link> specification of <Link to="subtractive">reflecting light</Link>.</p>
       {/* <Image 
          src={Figure}
          alt="" 
          // caption="RGB color space"
        ></Image> */}
      </Section>

      <Section title="Examples" level="2">
        <Section level="5" splitView 
          title="HSLuv"
          playground={<ColorPicker mode="hsluv" hideValue/>}
          >
          <p>HSLuv is a cylindrical transformation and adaptation of CIELUV created by <a href="https://hsluv.org">Alexei Boronine</a>.</p>
        </Section>

      </Section>


      {/* <Section title="Analysis of CIELUV" level="2" >
        <p>Knowing which <Link to="color-space">color space</Link> to use as a designer can be confusing. This analysis could help you in determining whether or not CIELAB is the right color space to try.</p>

        <h5>Methods and derivation</h5>
        <Table
          header={['Derived from', <Link to="chromatic-adaptation">Chromatic adaptation</Link>, <Link to='lightness'>Lightness</Link>, 'White point']}
        >
          <TableRow>
            <Link to="xyz">XYZ</Link>
            <>TODO</>
            <>L*</>
            <>TODO</>
          </TableRow>

        </Table>

        <h5>Pro's and con's of CIELUV</h5>
        <Table 
          splitView
          header={['Known issues & limitations', 'Benefits & strengths']}
        >
          <TableRow>
            <ul>
              <li>Same as LAB?</li>
            </ul>
            <ul>
              <li>Device-independant</li>
            </ul>
          </TableRow>
        </Table>

      </Section> */}


      <Section title="How it affects UI design" level="4" className="elements">
        <p>Most uses of color in user interfaces are not affected by color appearance models. You do not need to rely on these color spaces to create aesthetically appealing and accessible colors.</p>
        <p>Uniform color spaces are linear models of human vision. However, human perception is non-linear. Do not rely on these color spaces alone to create aesthetically balanced colors. </p>
        <p>CIELUV and HSLuv are not supported by web browsers.</p>
        <p><Link to="/color-scale">Color scales</Link> can be created by <Link to="color-interpolation">interpolation</Link> in various color spaces.</p>
        <p>It is unclear why or when to use CIELUV over <Link to="cielab">CIELAB</Link>, however it offers an alternative for <Link to="color-interpolation">color interpolation</Link> that may fit your needs.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
        <li><Link to="/trichromacy">Trichromacy</Link></li>
          <li><Link to="color-opponent-process">Color opponent process</Link></li>
          {/* <li><Link to="color-model">Color model</Link></li>
          <li><Link to="color-space">Color space</Link></li> */}
          <li><Link to="/color-scale">Color scales</Link></li>
          <li><Link to="/color-interpolation">Color interpolation</Link></li>
          <li><Link to="subtractive">Subtractie color mixing</Link></li>
          <li><Link to="cielab">CIELAB</Link></li>
        </ul>
      </Section>

      <CitationList tools>
        <ToolCitation url="https://hsluv.org" title="HSLuv" />
        <ToolCitation tool="D3 color" />
        <ToolCitation tool="Leonardo" />
      </CitationList>

      <CitationList>
        <Citation src="CAMS" pages="80-81"/>
        <Citation src="Colorimetry" pages="63-65" />
        <Citation src="Measuring color" pages="53-55" />
        <Citation src="Wikipedia" url='https://en.wikipedia.org/wiki/CIELUV' title="CIELUV" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default CIELUV;