import React, { useState } from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";
import * as Leo from '@adobe/leonardo-contrast-colors';

import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import Figure from '../img/Figure_AdaptiveColorTheme.png';
// import Example from '../img/Figure_AdaptiveColorTheme-Example.png';
// import Example2 from '../img/Figure_ColorModel-Example.png';
import PageHeader from '../components/PageHeader';
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";
import ToolCitation from "../components/ToolCitation";
// import CitationList from "../components/CitationList";
// import Citation from "../components/Citation";
const contrasts = [7, 30, 55, 65, 80, 100] // [1.2, 1.8, 3, 4.5, 7, 12]

  const gray = new Leo.BackgroundColor({
    name: 'gray',
    colorKeys: ['#000000'],
    ratios: contrasts,
    colorspace: 'RGB',
    smooth: true
  })

  const blue = new Leo.Color({
    name: 'blue',
    colorKeys: ['#1BAAE7'],
    ratios: contrasts,
    colorspace: 'RGB',
    smooth: true
  })

  const orange = new Leo.Color({
    name: 'orange',
    colorKeys: ['#FF8000'],
    ratios: contrasts,
    colorspace: 'RGB',
    smooth: true
  })

  const demoTheme = new Leo.Theme({
    colors: [blue, orange, gray],
    backgroundColor: gray,
    output: 'HEX',
    lightness: '100',
    formula: 'wcag3'
  })

  window.demoTheme = demoTheme;

const AdaptiveThemePlayground = (props) => {
  const [lightness, setLightness] = useState(100)
  const [contrast, setContrast] = useState(1)
  const [saturation, setSaturation] = useState(100)

  demoTheme.contrast = contrast;
  demoTheme.lightness = lightness;
  demoTheme.saturation = saturation;

  const colors = demoTheme.contrastColorPairs;

  const cssProps = () => { for (const [key, value] of Object.entries(colors)) {
    document.documentElement.style
      .setProperty(`--DEMO_${key}`, value);
    }
  }
  cssProps();

  return (
    <div style={{width: '100%', maxWidth: '580px', marginTop: '8px'}}>
      <div style={{display: 'grid', gridTemplateColumns: '32% 32% 32%', gap: '16px', width: '100%'}}>
        <div>
          <label htmlFor="lightness" id="lightnessLabel">Lightness: <span className="labelValue">{lightness}</span></label>
          <input id="lightness" aria-labelledby="lightnessLabel" defaultValue={lightness} type="range" min='0' max='100' step="1" onChange={(e) => {
            setLightness(parseInt(e.target.value, 10));
            cssProps();
          }}></input>
        </div>
        <div>
          <label htmlFor="contrast" id="contrastLabel">Contrast: <span className="labelValue">{contrast}</span></label>
          <input id="contrast" aria-labelledby="contrastLabel" defaultValue={contrast} type="range" min='0.25' max='2' step="0.01" onChange={(e) => {
            setContrast(parseFloat(e.target.value));
            cssProps();
          }}></input>
        </div>
        <div>
          <label htmlFor="saturation" id="saturationLabel">Saturation: <span className="labelValue">{saturation}</span></label>
          <input id="saturation" aria-labelledby="saturationLabel" defaultValue={saturation} type="range" min='0' max='100' step="1" onChange={(e) => {
            demoTheme.saturation = parseInt(e.target.value, 10);
            setSaturation(parseInt(e.target.value, 10));
            cssProps();
          }}></input>
        </div>
      </div>

      <div style={{margin: '16px 20px', borderRadius: '16px', border: '1px solid #ccc', color: 'var(--DEMO_gray500)', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2', overflow: 'hidden'}}>
        <div style={{height: '24px', backgroundColor: 'var(--DEMO_orange400)', color: 'var(--DEMO_gray100)', padding: '16px 32px'}}> Header </div>
        <div style={{ backgroundColor: 'var(--DEMO_background)', padding: '32px', display: 'flex', flexDirection: 'row', width: '-webkit-fill-available'}}> 
          <div style={{flexGrow: '0', paddingRight: '16px'}}>
            <p className="h3" style={{color: 'var(--DEMO_gray500)', marginTop: 0}}>Heading</p>
            <p style={{color: 'var(--DEMO_gray500)'}}>This is an example of body text in a user interface.</p>
            {/* <p style={{color: 'var(--DEMO_gray500)', maxWidth: '44ch'}}>Adjust the sliders above for lightness, contrast, and saturation to see the theme adapt.</p> */}
            <button style={{borderColor: 'var(--DEMO_orange400)', borderWidth: '2px', borderStyle:'solid', backgroundColor: 'var(--DEMO_orange400)', color: 'var(--DEMO_background)', padding: '8px 16px', borderRadius: '16px', textSize: '1em'}}>Button</button>
            <button style={{borderColor: 'var(--DEMO_orange400)', borderWidth: '2px', borderStyle:'solid', color: 'var(--DEMO_orange500)', padding: '8px 16px', borderRadius: '16px', textSize: '1em', marginLeft: '0.5em'}}>Button</button>
          </div>
          <div style={{justifySelf: 'flex-end', flexShrink: '0'}}>
            <div>
              <span style={{backgroundColor: 'var(--DEMO_gray100)', width: '32px', height: '32px', borderRadius: '100%', display: 'inline-flex'}}></span>
              <span style={{backgroundColor: 'var(--DEMO_gray200)', width: '32px', height: '32px', borderRadius: '100%', display: 'inline-flex'}}></span>
              <span style={{backgroundColor: 'var(--DEMO_gray300)', width: '32px', height: '32px', borderRadius: '100%', display: 'inline-flex'}}></span>
              <span style={{backgroundColor: 'var(--DEMO_gray400)', width: '32px', height: '32px', borderRadius: '100%', display: 'inline-flex'}}></span>
              <span style={{backgroundColor: 'var(--DEMO_gray500)', width: '32px', height: '32px', borderRadius: '100%', display: 'inline-flex'}}></span>
              <span style={{backgroundColor: 'var(--DEMO_gray600)', width: '32px', height: '32px', borderRadius: '100%', display: 'inline-flex'}}></span>
            </div>
            <div>
              <span style={{backgroundColor: 'var(--DEMO_blue100)', width: '32px', height: '32px', borderRadius: '100%', display: 'inline-flex'}}></span>
              <span style={{backgroundColor: 'var(--DEMO_blue200)', width: '32px', height: '32px', borderRadius: '100%', display: 'inline-flex'}}></span>
              <span style={{backgroundColor: 'var(--DEMO_blue300)', width: '32px', height: '32px', borderRadius: '100%', display: 'inline-flex'}}></span>
              <span style={{backgroundColor: 'var(--DEMO_blue400)', width: '32px', height: '32px', borderRadius: '100%', display: 'inline-flex'}}></span>
              <span style={{backgroundColor: 'var(--DEMO_blue500)', width: '32px', height: '32px', borderRadius: '100%', display: 'inline-flex'}}></span>
              <span style={{backgroundColor: 'var(--DEMO_blue600)', width: '32px', height: '32px', borderRadius: '100%', display: 'inline-flex'}}></span>
            </div>
            <div>
              <span style={{backgroundColor: 'var(--DEMO_orange100)', width: '32px', height: '32px', borderRadius: '100%', display: 'inline-flex'}}></span>
              <span style={{backgroundColor: 'var(--DEMO_orange200)', width: '32px', height: '32px', borderRadius: '100%', display: 'inline-flex'}}></span>
              <span style={{backgroundColor: 'var(--DEMO_orange300)', width: '32px', height: '32px', borderRadius: '100%', display: 'inline-flex'}}></span>
              <span style={{backgroundColor: 'var(--DEMO_orange400)', width: '32px', height: '32px', borderRadius: '100%', display: 'inline-flex'}}></span>
              <span style={{backgroundColor: 'var(--DEMO_orange500)', width: '32px', height: '32px', borderRadius: '100%', display: 'inline-flex'}}></span>
              <span style={{backgroundColor: 'var(--DEMO_orange600)', width: '32px', height: '32px', borderRadius: '100%', display: 'inline-flex'}}></span>
            </div>
          </div>
        </div>
        <div style={{height: '24px', backgroundColor: 'var(--DEMO_gray100)', padding: '16px 32px'}}> Footer </div>
      </div>

    </div>
  );
}

function AdaptiveColorTheme(props) {
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      key="adaptive-color-theme"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Adaptive color theme"
        description="A color theme with options that can be adjusted by people using the interface. Options affect every color in the theme such as contrast, lightness, and saturation."
      />

      <PageHeader
        category="Colors in use"
        title="Adaptive color theme"
        // subtitle=""
        />

      <Section
        playground={<AdaptiveThemePlayground />}
      >
        {/* <p className="body4">A color space is the total collection of possible colors within a particular set of primaries. Color spaces are specific implimentations of a <Link to="/color/color-model">color model</Link>.</p> */}
        <p className="body4">A <Link to="color-theme">color theme</Link> with options that can be adjusted by people using the interface. Options affect every color in the theme such as contrast, <Link to="lightness">lightness</Link>, and <Link to="saturation">saturation</Link>.</p>
      </Section>


      <Section title="How it affects UI design" level="4" className="elements">
        <p>Adaptive themes can provide <strong>higher contrast</strong> for users who need more contrast than WCAG <a href="https://www.w3.org/TR/WCAG21/#contrast-minimum" target="_blank" rel="noopener noreferrer">1.4.3 (contrast minimum)</a>. </p>
        <p>Adaptive themes can provide <strong>lower contrast</strong> and <strong>lightness controls</strong> for users with <Link to="light-sensitivity">light sensitivity</Link>.</p>
        <p>Adaptive themes can provide <strong>lower saturation</strong> for users who may benefit from lower saturated colors.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to="light-sensitivity">Light sensitivity</Link></li>
          <li><Link to="light-and-dark-adaptation">Light &amp; dark adaptation</Link></li>
          <li><Link to="color-vision-deficiency">Color vision deficiency</Link></li>
          <li><Link to="bezold-effect">Bezold effect</Link></li>
          <li><Link to="chromatic-adaptation">Chromatic adaptation</Link></li>
          <li><Link to="chromatic-luminance">Chromatic luminance</Link></li>
          <li><Link to="color-constancy">Color constancy</Link></li>
          <li><Link to="conrast-sensitivity">Contrast sensitivity</Link></li>
          <li><Link to="conrast-discrimination">Contrast discrimination</Link></li>
          <li><Link to="simultaneous-contrast">Simultaneous contrast</Link></li>
          <li><Link to="polygonal-colors">Polygonal colors</Link></li>
          <li><Link to="relative-luminance">Relative luminance</Link></li>
          <li><Link to="apca">APCA</Link></li>
          <li><Link to="light-mode">Light mode</Link></li>
          <li><Link to="dark-mode">Dark mode</Link></li>
          <li><Link to="increase-conrast-mode">Increase contrast mode</Link></li>
          <li><Link to="high-conrast-mode">High contrast mode</Link></li>
          <li><Link to="color-scale">Color scale</Link></li>
        </ul>
      </Section>

      <CitationList tools>
        <ToolCitation tool="Leonardo" />
      </CitationList>

      <CitationList>
        <Citation url="https://medium.com/@NateBaldwin/adaptive-color-in-design-systems-7bcd2e664fa0" title="Adaptive Color in Design Systems" publisher="Medium.com" />
        <Citation url="https://medium.com/@NateBaldwin/accessible-color-for-design-systems-just-got-easier-40e8420a8371" title="Accessible color for design systems just got easier" publisher="Medium.com" />
      </CitationList>

      {/* TODO: Add citations 
        TODO: Evaluate inline & related links
        TODO: Add main visual
        TODO: Add interactive example
      */}
      <Footer />

    </motion.div>
  )
}
 
export default AdaptiveColorTheme;