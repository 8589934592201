import React from "react"
import { Link } from "react-router-dom";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

// import Figure from '../img/Figure_RGB.png';
import PageHeader from '../components/PageHeader';
import ColorPicker from "../components/ColorPicker";
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";
// import { Table, TableRow } from "../components/Table";
import PageMeta from "../components/PageMeta";
import ToolCitation from "../components/ToolCitation";

function XYZ(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      key="xyz"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >

      <PageMeta 
        title="XYZ"
        description="A color space created by the CIE for color matching functions. It is based on imaginary primary colors and can be used to match all colors in the visible spectrum."
      />

      <PageHeader
        category="Color spaces"
        title="XYZ"
        subtitle="(Tristimulus values)"
        />

      <Section>
        <p className="body4">A color space created by the CIE for color matching functions. It is based on imaginary primary colors and can be used to match all colors in the <Link to="trichromacy">visible spectrum</Link> (demonstrated in the CIE <Link to="chromaticity">chromaticity</Link> diagram).</p>
        <p className="body4">XYZ was a transformation of CIE RGB color space, ensuring values are all positive and accommodating for <Link to="luminance">luminance</Link> equations of CIE photometry.</p>
        <p className="body4">XZY values are sometimes referred to as "tristimulus values". The "Y" value is also known as <Link to='relative-luminance'>relative luminance</Link>.</p>
      </Section>

      <Section title="Example" level="2">
        <Section level="5"  
          splitView
          playground={<ColorPicker mode="XYZ" hideValue />}
          >
          <p><strong>Note:</strong> XYZ is not supported in any devices. The colors shown in this tool only depict colors that are within the sRGB <Link to="gamut">gamut</Link>.</p>

        </Section>

      </Section>

      {/* <Section title="Analysis of XYZ" level="2" >
        <p>Knowing which <Link to="color-space">color space</Link> to use as a designer can be confusing. This analysis could help you in determining whether or not CIELAB is the right color space to try.</p>

        <h5>Methods and derivation</h5>
        <Table
          header={['Derived from', <Link to="chromatic-adaptation">Chromatic adaptation</Link>, <Link to='lightness'>Lightness</Link>, 'White point']}
        >
          <TableRow>
            <>TODO</>
            <>TODO</>
            <>TODO</>
            <>TODO</>
          </TableRow>

        </Table>

        <h5>Pro's and con's of XYZ</h5>
        <Table 
          splitView
          header={['Known issues & limitations', 'Benefits & strengths']}
        >
          <TableRow>
            <ul>
              <li>Not based on color apperance</li>
              <li>Unreliable for measuring color differences</li>
            </ul>
            <ul>
              <li>Device-independant</li>
            </ul>
          </TableRow>
        </Table>

      </Section> */}

      <Section title="How it affects UI design" level="4" className="elements">
        <p>XYZ is not based on color appearance and generally is not used for creating colors for user interfaces.</p>
        <p>XYZ's "Y" value, known as <Link to="relative-luminance">relative luminance</Link>, is used in WCAG 2.0 <a href="https://www.w3.org/WAI/GL/wiki/Relative_luminance" target="_blank" rel="noopener noreferrer">contrast calculations</a>.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to="/trichromacy">Trichromacy</Link></li>
          <li><Link to="/color-opponent-process">Color opponent process</Link></li>
          <li><Link to="/chromaticity">Chromaticity</Link></li>
          <li><Link to="/gamut">Gamut</Link></li>
          {/* <li><Link to="/lms">LMS</Link></li> */}
          <li><Link to="/cielab">CIELAB</Link></li>
          <li><Link to="/cielab">CIELUV</Link></li>
          <li><Link to="/relative-luminance">Relative luminance</Link></li>
        </ul>
      </Section>

      <CitationList tools>
        <ToolCitation tool="Color.js" />
      </CitationList>

      <CitationList>
        <Citation src="CAMS" pages="70-74, 79"/>
        <Citation src="Colorimetry" pages="29-35" />
        <Citation src="Reproduction of color" pages="101-103" />
        <Citation src="Measuring color" pages="235-236" />
        <Citation src="Wikipedia" url="https://en.wikipedia.org/wiki/Relative_luminance" title="Relative luminance" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default XYZ;