import React from "react"
import { Link } from "react-router-dom";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

// import Figure from '../img/Figure_RGB.png';
import PageHeader from '../components/PageHeader';
import ColorPicker from "../components/ColorPicker";
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";
// import { Table, TableRow } from "../components/Table";
import PageMeta from "../components/PageMeta";
import ToolCitation from "../components/ToolCitation";

function CAM02(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      key="cam02"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >

      <PageMeta 
        title="CAM02"
        description="A color appearance model created in 2002 by the International Commission on Illumination (CIE). CAM02 is an improved model after its predecessor, CIECAM97s."
      />

      <PageHeader
        category="Color spaces"
        title="CAM02"
        subtitle="(CIECAM02-UCS)"
        />

      <Section>
        <p className="body4">A <Link to="/color-appearance-model">color appearance model</Link> created in 2002 by the International Commission on Illumination (CIE). CAM02 is an improved model after its predecessor, CIECAM97s. </p>

      </Section>

      <Section title="Examples" level="2">
        <Section level="5" splitView 
          // title="LAB (CIELAB)"
          playground={<ColorPicker mode="jab" hideValue />}
          >
          <p>CAM02 is not available in CSS, but available to use in various color libraries (such as <a href="https://gramaz.io/d3-cam02/" target="_blank" rel="noopener noreferrer">d3-cam02</a>).</p>

        </Section>

        {/* <p>Cylindrical transformations provide a more human-centered approach to defining color, defined by three channels: <strong>luminance</strong>, <strong>chroma</strong>, and <strong>hue</strong>.</p> */}
        <Section level="5" splitView 
          title="Cylindrical transformation"
          playground={<ColorPicker mode="jch" hideValue/>}
          >
          <p>The cylindrical transformation of CAM02 uses properties Lightness, Chroma, and Hue.</p>

        </Section>
      </Section>


      {/* <Section title="Analysis of CAM02" level="2" >
        <p>Knowing which <Link to="color-space">color space</Link> to use as a designer can be confusing. This analysis could help you in determining whether or not CIELAB is the right color space to try.</p>

        <h5>Methods and derivation</h5>
        <Table
          header={['Derived from', <Link to="chromatic-adaptation">Chromatic adaptation</Link>, <Link to='lightness'>Lightness</Link>, 'White point']}
        >
          <TableRow>
            <Link to="xyz">XYZ</Link>
            <>TODO</>
            <>TODO</>
            <>TODO</>
          </TableRow>
        </Table>

        <h5>Pro's and con's of CAM02</h5>
        <Table 
          splitView
          header={['Known issues & limitations', 'Benefits & strengths']}
        >
          <TableRow>
            <ul>
              <li>Stuffs</li>
            </ul>
            <ul>
              <li>Device-independant</li>
            </ul>
          </TableRow>
        </Table>

      </Section> */}

      <Section title="How it affects UI design" level="4" className="elements">
        <p>Uniform color spaces are linear models of human vision. However, human perception is non-linear. Do not rely on these color spaces alone to create aesthetically balanced colors. </p>
        <p><Link to="/color-scales">Color scales</Link> can be created by <Link to="color-interpolation">interpolation</Link> in various color spaces.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to="/trichromacy">Trichromacy</Link></li>
          <li><Link to="color-opponent-process">Color opponent process</Link></li>
          <li><Link to="/color-scale">Color scales</Link></li>
          <li><Link to="/color-interpolation">Color interpolation</Link></li>
          <li><Link to="/cam16">CAM16</Link></li>
        </ul>
      </Section>

      <CitationList tools>
        <ToolCitation tool="Chroma.js" />
        <ToolCitation tool="Color.js" />
        <ToolCitation tool="D3 color" />
        <ToolCitation tool="Leonardo" />
      </CitationList>

      <CitationList>
        <Citation src="CAMS" page="290-301"/>
        <Citation author="Connor Gramazio" url="https://gramaz.io/d3-cam02/" title="CIECAM02 Color" />
        <Citation publication="MathWorks" url="https://www.mathworks.com/matlabcentral/fileexchange/62729-matplotlib-perceptually-uniform-colormaps" title="MatPlotLib Perceptually Uniform Colormaps" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default CAM02;