/**
 *  Note: All keywords must be in lower case,
 *  including keywords that are proper names.
 */

const SiteData = [
  {
    title: 'Introduction',
    category: 'uncategorized',
    path: '/introduction',
    keywords: ['about', 'how to', 'guide', 'guidelines', 'purpose', 'why', 'how']
  },
  /// Tutorials
  // {
  //   title: 'Choosing a color',
  //   category: 'Tutorials',
  //   path: '/choosing-a-color',
  //   keywords: ['tutorial', 'how to', 'chose', 'picking', 'selecting', 'color', 'colors', 'brand', 'product', 'primary', 'mood']
  // },
  // {
  //   title: 'Choosing a color space',
  //   category: 'Tutorials',
  //   path: '/choosing-a-color-space',
  //   keywords: ['tutorial', 'how to', 'chose', 'picking', 'selecting', 'color', 'colors', 'color space', 'color model', 'rgb', 'srgb', 'lab', 'lch', 'cielab', 'cielch', 'luv', 'cieluv', 'hsluv', 'hsl', 'hsv', 'hsb', 'hwb', 'ciecam02', 'cam02', 'color appearance model', 'uniform color space', 'perceptual balance', 'perception']
  // },
  // {
  //   title: 'Choosing a palette',
  //   category: 'Tutorials',
  //   path: '/choosing-a-palette',
  //   keywords: ['tutorial', 'how to', 'chose', 'color vision deficiency', 'colorblind', 'color blind', 'deuteranomaly', 'protanomaly', 'tritanomaly', 'deutan', 'protan', 'tritan', 'cvd', 'picking', 'selecting', 'color', 'colors', 'brand', 'product', 'primary', 'mood', 'harmony', 'relationship', 'monochromatic', 'analogous', 'complimentary', 'split complimentary', 'triadic', 'tetradic', 'square', 'scheme', 'full']
  // },
  // {
  //   title: 'Creating color scales',
  //   category: 'Tutorials',
  //   path: '/creating-color-scales',
  //   keywords: ['tutorial', 'how to', 'accessibility', 'a11y', 'accessible', 'inclusive', 'inclusion', 'chose', 'picking', 'selecting', 'color', 'colors', 'brand', 'product', 'color scale', 'gradient', 'tints', 'shades', 'tones', 'variable', 'values', 'lightnesses', 'options', 'aliases']
  // },
  // {
  //   title: 'Creating a theme',
  //   category: 'Tutorials',
  //   path: '/creating-a-theme',
  //   keywords: ['tutorial', 'how to', 'chose', 'picking', 'selecting', 'color', 'colors', 'brand', 'product', 'theme', 'scheme', 'dark', 'light', 'mode', 'color mode', 'contrast mode', 'branding', 'brands']
  // },
  // {
  //   title: 'Personalization',
  //   category: 'Tutorials',
  //   path: '/personalization',
  //   keywords: ['tutorial', 'how to', 'chose', 'picking', 'selecting', 'color', 'colors', 'brand', 'product', 'theme', 'scheme', 'dark', 'light', 'mode', 'color mode', 'contrast mode', 'branding', 'brands', 'accessibility', 'a11y', 'accessible', 'inclusive', 'inclusion', 'color vision deficiency', 'colorblind', 'color blind', 'deuteranomaly', 'protanomaly', 'tritanomaly', 'deutan', 'protan', 'tritan', 'cvd']
  // },

  /// OPTICS OF THE EYE
  {
    title: "Cornea",
    category: 'Physiology of the eye',
    path: '/cornea',
    keywords: [''] 
  },
  {
    title: "Pupil & iris",
    category: 'Physiology of the eye',
    path: '/pupil-and-iris',
    keywords: [''] 
  },
  {
    title: "Lens",
    category: 'Physiology of the eye',
    path: '/lens',
    keywords: [''] 
  },
  {
    title: "Retina",
    category: 'Physiology of the eye',
    path: '/retina',
    keywords: [''] 
  },


  /// Color vision
  {
    title: 'Trichromacy',
    category: 'Color vision',
    path: '/trichromacy',
    keywords: ['trichromatic vision', 'vision', 'red', 'green', 'blue', 'short', 'medium', 'long', 'swl', 'mwl', 'lwl', 'cones', 'photopigment', 'pigment', 'spectrum', 'visible', 'light']
  },
  {
    title: 'Color opponent process',
    category: 'Color vision',
    path: '/color-opponent-process',
    keywords: ['trichromatic', 'vision', 'red', 'green', 'blue', 'yellow', 'red-green', 'green-red', 'blue-yellow', 'yellow-blue', 'opposition', 'opposing', 'compliment', 'complimentary', 'opponency', 'color opponency', 'channels', 'channel', 'short', 'medium', 'long', 'swl', 'mwl', 'lwl', 'cones', 'photopigment', 'pigment', 'spectrum', 'visible', 'light']
  },
  {
    title: 'Chromatic aberration',
    category: 'Color vision',
    path: '/chromatic-aberration',
    keywords: ['aberration', 'trichromatic', 'vision', 'red', 'green', 'blue', 'light', 'refract', 'bend', 'reflect', 'focal', 'focal point', 'focus', 'depth', 'chromostereopsis', 'vibrate', 'vibration', 'contrast']
  },
  {
    title: 'Spectral colors',
    category: 'Color vision',
    path: '/spectral-colors',
    keywords: [ 'color', 'wavelength', 'frequency', 'rainbow', 'prism', 'visible', 'spectrum', 'red', 'orange', 'yellow', 'green', 'blue']
  },
  {
    title: 'Non-spectral colors',
    category: 'Color vision',
    path: '/non-spectral-colors',
    keywords: [ 'color', 'wavelength', 'frequency', 'rainbow', 'prism', 'visible', 'spectrum', 'purple', 'pink']
  },

   // VISUAL ACUITY
  {
    title: 'Contrast sensitivity',
    category: 'Contrast & visual acuity',
    path: '/contrast-sensitivity',
    keywords: ['contrast', 'low vision', 'acuity', 'luminance', 'discriminate', 'discrimination', 'luminosity', 'lightness', 'darkness', 'wcag', 'relative luminance', 'contrast ratio', 'apca', 'advanced perceptual contrast algorithm', 'accessibility', 'accessible', 'inclusive', 'inclusion', 'sensitivity', 'acuity', 'distinguish', 'spatial', 'frequency', 'cataracts', 'optic nerve', 'bands', 'boundaries', 'edge detection', 'typography', 'type', 'font', 'text size', 'font weight', 'low contrast', 'high contrast', 'windows']
  },
  {
    title: 'Contrast discrimination',
    category: 'Contrast & visual acuity',
    path: '/contrast-discrimination',
    keywords: ['contrast', 'low vision', 'acuity', 'luminance', 'discriminate', 'luminosity', 'lightness', 'darkness', 'wcag', 'relative luminance', 'contrast ratio', 'apca', 'advanced perceptual contrast algorithm', 'accessibility', 'accessible', 'inclusive', 'inclusion', 'sensitivity', 'acuity', 'distinguish', 'spatial', 'frequency', 'cataracts', 'optic nerve', 'bands', 'boundaries', 'edge detection', 'typography', 'type', 'font', 'text size', 'font weight', 'low contrast', 'high contrast', 'windows', 'chubb', 'illusion', 'checker']
  },  
  {
    title: 'Contrast polarity',
    category: 'Contrast & visual acuity',
    path: '/contrast-polarity',
    keywords: ['contrast', 'low vision', 'acuity', 'luminance', 'discriminate', 'luminosity', 'lightness', 'darkness', 'wcag', 'relative luminance', 'contrast ratio', 'apca', 'advanced perceptual contrast algorithm', 'accessibility', 'accessible', 'inclusive', 'inclusion', 'sensitivity', 'acuity', 'distinguish', 'spatial', 'frequency', 'cataracts', 'optic nerve', 'bands', 'boundaries', 'edge detection', 'typography', 'type', 'font', 'text size', 'font weight', 'low contrast', 'high contrast', 'windows', 'chubb', 'illusion', 'checker', 'dark mode', 'dark theme']
  },  
  {
    title: 'Dynamic range',
    category: 'Contrast & visual acuity',
    path: '/dynamic-range',
    keywords: ['contrast', 'luminance', 'mesopic', 'photopic', 'scotopic', 'light']
  },
  {
    title: 'Lateral inhibition',
    category: 'Contrast & visual acuity',
    path: '/lateral-inhibition',
    keywords: ['contrast', 'luminance', 'discriminate', 'luminosity', 'lightness', 'darkness', 'contrast ratio', 'accessibility', 'accessible', 'inclusive', 'inclusion', 'sensitivity', 'acuity', 'distinguish', 'bands', 'boundaries', 'edge detection', 'white', "white's", 'illusion', 'mach', 'dark', 'light']
  },
  {
    title: 'Optical glare',
    category: 'Contrast & visual acuity',
    path: '/optical-glare',
    keywords: ['light', 'halation', 'halo', 'spreading', 'light']
  },
  {
    title: "Stevens' power law",
    category: 'Contrast & visual acuity',
    path: '/stevens-power-law',
    keywords: ['appearance', 'adjacent', 'color scale', 'sequence', 'beside', 'weber', 'fechner', 'progress', 'progression', 'progressive', 'rate', 'stimulus', 'multiply', 'function']
  },
  {
    title: 'Visual acuity',
    category: 'Contrast & visual acuity',
    path: '/visual-acuity',
    keywords: ['vision', 'sight', 'nearsighted', 'farsighted', 'myopia', 'hyperopia' , 'contrast', 'low vision', 'acuity', 'luminance', 'discriminate', 'discrimination', 'luminosity', 'lightness', 'darkness', 'wcag', 'relative luminance', 'contrast ratio', 'apca', 'advanced perceptual contrast algorithm', 'accessibility', 'accessible', 'inclusive', 'inclusion', 'sensitivity', 'acuity', 'distinguish', 'spatial', 'frequency', 'cataracts', 'optic nerve', 'bands', 'boundaries', 'edge detection', 'typography', 'type', 'font', 'text size', 'font weight', 'low contrast', 'high contrast', 'windows']
  },


  // SENSORY ADAPTATIONS
  {
    title: 'Chromatic adaptation',
    category: 'Sensory adaptations',
    path: '/chromatic-adaptation',
    keywords: ['hue', 'chroma', 'chromaticity', 'saturation', 'brilliance',  'appearance', 'color constancy', 'chromatic bias', 'adjust', 'adjustment', 'change', 'cones', 'suppress', 'suppression', 'prolonged exposure', 'the dress', 'afterimage', 'after image', 'color opponency', 'opponent process']
  },
  {
    title: 'Contrast adaptation',
    category: 'Sensory adaptations',
    path: '/contrast-adaptation',
    keywords: ['contrast', 'adaptive', 'adaptable', 'change', 'low vision', 'acuity', 'luminance', 'discriminate', 'luminosity', 'lightness', 'darkness', 'wcag', 'relative luminance', 'contrast ratio', 'apca', 'advanced perceptual contrast algorithm', 'accessibility', 'accessible', 'inclusive', 'inclusion', 'sensitivity', 'acuity', 'distinguish', 'spatial', 'frequency', 'cataracts', 'optic nerve', 'bands', 'boundaries', 'edge detection', 'typography', 'type', 'font', 'text size', 'font weight', 'low contrast', 'high contrast', 'windows', 'chubb', 'illusion', 'checker']
  },
  {
    title: 'Light & dark adaptation',
    category: 'Sensory adaptations',
    path: '/light-and-dark-adaptation',
    keywords: ['light and dark adaptation', 'dark adaptation', 'light adaptation', 'vision', 'light', 'contrast', 'sensitivity', 'light', 'adapt', 'adaptation', 'shift', 'pupil', 'photopigment', 'pigment', 'cones', 'rods', 'response', 'responsive', 'hurt', 'dark' ]
  },


  // VISUAL IMPAIRMENTS
  {
    title: 'Myopia',
    category: 'Visual impairments',
    path: '/myopia',
    keywords: ['vision', 'impairment', 'corrective', 'lens', 'aberration', 'nearsightedness', 'near-sightedness', 'glasses']
  },
  {
    title: 'Hyperopia',
    category: 'Visual impairments',
    path: '/hyperopia',
    keywords: ['vision', 'impairment', 'corrective', 'lens', 'aberration', 'farsightedness', 'far-sightedness', 'glasses']
  },
  {
    title: 'Presbyopia',
    category: 'Visual impairments',
    path: '/presbyopia',
    keywords: ['vision', 'impairment', 'corrective', 'lens', 'flexible', 'inflexible', 'bifocal', 'trifocal', 'glasses', 'age', 'aging']
  },
  {
    title: 'Cataracts',
    category: 'Visual impairments',
    path: '/cataracts',
    keywords: ['vision', 'cataract', 'cataracts', 'glaucoma', 'impairment', 'corrective', 'lens', 'clouding', 'blurry', 'bluriness', 'age', 'aging']
  },
  {
    title: 'Astigmatism',
    category: 'Visual impairments',
    path: '/astigmatism',
    keywords: ['vision', 'impairment', 'corrective', 'lens', 'aberration', 'glasses']
  },
  {
    title: 'Light sensitivity',
    category: 'Visual impairments',
    path: '/light-sensitivity',
    keywords: ['vision', 'light', 'contrast', 'inclusive', 'inclusion', 'accessible', 'accessibility', 'a11y', 'sensitivity', 'light', 'migraines', 'hurt', 'dark' ]
  },
  {
    title: 'Color vision deficiency',
    category: 'Visual impairments',
    path: '/color-vision-deficiency',
    keywords: ['cvd', 'colorblindness', 'color blind', 'colorblind', 'light', 'contrast', 'inclusive', 'inclusion', 'accessible', 'accessibility', 'a11y', 'sensitivity', 'light', 'migraines', 'hurt', 'dark' ]
  },
  {
    title: 'Anomalous trichromacy',
    category: 'Visual impairments',
    path: '/anomalous-trichromacy',
    keywords: ['trichromacy', 'protan', 'deutan', 'tritan', 'protanomaly', 'deutanomaly', 'tritanomaly', 'cvd', 'trichromatic vision', 'red-green', 'red green', 'blue-yellow', 'blue yellow', 'trichromatic vision', 'vision', 'red', 'green', 'color vision deficiency', 'colorblindness', 'color blind', 'colorblind', 'inclusive', 'inclusion', 'accessible', 'accessibility', 'a11y', 'sensitivity' ]
  },
  {
    title: 'Dichromacy',
    category: 'Visual impairments',
    path: '/dichromacy',
    keywords: ['cvd', 'dichromatic vision','protan', 'deutan', 'tritan', 'protanopia', 'deutanopia', 'tritanopia','dichromat', 'red blind', 'green blind', 'blue blind', 'vision', 'color vision deficiency', 'colorblindness', 'color blind', 'colorblind', 'inclusive', 'inclusion', 'accessible', 'accessibility', 'a11y', 'sensitivity' ]
  },
  {
    title: 'Monochromacy',
    category: 'Visual impairments',
    path: '/monochromacy',
    keywords: [ 'cvd', 'scotopic', 'pigment', 'rare', 'cerebral achromatopsia', 'monochromatism', 'blue cone', 'BCM', 'genetic', 'inherited', 'monochromatic vision', 'achromatopsia', 'vision', 'color vision deficiency', 'colorblindness', 'color blind', 'colorblind', 'inclusive', 'inclusion', 'accessible', 'accessibility', 'a11y', 'sensitivity', 'stroke', 'cone', 'pigment', 'cerebral achromatopsia', 'cerebral monochromacy', 'missing']
  },


  // COLOR APPEARANCE PHENOMENA
  {
    title: 'Abney effect',
    category: 'Color appearance phenomena',
    path: '/abney-effect',
    keywords: ['purity on hue', 'hue', 'shift', 'appearance', 'added', 'mixing', 'additive']
  },
  {
    title: 'Bezold–Brücke shift',
    category: 'Color appearance phenomena',
    path: '/bezold-brucke-shift',
    keywords: ['hue', 'shift', 'appearance', 'temperature', 'warm', 'intensity', 'light', 'luminance', 'adaptation', 'luminosity', 'change']
  },
  {
    title: 'Bezold effect',
    category: 'Color appearance phenomena',
    path: '/bezold-effect',
    keywords: ['hue', 'chroma', 'chromaticity', 'saturation', 'brilliance',  'lightness', 'appearance', 'simultaneous', 'color constancy', 'chromatic bias', 'contrast', 'color contrast', 'spatial mixing', 'spatial color mixing', "white's illusion", 'white illusion']
  },
  {
    title: 'Chromostereopsis',
    category: 'Color appearance phenomena',
    path: '/chromostereopsis',
    keywords: ['chroma', 'chromaticity', 'saturation', 'brilliance',  'vibration', 'vibrate', 'focal depth', 'chromatic aberration', 'aberration', 'refract', 'refraction', 'depth']
  },
  {
    title: 'Helmholtz–Kohlrausch effect',
    category: 'Color appearance phenomena',
    path: '/helmholtz-kohlrausch-effect',
    keywords: ['helmoltz-kohlrausch', 'helmoltz', 'helm', 'hel', 'hem', 'kohl', 'kohlrausch', 'luminance on hue', 'hue', 'chroma', 'chromaticity', 'saturation', 'brilliance',  'perceived lightness', 'lightness', 'brightness', 'luminance', 'luminosity', 'appearance']
  },
  // {
  //   title: 'Helson–Judd effect',
  //   category: 'Color appearance phenomena',
  //   path: '/helson-judd-effect',
  //   keywords: ['helson-judd', 'helson', 'judd', 'warm', 'cool', 'color temperature']
  // },
  {
    title: 'Color constancy',
    category: 'Color appearance phenomena',
    path: '/color-constancy',
    keywords: ['hue', 'appearance', 'lightness', 'value', 'darkness', 'luminance', 'luminosity', 'chromatic adaptation', 'chromatic bias', 'adjust', 'adjustment', 'change', 'the dress', 'checker shadow illusion']
  },
  {
    title: 'Hunt effect',
    category: 'Color appearance phenomena',
    path: '/hunt-effect',
    keywords: ['hue', 'appearance', 'lightness', 'value', 'darkness', 'luminance', 'luminosity', 'adjust', 'adjustment', 'change', 'chroma', 'chromaticity', 'saturation', 'desaturate', 'saturate']
  },
  {
    title: 'Purkinje shift',
    category: 'Color appearance phenomena',
    path: '/purkinje-shift',
    keywords: ['hue', 'appearance', 'lightness', 'darkness', 'luminance', 'luminosity', 'effect', 'adjust', 'adjustment', 'change', 'shift', 'temperature', 'cool', 'warm', 'intensity', 'light', 'adaptation']
  },
  {
    title: 'Simultaneous contrast',
    category: 'Color appearance phenomena',
    path: '/simultaneous-contrast',
    keywords: ['lightness', 'brightness', 'brilliance', 'value', 'adjacent', 'surround', 'affected', 'appearance', 'chroma', 'chromaticity', 'saturation', 'hue', 'color']
  },
  {
    title: 'Spatial color mixing',
    category: 'Color appearance phenomena',
    path: '/spatial-color-mixing',
    keywords: ['hue', 'appearance', 'mix', 'blend', 'mixing', 'blending', 'optical']
  },
  {
    title: 'Successive contrast',
    category: 'Color appearance phenomena',
    path: '/successive-contrast',
    keywords: ['hue', 'chroma', 'chromaticity', 'saturation', 'brilliance',  'appearance', 'adjust', 'adjustment', 'change', 'cones', 'suppress', 'suppression', 'prolonged exposure', 'afterimage', 'after image', 'color opponency', 'opponent process']
  },

  // PROPERTIES OF COLOR
  {
    title: 'Color psychology',
    category: 'Properties of color',
    path: '/color-psychology',
    keywords: ['color', 'mood', 'moods', 'emotion', 'emotions', 'meaning', 'adjectives', 'descriptive', 'descriptions']
  },
  {
    title: 'Hue',
    category: 'Properties of color',
    path: '/hue',
    keywords: ['color', 'name', 'red', 'green', 'blue', 'orange', 'yellow', 'purple', 'fuchsia', 'chartreuse', 'lime', 'pink', 'brown', 'degree', 'degrees']
  },
  {
    title: 'Chroma',
    category: 'Properties of color',
    path: '/chroma',
    keywords: ['color', 'chroma', 'chromaticity', 'saturation', 'brilliance', 'vibrancy', 'intensity', 'pure', 'purity', 'vibrant', 'brilliant', 'tone', 'colorful', 'colorfulness']
  },
  {
    title: 'Lightness',
    category: 'Properties of color',
    path: '/lightness',
    keywords: ['color', 'lightness', 'value', 'brightness', 'luminosity', 'luminance', 'tint', 'shade']
  },
  {
    title: 'Opacity',
    category: 'Properties of color',
    path: '/opacity',
    keywords: ['color', 'alpha', 'transparent', 'transparency', 'translucent', 'translucency', 'opaque']
  },
  {
    title: 'Tone',
    category: 'Properties of color',
    path: '/tone',
    keywords: ['color', 'chroma', 'chromaticity', 'saturation', 'brilliance', 'vibrancy', 'intensity', 'pure', 'purity', 'vibrant', 'brilliant', 'tone', 'colorful', 'colorfulness']
  },
  {
    title: 'Tint',
    category: 'Properties of color',
    path: '/tint',
    keywords: ['color', 'lightness', 'value', 'brightness', 'luminosity', 'luminance', 'tint', 'shade']
  },
  {
    title: 'Shade',
    category: 'Properties of color',
    path: '/shade',
    keywords: ['color', 'lightness', 'value', 'brightness', 'luminosity', 'luminance', 'tint', 'shade']
  },
  {
    title: 'Chromaticity',
    category: 'Properties of color',
    path: '/chromaticity',
    keywords: ['color', 'cie', 'diagram', 'chroma', 'saturation', 'brilliance', 'vibrancy', 'intensity', 'pure', 'purity', 'vibrant', 'brilliant', 'tone', 'colorful', 'colorfulness']
  },

  // COLOR MIXING
  {
    title: 'Additive',
    category: 'Color mixing',
    path: '/additive',
    keywords: ['light', 'emit', 'emitted', 'source', 'mix', 'mixing', 'rgb', 'wavelength']
  },
  {
    title: 'Subtractive',
    category: 'Color mixing',
    path: '/subtractive',
    keywords: ['pigment', 'paint', 'reflective', 'reflect', 'reflected', 'mix', 'mixing', 'cmyk', 'ryb']
  },

  // RELATIONSHIPS
  {
    title: 'Warm & cool colors',
    category: 'Relationships',
    path: '/warm-and-cool-colors',
    keywords: ['temperature', 'color wheel', 'cool', 'warm', 'complimentary', 'hue']
  },
  {
    title: 'Analogous colors',
    category: 'Relationships',
    path: '/analogous-colors',
    keywords: ['harmony', 'scheme', 'palette', 'adjacent', 'proximity', 'similar', 'hue']
  },
  {
    title: 'Complimentary colors',
    category: 'Relationships',
    path: '/complimentary-colors',
    keywords: ['harmony', 'scheme', 'palette', 'opposite', 'proximity', 'opponent', 'opponency', 'hue']
  },
  {
    title: 'Split complimentary colors',
    category: 'Relationships',
    path: '/split-complimentary-colors',
    keywords: ['harmony', 'scheme', 'palette', 'opposite', 'proximity', 'opponent', 'opponency', 'hue']
  },
  {
    title: 'Triadic colors',
    category: 'Relationships',
    path: '/triadic-colors',
    keywords: ['harmony', 'scheme', 'palette', 'hue', 'triangle']
  },
  {
    title: 'Tetradic colors',
    category: 'Relationships',
    path: '/tetradic-colors',
    keywords: ['harmony', 'scheme', 'palette', 'hue', 'rectangle', 'square']
  },
  {
    title: 'Monochromatic colors',
    category: 'Relationships',
    path: '/monochromatic-colors',
    keywords: ['monochrome', 'achromatic', 'abney', 'effect', 'lightness', 'luminosity', 'luminance', 'value', 'scale']
  },
  {
    title: 'Polygonal colors',
    category: 'Relationships',
    path: '/polygonal-colors',
    keywords: ['harmony', 'scheme', 'palette', 'hue', 'even', 'distribute', 'distribution', 'distributed', 'balance']
  },
  ///////////
  {
    title: 'Color model',
    category: 'Color models',
    path: '/color-model',
    keywords: ['describe', 'description', 'mode', 'format', 'wheel', 'sphere', 'munsell', 'property', 'properties', 'primary', 'rgb', 'ryb', 'cmyk', 'appearance']
  },
  {
    title: 'RGB model',
    category: 'Color models',
    path: '/rgb-model',
    keywords: ['describe', 'description', 'mode', 'format', 'property', 'properties', 'primary', 'trichromatic', 'trichromacy', 'wavelength', 'sensitivity', 'cones', 'pigment', 'cube', 'additive']
  },
  {
    title: 'RYB model',
    category: 'Color models',
    path: '/ryb-model',
    keywords: ['describe', 'description', 'mode', 'format', 'property', 'properties', 'primary', 'trichromatic', 'trichromacy', 'subtractive', 'paint', 'traditional']
  },
  {
    title: 'CMYK model',
    category: 'Color models',
    path: '/cmyk-model',
    keywords: ['describe', 'description', 'mode', 'format', 'property', 'properties', 'primary', 'trichromatic', 'trichromacy', 'subtractive', 'print', 'paint']
  },

  ////////// COLOR SPACES
  {
    title: 'Color space',
    category: 'Color spaces',
    path: '/color-space',
    keywords: ['space', 'implementation', 'mode', 'format', 'math', 'spaces', 'formula', 'gamut', 'transform', 'transformations', 'cylindrical', 'cylinder', 'cube']
  },
  {
    title: 'Color profile',
    category: 'Color spaces',
    path: '/color-profile',
    keywords: ['space', 'implementation', 'mode', 'format', 'ICC', 'profile']
  },
  {
    title: 'Color appearance model',
    category: 'Color spaces',
    path: '/color-appearance-model',
    keywords: ['describe', 'description', 'mode', 'format', 'property', 'properties', 'primary', 'opponency', 'opponent', 'subtractive', 'reflective', 'perception']
  },
  {
    title: 'Gamut',
    category: 'Color spaces',
    path: '/gamut',
    keywords: ['gamut', 'range', 'hdr', 'high dynamic range', 'retina', 'display', 'profile']
  },
  // {
  //   title: 'Clamping',
  //   category: 'Color spaces',
  //   path: '/clamping',
  //   keywords: ['gamut', 'range', 'hdr', 'high dynamic range', 'retina', 'display', 'profile', 'round', 'clamp']
  // },
  {
    title: 'sRGB',
    category: 'Color spaces',
    path: '/srgb',
    keywords: ['space', 'implementation', 'math', 'number', 'numeric', 'mode', 'format', 'property', 'properties', 'primary', 'trichromatic', 'trichromacy', 'wavelength', 'sensitivity', 'cones', 'pigment', 'cube', 'additive', 'web', 'browser', 'hex', 'hsl', 'hsv', 'hsi', 'rgb', 'hwb', 'cylinder', 'cylindrical', 'transform', 'picker', 'pick', 'color picker']
  },
  {
    title: 'Display P3',
    category: 'Color spaces',
    path: '/display-p3',
    keywords: ['space', 'implementation', 'math', 'number', 'numeric', 'mode', 'format', 'property', 'properties', 'primary', 'trichromatic', 'trichromacy', 'wavelength', 'sensitivity', 'cones', 'pigment', 'additive', 'web', 'browser', 'picker', 'pick', 'color picker']
  },
  {
    title: 'LMS',
    category: 'Color spaces',
    path: '/lms',
    keywords: ['trichromacy', 'trichromatic', 'wavelength', 'photoreceptor', 'cones', 'rods', 'cie', 'uniform', 'perceptual', 'perceptually', 'space', 'implementation', 'math', 'number', 'numeric', 'mode', 'format', 'property', 'properties', 'opponent', 'opponency', 'subtractive', 'independant', 'device', 'browser', 'picker', 'pick', 'color picker']
  },
  {
    title: 'XYZ',
    category: 'Color spaces',
    path: '/xyz',
    keywords: ['cie', 'uniform', 'perceptual', 'perceptually', 'space', 'implementation', 'math', 'number', 'numeric', 'mode', 'format', 'property', 'properties', 'opponent', 'opponency', 'subtractive', 'independant', 'device', 'browser', 'picker', 'pick', 'color picker']
  },
  {
    title: 'CIELAB',
    category: 'Color spaces',
    path: '/cielab',
    keywords: ['cielab', 'uniform', 'perceptual', 'perceptually', 'lch', 'cielch', 'hcl', 'cie', 'space', 'implementation', 'math', 'number', 'numeric', 'mode', 'format', 'property', 'properties', 'opponent', 'opponency', 'subtractive', 'independant', 'device', 'browser', 'picker', 'pick', 'color picker']
  },
  {
    title: 'CIELUV',
    category: 'Color spaces',
    path: '/cieluv',
    keywords: ['cieluv', 'alexei', 'boronine', 'cylindrical', 'cylinder', 'uniform', 'perceptual', 'perceptually', 'hsluv', 'cie', 'space', 'implementation', 'math', 'number', 'numeric', 'mode', 'format', 'property', 'properties', 'opponent', 'opponency', 'additive', 'independant', 'picker', 'pick', 'color picker']
  },
  {
    title: 'CAM02',
    category: 'Color spaces',
    path: '/cam02',
    keywords: ['cielab', 'appearance', 'color appearance model', 'ciecam', 'ciecam02', 'ciecam02-ucs', 'uniform', 'perceptual', 'perceptually', 'cie', 'space', 'implementation', 'math', 'number', 'numeric', 'mode', 'format', 'property', 'properties', 'opponent', 'opponency', 'subtractive', 'independant', 'device', 'picker', 'pick', 'color picker']
  },
  {
    title: 'CAM16',
    category: 'Color spaces',
    path: '/cam16',
    keywords: ['cielab', 'appearance', 'color appearance model', 'ciecam', 'ciecam16', 'ciecam16-ucs', 'uniform', 'perceptual', 'perceptually', 'cie', 'space', 'implementation', 'math', 'number', 'numeric', 'mode', 'format', 'property', 'properties', 'opponent', 'opponency', 'subtractive', 'independant', 'device', 'picker', 'pick', 'color picker']
  },
  // {
  //   title: 'IPT',
  //   category: 'Color spaces',
  //   path: '/ipt',
  //   keywords: ['appearance', 'color appearance model', 'ictcp', 'uniform', 'perceptual', 'perceptually', 'rec', 'rec.2100', 'rec2100', 'space', 'implementation', 'math', 'number', 'numeric', 'mode', 'format', 'property', 'properties', 'opponent', 'opponency', 'additive', 'wide gamut', 'hdr', 'high dynamic range', 'monitors', 'device', 'picker', 'pick', 'color picker']
  // },
  {
    title: 'OKLAB',
    category: 'Color spaces',
    path: '/oklab',
    keywords: ['oklch',  'uniform', 'perceptual', 'perceptually', 'bjorn', 'ottosson', 'cam16', 'ipt', 'space', 'implementation', 'math', 'number', 'numeric', 'mode', 'format', 'property', 'properties', 'opponent', 'opponency', 'subtractive', 'independant', 'device', 'browser', 'picker', 'pick', 'color picker']
  },

  //////////
  {
    title: 'APCA',
    category: 'Measurements & formulas',
    path: '/apca',
    keywords: ['light', 'dark', 'adaptation', 'spatial', 'frequency', 'simultaneous', 'contrast', 'advanced', 'perceptual', 'formula', 'difference', 'algorithm', 'wcag', 'wcag 3', 'calculate', 'calculation', 'accessibility', 'accessible', 'a11y', 'inclusive', 'inclusion', 'compliance', 'compliant', 'guideline', 'guidelines', 'criteria', 'measurement', 'sensitivity', 'distinction', 'distinguish', 'discriminate', 'discrimination', 'cataracts', 'acuity', 'low vision']
  },
  {
    title: 'Color confusion lines',
    category: 'Measurements & formulas',
    path: '/color-confusion-lines',
    keywords: []
  },
  {
    title: 'Color difference',
    category: 'Measurements & formulas',
    path: '/color-difference',
    keywords: ['contrast', 'difference', 'differ', 'complimentary', 'delta', 'deltae', 'delta-e', 'distance', 'euclidian', 'formula', 'just noticeable', 'noticeable', 'jnd', 'macadam ellipses', 'macadam', 'macadam ellipse', 'ellipse']
  },
  {
    title: 'Color temperature',
    category: 'Measurements & formulas',
    path: '/color-temperature',
    keywords: ['temperature', 'cool', 'warm', 'complimentary', 'hue', 'degrees', 'kelvin', 'light']
  },
  {
    title: 'Luminance',
    category: 'Measurements & formulas',
    path: '/luminance',
    keywords: ['calculate', 'calculation', 'lightness', 'value', 'luminance', 'luminosity']
  },
  {
    title: 'Relative luminance',
    category: 'Measurements & formulas',
    path: '/relative-luminance',
    keywords: ['contrast', 'difference', 'formula', 'contrast ratio', 'accessibility', 'calculate', 'calculation', 'accessible', 'a11y', 'wcag 2', 'wcag', 'compliance', 'compliant', 'guideline', 'guidelines', 'criteria', 'measurement', 'sensitivity', 'distinction', 'distinguish', 'discriminate', 'discrimination', 'cataracts', 'acuity', 'low vision']
  },
  // {
  //   title: 'Von Kries',
  //   category: 'Measurements & formulas',
  //   path: '/von-kries',
  //   keywords: ['formula', 'color appearance', 'chromatic adaptation', 'transformation', 'method']
  // },
  {
    title: 'Color interpolation',
    category: 'Measurements & formulas',
    path: '/color-interpolation',
    keywords: ['gradient', 'ramp', 'stop', 'color stop', 'stops', 'color stops', 'design system', 'design systems', 'palette', 'scheme', 'swatch', 'swatches', 'contrast', 'wcag', 'contrast ratio', 'progression', 'balance', 'uniform', 'perceptual', 'hue', 'tint', 'shade', 'lightness', 'monochrome', 'monochromatic', 'monochromatic scale', 'bezold-brucke shift', 'purity on hue', 'abney', 'lateral inhibition', 'extrapolation', 'function', 'programatic', 'calculate', 'calculation', 'formula', 'formulaeic']
  },
  /////// COLOR MODES
  {
    title: 'Light mode',
    category: 'Device color modes',
    path: '/light-mode',
    keywords: ['light', 'adaptation', 'theme', 'device', 'operating system', 'shift', 'adjust', 'adjustment', 'preference', 'user preference', 'media query', 'prefers-color-scheme',]
  },
  {
    title: 'Dark mode',
    category: 'Device color modes',
    path: '/dark-mode',
    keywords: ['dark', 'adaptation', 'theme', 'device', 'operating system', 'shift', 'adjust', 'adjustment', 'preference', 'user preference', 'media query', 'prefers-color-scheme',]
  },
  {
    title: 'Increase contrast mode',
    category: 'Device color modes',
    path: '/increase-contrast-mode',
    keywords: ['contrast', 'high contrast', 'increase', 'mac', 'ios', 'setting', 'accessibility', 'a11y', 'accessible', 'inclusive', 'incusion', 'contrast sensitivity', 'sensitivity', 'adaptation', 'theme', 'device', 'operating system', 'shift', 'adjust', 'adjustment', 'preference', 'user preference', 'media query', 'prefers-contrast']
  },
  {
    title: 'High contrast mode',
    category: 'Device color modes',
    path: '/high-contrast-mode',
    keywords: ['contrast', 'high contrast', 'windows', 'whcm', 'windows high contrast', 'windows high contrast mode', 'increase', 'decrease', 'setting', 'accessibility', 'a11y', 'accessible', 'inclusive', 'incusion', 'contrast sensitivity', 'custom', 'customize', 'keywords', 'key words', 'ButtonFace', "ButtonText", 'Canvas', "CanvasText", 'Highlight', 'HighlightText', 'LinkText', 'media query', 'forced-colors', 'prefers-color-scheme', 'sensitivity', 'light', 'dark', 'migraine', 'cataract', 'adaptation', 'theme', 'device', 'operating system', 'shift', 'adjust', 'adjustment', 'preference', 'user preference', 'personalize', 'personalization']
  },

  ///
  {
    title: 'Color scale',
    category: 'UI & Data Visualization',
    path: '/color-scale',
    keywords: ['gradient', 'ramp', 'stop', 'color stop', 'stops', 'color stops', 'palette', 'scheme', 'swatch', 'swatches', 'progression', 'balance','perceptual']
  },
  {
    title: 'UI color scale',
    category: 'UI & Data Visualization',
    path: '/ui-color-scale',
    keywords: ['gradient', 'ramp', 'stop', 'color stop', 'stops', 'color stops', 'design system', 'design systems', 'palette', 'scheme', 'swatch', 'swatches', 'contrast', 'progression', 'balance','perceptual', 'monochromatic', 'monochromatic scale', 'bezold-brucke shift']
  },
  {
    title: 'Sequential scale',
    category: 'UI & Data Visualization',
    path: '/sequential-scale',
    keywords: ['gradient', 'ramp', 'stop', 'color stop', 'stops', 'color stops', 'data visualization', 'palette', 'scheme', 'progression']
  },
  {
    title: 'Diverging scale',
    category: 'UI & Data Visualization',
    path: '/diverging-scale',
    keywords: ['gradient', 'ramp', 'stop', 'color stop', 'stops', 'color stops', 'data visualization', 'palette', 'scheme', 'progression']
  },
  {
    title: 'Categorical scale',
    category: 'UI & Data Visualization',
    path: '/categorical-scale',
    keywords: ['gradient', 'ramp', 'data visualization', 'palette', 'scheme', 'colorblind', 'qualitative']
  },
  {
    title: 'Color theme',
    category: 'UI & Data Visualization',
    path: '/color-theme',
    keywords: ['light', 'dark', 'theme', 'scheme', 'palette', 'mode', 'brand', 'branding', 'contrast']
  },
  {
    title: 'Adaptive color theme',
    category: 'UI & Data Visualization',
    path: '/adaptive-color-theme',
    keywords: ['light', 'dark', 'theme', 'scheme', 'palette', 'mode', 'brand', 'branding', 'contrast', 'accessibility', 'accessible', 'a11y', 'inclusive', 'inclusion', 'user preference', 'preference', 'user', 'custom', 'customize', 'adaptable', 'variable', 'personalize', 'personalization']
  },



  {
    title: 'References',
    category: 'uncategorized',
    path: '/references',
    keywords: ['sources', 'citation']
  },
];

export default SiteData;