import React from "react"
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';
import { Link } from "react-router-dom";

import Figure from '../img/Figure_Cataracts.png';
import FigureDark from '../img/Figure_CataractsDark.png';
import ImageSlider from "../components/ImageSlider";
import Image1 from '../img/VisualAcuity_2020.png';
import Image2 from '../img/VisualAcuity_Cataracts01.png';
import PageHeader from '../components/PageHeader';

import CitationList from "../components/CitationList";
import Citation from "../components/Citation";

function Cataracts(props) {
  const mq = window.matchMedia('(prefers-color-scheme: dark)');
  const IMG = (mq.matches) ? FigureDark : Figure;

  const Slider = <ImageSlider 
    id="Cataracts" 
    images={ [Image1, Image2] } 
    labelLeft="Normal vision" 
    labelRight="Cataracts" 
    height="292px"
    defaultValue="0"
    />

  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      key="visual-acuity"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Cataracts"
        description='The'
      />

      <PageHeader
        title="Cataracts"
        // subtitle="(Nearsightedness)"
        />

      <Section 
        splitView
        figure={IMG}
        caption="The clouding of the lens causes light to disperse, resulting in a blurry image projected on the retina."
      >
        <p className="body4">A cataract is when the <Link to="lens">lens</Link> of an eye clouds from protien build-up. Cataractss can emerge later in age, or can be caused by eye injury or other conditions (like glaucoma).</p>
        <p className="body4">Cataractss disperse light, causing <Link to="visual-acuity">blurred vision</Link>, <Link to="optical-glare">optical glare</Link>, and loss less <Link to="chroma">vibrant</Link> color perception. </p>
      </Section>

      <Section 
        title="See how it looks" 
        // splitLevel 
        level="2"
        >
          <Section
            playground={Slider}
            splitView
          >
          <p>Use the slider to see how varying progression of cataracts can affect a person's vision.</p>
          <p>Cataracts can be corrected with or surgery.</p>

          </Section>
      </Section>


      <Section title="How it affects UI design" level="4" className="elements">
        <p>Users may need to increase <strong>contrast</strong> or <strong>lightness</strong> of an interface for better legibility. Consider <Link to="/adaptive-color-theme">personalization</Link> settings to give users the ability to adjust contrast to meet their needs.</p>
        <p>WCAG <a href="https://www.w3.org/TR/2008/REC-WCAG20-20081211/#visual-audio-contrast-scale" target="_blank" rel="noopener noreferrer">1.4.4 (Resize text)</a> provides guidance on how to support users with resizable text.</p>
        <p>Designing with color vision loss in mind is important. Most issues can be resolved by compliance with <a href="https://www.w3.org/TR/WCAG21/#use-of-color" target="_blank" rel="noopener noreferrer">WCAG 1.4.1 (use of color)</a> and <a href="https://www.w3.org/TR/WCAG21/#contrast-minimum" target="_blank" rel="noopener noreferrer">1.4.3 (contrast minimum)</a>.</p>
        <p>When color identification is important to all sighted users, use a color tool to evaluate <Link to="color-difference">color difference</Link>. Greater color difference increases the chances of color identification with color vision loss, however it is not a guarantee.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/visual-acuity'> Visual acuity </Link></li>
          <li><Link to="/optical-glare">Optical glare</Link></li>
          <li><Link to='/lens'> Lens </Link></li>
          <li><Link to='/contrast-sensitivity'>Contrast sensitivity</Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="NIH NEI" url="https://www.nei.nih.gov/learn-about-eye-health/eye-conditions-and-diseases/cataracts" title="Cataracts" />
        <Citation src="AAO.org" url="https://www.aao.org/eye-health/diseases/what-are-cataracts" title="What are cataracts" />
      </CitationList>
      
      <Footer />

    </motion.div>
  )
}
 
export default Cataracts;