import React from "react"
import { useState } from "react";
import chroma from 'chroma-js';
import extendChroma from "../utils/chroma-plus";
import ColorPicker from './ColorPicker';
import ColorDescription from 'color-description/dist/index.esm';
import Section from './Section';

// import './ColorDetails.scss';

extendChroma(chroma);

const ColorDetails = (props) => {

  const [color, setColor] = useState('#f26322')

  const cd = new ColorDescription(color);
  // const mode = props.mode;

  // let details;
  // if(props.mode === 'emotions') details = cd.emotions;
  // if(props.mode === 'descriptions') details = cd.descriptiveWords;
  // if(props.mode === 'temperature') details = cd.temeratureWords;
  // if(props.mode === 'nouns') details = cd.nouns;
  // const name = cd.nouns;

  const emotionsList = cd.emotions.map((d, i) => { 
    if(i === 0) return d.charAt(0).toUpperCase() + d.slice(1) + ', ';
    if(i+1 < cd.emotions.length) return d.toString() + ', ';
    else return d.toString();
    })
  const descriptiveWordsList = cd.descriptiveWords.map((d, i) => { 
    if(i === 0) return d.charAt(0).toUpperCase() + d.slice(1) + ', ';
    if(i+1 < cd.descriptiveWords.length) return d.toString() + ', ';
    else return d.toString();
    })

  const nounsList = cd.nouns.map((d, i) => { 
    if(i === 0) return d.charAt(0).toUpperCase() + d.slice(1) + ((cd.nouns.length > 1) ? ', ' : '');
    if(i+1 < cd.nouns.length) return d.toString() + ', ';
    else return d.toString();
    })
  
  const nameLabel = (cd.nouns.length > 1) ? 'Names' : 'Name';
  
  return (
    <Section 
      splitView
      reversed
      playground={<ColorPicker mode="OKLCH" hideValue hideReset setter={setColor}/>}
      >
      
      <article className="ColorDetails-article">
        <p key={cd.nouns} className="body6" style={{marginTop: `0`, marginBottom: '0', color: `var(--gray600)`}}>{nameLabel}:</p>
        <p key={color} className="body4" style={{fontFamily: `var(--fontSerifStack)`, marginTop: '0'}}>{nounsList}</p>
      </article>

      {/* <article className="ColorDetails-article">
        <p key={cd.nouns} className="body6" style={{marginTop: `1rem`, marginBottom: '0', color: `var(--gray600)`}}>Temperature:</p>
        <p key={color} className="body4" style={{fontFamily: `var(--fontSerifStack)`, marginTop: '0'}}>{cd.temeratureWords.value.toString()} ({cd.temeratureWords.descriptive[0]})</p>
      </article> */}

      <article className="ColorDetails-article">
        <p key={cd.nouns} className="body6" style={{marginTop: `1rem`, marginBottom: '0', color: `var(--gray600)`}}>Emotions:</p>
        <p key={color} className="body4" style={{fontFamily: `var(--fontSerifStack)`, marginTop: '0'}}>{emotionsList}</p>
      </article>

      <article className="ColorDetails-article">
        <p key={cd.nouns} className="body6" style={{marginTop: `1rem`, marginBottom: '0', color: `var(--gray600)`}}>Descriptions:</p>
        <p key={color} className="body4" style={{fontFamily: `var(--fontSerifStack)`, marginTop: '0'}}>{descriptiveWordsList}</p>
      </article>



    </Section>
  )
}
 
export default ColorDetails;