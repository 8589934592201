import { 
  Link, 
  useParams 
} from "react-router-dom";
import SiteData from "../SiteData";
import './Pagination.scss'

const PaginationLink = ({ page, linkTo }) => {
  // const title = page.charAt(0).toUpperCase() + page.slice(1);
  const title = page;

  // let linkTo = (page === "Home") ? "" : page.replaceAll(" ", "-").replaceAll("'", "").replaceAll("?", "").replaceAll("ü", "u").replaceAll("&", "and").toLowerCase();
  
  return (
    <Link 
      to={`/${linkTo}`} 
      className='pagination-link' 
    >
      {title}
    </Link>
  );
};

const Pagination = (props) => {
  // const setter = props.setIsMenuOpen;
  
  const index = SiteData.map((e) => {
    return e.path
  }).indexOf(props.page);

  const prevItem = (index - 1 >= 0 ) ? SiteData[index - 1].title : '';
  const nextItem = (index + 1 < SiteData.length - 1) ? SiteData[index + 1].title : '';
  const prevLink = (index - 1 >= 0 ) ? SiteData[index - 1].path.replace('/', '') : '';
  const nextLink = (index + 1 < SiteData.length - 1) ? SiteData[index + 1].path.replace('/', '') : '';

  const previous = (index - 1 >= 0 && index < SiteData.length - 1 ) ? <div className="pagination-item">
        <span className="pagination-heading">Previous:</span>
        <PaginationLink page={prevItem} linkTo={prevLink} /> 
      </div> : '' ;

  const next = (index + 1 < SiteData.length - 1) ? <div className="pagination-item">
        <span className="pagination-heading">Next:</span>
        <PaginationLink page={nextItem} linkTo={nextLink} /> 
      </div> : '';
  
  const className = (index - 1 < 0) ? "pagination-wrapper pagination-end" : ((index + 1 > SiteData.length) ? "pagination-wrapper pagination-start" : "pagination-wrapper");
  
  return (
    <div className={className}>
      {previous}
      {next}
    </div>
  );
};

export default Pagination;