import React from "react"
import PageMeta from "./components/PageMeta";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from './components/Animation';
import { motion } from "framer-motion";
import Footer from './components/Footer';
// import Image from './components/Image';
// import Section from './components/Section';
// import Button from './components/Button';

function Home(props) {
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >

      <PageMeta 
        title="Home"
        description="Color &amp; Contrast is an interactive guide to color for designers."
      />

    <div>
      {/* <section className="centered fullPage">
        <div className="coverPage">
          <p className="logoTop">The</p>
          <h1 className="logoLarge">COLOR</h1>
          <p className="logoBottom">Field Guide</p>
          <hr className="separator" />
          <p className="coverPage-description">
            A reference guide for user interface designers.
            </p>
        </div>
      </section> */}

          {/* <p className="coverPage-description">A comprehensive guide to the theory and science of color Color perception as it applies to user interface design.</p> */}

      <section className="hero">
        {/* <h1>An interactive guide to color.</h1> */}
        <h1 className="hero-main">An interactive guide to color &amp; contrast</h1>

        {/* <p className="body3">Everything user interface designers need to know about how people sense, perceive, measure, and experience color.</p> */}
        <p className="body4">A comprehensive guide for exploring and learning about the theory, science, and perception of color and contrast.</p>
      </section>

      <section>
        <div className="homeColorWrap" id="color_wrap-1">
          <div className="homeColorWrap" id="color_wrap-2">
            <div className="homeColorWrap" id="color_wrap-3">
              <div className="homeColorWrap" id="color_wrap-4"></div>
            </div>
          </div>
        </div>
      </section>

      {/* <section>
        <h3>Purpose</h3>
        <p>To aid designers while working with color by giving concise definitions with relevant and actionable insights. </p>
      </section>
      
      <section>
        <h3>Topic definitions</h3>
        <p>Each topic is defined in a succinct, approachable way. Topics are not defined or explored in depth.</p>
      </section>

      <section>
        <h3>Contextual references</h3>
        <p>Each topic has a section titled "How it affects UI design". This section provides a concise insight relative to the topic and hot it may affect user interface design and your work.</p>
      </section> */}
    </div>

      <Footer/>
    </motion.div>
  )
}
 
export default Home;