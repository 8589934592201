import React from "react"
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';
import { Link } from "react-router-dom";

import Figure from '../img/Figure_LateralInhibition.png';
import PageHeader from '../components/PageHeader';

// import ImageSlider from "../components/ImageSlider";
import Image1 from '../img/Figure_WhitesIllusion1.png';
import Image2 from '../img/Figure_WhitesIllusion2.png';
import MachBands from '../img/Figure_MachBandIllusion.png';
import MachBandsDark from '../img/Figure_MachBandIllusion_Dark.png';
import './WhitesIllusion.scss';
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";


class WhitesIllusionSlider extends React.Component {
  constructor(props) {
    super(props)
    this.id = props.id;
    this.sliderId = `${this.id}-ImageSlider-control`;

    this.width = (this.props.width) ? this.props.width : '100%';
    this.height = (this.props.height) ? this.props.height : '350px';
    this.defaultValue = 0;
    this.defaultGrayOffset = 0;
    this.maxValue = (366 / 2) - 62; // 40 = half of gray rectangle width

    document.documentElement.style
      .setProperty(`--MunkerGrayWhitePosition`, `calc(10% - ${(+this.defaultGrayOffset/100 * 80) - 20}px)`);
    document.documentElement.style
      .setProperty(`--MunkerGrayBlackPosition`, `calc(10% - ${(+this.defaultGrayOffset/100 * 80) - 20}px)`);

    this._resetSlider = () => {
      const range = document.getElementById(this.sliderId);
      range.value = this.defaultValue;
      this._update();
    }
    
    this._ease = (x) => {
      return x;
    }
    this._update = (e) => {
      // let val = (!e) ? this.defaultGrayOffset : (+e.target.value / this.maxValue) * 100 + this.defaultGrayOffset;

      // let val = (!e) ? this.defaultGrayOffset : `calc(${(+e.target.value / 2)}% - ${(+e.target.value/100 * 80) - 40}px)`;
      let val = (!e) ? `calc(10% - ${(+this.defaultGrayOffset/100 * 80) - 20}px)` : `calc(${(+e.target.value / 2)}% - ${(+e.target.value/100 * 80) - 40}px)`;

      document.documentElement.style
        .setProperty(`--MunkerGrayWhitePosition`, `${val}`);
      document.documentElement.style
        .setProperty(`--MunkerGrayBlackPosition`, `${val}`);
      
    }

  }

  render() {
    return (
      <div className="ImageSlider">
        <div className="ImageSlider-container" style={{height: this.height}}>
          <div className="BlockRow BlockRow--background">
            <div className="GrayBlock"></div>
          </div>          <div className="BlockRow BlockRow--foreground">
            <div className="GrayBlock"></div>
          </div>
          <div className="BlockRow BlockRow--background">
            <div className="GrayBlock"></div>
          </div>
          <div className="BlockRow BlockRow--foreground">
            <div className="GrayBlock"></div>
          </div>
          <div className="BlockRow BlockRow--background">
            <div className="GrayBlock"></div>
          </div>
          <div className="BlockRow BlockRow--foreground">
            <div className="GrayBlock"></div>
          </div>
          <div className="BlockRow BlockRow--background">
            <div className="GrayBlock"></div>
          </div>
          <div className="BlockRow BlockRow--foreground">
            <div className="GrayBlock"></div>
          </div>
          <div className="BlockRow BlockRow--background">
            <div className="GrayBlock"></div>
          </div>
          <div className="BlockRow BlockRow--foreground">
            <div className="GrayBlock"></div>
          </div>
          <div className="BlockRow BlockRow--background">
            <div className="GrayBlock"></div>
          </div>
        </div>
        <div className="ImageSlider-labels" style={{width: this.width}}>
          <label>{this.props.labelLeft}</label>
          <label>{this.props.labelRight}</label>
        </div>
        <div className="ImageSlider-controls">
          <input 
            id={this.sliderId} 
            className="ImageSlider-control"
            type="range" 
            min="20" 
            max="100" 
            defaultValue={ this.defaultValue }
            step="0.5"
            style={{width: this.width}}
            onInput={(e) => {this._update(e)}} 
          /> 
          <button type="button" className="button button--reset" onClick={() => this._resetSlider()}>Reset</button>
        </div>
        
      </div>
    )
  }
}


function LateralInhibition(props) {
  const mq = window.matchMedia('(prefers-color-scheme: dark)');
  const IMG = (mq.matches) ? MachBandsDark : MachBands;
  
  const Slider = <WhitesIllusionSlider 
    id="WhitesIllusion" 
    images={ [Image1, Image2,] } 
    labelLeft="Separate" 
    labelRight="Together" 
    height="176px"
  />

  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      key="lateral-inhibition"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Lateral inhibition"
        description="A process that emphasizes boundaries or edges between objects of different luminance. Used to assist detecting objects by increasing the perceived brightness contrast between them."
      />

      <PageHeader
        category="Contrast perception"
        title="Lateral inhibition"
        />

      <Section
      figure={Figure}
      caption="Image on the left demonstrating squares of different luminosity, compared to a simulation on the right of the lateral inhibition and its affect on emphasizing the boundaries between squares."
      >
      <p className="body4">A process that emphasizes boundaries or edges between objects of different luminance. Used to assist detecting objects by increasing the perceived brightness contrast between them.</p>

      </Section>

      <Section title="Examples" level="2">
        <Section
          level='4' 
          splitView
          title="White's illusion"
          playground={Slider}
          // alt="alt text"
          // caption="The gray rectangles displayed over black and white stripes appears to have different lightnesses. Move the slider to reveal the illusion."
        >
          <p>The lightness of a color (gray) when placed near black appears brighter than the same color placed near white.</p>
          <p>The gray rectangles displayed over black and white stripes appears to have different lightnesses. Move the slider to reveal the illusion.</p>
        </Section>

        <Section 
          level="4"
          splitView
          title="Mach bands illusion / fluting"
          figure={IMG}
          // alt="Row of vertical gray rectangles of different luminosities"
          caption="The sequence of grays appear as gradients, demonstrated by chart of percieved luminance, despite being solid colors as demonstrated by step chart of actual luminance."
          >
            <p>An illusion where the contrast between adjacent values appears to increase. The gray values are solid and measured with equal difference in lightness. However, they appear to have a gradient-like appearance when viewed adjacently.</p>
          </Section>
      </Section>

      <Section title="How it affects UI design" level="4" className="elements">
        <p>When creating a color system, analyzing colors as a <Link to="color-scale">color scale</Link> can be affected by the perceptual effects of lateral inhibition. Evaluate colors adjacently, separately, and in the context of a user interface.</p>
        <p>Be cautious of using high contrast colors for non-essential elements of an interface. The percieved contrast can draw unnecessary attention to these elements and may affect <Link to="contrast-discrimination">contrast discrimination</Link>.</p>
        <p>Not all people have the same <Link to="contrast-sensitivity">contrast sensitivity</Link>. Consider personalization settings to give users the ability to adjust contrast to meet their needs.</p>
        <p> Supporting <Link to="increase-contrast-mode">iOS increase contrast mode</Link> and <Link to="high-contrast-mode">Windows high contrast mode</Link> will help users with low contrast sensitivity.</p>
        {/* <p>This illusion can effect the experience of <Link to="dark-mode">dark mode</Link> as it relates to <Link to="/simultaneous-contrast">simultaneous lightness contrast</Link>. </p> */}

      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to="visual-acuity">Visual acuity</Link></li>
          <li><Link to="contrast-sensitivity">Contrast sensitivity</Link></li>
          <li><Link to="lightness">Lightness</Link></li>
          <li><Link to="luminance">Luminance</Link></li>
          <li><Link to="relative-luminance">Relative luminance</Link></li>
          <li><Link to="apca">APCA</Link></li>
          <li><Link to="bezold-effect">Bezold effect</Link></li>
          <li><Link to="simultaneous-contrast">Simultaneous contrast</Link></li>
          <li><Link to="color-scale">Color scale</Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="Psychophysical" pages="143, 175" />
        <Citation src="Visual Perception" pages="27-28" />
        <Citation src="Understanding Color" pages="51, 117" />
        <Citation publisher="ScienceDirect" url="https://www.sciencedirect.com/topics/neuroscience/lateral-inhibition" title="A Neural Model of Attention and Feedback for Computing Perceived Brightness in Vision" author="Ashish Bakshi, Kuntal Ghosh"/>
        <Citation src="Wikipedia" url="https://en.wikipedia.org/wiki/Lateral_inhibition" title="Lateral inhibition" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default LateralInhibition;