import React from 'react';
import { Link } from 'react-router-dom';
import './Popover.scss'
import './SearchList.scss';

function SearchList({ filteredPages, setter, menuSetter }) {
  let filtered = filteredPages.map( page =>  
      <div key={page.title}> 
        <Link to={page.path} className='SearchList-item item' role="option" onClick={
          e => { 
            setTimeout(e=> {
              setter(false);
              menuSetter(false);
            }, 150);
          }
        } >
            {/* <span className="item--topLevel SearchList-item--category">
              {page.category} 
            </span> */}
            <span className="SearchList-item--title">
              {page.title}
            </span>
        </Link> 
      </div>); 
  if(filtered.length === 0) filtered = <span className='SearchPlaceholder'>No results</span>;

  return (
    <div role="listbox" className="Popover" style={{height: 'fit-content'}}>
      {filtered}
    </div>
  );
}

export default SearchList;