import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import Figure from '../img/Figure_MonochromaticColors.png';
import PageHeader from '../components/PageHeader';
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";
import ToolCitation from "../components/ToolCitation";


function MonochromaticColors(props) {
  
  return (
    <motion.div
      style={pageStyle}
      key="monochromatic-colors"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Monochromatic colors"
        description="Monochromatic colors are tints or shades of a color with the same hue."
      />

      <PageHeader
        category="Color relationships"
        title="Monochromatic colors"
        // subtitle=""
        />

      <Section
        figure={Figure}
        alt="Color wheel with orange, yellow-green, and red circles displayed in close proximity" 
      >
        {/* <p className="body4">A color space is the total collection of possible colors within a particular set of primaries. Color spaces are specific implimentations of a <Link to="/color-model">color model</Link>.</p> */}
        <p className="body4">Colors that are <Link to="tint">tints</Link> or <Link to="shade">shades</Link> of the same <Link to="hue">hue</Link>.</p>

      </Section>


      <Section title="How it affects UI design" level="4" className="elements">
        <p>Monochromatic colors can be used to create uniform <Link to="/color-scale">color scales</Link> for user interfaces.</p>
      </Section>


      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/tint'> Tint </Link></li>
          <li><Link to='/shade'> Shade </Link></li>
          <li><Link to='/hue'> Hue </Link></li>
          <li><Link to='/lightness'> Lightness </Link></li>
          <li><Link to='/color-scale'> Color scale </Link></li>
          <li><Link to='/abney-effect'> Abney effect </Link></li>
          <li><Link to='/bezold-brucke-shift'> Bezold–Brücke shift </Link></li>
        </ul>
      </Section>

      <CitationList tools>
        <ToolCitation tool="Adobe color" />
      </CitationList>

      <CitationList>
        <Citation src="Launching the Imagination" pages="382" />
        <Citation src="Understanding Color" pages="149, 230" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default MonochromaticColors;