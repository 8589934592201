import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import Figure from '../img/Figure_WarmAndCoolColors.png';
import FigureDark from '../img/Figure_WarmAndCoolColors_Dark.png';
import PageHeader from '../components/PageHeader';
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";

function WarmAndCoolColors(props) {
  const mq = window.matchMedia('(prefers-color-scheme: dark)');
  const IMG = (mq.matches) ? FigureDark : Figure;

  return (
    <motion.div
      style={pageStyle}
      key="warm-and-cool-colors"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Warm and cool colors"
        description="Color wheels are split into two sub-sections of color: warm and cool colors. Warm and cool colors are related to the measurement of color temperature."
      />

      <PageHeader
        category="Color relationships"
        title="Warm &amp; cool colors"
        // subtitle=""
        />

      <Section
        figure={IMG}
        alt="Color wheel with orange, yellow-green, and red circles displayed in close proximity" 
      >
        {/* <p className="body4">A color space is the total collection of possible colors within a particular set of primaries. Color spaces are specific implimentations of a <Link to="/color-model">color model</Link>.</p> */}
        <p className="body4">Color wheels are split into two sections: <strong>warm</strong> and <strong>cool</strong> colors. These are related to <Link to="color-temperature">color temperature</Link> and are used to create dynamic color palettes.</p>
        <p className="body4">Warm colors include green, yellow, orange, and red. Cool colors include cyan, blue, purple, and fuchsia.</p>

      </Section>

      {/* <Section title="Try it out">
        <p>Color relationships are abstract ideas. Unique colors are produced when these relationships are applied to specific implementations of a <Link to='/color-model'>color model</Link> (<Link to='/color-space'>color spaces</Link>). </p>
        
        <ColorHarmonyPicker relationship="Analogous" mode="HSL" hideReset hideValue/>
      </Section> */}


      <Section title="How it affects UI design" level="4" className="elements">
        <p><Link to="/complimentary-colors">Complimentary colors</Link> tend to be of opposing temperatures. Using warm and cool colors together creates dynamic color contrast.</p>
        <p>Warm colors can communicate excitement, friendliness, and positivity.</p>
        <p>Cool colors can be used to communicate calmness, peace, approachability, and sadness.</p>
        <p>Certain combinations of warm and cool colors are not distinguishable to people with <Link to="/color-vision-deficiency">color vision deficiencies</Link>.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/hue'> Hue </Link></li>
          <li><Link to='/chromaticity'> Chromaticity </Link></li>
          <li><Link to='/color-temperature'> Color temperature </Link></li>
          <li><Link to='/complimentary-colors'> Complimentary colors </Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="Understanding Color" pages="72" />
        <Citation url="https://www.colorsexplained.com/warm-and-cool-colors/" publication="Colors Explained" title="Warm and Cool Colors: What They Are and How To Use Them" />
        <Citation url="https://justpaint.org/defining-warm-and-cool-colors-its-all-relative/" publication="Just Paint (Golden Artist Colors, Inc)" title="Defining Warm and Cool Colors: It's All Relative" author="Michael Townsend" year="2017" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default WarmAndCoolColors;