import React from "react"
import PageMeta from "../components/PageMeta";

// 
import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

// import Caption from '../components/Caption';
import Figure from '../img/Figure_AbneyEffect.png';
import PageHeader from '../components/PageHeader';

import { Link } from "react-router-dom";
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";
// import SocialShareButton from "../components/SocialShareButton";

function AbneyEffect(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      key="abney-effect"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Additive color mixing"
        description="(Purity on hue effect) Colors appear to change in hue as white light is added to the color."
      />

      <PageHeader
        category="Color perception"
        title="Abney effect"
        subtitle="(Purity on hue effect)"
      />

      <Section
        splitView
        figure={Figure}
        alt="alt text"
        caption="Top row illustrates as white is added to blue, the new color appears to shift its hue toward purple. Bottom row of blues for comparison."
      >
      <p className="body4">Colors appear to change in <Link to="hue">hue</Link> as white light is <Link to="additive">added</Link> to the color.</p>
      </Section>

      <Section title="How it affects UI design" level="4" className="elements">
        <p>When creating <Link to='/color-scale'>color scales</Link>, or <Link to="tint">tints</Link> of a color, artificially shifting the <Link to="hue">hue</Link> will help combat the Abney effect.</p>
        <p>The Abney effect is created from <Link to="additive">additive color mixing</Link>. A related phenomena is the <Link to="bezold-brucke-shift">Bezold–Brücke shift</Link>, which can help to inform the creation of tints with natural hue appearance.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          {/* <li><Link to='/color-opponent-process'> Color opponent process </Link></li> */}
          {/* <li><Link to='/simultaneous-contrast'> Simultaneous contrast </Link></li> */}
          <li><Link to='/bezold-brucke-shift'> Bezold–Brücke shift </Link></li>
          <li><Link to="additive">Additive color mixing</Link></li>
          <li><Link to='/color-scale'> Color scale </Link></li>
          <li><Link to='hue'>Hue</Link></li>
          <li><Link to='lightness'>Lightness</Link></li>
          <li><Link to='luminance'>Luminance</Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="Psychophysical" pages="261"/>
        <Citation src="CAMS" pages="241, 263" />
        <Citation publisher="Wikipedia" title="Abney effect" url="https://en.wikipedia.org/wiki/Abney_effect" />
        <Citation publisher="PsychologySays.net" title="Abney effect: what it is and how it influences our perception of color" url="https://psychologysays.net/neurosciences/abney-effect-what-it-is-and-how-it-influences-our-perception-of-color/" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default AbneyEffect;