import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import Figure from '../img/Figure_SubtractiveColorMixing.png';
// import Example from '../img/Figure_SubtractiveColorMixing-Example.png';
// import Example2 from '../img/Figure_ColorModel-Example.png';
import PageHeader from '../components/PageHeader';
import Citation from "../components/Citation";
import CitationList from "../components/CitationList";


function SubtractiveColorMixing(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      key="subtractive-color-mixing"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Subtractive color mixing"
        description="When color is created by mixing pigments of various color."
      />

      <PageHeader
        category="Color mixing"
        title="Subtractive"
        // subtitle=""
        />

      <Section
        figure={Figure}
      >
        {/* <p className="body4">A color space is the total collection of possible colors within a particular set of primaries. Color spaces are specific implimentations of a <Link to="/color/color-model">color model</Link>.</p> */}
        <p className="body4">When color is created by mixing pigments of various color.</p>

      </Section>


      <Section title="How it affects UI design" level="4" className="elements">
        <p>Subtractive mixing is not supported in digital devices, but many people have a subtractive color mixing <Link to="color-model">mental model</Link>, as many people's interaction with color is with physical mediums.</p>
        <p>CSS <a href="https://developer.mozilla.org/en-US/docs/Web/CSS/color_value/color-mix" target="_blank" rel="noopener noreferrer"><code>color-mix</code></a> can approximate mixing methods for various <Link to="color-space">color spaces</Link>, including those modeled after subtractive properties of reflected light. Not all browsers support <code>color-mix</code>.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/hue'> Hue </Link></li>
          <li><Link to='/chroma'> Chroma </Link></li>
          <li><Link to='/lightness'> Lightness </Link></li>
          <li><Link to='/color-model'> Color model </Link></li>
          <li><Link to='/ryb-model'> RYB model </Link></li>
          <li><Link to='/cmyk-model'> CMYK model </Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="Understanding Color" pages="86, 233" />
        <Citation src="Handprint.com" url="https://www.handprint.com/HP/WCL/color5.html#theorysub" title="Additive &amp; subtractive color mixing"/>
        <Citation src="Wikipedia" url="https://en.wikipedia.org/wiki/Subtractive_color" title="Subtractive color" />
      </CitationList>


      <Footer />

    </motion.div>
  )
}
 
export default SubtractiveColorMixing;