import React from "react"
import chroma from 'chroma-js';
import extendChroma from "../utils/chroma-plus";
import lms from 'color-space/lms.js';
import xyz from 'color-space/xyz.js';

import './ColorPicker.scss';

// const chroma = require('chroma-js');
// const { extendChroma } = require('../utils/chroma-plus');

extendChroma(chroma);


const Round = (value) => {
  return Math.round( value * 100 ) / 100;
}

const BackgroundColor = (mode, colorArray, color) => {
  return (mode === 'HSV' || mode === 'LAB' || mode === 'LCH' || mode === 'OKLAB' || mode === 'OKLCH') 
  ? new chroma(...colorArray, mode).hex() 
  : ( (mode === 'hsluv') 
        ? chroma.hsluv(...colorArray) 
        : ( (mode==='jab') 
          ? chroma.jab(...colorArray)
          : ( 
            (mode==='jch') 
            ? chroma.jch(...colorArray)
            : color)
          )
    );
}

const NewColor = (mode, c1value, c2value, c3value, colorArray) => {
  return (mode === 'Display-P3') 
  ? `color(display-p3 ${Round(c1value)} ${Round(c2value)} ${Round(c3value)})`
  : ((mode === 'HWB') 
      ? `hwb(${c1value} ${Math.round(c2value * 100)}% ${Math.round(c3value * 100)}%)`
      : ( (mode === 'HSV') 
        ? `hsv(${c1value} ${Math.round(c2value * 100)}% ${Math.round(c3value * 100)}%)`
        : ((mode === 'HSL') 
          ? `hsl(${c1value}deg ${Math.round(c2value * 100)}% ${Math.round(c3value * 100)}%)`
          : ((mode === 'LAB' || mode === 'LCH' || mode === 'OKLAB' || mode === 'OKLCH' || mode === 'jab' || mode === 'jch') 
            ? `${mode.toLowerCase()}(${c1value}% ${c2value} ${c3value})`
            : ( (mode === 'hsluv')
              ? `${mode.toLowerCase()}(${c1value} ${c2value} ${c3value})`
              : ( (mode=== 'LMS') 
                  ? new chroma(...xyz.rgb(lms.xyz(colorArray)), 'rgb').hex()
                  // ? rgb.xyz.lms(colorArray)
                  : ( 
                    (mode === 'XYZ') 
                    ? new chroma(...xyz.rgb(colorArray), 'rgb').hex()
                    : new chroma(...colorArray, mode).css(mode.toString())
              )
            )
          )
        )
      )
    )
  );  
}

class ColorPicker extends React.Component {
  constructor(props) {
    super(props)
    this.setter = props.setter;
    this.defaultColor = (props.color) ? props.color : 'rgb(242, 99, 34)'; // '#f26322

    this.customId = (this.props.customId) ? this.props.customId : '';

    

    // this.mode = (this.props.mode === 'sRGB' || this.props.mode === 'Display-P3') ? 'RGB' : this.props.mode ;
    this.mode = (this.props.mode === 'sRGB') ? 'RGB' : this.props.mode ;
    if(this.mode === 'RGB' || this.mode === 'Display-P3') this.c1 = 'Red'
    else if(this.mode === 'HSL' || this.mode === 'HSV' || this.mode === 'HWB' || this.mode === 'hsluv') this.c1 = 'Hue'
    else if(this.mode === 'LAB' || this.mode === 'LCH' || this.mode === 'OKLAB' || this.mode === 'OKLCH' || this.mode === 'jab' || this.mode === 'jch') this.c1 = 'Lightness'
    else if(this.mode === 'LMS') this.c1 = 'Long';
    else if(this.mode === 'XYZ') this.c1 = 'X';
    else this.c1 = 'undefined'
    this.c1min = (this.mode === 'RGB' || this.mode === 'Display-P3') ? '0' : '0'
    if(this.mode === 'RGB') this.c1max = '255'
    else if(this.mode === 'Display-P3') this.c1max = '1'
    else if(this.mode === 'HSL' || this.mode === 'HSV' || this.mode === 'HWB' || this.mode === 'hsluv') this.c1max = '360'
    else if(this.mode === 'LAB' || this.mode === 'LCH' ) this.c1max = '100'
    else if(this.mode === 'OKLAB' || this.mode === 'OKLCH') this.c1max = '1'
    else this.c1max = '100';
    this.c1increment = (this.mode === 'OKLAB' || this.mode === 'OKLCH' || this.mode === 'Display-P3') ? '0.01' : '1'
    
    this.c1defaultValue = (this.mode === 'HSL' || this.mode === 'HSV' || this.mode === 'HWB') 
                        ? 19 
                        : ( 
                          (this.mode === 'Display-P3') 
                          ? 0.882 
                          : (
                            (this.mode === 'LMS') 
                            ? 42
                            : (
                               (this.mode === 'XYZ')
                               ? 41
                               : Round(new chroma(this.defaultColor)[this.mode.toLowerCase()]()[0]) ))
                              );
    // if(this.defaultColor) this.c1defaultValue = new chroma(this.defaultColor)[this.mode.toLowerCase()]()[0];
    // else if(this.mode === 'RGB' || this.mode === 'Display-P3') this.c1defaultValue = Number(this.c1max);
    // else if(this.mode === 'HSL' || this.mode === 'HSV' || this.mode === 'HWB') this.c1defaultValue = Number(30);
    // else if( this.mode === 'hsluv') this.c1defaultValue = Number(22);
    // else if(this.mode === 'LAB' || this.mode === 'LCH') this.c1defaultValue = Number(67);
    // else if(this.mode === 'jab') this.c1defaultValue = Number(66);
    // else if(this.mode === 'jch') this.c1defaultValue = Number(53);
    // else if(this.mode === 'OKLAB' || this.mode === 'OKLCH') this.c1defaultValue = Number(0.73);
    // else this.c1defaultValue = Number(this.c1max);
    this.c1value = this.c1defaultValue;

    if(this.mode === 'RGB' || this.mode === 'Display-P3') this.c2 = 'Green'
    else if(this.mode === 'HSL' || this.mode === 'HSV' || this.mode === 'hsluv') this.c2 = 'Saturation'
    else if(this.mode === 'HWB') this.c2 = 'Whiteness'
    else if(this.mode === 'LAB' || this.mode === 'OKLAB' || this.mode === 'jab') this.c2 = 'Redness / Greenness';
    else if(this.mode === 'LCH' || this.mode === 'OKLCH' || this.mode === 'jch') this.c2 = 'Chroma';
    else if(this.mode === 'LMS') this.c2 = 'Medium';
    else if(this.mode === 'XYZ') this.c2 = 'Y';
    else this.c2 = 'undefined'
    if(this.mode === 'LAB') this.c2min = -128
    else if(this.mode === 'OKLAB') this.c2min =  -0.233;
    else if(this.mode === 'jab') this.c2min = -50;
    else this.c2min = 0;
    if(this.mode === 'RGB') this.c2max = 255;
    else if(this.mode === 'Display-P3') this.c2max = '1'
    else if(this.mode === 'HSL' || this.mode === 'HSV' || this.mode === 'HWB') this.c2max =  1;
    else if(this.mode === 'LAB') this.c2max = 127;
    else if(this.mode === 'OKLAB') this.c2max = 0.276;
    else if(this.mode === 'OKLCH') this.c2max = 0.322;
    else if(this.mode === 'jab') this.c2max = 50;
    else this.c2max = 100;    
    this.c2increment = (this.mode === 'RGB' || this.mode === 'LAB' || this.mode === 'LCH') ? 1 : ((this.mode === 'OKLAB' || this.mode=== 'OKLCH') ? 0.001 : 0.01)
    
    // this.c2defaultValue = (this.mode === 'HSL' || this.mode === 'HSV') ? 0.89 : ( (this.mode === 'HWB') ? 0 : ( (this.mode === 'Display-P3') ? 0.424 : Round(new chroma(this.defaultColor)[this.mode.toLowerCase()]()[1]) ) );
    this.c2defaultValue = (this.mode === 'HSL') 
                          ? 0.89 :( 
                              (this.mode === 'HSV') 
                              ? 0.95 
                              : ( 
                                  (this.mode === 'HWB') 
                                  ? 0.05 
                                  : ( 
                                      (this.mode === 'Display-P3') 
                                      ? 0.424 
                                      : ( (this.mode === 'LMS') 
                                          ? 18 
                                          : (
                                            (this.mode === 'XYZ')
                                            ? 28
                                            : Round(new chroma(this.defaultColor)[this.mode.toLowerCase()]()[1]) ) ) ) ) );
    // if(this.defaultColor) this.c2defaultValue = new chroma(this.defaultColor)[this.mode.toLowerCase()]()[1];
    // else if(this.mode === 'RGB' || this.mode === 'Display-P3') this.c2defaultValue = Number(this.c2max / 2);
    // else if(this.mode === 'HSL' || this.mode === 'HSV') this.c2defaultValue = 1;
    // else if (this.mode === 'HWB') this.c2defaultValue = 0;
    // else if (this.mode === 'LAB') this.c2defaultValue = 43;
    // else if (this.mode === 'LCH') this.c2defaultValue = 85;
    // else if (this.mode === 'OKLAB') this.c2defaultValue = 0.11;
    // else if (this.mode === 'OKLCH') this.c2defaultValue = 0.19;
    // else if (this.mode === 'hsluv') this.c2defaultValue = 93;
    // else if (this.mode === 'jab') this.c2defaultValue = 26;
    // else if (this.mode === 'jch') this.c2defaultValue = 75;
    // else this.c2defaultValue = Number(this.c2max / 2);
    this.c2value = this.c2defaultValue;

    if(this.mode === 'RGB' || this.mode === 'Display-P3') this.c3 = 'Blue';
    else if(this.mode === 'HSL' || this.mode === 'hsluv') this.c3 = 'Lightness';
    else if(this.mode === 'HSV') this.c3 = 'Value';
    else if(this.mode === 'HWB') this.c3 = 'Blackness';
    else if(this.mode === 'LAB' || this.mode === 'OKLAB' || this.mode === 'jab') this.c3 = 'Blueness / Yellowness';
    else if(this.mode === 'LCH' || this.mode === 'OKLCH' || this.mode === 'jch') this.c3 = 'Hue';
    else if(this.mode === 'LMS') this.c3 = 'Short';
    else if(this.mode === 'XYZ') this.c3 = 'Z';
    else this.c3 = 'undefined'
    if(this.mode === 'RGB' || this.mode === 'Display-P3') this.c3min = 0;
    else if(this.mode === 'LAB') this.c3min = -128;
    else if(this.mode === 'OKLAB') this.c3min = -0.311;
    else if(this.mode === 'jab') this.c3min = -50;
    else this.c3min = 0;
    if(this.mode === 'RGB') this.c3max = '255'
    else if(this.mode === 'Display-P3') this.c3max = '1'
    else if(this.mode === 'HSL' || this.mode === 'HSV' || this.mode === 'HWB') this.c3max = '1'
    else if(this.mode === 'LAB') this.c3max = 127;
    else if(this.mode === 'OKLAB') this.c3max = 0.198;
    else if(this.mode === 'LCH' || this.mode === 'OKLCH' || this.mode === 'jch') this.c3max = 360;
    else if(this.mode === 'jab') this.c3max = 50;
    else this.c3max = '100';    
    this.c3increment = (this.mode === 'RGB' || this.mode === 'LAB' || this.mode === 'LCH') ? '1' : '0.01'
    
    this.c3defaultValue = (this.mode === 'HSL') 
                          ? 0.55 
                          :( 
                              (this.mode === 'HSV') 
                              ? 0.95 
                              : ( 
                                  (this.mode === 'HWB') 
                                  ? 0 
                                  : ( 
                                      (this.mode === 'Display-P3') 
                                      ? 0.217 
                                      :( 
                                        (this.mode==='LMS') 
                                        ? 5 
                                        : (
                                          (this.mode==='XYZ')
                                          ? 5
                                          : Round(new chroma(this.defaultColor)[this.mode.toLowerCase()]()[2])) ) ) ) );
    // if(this.defaultColor) this.c3defaultValue = new chroma(this.defaultColor)[this.mode.toLowerCase()]()[2];
    // else if(this.mode === 'RGB' || this.mode === 'Display-P3') this.c3defaultValue = Number(this.c3min);
    // else if(this.mode === 'HSL') this.c3defaultValue = 0.5;
    // else if (this.mode === 'HSV') this.c3defaultValue = 1;
    // else if (this.mode === 'HWB') this.c3defaultValue = 0;
    // else if (this.mode === 'LAB') this.c3defaultValue = 74;
    // else if (this.mode === 'LCH') this.c3defaultValue = 60;
    // else if (this.mode === 'OKLAB') this.c3defaultValue = 0.15;
    // else if (this.mode === 'OKLCH') this.c3defaultValue = 53;  
    // else if (this.mode === 'hsluv') this.c3defaultValue = 60;
    // else if (this.mode === 'jab') this.c3defaultValue = 24;
    // else if (this.mode === 'jch') this.c3defaultValue = 43;
    // else this.c3defaultValue = Number(this.c3min);
    this.c3value = this.c3defaultValue;

    this.colorArray = [this.c1value, this.c2value, this.c3value];  
    // console.log(this.mode, this.colorArray)
    // let XYZ = lms.xyz(this.colorArray);
    // let RGB = xyz.rgb(lms.xyz(this.colorArray))
    // console.log(RGB)
    this.color = NewColor(this.mode, this.c1value, this.c2value, this.c3value, this.colorArray);                       
    // this.color = (this.mode === 'LMS') ? '#ff00ff' : NewColor(this.mode, this.c1value, this.c2value, this.c3value, this.colorArray);                       
    
    this._updateColor = () => {
      this.colorArray = [this.c1value, this.c2value, this.c3value];
      this.color = NewColor(this.mode, this.c1value, this.c2value, this.c3value, this.colorArray);   
      const backgroundColor = (!this.mode === 'Display-P3') ? this.color : BackgroundColor(this.mode, this.colorArray, this.color);

      document.getElementById(`pickerSwatch_${this.props.mode}${this.customId}`).style.backgroundColor = backgroundColor;
      if(!this.props.hideValue) document.getElementById(`stringValue_${this.props.mode}${this.customId}`).innerHTML = this.color;

      if(this.setter) this.setter(backgroundColor)
    }

  }
  render() {
    const StringValue = (this.props.hideValue) ? '' : <code className="ColorPicker_stringValue" id={'stringValue_' + this.props.mode}>{this.color}</code>;
    const ResetButton = (this.props.hideReset) ? '' 
            : <button className="button button--reset" onClick={() => {
              // Set values to default
              this.c1value = this.c1defaultValue;
              this.c2value = this.c2defaultValue;
              this.c3value = this.c3defaultValue;
              document.getElementById('c1Slider_' + this.props.mode + this.customId).value = this.c1defaultValue
              document.getElementById('c2Slider_' + this.props.mode + this.customId).value = this.c2defaultValue
              document.getElementById('c3Slider_' + this.props.mode + this.customId).value = this.c3defaultValue
              // Re-run color update
              this._updateColor()
            }}>Reset</button>;

    return (
      <>
      <div className="ColorPicker">
        <div className="ColorPicker_swatch" id={'pickerSwatch_' + this.props.mode + this.customId} style={{backgroundColor: BackgroundColor(this.mode, this.colorArray, this.color)}}></div>
        <div className="ColorPicker_controls">
          <div className="ColorPicker_inputGroup">
            <label>{this.c1}</label>
            <input id={'c1Slider_' + this.props.mode + this.customId} type="range" defaultValue={this.c1value} min={this.c1min} max={this.c1max} step={this.c1increment} onInput={(e) => {this.c1value = Number(e.target.value); this._updateColor()}}/>
          </div>
          <div className="ColorPicker_inputGroup">
            <label>{this.c2}</label>
            <input id={'c2Slider_' + this.props.mode + this.customId} type="range" defaultValue={this.c2value} min={this.c2min} max={this.c2max} step={this.c2increment} onInput={(e) => {this.c2value = Number(e.target.value); this._updateColor()}}/>
          </div>
          <div className="ColorPicker_inputGroup">
            <label>{this.c3}</label>
            <input id={'c3Slider_' + this.props.mode + this.customId} type="range" defaultValue={this.c3value} min={this.c3min} max={this.c3max} step={this.c3increment} onInput={(e) => {this.c3value = Number(e.target.value); this._updateColor()}}/>
          </div>
          {ResetButton}
        </div>
      </div>

      {StringValue}
      </>
    )
  }
}
 
export default ColorPicker;