import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import Figure from '../img/Figure_Chroma.png';
// import Example from '../img/Figure_Chroma-Example.png';
// import Example2 from '../img/Figure_ColorModel-Example.png';
import PageHeader from '../components/PageHeader';
import Citation from "../components/Citation";
import CitationList from "../components/CitationList";


function Chroma(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      key="choma"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Chroma"
        description="(Saturation, brilliance, vibrancy) The colorfulness of a color, independant from a color's lightness. A change in this property results in a tone."
      />

      <PageHeader
        category="Color properties"
        title="Chroma"
        subtitle="(Colorfulness, saturation, brilliance, vibrancy)"
        />

      <Section
        figure={Figure}
        alt="Orange circle with arrow pointing to a lighter orange circle, demonstrating its Chroma"       
      >
        <p className="body4">The colorfulness of a color, independant from a color's lightness. A change in this property results in a <Link to="tone">tone</Link>.</p>
        <p className="body4">In color spaces, chroma, brilliance, and saturation are unique measurements.</p>
        
      </Section>


      <Section title="How it affects UI design" level="4" className="elements">
        <p>The chroma of a color can be perceived differently between <Link to="light-mode">light</Link> and <Link to="dark-mode">dark</Link> modes (or other <Link to="color-theme">color themes</Link>) as a result of the <Link to="/bezold-effect">Bezold effect</Link>.</p>
        <p>The chroma of a color can be perceived differently when adjacent to other colors of varying chroma due to <Link to="/simultaneous-contrast">simultaneous contrast</Link>.</p>
        <p>Lower chroma colors can be more difficult to distinguish for people with <Link to='color-vision-deficiency'>color vision deficiencies</Link>.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/color-psychology'> Color psychology </Link></li>
          <li><Link to='/hue'> Hue </Link></li>
          <li><Link to='/lightness'> Lightness </Link></li>
          <li><Link to='/tone'> Tone </Link></li>
          <li><Link to='/color-model'> Color model </Link></li>
          <li><Link to='/color-space'> Color space </Link></li>
          {/* <li><Link to='color-vision-deficiency'>Color vision deficiency</Link></li>
          <li><Link to="light-mode">Light mode</Link></li>
          <li><Link to="dark-mode">Dark mode</Link></li>
          <li><Link to="color-theme">Color theme</Link></li>
          <li><Link to="simultaneous-contrast">Simultaneous contrast</Link></li>
          <li><Link to="bezold-effect">Bezold effect</Link></li> */}
        </ul>
      </Section>
      
      <CitationList>
        <Citation src="Classical Painting" pages="81-82" />
        <Citation src="Launching the Imagination" pages="378" />
        <Citation src="Understanding Color" pages="64, 232" />
        <Citation src="Wikipedia" url="https://en.wikipedia.org/wiki/Colorfulness" title="Colorfulness" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default Chroma;