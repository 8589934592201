import React from "react"
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import Figure from '../img/Figure_ChromaticAberration.png';
import FigureDark from '../img/Figure_ChromaticAberration_Dark.png';
import PageHeader from '../components/PageHeader';

import { Link } from "react-router-dom";
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";

function ChromaticAberration(props) {
  const mq = window.matchMedia('(prefers-color-scheme: dark)');
  const IMG = (mq.matches) ? FigureDark : Figure;

  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      key="chromatic-aberration"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Chromatic aberration"
        description="Different wavelengths of light refract through a lens at different angles. This causes focal points to be at different depths of the retina resulting in an effect called chromostereopsis."
      />

      <PageHeader
        category="Color vision"
        title="Chromatic aberration"
        // subtitle="(White's illusion)"
        />

      <Section
        figure={IMG}
        caption="Short (blue), medium (green), and long (red) wavelengths refract at different angles resulting in unique focal depths."
      >
      <p className="body4">Different wavelengths of light refract through a lens at different angles. This causes focal points to be at different depths of the retina resulting in an effect called <Link to="chromostereopsis">chromostereopsis</Link>.</p>
      <p className="body4">Chromatic aberration can be caused by the lens and exaggerated by conditions such as astigmatism, myopia (nearsightedness), or hyperopia (farsightedness).</p>
      </Section>


      <Section title="How it affects UI design" level="4" className="elements">
        <p>Be sensitive to juxtapositioning highly saturated reds and blues, as they will create a "vibration" appearance called <Link to="/chromostereopsis">chromostereopsis</Link>. This can be unpleasant and causes text legibility issues.</p>
        <p>Defocused blue light from chromatic aberration can cause digital eye strain. This does not mean blue color should be avoided, as many colors (including white) include blue light.</p>
        <p>Supporting <Link to="/dark-mode">dark mode</Link> and <Link to="/high-contrast-mode">Windows high contrast mode</Link> can help viewers avoid eye strian.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/chromostereopsis'> Chromostereopsis </Link></li>
          <li><Link to='/dark-mode'> Dark mode </Link></li>
          <li><Link to='/high-contrast-mode'> High contrast mode </Link></li>
          <li><Link to='/light-sensitivity'> Light sensitivity </Link></li>
          <li><Link to='/myopia'> Myopia </Link></li>
          <li><Link to='/hyperopia'> Hyperopia </Link></li>
          <li><Link to='/astigmatism'> Astigmatism </Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="Visual Perception" pages="16-17"/>
        <Citation url="https://www.2020mag.com/ce/blue-light-refocused-separating-science-35675" title="Blue light refocused - Separating science from speculation" author="Gary Morgan, OD" src="2020mag" />
        <Citation url="https://www.aao.org/Assets/42cd446b-9d31-49a9-bd95-add573cd64e9/637151349610530000/bo26-pdf?inline=1" title="Chromatic Aberration: Basic Optics, Chapter 6" src="AAO.org" />
        <Citation src="WebMD" url="https://www.webmd.com/connect-to-care/lasik/the-difference-between-hyperopia-and-myopia" title="Hyperopia vs. Myopia: What's the Difference?" author="Manjari Bansal" />
        <Citation src="Mayo Clinic" url="https://www.mayoclinic.org/diseases-conditions/astigmatism/symptoms-causes/syc-20353835" title="Astigmatism" author="Mayo Clinic Staff" />
      </CitationList>


      <Footer />

    </motion.div>
  )
}
 
export default ChromaticAberration;