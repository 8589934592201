import React, { useState } from 'react';
import SearchList from './SearchList';
import './Search.scss';

function Search({ details, menuSetter }) {

  const [searchField, setSearchField] = useState("");
  const [searchShow, setSearchShow] = useState(false);

  const filteredPages = details.filter(
    page => {
      let input = searchField.toLowerCase();

      return (
        page
          .title.toLowerCase()
          .includes(input) 
        || 
        page
          .keywords
          .find(element => {
            if (element.includes(input)) {
              return true;
            } else {
              return false;
            }
          })
      );
    }
  );


  const handleChange = e => {
    setSearchField(e.target.value);
    if(e.target.value==="" || e.target.value.length < 3){
      setSearchShow(false);
    }
    else {
      setSearchShow(true);
    }
  };

  function searchList() {
  	if (searchShow) {
	  	return (
        <SearchList filteredPages={filteredPages} setter={setSearchShow} menuSetter={menuSetter}/>
	  	);
	  }
  }

  return (
    <form 
      className="SearchWrapper"
      id="site-search"
      role="search"
      aria-label="Sitewide"
      >
      <input 
        className="SearchField"
        id="siteSearch"
        type = "search" 
        name="search"
        placeholder = "Search site" 
        spellCheck="false"
        onChange = {handleChange}
      />
      <div className="SearchPopover">
        {searchList()}
      </div>
		</form>
  );
}

export default Search;