import * as Leo from '@adobe/leonardo-contrast-colors';

const Theme = () => {
  const mq = window.matchMedia('(prefers-color-scheme: dark)');

  const lightness = (mq.matches) ? 8 : 100;
  const contrast = (mq.matches) ? 1.05 : 1;

  // https://leonardocolor.io/theme.html?name=Color+Field+Guide+%28Dark%29&config=%7B%22baseScale%22%3A%22Gray%22%2C%22colorScales%22%3A%5B%7B%22name%22%3A%22Gray%22%2C%22colorKeys%22%3A%5B%22%23000000%22%5D%2C%22colorspace%22%3A%22RGB%22%2C%22ratios%22%3A%5B%221.01%22%2C%221.18%22%2C%221.8%22%2C%223%22%2C%224.6%22%2C%227.57%22%5D%2C%22smooth%22%3Afalse%7D%2C%7B%22name%22%3A%22Purple%22%2C%22colorKeys%22%3A%5B%22%237d1ae0%22%2C%22%23daaffd%22%2C%22%23320070%22%5D%2C%22colorspace%22%3A%22CAM02p%22%2C%22ratios%22%3A%5B%221.01%22%2C%221.18%22%2C%221.8%22%2C%223%22%2C%224.6%22%2C%227.57%22%5D%2C%22smooth%22%3Atrue%7D%2C%7B%22name%22%3A%22Dark+blue%22%2C%22colorKeys%22%3A%5B%22%231fadea%22%2C%22%23003166%22%5D%2C%22colorspace%22%3A%22RGB%22%2C%22ratios%22%3A%5B%221.01%22%2C%221.18%22%2C%221.8%22%2C%223%22%2C%224.6%22%2C%227.57%22%5D%2C%22smooth%22%3Atrue%7D%5D%2C%22lightness%22%3A100%2C%22contrast%22%3A1%2C%22saturation%22%3A100%7D
  
  const grayRatios = (mq.matches) 
    ? [-1.3, 1, 1.32, 1.93, 3, 4.5, 6.2, 8.75, 12]
    // : [1, 1.16, 1.8, 3, 4.5, 6, 10, 15] ;
    : [-1.3, 1, 1.18, 1.8, 3, 4.6, 7.57, 11.9, 17] ;
  const colorRatios = (mq.matches) 
    ? [1.2, 3, 4.5, 6.5, 9.5, 13] 
    : [1.2, 3, 4.5, 6.5, 9.5, 13];
  
  const gray = new Leo.BackgroundColor({
    name: 'gray', 
    colorKeys: ['#cacaca'], 
    colorspace: 'RGB', 
    ratios: grayRatios
  });
  
  const oceanBlue = new Leo.BackgroundColor({
    name: 'oceanBlue',
    colorKeys: ["#1fadea", "#003166"],
    colorspace: 'RGB',
    ratios: colorRatios,
    smooth: true
  })
  
  const burntOrange = new Leo.BackgroundColor({
    name: 'burntOrange',
    colorKeys: ["#7d1ae0", "#daaffd", "#320070"],
    colorspace: 'CAM02p',
    ratios: colorRatios,
    smooth: true
  })
  
  const adaptiveTheme = new Leo.Theme({
    colors: [
      gray, 
      oceanBlue,
      burntOrange
    ], 
    backgroundColor: gray, 
    lightness: lightness,
    contrast: contrast,
  });
  
  return adaptiveTheme;
}


export default Theme;