import React from "react";
import { useState } from "react";
import round from '../utils/round';

import './OpponentSlider.scss';

const spectrum = ["#000000", "#010022", "#050042", "#0d005d", "#140076", "#1c008d", "#2200a4", "#2700ba", "#2900d2", "#1900f2", "#001bff", "#0030ff", "#003eff", "#004aff", "#0055ff", "#005fff", "#0069ff", "#0071ff", "#007aff", "#0082ff", "#008aff", "#0092fa", "#009af5", "#00a3eb", "#00abdc", "#00b4cb", "#00bcb4", "#00c49a", "#00cf43", "#43d100", "#63d300", "#78d500", "#84d700", "#91d900", "#9bdc00", "#a5de00", "#abe100", "#b0e400", "#b6e700", "#b9e900", "#bdec00", "#bfed00", "#c1ef00", "#c3f000", "#c5f200", "#c6f300", "#c8f400", "#caf600", "#ccf700", "#cef900", "#d0fa00", "#d7f600", "#dcf200", "#e0ef00", "#e4eb00", "#e8e800", "#ebe500", "#eee200", "#f1df00", "#f4db00", "#f9d500", "#ffce00", "#ffc900", "#ffc400", "#ffbf00", "#ffb900", "#ffb300", "#ffad00", "#ffa600", "#ff9e00", "#ff9700", "#ff8e00", "#ff8500", "#ff7c00", "#ff7100", "#ff6500", "#ff5700", "#ff4700", "#ff3100", "#fe0802", "#f50012", "#eb0019", "#e1001f", "#d7001e", "#cd001f", "#c3001e", "#b8001d", "#ad001b", "#a20019", "#970016", "#8c0013", "#800010", "#74000c", "#68000a", "#5b0006", "#4d0004", "#3f0002", "#300001", "#200000", "#0d0000", "#000000"];

const normalize = (range1, range2, value) => {
  const normalized = value - range1[0];
  const perc = normalized/range1[1];
  const newMin = range2[1]-range2[0]
  const newValOffset = newMin * perc;
  const newVal = newValOffset + range2[0];
  return newVal;
}

const getPercent = (range1, value, multiple) => {
  const normalized = value - range1[0];
  const newMax = range1[1] - range1[0];
  const perc = normalized/newMax;

  return (multiple) ? perc * multiple : perc;
}

const Waveforms = ({number, width, sliderMin, sliderMax}) => {
  const waves = [];
  const min = 14;
  const max = 76;

  // const normalized = width - 380;
  // const perc = normalized/720;
  // const newWidth = (max-min) * perc + min;
  const newWidth = normalize([sliderMin, sliderMax], [min, max], width);

  const reversed = 54 - newWidth;

  for(let i=0; i < Number(number); i++) {
    waves.push(
      <svg version="1.1" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" width={reversed} height="42" viewBox="0 0 32 104">
        <path d="M30.57,50.23l-.11,1.66h0c-.81,13.22-3.01,43.72-6.44,47.89-3.14-3.71-4.84-30.19-5.98-47.9l-.03-.45C15.61,14.1,14.14,0,8.26,0,3.65,0,1.77,8.64,0,30.3v51.24c.86-7.22,1.67-16.74,2.44-29.28h0l.12-1.77C3.75,32.48,5.36,8.06,8.26,4.26c2.95,3.86,4.56,28.94,5.75,47.43l.03,.45c2.54,39.55,4.25,51.86,10,51.86,2.81,0,5.55-2.16,7.96-22.46V30.3c-.47,5.71-.93,12.32-1.43,19.92Z"/>
      </svg>
      // <svg version="1.1" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" width={newWidth} height="42" viewBox="0 0 44 142">
      //   <path id="Path_14" data-name="Path 14" d="M-251,1805.911c13.708,0,9.6-140,22.268-140s8.633,140,21.732,140" transform="translate(251 -1664.911)" fill="none" stroke="var(--gray500)" stroke-width="4"/>
      // </svg>
    )
  }

  return waves
}

const OpponentSlider = (props) => {
  const [waveForm, setWaveForm] = useState(380);
  const [RedConeColor, setRedConeColor] = useState('rgba(0,0, 0, 0)');
  const [GreenConeColor, setGreenConeColor] = useState('rgba(0,0, 0, 0)');
  const [BlueConeColor, setBlueConeColor] = useState('rgba(0,0, 0, 0)');

  const sliderMin = 380;
  const sliderMax = 720;

  const redMin = 430;
  const redMax = 700;
  
  const greenMin = 430;
  const greenMax = 650;

  const blueMin = 380;
  const blueMax = 510;

  // How much above 100% to allow percentage to go.
  // To simulate a bell shape to opacity.
  const multiple = 2;

  function handleChange(e) {
    const value = +e.target.value;
    setWaveForm(value);

    let YBperc = getPercent([blueMin, redMax], value, 8);
    let YBval = (YBperc > 4) ? 4 - (YBperc - 4) : YBperc;
    let clampYB = (YBval > 1) ? 1 : YBval;

    let RGperc = getPercent([greenMin, redMax], value, 6);
    let RGval = (RGperc > 3) ? 3 - (RGperc - 3) : RGperc;
    let clampRG = (RGval > 1) ? 1 : RGval;

    document.documentElement.style 
      .setProperty('--blueYellowChannelOpacity', clampYB)
    document.documentElement.style 
      .setProperty('--redGreenChannelOpacity', clampRG)

    const sliderPercInt = getPercent([sliderMin, sliderMax], value);
    const sliderPerc = round( sliderPercInt ) * 100;
    
    const swatchColor = spectrum[Math.floor(sliderPerc)];
    document.documentElement.style
      .setProperty('--opponentSwatchColor', `${swatchColor}`)

    if(value >= redMin && value <= redMax) {
      let perc = getPercent([redMin, redMax], value, multiple);
      let newVal = (perc > 1) ? 1 - (perc - 1) : perc;

      const newColor = `rgba(255, 0, 0, ${newVal})`
      document.documentElement.style
        .setProperty('--redChannelGradientOpacity', newVal)
      setRedConeColor(newColor)
    } else {
      setRedConeColor('rgba(255, 0, 0, 0)')
      document.documentElement.style
      .setProperty('--redChannelGradientOpacity', 0)
    }

    if(value >= greenMin && value <= greenMax) {
      let perc = getPercent([greenMin, greenMax], value, multiple);
      let newVal = (perc > 1) ? 1 - (perc - 1) : perc;

      // let percGrad = getPercent([greenMin-20, greenMax], value, multiple);
      // let newValGrad = (percGrad > 1) ? 1 - (percGrad - 1) : percGrad;

      const newColor = `rgba(0, 255, 0, ${newVal})`
      document.documentElement.style
        .setProperty('--greenChannelGradientOpacity', newVal)
      setGreenConeColor(newColor)
    } else {
      setGreenConeColor('rgba(0, 255, 0, 0)')
      document.documentElement.style
      .setProperty('--greenChannelGradientOpacity', 0)
    }

    if(value >= blueMin && value <= blueMax) {
      let perc = getPercent([blueMin, blueMax], value, multiple);
      let newVal = (perc > 1) ? 1 - (perc - 1) : perc;

      const newColor = `rgba(0, 0, 255, ${newVal})`
      document.documentElement.style
        .setProperty('--blueChannelGradientOpacity', newVal)

      setBlueConeColor(newColor)
    } else {
      setBlueConeColor('rgba(0, 0, 255, 0)')
      document.documentElement.style
      .setProperty('--blueChannelGradientOpacity', 0)
    }

    if(value >= greenMin && value <= redMax) {
      let perc = getPercent([greenMin, redMax], value, multiple);
      let newVal = (perc > 1) ? 1 - (perc - 1) : perc;
      document.documentElement.style
      .setProperty('--yellowChannelGradientOpacity', newVal)
    } else {
      document.documentElement.style
        .setProperty('--yellowChannelGradientOpacity', 0)
    }

    if (value >= blueMin && value <= redMax) {
      const handleMax = (greenMax - redMin) / 2 + (redMin + 15);
      const handleMin = (blueMax - blueMin) / 2 + blueMin;
      let handlePerc = getPercent([handleMin, handleMax], value);
      if(handlePerc > 1) handlePerc = 1;
      if(handlePerc < 0) handlePerc = 0;
      document.documentElement.style
        .setProperty('--blueYellowHandlePos', `calc(${handlePerc * 100}% - ${handlePerc * 24}px)`)
    }
    if(value >= greenMin && value <= redMax) {
      const handleMax = (redMax - redMin) / 2 + redMin;
      const handleMin = (greenMax - greenMin) / 2 + greenMin;
      let handlePerc = getPercent([handleMin, handleMax], value);
      if(handlePerc > 1) handlePerc = 1;
      if(handlePerc < 0) handlePerc = 0;
      document.documentElement.style
        .setProperty('--redGreenHandlePos', `calc(${handlePerc * 100}% - ${handlePerc * 24}px)`)
    }
  }

  return (
    <div className="OpponentSlider-wrapper">
      <div className="OpponentSlider-col-Wave">
        <p className="OppoentSlider-title">
          {/* Wavelength */}
          <label htmlFor="wavelength" id="wavelengthLabel">Light wavelength</label>
        </p>
        <div className="OpponentSlider-waveform">
          <div className="waveformWrap">
            <Waveforms key='1' width={waveForm} number='20' sliderMin={sliderMin} sliderMax={sliderMax}/>
          </div>
          <div className="waveformWrap">
            <Waveforms key='2' width={waveForm} number='20' sliderMin={sliderMin} sliderMax={sliderMax}/>
          </div>
          <div className="waveformWrap">
            <Waveforms key='3'  width={waveForm} number='20' sliderMin={sliderMin} sliderMax={sliderMax}/>
          </div>
        </div>
        <div>
          {/* <label for="wavelength" id="wavelengthLabel">Wavelength</label> */}
          <input id="wavelength" aria-labelledby="wavelengthLabel" type="range" min={sliderMin} max={sliderMax} onChange={(e) => {
            handleChange(e);
          }}></input>
        </div>

      </div>
      <div className="OpponentSlider-col-receptors">
        <p className="OppoentSlider-title">
          Photoreceptors
        </p>
        <div className="OpponentSlider-cones">
          <div className="Cone" style={{backgroundColor: RedConeColor}}>LWS</div>
          <div className="Cone" style={{backgroundColor: GreenConeColor}}>MWS</div>
          <div className="Cone" style={{backgroundColor: BlueConeColor}}>SWS</div>
        </div>
      </div>
      <div className="OpponentSlider-col-channels">
        <p className="OppoentSlider-title">
          Opponent channels
        </p>
        <div className="OpponentSlider-channels">
          <div className="ChannelSlider">
            <div className="ChannelSlider-handle" id="redGreenHandle"></div>
          </div>
          <div className="ChannelSlider">
            <div className="ChannelSlider-handle" id="blueYellowHandle"></div>
          </div>
        </div>
      </div>
      <div className="OpponentSlider-col-color">
        <p className="OppoentSlider-title">
          Percieved color
        </p>
        <div id="opponentColorSwatch"></div>
      </div>
    </div>
  );
}

export default OpponentSlider;