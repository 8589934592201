import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";
import Footer from '../components/Footer';


import FigureHue from '../img/Figure_Hue.png';
import FigureChroma from '../img/Figure_Chroma.png';
import FigureLightness from '../img/Figure_Lightness.png';
import PageHeader from '../components/PageHeader';


function ColorProperties(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      key="color-properties"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Color properties"
        description="(Parameters / channels) Color properties are abstract ways of describing a color. A uniform system of properties is a color model."
      />

      <PageHeader
        category="Color relationships"
        title="Color properties"
        subtitle="(Parameters / channels)"
        />

      <Section>
        {/* <p className="body4">A color space is the total collection of possible colors within a particular set of primaries. Color spaces are specific implimentations of a <Link to="/color/color-model">color model</Link>.</p> */}
        <p className="body4">Color properties are abstract ways of describing a color. A uniform system of properties is a <Link to="/color/color-model">color model</Link>.</p>
        
        {/* <Image 
          src={Figure}
          alt="Geometric forms for color spaces sRGB (cube), HSV (cylinder) and CIELAB (irregular shape)" 
          caption=""
        ></Image> */}
      </Section>

      <Section>
        <Section level="4" splitView 
            figure={FigureHue}
            alt=""
            // playground={<ColorPickerChannel channel="Hue" />}
            title="Hue"
          >
          <p>Hue is typically referred to using named colors, such as "red", and defines a relative color.</p>
          <p>In <Link to="/color/color-space">color spaces</Link>, hue is measured in degrees and defines specific values of color.</p>
        </Section>

        <Section level="4" splitView 
            figure={FigureChroma}
            alt=""
            // playground={<ColorPickerChannel channel="Chroma" />}
            title="Chroma / Saturation"
          >
          <p>The "colorfulness" of a color, independant from a color's lightness. A change in this property results in a <Link to="/color/tone">tone</Link>.</p>
          <p>In <Link to="/color/color-space">color spaces</Link>, chroma and saturation are unique measurements.</p>
        </Section>

        <Section level="4" splitView 
            figure={FigureLightness}
            alt=""
            // playground={<ColorPickerChannel channel="Lightness" />}
            title="Lightness / Value"
          >
          <p>The amount of lightness of a color, or its relative proximity to white or black. A change in this property results in a <Link to="/color/tint">tint</Link> or <Link to="/color/shade">shade</Link>.</p>
          <p>In <Link to="/color/color-space">color spaces</Link>, lightness and value are unique measurements.</p>
        </Section>

{/* 
      <Section title="Relational properties">
        <Section level="4" splitView 
            // figure={Example2}
            // alt="alt text"
            // caption="What you see here."
            title="Tint"
          >
          <p>A lighter version of a color, generally equal to "adding white". </p>
        </Section>
        <Section level="4" splitView 
            // figure={Example2}
            // alt="alt text"
            // caption="What you see here."
            title="Shade"
          >
          <p>A darker version of a color, generally equal to "adding black". </p>
        </Section>
        <Section level="4" splitView 
            // figure={Example2}
            // alt="alt text"
            // caption="What you see here."
            title="Tone"
          >
          <p>A less colorful version of a color, generally equal to "adding gray" or "adding the color's compliment". </p>
        </Section>*/}
      </Section> 


      <Section title="How it affects UI design" level="4" className="elements">
        <p>Terminology is important for communication and alignment. Having a familiar foundation of color terms can help you clearly communicate ideas with your team.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/tone'> Tone </Link></li>
          <li><Link to='/tint'> Tint </Link></li>
          <li><Link to='/shade'> Shade </Link></li>
          <li><Link to='/color-scale'> Color scale </Link></li>
        </ul>
      </Section>

      {/* TODO: Add citations 
        TODO: Evaluate inline & related links
        TODO: Proof UI effects
      */}

      <Footer />

    </motion.div>
  )
}
 
export default ColorProperties;