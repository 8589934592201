import React from "react"
import { Link } from "react-router-dom";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Pagination from '../components/Pagination';
import Footer from '../components/Footer';

// import Figure from '../img/Figure_TutorialChoosingColor.png';
// import Example from '../img/Figure_TutorialChoosingColorCIE.png';
// import Example2 from '../img/Figure_ColorModel-Example.png';
import PageHeader from '../components/PageHeader';
// import ImageSlider from "../components/ImageSlider";
// import Image1 from '../img/Figure_ContrastConstancy1.png';
// import Image2 from '../img/Figure_ContrastConstancy2.png';
// import { findByLabelText } from "@testing-library/react";


function TutorialChoosingColor(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      key="tutorial-choosing-color"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageHeader
        category="Tutorials"
        title="Choosing a color"
        subtitle="Brand or product identity"
        />

      <Section level="2">
        <p className="body4">Chosing a color for your product or brand identity can reinforce its purpose.</p>
      </Section>

      <Section 
        overline="Step 1" 
        title="Emotional meaning" 
        level="2"
        >
        <p>Colors can convey certain emotions. Choose a color that has emotional qualities that align with your brand identity, or the intended feeling of our product.</p>
        <p>Color picker that displays emotions from database?</p>
      </Section>

      <Section 
        overline="Step 2" 
        title="Cultural meaning" 
        level="2"
        >
        <p>Cultures have different connotations for colors. Validate that the emotional &amp; cultural meanings align with your brand identity.</p>
        <p>Color picker that displays cultural meanings from database?</p>
      </Section>

      <Section 
        overline="Step 3"
        title="Color management" 
        level="2"
        >
        <p>Chances are your color will be displayed in <Link to="srgb">sRGB</Link> or <Link to="display-p3">display p3</Link>. If your brand will extend to print materials, <Link to="cmyk">CMYK</Link> will also need supported.</p>
        <p>Ensure your color looks appropriate for each of these that you support.</p>
        <p>Color picker showing all three spaces?</p>
      </Section>


      <Pagination 
        // previous="firstOne"
        next="Choosing a palette"
      />

      <Footer />

    </motion.div>
  )
}
 
export default TutorialChoosingColor;