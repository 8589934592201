import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import Figure from '../img/Figure_RGB.png';
// import Example from '../img/Figure_RGB_Example.png';
// import ExampleDark from '../img/Figure_RGB_Example_Dark.png';
import PageHeader from '../components/PageHeader';
// import ColorPicker from "../components/ColorPicker";
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";

function RGBModel(props) {
  // const mq = window.matchMedia('(prefers-color-scheme: dark)');
  // const IMG = (mq.matches) ? ExampleDark : Example;

  return (
    <motion.div
      style={pageStyle}
      key="rgb-model"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="RGB model"
        description="A color model defined by three primaries; red, green, and blue. The RGB model is based on principles of trichromatic vision and additive color mixing."
      />

      <PageHeader
        category="Color models"
        title="RGB model"
        // subtitle=""
        />

      <Section
        splitView
        figure={Figure}
      >
        <p className="body4">A <Link to="/color-model">color model</Link> defined by three primaries; red, green, and blue. The RGB model is based on principles of <Link to='/trichromacy'>trichromatic vision</Link> and <Link to="/additive">additive color mixing</Link>.</p>
      </Section>

      <Section 
        title="Implementations &amp; key differentiators" 
        level="3"
        // splitView
        // figure={Example}
        // caption="something"
        >
        <Section 
          // title="Implementations &amp; key differentiators" 
          // level="3"
          splitView
          // figure={IMG}
          // caption="something"
          >
            <p>Display-P3 and sRGB have a different <Link to="gamut">gamut</Link>. This results in more vibrant colors in Display-P3 compared to sRGB.</p>
            <h5>Implementations:</h5>
            <p>
              <ul>
                <li><Link to="srgb">sRGB (HSL, HSV, HWB)</Link></li>
                <li><Link to="display-p3">Display-P3</Link></li>
              </ul>
            </p>
          </Section>

      </Section>

      {/* <Section title="RGB color spaces">
        <Section level="4" splitView 
          title="sRGB"
          playground={<ColorPicker mode="sRGB" />}
        >
        <p>The sRGB color space is the web default, and supported by most digital displays. HEX format is a base 16 format for RGB.</p>
        <p>CSS annotation:</p>
        <ul>
          <li> <code>rgb(255, 255, 255)</code> </li>
          <li> <code>rgba(255, 255, 255, 1)</code> with transparency</li>
          <li> <code>#RRGGBBAA</code> </li>
          <li> <code>#RRGGBBAA</code> with transparency</li>
        </ul>
        </Section>

        <Section level="4" splitView 
          title="Display-P3"
          playground={<ColorPicker mode="Display-P3" />}
        >
        <p>Modern devices like smartphones, tablets, and retina screens use Display-P3. It provides a much wider collection of color, which are more colorful than sRGB.<strong> Not supported in all browsers</strong>.</p>
        <p>CSS annotation:</p>
        <ul>
          <li> <code>color(display-p3 1 1 1)</code> </li>
        </ul>
        </Section>

        <Section level="4" splitView 
          title="HSL"
          playground={<ColorPicker mode="HSL" />}

        >
        <p>HSL is a cylindrical transformation of sRGB color space. Properties are Hue, Saturation, and Lightness.</p>
        <p>CSS annotation:</p>
        <ul>
          <li> <code>hsl(360deg, 100%, 100%)</code> </li>
          <li> <code>hsla(360deg, 100%, 100%, 1)</code> with transparency</li>
        </ul>
        </Section>

        <Section level="4" splitView 
          title="HWB"
          playground={<ColorPicker mode="HWB" />}

        >
        <p>HWB is a cylindrical transformation of sRGB color space and is closely related to HSV. Properties are Hue, Whiteness, and Blackness.</p>
        <p>CSS annotation:</p>
        <ul>
          <li> <code>hwb(360 100% 100%)</code> </li>
          <li> <code>hwb(360 100% 100% / 1)</code> with transparency</li>
        </ul>
        </Section>

        <Section level="4" splitView 
          title="HSV"
          playground={<ColorPicker mode="HSV" />}
        >
        <p>HSV is a cylindrical transformation of sRGB color space. Properties are Hue, Saturation, and Value.</p>
        <p>HSV is not available in CSS.</p>

        </Section>

      </Section> */}


      <Section title="How it affects UI design" level="4" className="elements">
        <p>RGB color spaces are widely supported in most technologies, and are a safe approach to define colors for UI.</p>
        <p>RGB color spaces are not perceptually uniform. Using these to mix <Link to="tint">tints</Link>, <Link to="shade">shades</Link>, or <Link to='tone'>tones</Link> can be very difficult and yield unexpected results.</p>
        <p>When trying to create perceptually uniform colors, use a <Link to="color-appearance-model">color appearance model</Link> instead.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to="color-model">Color model</Link></li>
          <li><Link to="color-space">Color space</Link></li>
          <li><Link to="additive">Additive color mixing</Link></li>
          <li><Link to="tint">Tint</Link></li>
          <li><Link to="shade">Shade</Link></li>
          <li><Link to="tone">Tone</Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation url="https://acsweb.ucsd.edu/~ncn003/CSE3/Lab2/ColorModels.htm" publication="UC San Diego" title="Color models" />
        <Citation src="Wikipedia" url="https://en.wikipedia.org/wiki/Color_model" title="Color model"/>
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default RGBModel;