import React from "react"
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';
import { Link } from "react-router-dom";

import Figure from '../img/Figure_Presbyopia.png';
import FigureDark from '../img/Figure_PresbyopiaDark.png';
import ImageSlider from "../components/ImageSlider";
import Image1 from '../img/VisualAcuity_2020.png';
import Image2 from '../img/VisualAcuity_Hyperopia01.png';
import Image3 from '../img/VisualAcuity_Hyperopia02.png';
import PageHeader from '../components/PageHeader';

import CitationList from "../components/CitationList";
import Citation from "../components/Citation";

function Presbyopia(props) {
  const mq = window.matchMedia('(prefers-color-scheme: dark)');
  const IMG = (mq.matches) ? FigureDark : Figure;

  const Slider = <ImageSlider 
    id="Presbyopia" 
    images={ [Image1, Image2, Image3] } 
    labelLeft="Normal vision" 
    labelRight="Presbyopia" 
    height="292px"
    defaultValue="0"
    />

  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      key="visual-acuity"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Presbyopia"
        description='A condition where it becomes difficult to see objects up close.'
      />

      <PageHeader
        title="Presbyopia"
        // subtitle="(Old eye)"
        />

      <Section 
        splitView
        figure={IMG}
        caption="The inflexibility of the lens causes light from nearby objects to go out of focus."
      >
        <p className="body4">A condition where it becomes difficult to see objects up close.</p>
        <p className="body4">Presbyopia is caused by the hardening of the <Link to="lens">lens</Link> due to aging. </p>
      </Section>

      <Section 
        title="See how it looks" 
        // splitLevel 
        level="2"
        >
          <Section
            playground={Slider}
            splitView
          >
          <p>Use the slider to see how varying degrees of presbyopia can affect a person's vision.</p>
          <p>Presbyopia can be treated with reading glasses, bifocals, trifocals, or progressive lenses.</p>

          </Section>
      </Section>


      <Section title="How it affects UI design" level="4" className="elements">
        <p>Users may need to view screens at a distance, which can affect <Link to="contrast-discrimination">contrast discrimination</Link>.</p>
        <p>Users may need to increase <strong>contrast</strong> or <strong>lightness</strong> of an interface for better legibility. Consider <Link to="/adaptive-color-theme">personalization</Link> settings to give users the ability to adjust contrast to meet their needs.</p>
        <p>WCAG <a href="https://www.w3.org/TR/2008/REC-WCAG20-20081211/#visual-audio-contrast-scale" target="_blank" rel="noopener noreferrer">1.4.4 (Resize text)</a> provides guidance on how to support users with resizable text.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/visual-acuity'> Visual acuity </Link></li>
          <li><Link to='/lens'> Lens </Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="CAMS" pages="3" />
        <Citation src="NIH NIE" url="https://www.nei.nih.gov/learn-about-eye-health/eye-conditions-and-diseases/presbyopia" title="Presbyopia" />
        <Citation src="AAO.org" url="https://www.aao.org/eye-health/diseases/what-is-presbyopia" title="What is presbyopia?" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default Presbyopia;