import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import Figure from '../img/Figure_Dichromacy.png';
import FigureDark from '../img/Figure_Dichromacy_Dark.png';
import PageHeader from '../components/PageHeader';
import FigureDeuteranopia from '../img/Figure_Deuteranopia.png'
import FigureProtanopia from '../img/Figure_Protanopia.png'
import FigureTritanopia from '../img/Figure_Tritanopia.png'
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";
import ToolCitation from "../components/ToolCitation";

function Dichromacy(props) {
  const mq = window.matchMedia('(prefers-color-scheme: dark)');
  const IMG = (mq.matches) ? FigureDark : Figure;
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      key="dichromacy"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Dichromacy"
        description="(Dichromatic vision) A type of color vision deficiency where one of the cone pigments is missing, resulting in only two wavelength sensitivities."
      />

      <PageHeader
        category="Color vision"
        title="Dichromacy"
        subtitle="(Dichromatic vision)"
        />

      <Section
        figure={IMG}
        caption="Examples of tritanopia (missing short / blue cones), deuteranopia (missing medium / green cones), and protanopia (missing long / red cones)."
      >
        <p className="body4">A type of <Link to="/color-vision-deficiency">color vision deficiency</Link> where one of the <Link to="retina">cone pigments</Link> is missing, resulting in only two wavelength sensitivities. </p>

      </Section>


      <Section
        title="Deuteranopia"
        level="4"
        splitView
        caption="Example image simulating deuteranopia"
        figure={FigureDeuteranopia}
      >
        <p>This type of color vision is caused by missing medium wavelength (green) cones.</p>
        <p>Deuteranopia affects 1.27% of males and 0.01% of females.</p>
      </Section>
      
      <Section
        title="Protanopia"
        level="4"
        splitView
        caption="Example image simulating protanopia"
        figure={FigureProtanopia}
      >
        <p>This type of color vision is caused by missing long wavelength (red) cones.</p>
        <p>Protanopia is less common and affects 1.01% of males and 0.02% of females.</p>
      </Section>

      <Section
        title="Tritanopia"
        level="4"
        splitView
        caption="Example image simulating tritanopia"
        figure={FigureTritanopia}
      >
        <p>This type of color vision is caused by missing short wavelength (blue) cones.</p>
        <p>Tritanopia is very rare and affects 0.0001% of males.</p>
      </Section>



      <Section title="How it affects UI design" level="4" className="elements">
        <p>Designing with color vision deficiencies in mind is important. Most issues can be resolved by compliance with <a href="https://www.w3.org/TR/WCAG21/#use-of-color" target="_blank" rel="noopener noreferrer">WCAG 1.4.1 (use of color)</a> and <a href="https://www.w3.org/TR/WCAG21/#contrast-minimum" target="_blank" rel="noopener noreferrer">1.4.3 (contrast minimum)</a>.</p>
        <p>When color identification is important to all sighted users, use a color tool to evaluate <Link to="color-difference">color difference</Link> for each color deficiency type.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to="retina">Retina</Link></li>
          <li><Link to='/color-vision-deficiency'> Color vision deficiency </Link></li>
          <li><Link to='/trichromacy'> Trichromacy </Link></li>
          <li><Link to='/anomalous-trichromacy'> Anomalous trichromacy </Link></li>
          <li><Link to='/monochromacy'> Monochromacy </Link></li>
          <li><Link to="/color-confusion-lines">Color confusion lines</Link></li>
        </ul>
      </Section>

      <CitationList tools>
        <ToolCitation name="Coblis" url="https://www.color-blindness.com/coblis-color-blindness-simulator/" />
        <ToolCitation name="CVD Simulation Web App" url="https://www.myndex.com/CVD/" />
        <ToolCitation name="Colorblindly" url="https://chrome.google.com/webstore/detail/colorblindly/floniaahmccleoclneebhhmnjgdfijgg" />
        <ToolCitation tool="Leonardo" />
      </CitationList>

      <CitationList>
        <Citation src="CVD" pages="202-201" />
        <Citation src="Measuring color" pages="438, 445" />
        <Citation src="Color-blindness.com" url="https://www.color-blindness.com/types-of-color-blindness/" title="Types of color blindness" />
        <Citation src="Myndex" title="Color vision deficiency simulator" url="https://www.myndex.com/CVD/"/>
        <Citation src="WCAG" url="https://www.w3.org/TR/WCAG21/#use-of-color" title="Success Criterion 1.4.1 Use of Color" />
        <Citation src="WCAG" url="https://www.w3.org/TR/WCAG21/#contrast-minimum" title="Success Criterion 1.4.3 Contrast (Minimum)" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default Dichromacy;