import React from "react"
import PageMeta from "../components/PageMeta";

import Image from "../components/Image";
import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import Figure from '../img/Figure_ContrastSensitivity.png';
import FigureDark from '../img/Figure_ContrastSensitivity_Dark.png';
import Gausian from '../img/Figure_ContrastSensitivityGausian.png';
import PageHeader from '../components/PageHeader';

import { Link } from "react-router-dom";
import './ContrastSensitivity.scss';
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";

const Round = (value) => {
  return Math.round( value * 100 ) / 100;
}

class ContrastSensitivitySliders extends React.Component {
  constructor(props) {
    super(props)
    this.id = props.id;
    this.sliderId = `${this.id}-ImageSlider-control`;

    // this.step = "0.05";

    this.width = (this.props.width) ? this.props.width : '100%';
    this.height = (this.props.gausian) ? '366px' : ((this.props.height) ? this.props.height : '350px');
    this.defaultValue = 3;
    this.defaultSize = Round(Math.pow((this.defaultValue), 3));
    this.defaultBrightness = 100;
    this.defaultContrast = (!this.props.gausian) ? 0.4 : 1;
    this.contrastIncrements = [0.5, 1, 3, 5, 9, 15, 20, 30, 45, 70, 100]
    
    document.documentElement.style
      .setProperty(`--ContrastSensitivityBackgroundSize`, `${this.defaultSize}px`);
    document.documentElement.style
      .setProperty(`--ContrastSensitivityBlackOverlay`, `${100 - this.defaultBrightness}%`);
    document.documentElement.style
      .setProperty(`--ContrastSensitivityContrast`, `${this.defaultContrast}`);

    this.gausian = (this.props.gausian) ? <Image src={Gausian} style={{position: 'absolute', zIndex: '2'}} /> : '';
    this.containerClass = (!this.props.gausian) ? "ImageSlider-container ContrastImageContainer ContrastImageContainer--round": "ImageSlider-container ContrastImageContainer";

    this._resetSlider = () => {
      const range1 = document.getElementById(this.sliderId + '-1');
      const range2 = document.getElementById(this.sliderId + '-2');
      range1.value = this.defaultSize;
      range2.value = this.defaultBrightness;
      this._updateWidth();
      this._updateBrightness();
    }
    
    this._ease = (x) => {
      return x;
    }
    this._updateWidth = (e) => {
      let val = (!e) ? this.defaultSize : +e.target.value;
      let stepInc = Round(Math.pow((val), 3));
      if(stepInc < 3) stepInc = 3;

      document.documentElement.style
        .setProperty(`--ContrastSensitivityBackgroundSize`, `${stepInc}px`);
    }

    this._updateBrightness = (e) => {
      let val = (!e) ? this.defaultBrightness : +e.target.value;
      document.documentElement.style
        .setProperty(`--ContrastSensitivityBlackOverlay`, `${100 - val}%`);
    }

    this._updateContrast = (e) => {
      let val = (!e) ? this.defaultContrast : +e.target.value;
      // let contrast = this.contrastIncrements[val];
      let contrast = val;

      document.documentElement.style
        .setProperty(`--ContrastSensitivityContrast`, `${contrast/100}`);
    }


  }

  render() {
    return (
      <div className="ImageSlider">
        <div className={this.containerClass} style={{height: this.height}}>
          <div className="ContrastBlackOverlay"></div>
          {this.gausian}
          <div className="ContrastBackground"></div>
        </div>
        <div className="ImageSlider-labels" style={{width: this.width}}>
          <label>{this.props.slider1LabelLeft}</label>
          <label>{this.props.slider1LabelRight}</label>
        </div>
        <div className="ImageSlider-controls">
          <input 
            id={this.sliderId + '-1'} 
            className="ImageSlider-control"
            type="range" 
            min="1.44" 
            max="5.5" 
            defaultValue={ this.defaultValue }
            step="0.5"
            style={{width: this.width}}
            onInput={(e) => {this._updateWidth(e)}} 
          /> 
        </div>

        <div className="ImageSlider-labels" style={{width: this.width}}>
          <label>{this.props.slider2LabelLeft}</label>
          <label>{this.props.slider2LabelRight}</label>
        </div>
        <div className="ImageSlider-controls">
          <input 
            id={this.sliderId + '-2'} 
            className="ImageSlider-control"
            type="range" 
            min="0" 
            max="100" 
            defaultValue={ this.defaultContrast * 100 }
            step="1"
            style={{width: this.width}}
            onInput={(e) => {this._updateContrast(e)}} 
          /> 
        </div>
        
        <div className="ImageSlider-labels" style={{width: this.width}}>
          <label>{this.props.slider3LabelLeft}</label>
          <label>{this.props.slider3LabelRight}</label>
        </div>
        <div className="ImageSlider-controls">
          <input 
            id={this.sliderId + '-3'} 
            className="ImageSlider-control"
            type="range" 
            min="0" 
            max="100" 
            defaultValue={ this.defaultBrightness }
            step="1"
            style={{width: this.width}}
            onInput={(e) => {this._updateBrightness(e)}} 
          /> 
          <button className="button button--reset" onClick={() => this._resetSlider()}>Reset</button>
        </div>
        
      </div>
    )
  }
}

function ContrastSensitivity(props) {  
  var mql = window.matchMedia('(min-width: 434px)');
  let imageHeight = (mql.matches) ? '348px' : 'calc(100vw - 80px)';

  const mq = window.matchMedia('(prefers-color-scheme: dark)');
  const IMG = (mq.matches) ? FigureDark : Figure;
  
  const Slider = <ContrastSensitivitySliders 
    id="ContrastSensitivity" 
    slider1LabelLeft="Narrow" 
    slider1LabelRight="Wide" 
    slider2LabelLeft="Low contrast" 
    slider2LabelRight="High contrast" 
    slider3LabelLeft="Low light" 
    slider3LabelRight="Bright light" 
    height={imageHeight}
    // gausian
    />

  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      key="contrast-sensitivity"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Contrast sensitivity"
        description="A measurement of a persons' ability to distinguish an object from its background."
      />

      <PageHeader
        category="Contrast perception"
        title="Contrast sensitivity"
        // subtitle="(White's illusion)"
        />

      <Section
        figure={IMG}
        caption="Thresholds of normal luminance contrast sensitivity across spatial frequencies shown for photopic vision (well lit), mesopic vision (twilight), and scotopic vision (low light)."      
      >
        <p className="body4"> A measurement of a persons' ability to distinguish an object from its background. </p>
        <p className="body4"> Contrast sensitivity is affected by lighting intensity and spatial frequency. Sensitivity thresholds are unique to individuals based on a number of factors (such as cataracts or damage to the optic nerve).</p>

      </Section>

      <Section title="Example" level="2">
        <Section 
          level="4" 
          splitView
          // title="Try it out"
          playground={Slider}
          >
          <p> Use the controls to change the spatial frequency (width) of the vertical bands, contrast, and brightness of the simulation.</p>
          <p> For this test, people are shown sets of discs with progressively lower contrast (at fixed widths). They are asked to identify the lowest contrast disc they are still able to see the vertical bands.</p>
          <p> Results are plotted against a chart (like the example above) to identify the individual's contrast sensitivity.</p>
          {/* <p> The vertical bands will appear to have different boundaries based on the position of the controls. </p> */}
          <p className="body6"><strong>Note:</strong> <em>This is a simulation of the contrast sensitivity test and is not intended to be used for actual contrast sensitivity testing or diagnosis.</em></p>
        </Section>
      </Section>



      <Section title="How it affects UI design" level="4" className="elements">
        <p>Not all people have the same contrast sensitivity. WCAG <a href="https://www.w3.org/TR/WCAG21/#contrast-minimum" target="_blank" rel="noopener noreferrer">1.4.3 (contrast minimum)</a> provides a general threshold for making text &amp; components percievable to most sighted users.</p>
        <p>Typographic sizes and font weights affect the perception of contrast in text. Lighter font weights (or smaller text) should use higher contrast colors.</p>
        <p>In some cases, users may benefit from higher contrast than recommended minimums, whereas other users <strong>may be negatively impacted by high contrast interfaces</strong>.  Consider <Link to="adaptive-theme">personalization settings</Link> to give users the ability to adjust contrast to meet their needs.</p>
        <p> Supporting <Link to="increase-contrast-mode">iOS increase contrast mode</Link> and <Link to="high-contrast-mode">Windows high contrast mode</Link> will help users with low contrast sensitivity.</p>
        <p> Additional factors need to be considered from related sources below.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/contrast-discrimination'> Contrast discrimination </Link></li>
          <li><Link to="contrast-adaptation">Contrast adaptation</Link></li>
          <li><Link to='/lateral-inhibition'> Lateral inhibition </Link></li>
          <li><Link to='/relative-luminance'> Relative luminance </Link></li>
          <li><Link to='/apca'> APCA </Link></li>
          <li><Link to='/color-difference'> Color difference </Link></li>
          <li><Link to='/simultaneous-contrast'> Simultaneous contrast </Link></li>
          <li><Link to='/bezold-effect'> Bezold effect </Link></li>
          <li><Link to='/stevens-power-law'> Stevens' power law </Link></li>
          <li><Link to='/light-and-dark-adaptation'> Light &amp; dark adaptation </Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="CAMS" pages="28-29"/>
        <Citation src="Psychophysical" pages="149-151" />
        <Citation src="Visual Perception" pages="34" />
        <Citation url="https://www.sciencedirect.com/topics/medicine-and-dentistry/contrast-sensitivity#:~:text=Contrast%20sensitivity%20is%20a%20measure,background%20than%20a%20black%20letter." publication="ScienceDirect" title="Contrast sensitivity" author="John F. Salmon MD, FRCS, FRCOphth" />
        <Citation src="NCBI" url="https://www.ncbi.nlm.nih.gov/books/NBK219042/" title="Contrast sensitivity function" year="1985" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default ContrastSensitivity;