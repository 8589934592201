import React from "react"
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';
import { Link } from "react-router-dom";

import Figure from '../img/Figure_ContrastDiscrimination.png';
// import ImageSlider from "../components/ImageSlider";
// import Image1 from '../img/Figure_ContrastConstancy1.png';
// import Image2 from '../img/Figure_ContrastConstancy2.png';
import PageHeader from '../components/PageHeader';

import CitationList from "../components/CitationList";
import Citation from "../components/Citation";

function ContrastDiscrimination(props) {
  // const Slider = <ImageSlider 
  //   id="ContrastDiscrimination" 
  //   images={ [Image1, Image2,] } 
  //   labelLeft="Illusion" 
  //   labelRight="Reveal" 
  //   height="172px"
  //   />

  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      key="contrast-discrimination"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Contrast discrimination"
        description='The ability to distinguish differences in contrast between objects. "Contrast constancy" is identifying consistent contrast between objects on different surrounding backgrounds.'
      />

      <PageHeader
        category="Contrast perception"
        title="Contrast discrimination"
        subtitle="(Contrast constancy)"
        />

      <Section
        figure={Figure}
        caption="An example of a test for evaluating contrast discrimination (Cannon and Fullenkamp 1991). The inner circle has lower contrast than the outer ring."
      >
        <p className="body4">The ability to distinguish differences in contrast between objects. "Contrast constancy" is identifying consistent contrast between objects on different surrounding backgrounds.</p>
        {/* <p className="body4">.</p>  */}
        {/* regardless of size or frequency */}

      </Section>

      {/* <Section title="Example" level="2">

        <Section level="4" splitView 
          playground={Slider}
          title="Chubb illusion"
          // alt="alt text"
          // caption="The highlighted checkerboards appear to be the same (having the same contrast between light and dark squares). Move the slider to reveal how different the two highlighted checkers are. The internal checkers do not change."
        >
          <p>The central, hightlighted checkerboard on both sides of the image appear to have the same contrast for their internal squares.</p>
          <p>Move the slider to reveal how different the two highlighted checkers are. The internal checkers do not change.</p>
          <p>This illusion demonstrates an error in contrast discrimination due to local <Link to="contrast-adaptation">contrast adaptation</Link>.</p>
        </Section>

      </Section> */}

      <Section title="How it affects UI design" level="4" className="elements">
        <p>Surrounding elements and their contrast can effect the perception of contrast for other elements on screen. </p>
        <p>A large amount of high contrast non-essential elements can effect the percieved contrast and identifiability of text or components. </p>
        <p>When comparing and evaluating <Link to="dark-mode">dark</Link> and <Link to="light-mode">light</Link> mode or various <Link to="color-theme">color themes</Link>, contrast constancy is the subjective method of comparing colors to appear to have a consistent contrast between modes. The perceived contrast and <Link to="apca">calculated contrast</Link> may not match. Ensure your colors meet or exceed WCAG <a href="https://www.w3.org/TR/WCAG21/#contrast-minimum" target="_blank" rel="noopener noreferrer">1.4.3 (contrast minimum)</a>.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to="/visual-acuity">Visual acuity</Link></li>
          <li><Link to='/contrast-sensitivity'> Contrast sensitivity </Link></li>
          <li><Link to="contrast-adaptation">Contrast adaptation</Link></li>
          <li><Link to='/relative-luminance'> Relative luminance </Link></li>
          <li><Link to='/apca'> APCA </Link></li>
          <li><Link to='/color-difference'> Color difference </Link></li>
          <li><Link to='/simultaneous-contrast'> Simultaneous contrast </Link></li>
          <li><Link to='/bezold-effect'> Bezold effect </Link></li>
          <li><Link to='/stevens-power-law'> Stevens' power law </Link></li>
          <li><Link to='/light-and-dark-adaptation'> Light &amp; dark adaptation </Link></li>
          <li><Link to='/lateral-inhibition'> Lateral inhibition </Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="Visual Perception" pages="152-153" />
        <Citation publication="Journal of Vision" url="https://jov.arvojournals.org/article.aspx?articleid=2192665#:~:text=Contrast%20discrimination%20is%20a%20basic,%2Dbase%20levels%20(pedestals)." title="Perceptual learning in contrast discrimination: The effect of contrast uncertainty" author="Y. Adini, Amos Wilkonsky, Roni Haspel, Misha Tsodyks, Dov Sagi" />
        <Citation src="Wikipedia" url="https://en.wikipedia.org/wiki/Chubb_illusion" title="Chubb illusion"/>
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default ContrastDiscrimination;