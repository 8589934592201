import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import Figure from '../img/Figure_Shade.png';
import FigureDark from '../img/Figure_Shade_Dark.png';
// import Example from '../img/Figure_Shade-Example.png';
// import Example2 from '../img/Figure_ColorModel-Example.png';
import PageHeader from '../components/PageHeader';
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";


function Shade(props) {
  const mq = window.matchMedia('(prefers-color-scheme: dark)');
  const IMG = (mq.matches) ? FigureDark : Figure;

  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      key="shade"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Shade"
        description="A darker version of a color, generally equal to adding black."
      />

      <PageHeader
        category="Color properties"
        title="Shade"
        // subtitle=""
        />

      <Section
        figure={IMG}
        alt="Orange circle with arrow pointing to a darker orange circle, demonstrating its shade" 
      >
        <p className="body4">A <Link to="lightness">darker</Link> version of a color, generally equal to "adding black".</p>

      </Section>


      <Section title="How it affects UI design" level="4" className="elements">
        <p>Shades are utilized in creating <Link to="color-scale">color scales</Link>. They provide higher or lower contrast colors for various UI applications like text, borders, or backgrounds.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/lightness'> Lightness </Link></li>
          <li><Link to='/tint'> Tint </Link></li>
          <li><Link to='/color-model'> Color model </Link></li>
          <li><Link to='/color-space'> Color space </Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="Understanding Color" pages="86-87"/>
        <Citation src="Launching the Imagination" pages="384" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default Shade;