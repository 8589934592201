import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';
import Citation from "../components/Citation";
import CitationList from "../components/CitationList";

import Figure from '../img/Figure_Trichromacy.png';
import FigureDark from '../img/Figure_Trichromacy_Dark.png';
import PageHeader from '../components/PageHeader';

function Trichromacy(props) {
  const mq = window.matchMedia('(prefers-color-scheme: dark)');
  const IMG = (mq.matches) ? FigureDark : Figure;

  return (
    <motion.div
      style={pageStyle}
      key="trichromacy"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Trichromacy"
        description="(Trichromatic vision) Color vision where all perceived hues are created by mixtures of three primary colors (e.g. red, green, &amp; blue). Trichromacy is a photoreceptor-level understanding of color perception."
      />

      <PageHeader
        category="Color vision"
        title="Trichromacy"
        subtitle="(Trichromatic vision)"
        />

      <Section
        figure={IMG}
        caption="Wavelength sensitivities illustrated on the spectrum of perceivable colors of trichromacy."
      >
        <p className="body4">Color vision where all perceived hues are created by mixtures of three primary colors (e.g. red, green, &amp; blue). Trichromacy is a <Link to="retina">photoreceptor-level</Link> understanding of color perception. </p>
        
      </Section>

      {/* <Section title="How it affects UI design" level="4" className="elements">
        <p>Not every person has the same wavelength sensitivities. Some people have sensitivities to different wavelengths, and others may have different degrees of sensitivity (called color vision deficiencies).</p>
        <p>Designing for color vision deficiencies is important, and can be accomplished by compliance with WCAG 1.4 (link) and 4.11 (link).</p>
      </Section> */}

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to="retina">Retina</Link></li>
          <li><Link to='/color-opponent-process'> Color opponent process </Link></li>
          <li><Link to='/color-vision-deficiency'> Color vision deficiency </Link></li>
          <li><Link to='/chromatic-abberation'> Chromatic abberation </Link></li>
          <li><Link to="/spectral-colors">Spectral colors</Link></li>
          <li><Link to="/non-spectral-colors">Non-spectral colors</Link></li>
          <li><Link to='/chromostereopsis'> Chromostereopsis </Link></li>
          <li><Link to='/rgb'> RGB </Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="Psychophysical" pages="219, 221"/>
        <Citation src="Visual Perception" pages="19-20"/>
        <Citation src="Wikipedia" url="https://en.wikipedia.org/wiki/Light" title="Light"/>
        <Citation src="Wikipedia" url="https://en.wikipedia.org/wiki/Spectral_sensitivity" title="Spectral sensitivity" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default Trichromacy;