import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import Figure from '../img/Figure_Lightness.png';
// import Example from '../img/Figure_Lightness-Example.png';
// import Example2 from '../img/Figure_ColorModel-Example.png';
import PageHeader from '../components/PageHeader';
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";


function Lightness(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      key="lightness"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Lightness"
        description="The amount of lightness of a color, or its relative proximity to white or black. A change in this property results in a tint or shade."
      />

      <PageHeader
        category="Color properties"
        title="Lightness"
        subtitle="(Value, brightness)"
        />

      <Section
        figure={Figure}
        alt="Orange circle with arrow pointing to a lighter orange circle, demonstrating its Lightness" 
      >
        <p className="body4">The amount of lightness of a color, or its relative proximity to white or black. A change in this property results in a <Link to="tint">tint</Link> or <Link to="shade">shade</Link>.</p>
        <p className="body4">In color spaces, lightness, brightness, and value are unique measurements.</p>
        
      </Section>


      <Section title="How it affects UI design" level="4" className="elements">
        <p>The lightness of a color can be perceived differently between <Link to="light-mode">light</Link> and <Link to="dark-mode">dark</Link> modes (or other <Link to="color-theme">color themes</Link>) as a result of the <Link to="/bezold-effect">Bezold effect</Link> and <Link to="/lateral-inhibition">lateral inhibition</Link>.</p>
        <p>The lightness of a color can be perceived differently when adjacent to other colors of varying lightnesses due to <Link to="/simultaneous-contrast">simultaneous contrast</Link>.</p>
        <p>Basic <Link to="color-scale">color scales</Link> are created using a single color of various lightnesses.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/color-psychology'> Color psychology </Link></li>
          <li><Link to='/hue'> Hue </Link></li>
          <li><Link to='/chroma'> Chroma </Link></li>
          <li><Link to='/tint'> Tint </Link></li>
          <li><Link to='/shade'> Shade </Link></li>
          <li><Link to='/color-model'> Color model </Link></li>
          <li><Link to='/color-space'> Color space </Link></li>
          <li><Link to="/color-scale">Color scale</Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="Classical Painting" pages="81-82" />
        <Citation src="Launching the Imagination" pages="384" />
        <Citation src="Understanding Color" pages="234" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default Lightness;