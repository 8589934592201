import React from "react"
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import Figure from '../img/Figure_StevensPowerLaw.png';
import FigureDark from '../img/Figure_StevensPowerLaw_Dark.png';
import PageHeader from '../components/PageHeader';

import { Link } from "react-router-dom";
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";

function StevensPowerLaw(props) {
  const mq = window.matchMedia('(prefers-color-scheme: dark)');
  const IMG = (mq.matches) ? FigureDark : Figure;
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      key="stevens-power-law"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Stevens' power law"
        description="The perceived rate of change in stimulus differs from the actual rate of change. It progresses by a multiplying factor against the actual intensity (a power function)."
      />

      <PageHeader
        category="Color perception"
        title="Stevens' power law"
        subtitle="(Successor to the Weber-Fechner law)"
        />

      <Section
        figure={IMG}
        caption="A comparison of gray color scales following a linear progression vs an approximate power function to produce a more optically balanced progression."
      >
        <p className="body4">The perceived rate of change in stimulus differs from the actual rate of change. It progresses by a multiplying factor against the actual <Link to="luminance">intensity</Link> (a power function).</p>

      </Section>


      <Section title="How it affects UI design" level="4" className="elements">
        <p>When creating a <Link to="color-scale">color scale</Link>, Stevens' power law can inform your decision on how to make the progression of <Link to="tint">tints</Link> and <Link to="shade">shades</Link> appear <strong>perceptually balanced</strong>.</p>
        <p>Some <Link to="color-appearance-model">color appearance models</Link> are built with considerations to Stevens' power law.</p>
        <p>Always ensure that there are colors in your <Link to="color-scale">color scale</Link> that meet or exceed WCAG <a href="https://www.w3.org/TR/WCAG21/#contrast-minimum" target="_blank" rel="noopener noreferrer">1.4.3 (contrast minimum)</a>, regardless of their appearance on a scale.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to="lateral-inhibition">Lateral inhibition</Link></li>
          <li><Link to="contrast-sensitivity">Contrast sensitivity</Link></li>
          <li><Link to="contrast-discrimination">Contrast discrimination</Link></li>
          <li><Link to='tint'> Tint </Link></li>
          <li><Link to='shade'> Shade </Link></li>
          <li><Link to='/luminance'> Luminance </Link></li>
          <li><Link to='/relative-luminance'> Relative luminance </Link></li>
          <li><Link to='/apca'> APCA </Link></li>
          <li><Link to='/color-difference'> Color difference </Link></li>
          <li><Link to='color-appearance-model'> Color appearance model </Link></li>
          <li><Link to='color-scale'> Color scale </Link></li>

        </ul>
      </Section>

      <CitationList>
        <Citation src="Psychophysical" pages="29-30" />
        <Citation src="CAMS" pages="42, 128" />
        <Citation src="Colorimetry" pages="274" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default StevensPowerLaw;