import React from "react"
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import Figure from '../img/Figure_Gamut.png';
import FigureDark from '../img/Figure_GamutDark.png';
import PageHeader from '../components/PageHeader';

import { Link } from "react-router-dom";
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";

function Gamut(props) {
  const mq = window.matchMedia('(prefers-color-scheme: dark)');
  const IMG = (mq.matches) ? FigureDark : Figure;

  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      key="gamut"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Gamut"
        description="All available colors within a specific color space."
      />

      <PageHeader
        category="Color spaces"
        title="Gamut"
        // subtitle=""
        />

      <Section
        splitView
        figure={IMG}
        alt="alt text"
        caption="Gamuts outlined on the visible spectrum for Adobe Pro Photo, Display-P3, sRGB, and CMYK color spaces."
      >
      <p className="body4">All available colors within a specific <Link to="color-space">color space</Link>. </p>
      <p className="body4">Color spaces with a high range of colors are referred to as "wide-gamut".</p>
      <p className="body4">Colors that are out of gamut can be replaced by in-gamut colors using various methods called <strong>gamut mapping</strong>.</p>
      </Section>

      <Section title="How it affects UI design" level="4" className="elements">
        <p>Wide-gamut <Link to="color-space">color spaces</Link> provide a higher range of color.</p>
        <p>The wide-gamut color space <Link to="display-p3">display-p3</Link> is gaining browser support and used in retina displays.</p>
        <p>Colors that are out of gamut can be mapped to the closest approximate color within the supported color space. Most color libraries support gamut mapping.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/color-model'> Color model </Link></li>
          <li><Link to='/color-space'> Color space </Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="CAMS" pages="357" />
        <Citation src="Reproduction of color" pages="581-582" />
        <Citation src="Wikipedia" url="https://en.wikipedia.org/wiki/Gamut" title="Gamut" />
      </CitationList>


      <Footer />

    </motion.div>
  )
}
 
export default Gamut;