import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import Figure from '../img/Figure_AnomalousTrichromacy.png';
import FigureDark from '../img/Figure_AnomalousTrichromacy_Dark.png';

import FigureNormal from '../img/Figure_Normal.png';
import FigureDeuteranomaly from '../img/Figure_Deuteranomaly.png'
import FigureDeuteranopia from '../img/Figure_Deuteranopia.png'
import FigureProtanomaly from '../img/Figure_Protanomaly.png'
import FigureProtanopia from '../img/Figure_Protanopia.png'
import FigureTritanomaly from '../img/Figure_Tritanomaly.png'
import FigureTritanopia from '../img/Figure_Tritanopia.png'

import PageHeader from '../components/PageHeader';
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";
import ImageSlider from "../components/ImageSlider";
import ToolCitation from "../components/ToolCitation";

function AnomalousTrichromacy(props) {
  const mq = window.matchMedia('(prefers-color-scheme: dark)');
  const IMG = (mq.matches) ? FigureDark : Figure;

  const Deutan = <ImageSlider 
    id="Deuteranomaly" 
    images={ [FigureNormal, FigureDeuteranomaly, FigureDeuteranopia] } 
    labelLeft="No anomaly" 
    labelRight="Strong anomaly"
    height="226px"
    defaultValue="50"
  />
  const Protan = <ImageSlider 
    id="Protanomaly" 
    images={ [FigureNormal, FigureProtanomaly, FigureProtanopia] } 
    labelLeft="No anomaly" 
    labelRight="Strong anomaly"
    height="226px"
    defaultValue="50"
  />
  const Tritan = <ImageSlider 
    id="Tritanomaly" 
    images={ [FigureNormal, FigureTritanomaly, FigureTritanopia] } 
    labelLeft="No anomaly" 
    labelRight="Strong anomaly"
    height="226px"
    defaultValue="50"
  />

  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      key="anomolous-trichromacy"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Anomalous trichromacy"
        description="A type of color vision deficiency where one of the cone pigments in the eye has a different wavelength sensitivity than normal."
      />

      <PageHeader
        category="Color vision"
        title="Anomalous trichromacy"
        // subtitle='A type of color vision deficiency'
        />

      <Section
        figure={IMG}
        caption="Examples of tritanomaly, deuteranomaly, and protanomaly illustrating shift in wavelength sensitivity from normal."
      >
        <p className="body4"> A type of <Link to="/color-vision-deficiency">color vision deficiency</Link> where one of the <Link to="retina">cone pigments</Link> in the eye has a different wavelength sensitivity than normal. Approximately 6% of males and 0.4% of femails are anomolous trichromats. </p>

      </Section>

      <Section
        title="Deuteranomaly"
        level="4"
        splitView
        playground={Deutan}
      >
        <p>This type of color vision is caused by malfunctioning medium wavelength (green) cones.</p>
        <p>Deuteranomaly is the most common and affects 4.63% of men and 0.36% of women.</p>
      </Section>
      
      <Section
        title="Protanomaly"
        level="4"
        splitView
        playground={Protan}
      >
        <p>This type of color vision is caused by malfunctioning long wavelength (red) cones.</p>
        <p>Protanomaly is less common and affects 1.08% of men and 0.03% of women.</p>
      </Section>

      <Section
        title="Tritanomaly"
        level="4"
        splitView
        playground={Tritan}
      >
        <p>This type of color vision is caused by malfunctioning short wavelength (blue) cones.</p>
        <p>Tritanomaly is very rare and affects 0.0002% of men.</p>
      </Section>


      <Section title="How it affects UI design" level="4" className="elements">
        <p>Designing with color vision deficiencies in mind is important. Most issues can be resolved by compliance with <a href="https://www.w3.org/TR/WCAG21/#use-of-color" target="_blank" rel="noopener noreferrer">WCAG 1.4.1 (use of color)</a> and <a href="https://www.w3.org/TR/WCAG21/#contrast-minimum" target="_blank" rel="noopener noreferrer">1.4.3 (contrast minimum)</a>.</p>
        <p>When color identification is important to all sighted users, use a color tool to evaluate <Link to="color-difference">color difference</Link> for each color deficiency type.</p>
        <p>Most simulation tools imply a fixed experience for each vision type. Anomalous trichromats vary in the strength of their color deficiencies. Reliance on these tools (along with subjective decisions) can result in falsely identifying safe colors.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to="retina">Retina</Link></li>
          <li><Link to='/color-vision-deficiency'> Color vision deficiency </Link></li>
          <li><Link to='/trichromacy'> Trichromacy </Link></li>
          <li><Link to='/dichromacy'> Dichromacy </Link></li>
          <li><Link to='/monochromacy'> Monochromacy </Link></li>
          <li><Link to="/color-confusion-lines">Color confusion lines</Link></li>
        </ul>
      </Section>

      <CitationList tools>
        <ToolCitation name="Coblis" url="https://www.color-blindness.com/coblis-color-blindness-simulator/" />
        <ToolCitation name="CVD Simulation Web App" url="https://www.myndex.com/CVD/" />
        <ToolCitation name="Colorblindly" url="https://chrome.google.com/webstore/detail/colorblindly/floniaahmccleoclneebhhmnjgdfijgg" />
        <ToolCitation tool="Leonardo" />
      </CitationList>

      <CitationList>
        <Citation src="Psychophysical" pages="268-269"/>
        <Citation src="CVD" pages="202-201" />
        <Citation src="Measuring color" pages="438, 445" />
        <Citation src="Color-blindness.com" url="https://www.color-blindness.com/types-of-color-blindness/" title="Types of color blindness" />
        <Citation src="WCAG" url="https://www.w3.org/TR/WCAG21/#use-of-color" title="Success Criterion 1.4.1 Use of Color" />
        <Citation src="WCAG" url="https://www.w3.org/TR/WCAG21/#contrast-minimum" title="Success Criterion 1.4.3 Contrast (Minimum)" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default AnomalousTrichromacy;