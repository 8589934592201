import React from "react"


import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import Figure from '../img/Figure_CrawfordStilesEffect.png';
import PageHeader from '../components/PageHeader';

import { Link } from "react-router-dom";

function CrawfordStilesEffect(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      key="crawford-stiles"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageHeader
        category="Color perception"
        title="Crawford-Stiles effect"
        // subtitle="(Successor to the Weber-Fechner law)"
        />

      {/* <Section 
          splitView
          src={Figure}
          caption="The same gray value displayed over black stipes (A) and white stripes (B) appears to have different lightness."
      >
        <p className="body4">The lightness of a color (gray) when placed near black appears brighter than the same color placed near white.</p>
      </Section> */}

        <Section level="4" splitView 
          figure={Figure}
          alt="alt text"
          caption="The same gray value displayed over black stipes (A) and white stripes (B) appears to have different lightness."
        >
        <p className="body4">The lightness of a color (gray) when placed near black appears brighter than the same color placed near white.</p>
        </Section>

      <Section title="How it affects UI design" level="4" className="elements">
        <p>This illusion can effect the experience of dark mode as it relates to simultaneous brightness contrast. </p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/'> Simultaneous contrast </Link></li>
          <li><Link to='/color-opponent-process'> Something else </Link></li>
        </ul>
      </Section>


      <Footer />

    </motion.div>
  )
}
 
export default CrawfordStilesEffect;