import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';
import Scotopic from '../img/Figure_PurkinjeEffectScotopic.png';
import Mesopic from '../img/Figure_PurkinjeEffectMesopic.png';
import Photopic from '../img/Figure_PurkinjeEffectPhotopic.png';
import ImageSlider from "../components/ImageSlider";
import Citation from "../components/Citation";
import CitationList from "../components/CitationList";
// import './PurkinjeEffect.scss';

// import Figure from '../img/Figure_PurkinjeEffect.png';
import PageHeader from '../components/PageHeader';


function PurkinjeEffect(props) {
  const CaveSim = <ImageSlider 
    id="PurkinjeShift" 
    images={ [Scotopic, Mesopic, Photopic] } 
    labelLeft="Low light" 
    labelRight="Bright light" 
    // contained
    height="262px"
    defaultValue='100'
    // pan
    // width='230px'
    />

  return (
    <motion.div
      style={pageStyle}
      key="purkinje-shift"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Purkinje shift"
        description=">(Purkinje effect) Peak wavelength sensitivity of photoreceptors shift towards blue in dark light during dark adaptation. Reds will appear relatively darker than other colors as light levels decrease."
      />

      <PageHeader
        category="Color vision"
        title="Purkinje shift"
        subtitle="(Purkinje effect)"
        />

        {/* Prounounced like "put a fork in ya"... "Porkinyeh" */}

        <Section splitView
          playground={CaveSim}
        >
          <p className="body4">Peak <Link to="trichromacy">wavelength sensitivity</Link> of photoreceptors shift towards blue in dark light during <Link to="light-and-dark-adaptation">dark adaptation</Link>. Reds will appear relatively darker than other colors as light levels decrease.</p>
        </Section>

      <Section title="How it affects UI design" level="4" className="elements">
        <p><Link to="warm-and-cool-colors">Warm colors</Link> should shift towards <Link to="warm-and-cool-colors">cooler hues</Link> as they get darker if you want colors to emulate properties of the physical world.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/light-and-dark-adaptation'> Light &amp; dark adaptation </Link></li>
          <li><Link to='/luminance'> Luminance </Link></li>
          <li><Link to='/chroma'> Chroma </Link></li>
          <li><Link to='/chromaticity'> Chromaticity </Link></li>
          <li><Link to='/bezold-brucke-shift'> Bezold–Brücke shift</Link></li>

        </ul>
      </Section>

      <CitationList>
        <Citation src="CAMS" pages="10, 70" />
        <Citation src="Measuring color" pages="446" />
        <Citation title="Purkinje effect" publisher="Wikipedia" url="https://en.wikipedia.org/wiki/Purkinje_effect"/>
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default PurkinjeEffect;