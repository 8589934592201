import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

// import Figure from '../img/Figure_RGB.png';
import PageHeader from '../components/PageHeader';
import ColorPicker from "../components/ColorPicker";
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";
import ToolCitation from "../components/ToolCitation";
// import { Table, TableRow } from "../components/Table";

function OKLAB(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      key="oklab"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="OKLAB"
        description="A uniform color space created in 2020 by Björn Ottosson. OKLAB is an opponent color space based on based on CAM16-UCS and IPT color spaces."
      />

      <PageHeader
        category="Color spaces"
        title="OKLAB"
        // subtitle=""
        />

      <Section>
        <p className="body4">A <Link to="/color-appearance-model">uniform color space</Link> created in 2020 by <a href="https://bottosson.github.io/posts/oklab/" target="_blank" rel="noopener noreferrer">Björn Ottosson</a>. OKLAB is an <Link to="color-opponent-process">opponent color space</Link> based on based on <Link to="cam16">CAM16-UCS</Link> and IPT color spaces. It is intended to be more perceptually uniform color space, blend well, and behave well numerically.</p>
        {/* <Image 
          src={Figure}
          alt="" 
          // caption="RGB color space"
        ></Image> */}
      </Section>

      <Section title="Examples" level="2">
        <Section level="5" splitView 
          title="OKLAB"
          playground={<ColorPicker mode="OKLAB" />}
          >
          <p>CSS annotation:</p>
          <ul>
            <li> <code>oklab(40% 81 20)</code> </li>
            <li> <code>oklab(40% 81 20 / 1)</code> with transparency</li>
          </ul>
        </Section>

        <Section level="5" splitView 
          title="OKLCH"
          playground={<ColorPicker mode="OKLCH" />}
          >
          <p>OKLCH is a cylindrical transformation of LAB color space. Properties are Lightness, Chroma, and Hue.</p>
          <p>CSS annotation:</p>
          <ul>
            <li> <code>oklch(40% 81 350)</code> </li>
            <li> <code>oklch(40% 81 350 / 1)</code> with transparency</li>
          </ul>
        </Section>

      </Section>

      {/* <Section title="Analysis of OKLAB" level="2" >
        <p>Knowing which <Link to="color-space">color space</Link> to use as a designer can be confusing. This analysis could help you in determining whether or not CIELAB is the right color space to try.</p>

        <h5>Methods and derivation</h5>
        <Table
          header={['Derived from', <Link to="chromatic-adaptation">Chromatic adaptation</Link>, <Link to='lightness'>Lightness</Link>, 'White point']}
        >
          <TableRow>
            <ul>
              <li><Link to="ipt">IPT</Link> (hue) </li>
              <li><Link to="cam16">CAM16</Link> (chroma and lightness)</li>
            </ul>
            <>TODO</>
            <>TODO</>
            <>TODO</>
          </TableRow>

        </Table>

        <h5>Pro's and con's of OKLAB</h5>
        <Table 
          splitView
          header={['Known issues & limitations', 'Benefits & strengths']}
        >
          <TableRow>
            <ul>
              <li>Stuffs</li>
            </ul>
            <ul>
              <li>Device-independant</li>
            </ul>
          </TableRow>
        </Table>

      </Section> */}
      

      <Section title="How it affects UI design" level="4" className="elements">
        <p>Uniform color spaces are linear models of human vision. However, human perception is non-linear. Do not rely on these color spaces alone to create aesthetically balanced colors. </p>
        <p>Browser support is still growing for LAB, OKLAB, LCH and OKLCH color spaces. Check <a href="https://www.w3.org/TR/css-color-4/" target="_blank" rel="noopener noreferrer">CSS Module level 4</a> for more detail.</p>
        <p><Link to="/color-scale">Color scales</Link> can be created by <Link to="color-interpolation">interpolation</Link> in various color spaces.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to="/trichromacy">Trichromacy</Link></li>
          <li><Link to="color-opponent-process">Color opponent process</Link></li>
          <li><Link to="/color-scale">Color scales</Link></li>
          <li><Link to="/color-interpolation">Color interpolation</Link></li>
          <li><Link to="/cam16">CAM16</Link></li>
        </ul>
      </Section>

      <CitationList tools>
        <ToolCitation tool="Chroma.js" />
        <ToolCitation tool="Color.js" />
        <ToolCitation tool="D3 color" />
        <ToolCitation tool="Leonardo" />
      </CitationList>

      <CitationList>
        <Citation url="https://bottosson.github.io/posts/oklab/" author="Björn Ottosson" title="A perceptual color space for image processing" />
        <Citation url="https://raphlinus.github.io/color/2021/01/18/oklab-critique.html" author="Raph Levien" title="An interactive review of Oklab" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default OKLAB;