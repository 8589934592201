import React from "react"
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

// import Figure from '../img/Figure_ColorConfusionLines.png';
// import FigureDark from '../img/Figure_ColorConfusionLines_Dark.png';
import ImageProtan from '../img/Figure_Confusion_Protan.png';
import ImageDeutan from '../img/Figure_Confusion_Deutan.png';
import ImageTritan from '../img/Figure_Confusion_Tritan.png';
import PageHeader from '../components/PageHeader';

import { Link } from "react-router-dom";
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";
import ImageSlider from "../components/ImageSlider";

function ColorConfusionLines(props) {
  // const mq = window.matchMedia('(prefers-color-scheme: dark)');
  // const IMG = (mq.matches) ? FigureDark : Figure;
  const ConfusionLines = 
  <ImageSlider 
    id="ConfusionLines" 
    images={ [ImageProtan, ImageDeutan, ImageTritan] } 
    labelLeft="Protan" 
    labelCenter="Deutan"
    labelRight="Tritan" 
    radio
    // height="375px"
  />

  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      key="color-confusion-lines"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Color confusion lines"
        description="Lines in a CIE chromaticity diagram that intersect colors that are difficult to distinguish for people with color vision deficiencies."
      />

      <PageHeader
        category="Measurement &amp; formulas"
        title="Color confusion lines"
        // subtitle="(Delta E)"
        />

      <Section
        splitView
        playground={ConfusionLines}
        caption="Select a deficiency type to preview confusion lines. Sample colors shown along confusion lines with simulation to demonstrate color appearance for the selected deficiency type."
      >
        <p className="body4">Lines in a CIE <Link to="chromaticity">chromaticity</Link> diagram that <strong>intersect colors that are difficult to distinguish</strong> for people with <Link to="color-vision-deficiency">color vision deficiencies</Link>.</p>
        <p className="body4">The point where confusion lines converge is the <strong>copunctal point</strong>.</p>

      </Section>

      {/* <Section title="Try it out">
        <p>Picker here</p>
      </Section> */}

      <Section title="How it affects UI design" level="4" className="elements">
        <p>Some color tools provide visualization of color confusion lines to assist in color picking.</p>
        <p>Confusion lines alone are insufficient for determining "safe" colors. Additional analysis of simulated colors should be done using the <Link to="color-difference">color difference</Link> formula (Delta E).</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/chromaticity'> Chromaticity </Link></li>
          <li><Link to='/color-vision-deficiency'> Color vision deficiency </Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation url="https://colorusage.arc.nasa.gov/indiv_diffs.php" publisher="Color Usage Lab, Nasa Ames Research Center" title="Individual Differences in Color Vision"/>
        <Citation url="https://webvision.med.utah.edu/book/part-viii-psychophysics-of-vision/color-perception/" publisher="Webvision.med.utah.edu" title="Color perception" authors="Michael Kalloniatis and Charles Luu" />
        <Citation src="Color-blindness.com" url="https://www.color-blindness.com/2009/01/19/colorblind-colors-of-confusion/"/>
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default ColorConfusionLines;