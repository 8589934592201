import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import Figure from '../img/Figure_TriadicColors.png';
import PageHeader from '../components/PageHeader';
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";
import ToolCitation from "../components/ToolCitation";


function TriadicColors(props) {
  
  return (
    <motion.div
      style={pageStyle}
      key="triadic-colors"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Triadic colors"
        description="TBD."
      />

      <PageHeader
        category="Color relationships"
        title="Triadic colors"
        // subtitle=""
        />

      <Section
        figure={Figure}
        alt="Color wheel with orange, yellow-green, and red circles displayed in close proximity" 
      >
        <p className="body4">Colors positioned on the color wheel along an equilateral triangle. One of Johannes Itten's "harmonious chords": geometric points demonstrating color "notes" as a basis for composition.</p>

      </Section>


      <Section title="How it affects UI design" level="4" className="elements">
        <p>Triadic colors provide a wide range of differentiation of color in a UI color palette for creating a strong visual impact.</p>
        <p>A triadic palette may be prone to color conflicts for people who have a <Link to="color-vision-deficiency">color vision deficiency</Link>.</p>
        <p><Link to="hue">Hue</Link> is treated differently between <Link to="color-space">color spaces</Link>, which can effect which colors are considered to be triadic.</p>
      </Section>

      <CitationList tools>
        <ToolCitation tool="Adobe color" />
      </CitationList>
      

      <CitationList>
        <Citation src="Understanding Color" pages="140, 141" />
        <Citation src="Launching the Imagination" pages="64" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default TriadicColors;