import React from "react"
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';
import { Link } from "react-router-dom";

import Figure from '../img/Figure_Hyperopia.png';
import FigureDark from '../img/Figure_HyperopiaDark.png';
import ImageSlider from "../components/ImageSlider";
import Image1 from '../img/VisualAcuity_2020.png';
import Image2 from '../img/VisualAcuity_Hyperopia01.png';
import Image3 from '../img/VisualAcuity_Hyperopia02.png';
import PageHeader from '../components/PageHeader';

import CitationList from "../components/CitationList";
import Citation from "../components/Citation";

function Hyperopia(props) {
  const mq = window.matchMedia('(prefers-color-scheme: dark)');
  const IMG = (mq.matches) ? FigureDark : Figure;

  const Slider = <ImageSlider 
    id="Myopia" 
    images={ [Image1, Image2, Image3] } 
    labelLeft="Normal vision" 
    labelRight="Hyperopia" 
    height="292px"
    defaultValue="0"
    />

  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      key="visual-acuity"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Hyperopia"
        description='The ability to detect small spatial details with precision (clarity or sharpness of vision)'
      />

      <PageHeader
        title="Hyperopia"
        subtitle="(Farsightedness)"
        />

      <Section 
        splitView 
        figure={IMG}
        caption="The compressed shape of the eye causes the focal point of refracted light to be inset."
      >
        <p className="body4">A condition where a person can see distant objects clearly, but nearby objects may become blurry.</p>
        <p className="body4">The eye's shape is compressed, causing the focal point of the lens to be offset.</p>
      </Section>

      <Section 
        title="See how it looks" 
        // splitLevel 
        level="2"
        >
          <Section
            playground={Slider}
            splitView
          >
          <p>Use the slider to see how varying degrees of hyperopia can affect a person's vision.</p>
          <p>Most conditions can be corrected with eyeglasses, contacts, or eye surgery.</p>

          </Section>
      </Section>

      <Section title="How it affects UI design" level="4" className="elements">
        <p>Hyperopia can cause or exaggerate <Link to="chromatic-abberation">chromatic abberation</Link>. In some cases, corrective lenses can be the cause of exaggerated abberation. Be sensitive to juxtapositioning highly <Link to="chroma">saturated</Link> reds and blues, as they will create a "vibration" appearance called <Link to="chromostereopsis">chromostereopsis</Link>. This can be unpleasant and causes text legibility issues. </p>
        <p>Users may need to adjust text size in the interface for better legibility.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/visual-acuity'> Visual acuity </Link></li>
          <li><Link to='/chromatic-abberation'> Chromatic abberation </Link></li>
          <li><Link to='/chromostereopsis'> Chromostereopsis </Link></li>
          <li><Link to='/myopia'> Myopia </Link></li>
          <li><Link to='/astigmatism'> Astigmatism </Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="AOA.org" url="https://www.aoa.org/healthy-eyes/eye-and-vision-conditions/hyperopia" title="Hyperopia (farsightedness)" />
        <Citation src="WebMD" url="https://www.webmd.com/eye-health/farsightedness" title="Hyperopia (Farsightedness)" />
        <Citation src="Mayo Clinic" url="https://www.mayoclinic.org/diseases-conditions/farsightedness/symptoms-causes/syc-20372495" title="Farsightedness" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default Hyperopia;