import React from "react"
import './Citation.scss';


class ToolCitation extends React.Component {
  constructor(props) {
    super(props)

    this.tool = props.tool;
    this.name = props.name;
    this.url = props.url;

    this.existing = {
      'Leonardo': {
        name: 'Leonardo color',
        url: 'https://leonardocolor.io',
      },
      'Color.js': {
        name: 'Color.js',
        url: 'https://colorjs.io',
      },
      'Chroma.js': {
        name: 'Chroma.js',
        url: 'https://gka.github.io/chroma.js/',
      },
      'D3 color': {
        name: "D3 color",
        url: "https://github.com/d3/d3-color"
      },
      'Adobe color': {
        name: 'Adobe color',
        url: "https://color.adobe.com/"
      }
    }

    if(this.existing.hasOwnProperty(this.tool)) {
      const cite = this.existing[this.tool];
      let _ref = (this.url) ? <a href={this.url} target="blank">{this.url}</a> : <a href={cite.url} target="blank">{cite.url}</a>;
      this.citation = <> {cite.name}: {_ref} </>
    } else {
      let _ref = (this.url) ? <a href={this.url} target="blank">{this.url}</a> : '';
      this.citation = <> {this.name}: {_ref} </>
    }

  }
  render() {
    return (
      <li><cite className="Citation">{this.citation}</cite></li>
    )
  }
}
 
export default ToolCitation;