import React from "react"
import { Link } from "react-router-dom";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Pagination from '../components/Pagination';
import Footer from '../components/Footer';

// import Figure from '../img/Figure_TutorialCreatingTheme.png';
// import Example from '../img/Figure_TutorialCreatingThemeCIE.png';
// import Example2 from '../img/Figure_ColorModel-Example.png';
import PageHeader from '../components/PageHeader';
// import ImageSlider from "../components/ImageSlider";
// import Image1 from '../img/Figure_ContrastConstancy1.png';
// import Image2 from '../img/Figure_ContrastConstancy2.png';
// import { findByLabelText } from "@testing-library/react";


function TutorialCreatingTheme(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      key="tutorial-creating-theme"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageHeader
        category="Tutorials"
        title="Creating a theme"
        subtitle="Light, dark, &amp; additional brands"
        />

      <Section level="2">
        <p className="body4">Creating a <Link to="color-theme">color theme</Link> is essential to user interfaces. It brings all of your colors and variations into a single, contextual experience.</p>
      </Section>

      <Section 
        overline="Step 1" 
        title="Emotional meaning" 
        level="2"
        >
        <p>Colors can convey certain emotions. Choose a color that has emotional qualities that align with your brand identity, or the intended feeling of our product.</p>
        <p>Color picker that displays emotions from database?</p>
      </Section>


      <Pagination 
        previous="Creating color scales"
        next="Personalization"
      />

      <Footer />

    </motion.div>
  )
}
 
export default TutorialCreatingTheme;