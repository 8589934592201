import React from "react"
import PageMeta from "../components/PageMeta";

// 
import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';
import { Link } from "react-router-dom";

import Figure from '../img/Figure_DynamicRange.png';
// import FigureDark from '../img/Figure_DynamicRangeDark.png';
// import ImageSlider from "../components/ImageSlider";
// import Image1 from '../img/VisualAcuity_2020.png';
// import Image2 from '../img/VisualAcuity_Myopia01.png';
// import Image3 from '../img/VisualAcuity_Myopia02.png';
import PageHeader from '../components/PageHeader';

import CitationList from "../components/CitationList";
import Citation from "../components/Citation";

function DynamicRange(props) {
  // const mq = window.matchMedia('(prefers-color-scheme: dark)');
  // const IMG = (mq.matches) ? FigureDark : Figure;

  // const Slider = <ImageSlider 
  //   id="DynamicRange" 
  //   images={ [Image1, Image2, Image3] } 
  //   labelLeft="Normal vision" 
  //   labelRight="DynamicRange" 
  //   height="292px"
  //   defaultValue="0"
  //   />

  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      key="visual-acuity"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Dynamic range"
        description='The difference between the lightest light and darkest darks within an image or environment.'
      />

      <PageHeader
        title="Dynamic range"
        // subtitle="(Nearsightedness)"
        />

      <Section 
        splitView
        figure={Figure}
        caption="Checkerboards with a (relative) high dynamic range, compared to another set with lower dynamic range."
      >
        <p className="body4">The difference between the lightest light and darkest darks within an image or environment.</p>
        <p className="body4">The visual system accommodates for changes in the dynamic range with <Link to="contrast-adaptation">contrast adaptation</Link> and <Link to="light-and-dark-adaptation">light &amp; dark adaptation</Link>.</p>
        {/* <p className="body4">In digital imaging, high dynamic range means the camera captures a wide range of bright and dark areas that would otherwise need multiple exposures (bracketing).</p> */}
      </Section>

      {/* <Section 
        title="See how it looks" 
        // splitLevel 
        level="2"
        >
          <Section
            playground={Slider}
            splitView
          >
          <p>Use the slider to see how varying degrees of myopia can affect a person's vision.</p>
          <p>Most conditions can be corrected with eyeglasses, contacts, or eye surgery.</p>

          </Section>
      </Section> */}


      <Section title="How it affects UI design" level="4" className="elements">
        <p>Variance in dynamic ranges can affect <Link to="contrast-sensitivity">contrast sensitivity</Link> and <Link to="contrast-discrimination">discrimination</Link> and causes legibility issues with text.</p>
        <p>Supporting <Link to="increase-contrast-mode">iOS increase contrast mode</Link> and <Link to="high-contrast-mode">Windows high contrast mode</Link> will help users in high or low dynamic range environments.</p>
        <p>Consider <Link to="adaptive-theme">personalization settings</Link> to give users the ability to adjust contrast and lightness. This will adapt the interface and better align with their sensory adaptations (<Link to="contrast-adaptation">contrast</Link>, <Link to="light-and-dark-adaptation">light &amp; dark</Link>) and variance in dynamic ranges.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/light-and-dark-adaptation'> Light &amp; dark adaptation </Link></li>
          <li><Link to='/contrast-adaptation'> Contrast adaptation </Link></li>
          <li><Link to='/contrast-sensitivity'> Contrast sensitivity </Link></li>
          <li><Link to='/increase-contrast-mode'> Increase contrast mode </Link></li>
          <li><Link to='/high-contrast-mode'> High contrast mode </Link></li>
          <li><Link to='/adaptive-color-theme'> Adaptive color theme </Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="CAMS" pages="399-401"/>
        <Citation src="NIH NLM" url="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3244211/" title="The Dynamic Range of Human Lightness Perception" author="Ana Radonjić, Sarah R. Allred, Alan L. Gilchrist, and David H. Brainard1"/>
        <Citation url="https://www.edge-ai-vision.com/2021/11/what-is-high-dynamic-range-hdr-how-do-hdr-cameras-work/" title="What is High Dynamic Range (HDR)? How Do HDR Cameras Work?" publisher="Edge AI + Vision Alliance" />
        <Citation src="Wikipedia" url="https://en.wikipedia.org/wiki/Dynamic_range" title="Dynamic range" />
      </CitationList>
      
      <Footer />

    </motion.div>
  )
}
 
export default DynamicRange;