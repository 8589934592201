import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import Figure from '../img/Figure_HighContrastMode.png';
// import Example from '../img/Figure_HighContrastMode-Example.png';
// import Example2 from '../img/Figure_ColorModel-Example.png';
import PageHeader from '../components/PageHeader';
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";


function HighContrastMode(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      key="high-contrast-mode"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="High contrast mode"
        description="Windows high contrast mode is an device-specific color mode. It effects the colors of interfaces at an operating system level. It provides users with the ability to customize colors in their entire device."
      />

      <PageHeader
        category="Color for design systems"
        title="High contrast mode"
        subtitle="(Windows)"
        />

        <Section
          figure={Figure}
          alt="Gradient of color from yellow to orange to dark purple" 

        >
          <p className="body4">Windows high contrast mode is an device-specific color mode. It effects the colors of interfaces at an operating system level. It provides users with the ability to customize colors in their entire device.</p>
          
        </Section>

      <Section title="Supporting windows high contrast mode">
        <p>This mode overrides styling and leverages a simplified semantic color set. Components and aspects of UI will need to be mapped to these Windows color keywords:
          <ul>
            <li><code>ButtonFace</code> (Button background color)</li>
            <li><code>ButtonText</code> (Button text color)</li>
            <li><code>Canvas</code> (Background color)</li>
            <li><code>CanvasText</code> (Default text color)</li>
            <li><code>Highlight</code> (Selected text background color)</li>
            <li><code>HighlightText</code> (Selected text color)</li>
            <li><code>LinkText</code> (Link text color)</li>
          </ul>
        </p>
      </Section>


      <Section title="How it affects UI design" level="4" className="elements">
        <p>In web applications, high contrast mode can be given unique styling using the <a href="https://developer.mozilla.org/en-US/docs/Web/CSS/@media/forced-colors" target="_blank" rel="noopener noreferrer"><code>forced-colors</code> media query</a>. This can also be used in conjunction with the <a href="https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-color-scheme" target="_blank" rel="noopener noreferrer"><code>prefers-color-scheme</code> media query</a> to target light or dark themes. </p>
        <p>People with low vision rely on this mode to provide better visibility of content.</p>
        <p>People with <Link to="light-sensitivity">light sensitivity</Link> rely on this mode to decrease contrast of their device.</p>
        <p>It is highly recommended to support Windows high contrast mode to be inclusive and accessible to as many sighted people as possible.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/contrast-sensitivity'> Contrast sensitivity </Link></li>
          <li><Link to='/light-and-dark-adaptation'> Light and dark adaptation </Link></li>
          <li><Link to='/visual-acuity'> Visual acuity </Link></li>
          <li><Link to='/increase-contrast-mode'> Increase contrast mode </Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation url="https://blogs.windows.com/msedgedev/2020/09/17/styling-for-windows-high-contrast-with-new-standards-for-forced-colors/" publisher="Windows" title="Styling for Windows high contrast with new standards for forced colors." />
        <Citation url="https://developer.mozilla.org/en-US/docs/Web/CSS/@media/forced-colors" publisher="Mozilla" title="forced-colors" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default HighContrastMode;