import React from "react"
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';
import { Link } from "react-router-dom";

import Figure from '../img/Figure_ContrastAdaptation.png';
import ImageSlider from "../components/ImageSlider";
import Image1 from '../img/Figure_ContrastConstancy1.png';
import Image2 from '../img/Figure_ContrastConstancy2.png';
import PageHeader from '../components/PageHeader';
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";

function ContrastAdaptation(props) {
  const Slider = <ImageSlider 
    id="ContrastAdaptation" 
    images={ [Image1, Image2,] } 
    labelLeft="Illusion" 
    labelRight="Reveal" 
    height="172px"
    />

  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      key="contrast-adaptation"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Contrast adaptation"
        description=''
      />

      <PageHeader
        category="Contrast perception"
        title="Contrast adaptation"
        // subtitle="(Contrast constancy)"
        />

      <Section
        splitView
        figure={Figure}
        alt="Side by side of two identical paintings of a Catholic cathedral, except the first image has normal or high contrast, and the second has lower contrast."
        caption="Monet's Rouen Cathedral, Portal and Tower Saint-Romain in the Sun and Rouen Cathedral, West Façade"      
      >
        <p className="body4">A process of the visual system to increase or decrease contrast sensitivity in response to surrounding contrast.</p>

        <p className="body4">This adaptation occurs by neurons in the visual cortex over a matter of seconds.</p>
      </Section>

      <Section title="Example" level="2">

        <Section level="4" splitView 
          playground={Slider}
          title="Chubb illusion"
          // alt="alt text"
          // caption="The highlighted checkerboards appear to be the same (having the same contrast between light and dark squares). Move the slider to reveal how different the two highlighted checkers are. The internal checkers do not change."
        >
          <p>The central, hightlighted checkerboard on both sides of the image <strong>appear to have the same contrast</strong> for their internal squares.</p>
          <p>Move the slider to reveal how different the two highlighted checkers are. The internal checkers do not change.</p>
          <p>This illusion demonstrates how <strong>percieved contrast</strong> and <strong>actual contrast</strong> can differ due to the effects of contrast adaptation.</p>
        </Section>

      </Section>

      <Section title="How it affects UI design" level="4" className="elements">
        <p>When an entire screen design is lower in contrast, high contrast elements will have a greater percieved contrast, as the viewer will have adapted to the lower contrast surroundings.</p>
        <p>Avoid designing an entire interface in high contrast. Viewers will adapt to the high contrast and have less ability to <Link to="contrast-discrimination">discriminate</Link> visual hierarchy or identify higher important elements.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to="/visual-acuity">Visual acuity</Link></li>
          <li><Link to='/contrast-sensitivity'> Contrast sensitivity </Link></li>
          <li><Link to='/relative-luminance'> Relative luminance </Link></li>
          <li><Link to='/apca'> APCA </Link></li>
          <li><Link to='/color-difference'> Color difference </Link></li>
          <li><Link to='/simultaneous-contrast'> Simultaneous contrast </Link></li>
          <li><Link to='/bezold-effect'> Bezold effect </Link></li>
          <li><Link to='/stevens-power-law'> Stevens' power law </Link></li>
          <li><Link to='/light-and-dark-adaptation'> Light &amp; dark adaptation </Link></li>
          <li><Link to='/lateral-inhibition'> Lateral inhibition </Link></li>
        </ul>
      </Section>

      <CitationList>
        {/* <Citation src="Visual Perception" pages="152-153" />
        <Citation publication="Journal of Vision" url="https://jov.arvojournals.org/article.aspx?articleid=2192665#:~:text=Contrast%20Adaptation%20is%20a%20basic,%2Dbase%20levels%20(pedestals)." title="Perceptual learning in contrast Adaptation: The effect of contrast uncertainty" author="Y. Adini, Amos Wilkonsky, Roni Haspel, Misha Tsodyks, Dov Sagi" /> */}
        <Citation src="NIH NCBI" url="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC1475737" />
        <Citation url="https://www.nature.com/articles/s41467-019-08894-8" publisher="Nature.com (Nature Communications)" title="Contrast and luminance adaptation alter neuronal coding and perception of stimulus orientation" author="Masoud Ghodrati, Elizabeth Zavitz, Marcello G. P. Rosa, Nicholas S. C. Price"/>
        <Citation url="https://medicine.yale.edu/lab/mccormick/seminars/contrast/" publisher="Yale School of Medicine" title="Contrast Adaptation in Visual Cortical Neurons" />
        <Citation src="Wikipedia" url="https://en.wikipedia.org/wiki/Rouen_Cathedral_(Monet_series)n" title="Rouen Cathedral (Monet series)"/>
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default ContrastAdaptation;