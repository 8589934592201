import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

// import Figure from '../img/Figure_RGB.png';
import PageHeader from '../components/PageHeader';
import ColorPicker from "../components/ColorPicker";
// import { Table, TableRow } from "../components/Table";
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";
import ToolCitation from "../components/ToolCitation";

function LAB(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      key="cielab"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="CIELAB"
        description="A uniform color space reated in 1976 by the CIE. LAB is an opponent color space."
      />

      <PageHeader
        category="Color spaces"
        title="CIELAB"
        subtitle="(LAB)"
        />

      <Section>
        <p className="body4">A <Link to="/color-appearance-model">uniform color space</Link> created in 1976 by the International Commission on Illumination (CIE). CIELAB is an <Link to="color-opponent-process">opponent color</Link> space.</p>
        <p className="body4">CIELAB and <Link to="cieluv">CIELUV</Link> were both recommended as interim solutions for solving <Link to="color-difference">color difference</Link> specification of <Link to="subtractive">reflecting light</Link>.</p>
        {/* <Image 
          src={Figure}
          alt="" 
          // caption="RGB color space"
        ></Image> */}
      </Section>

      <Section title="Examples" level="2">
        <Section level="5" splitView 
          // title="LAB (CIELAB)"
          playground={<ColorPicker mode="LAB" />}
          >
          <p>CSS annotation:</p>
          <ul>
            <li> <code>lab(40% 81 20)</code> </li>
            <li> <code>lab(40% 81 20 / 1)</code> with transparency</li>
          </ul>
        </Section>

        {/* <p>Cylindrical transformations provide a more human-centered approach to defining color, defined by three channels: <strong>luminance</strong>, <strong>chroma</strong>, and <strong>hue</strong>.</p> */}
        <Section level="5" splitView 
          title="LCH"
          playground={<ColorPicker mode="LCH" />}
          >
          <p>LCH is a cylindrical transformation of LAB color space. Properties are Lightness, Chroma, and Hue.</p>
          <p>CSS annotation:</p>
          <ul>
            <li> <code>lch(40% 81 350)</code> </li>
            <li> <code>lch(40% 81 350 / 1)</code> with transparency</li>
          </ul>
        </Section>

      </Section>

      {/* <Section title="Analysis of CIELAB" level="2" >
        <p>Knowing which <Link to="color-space">color space</Link> to use as a designer can be confusing. This analysis could help you in determining whether or not CIELAB is the right color space to try.</p>

        <h5>Methods and derivation</h5>
        <Table
          header={['Derived from', <Link to="chromatic-adaptation">Chromatic adaptation</Link>, <Link to='lightness'>Lightness</Link>, 'White point']}
        >
          <TableRow>
            <Link to="xyz">XYZ</Link>
            <Link to="von-kries">"Wrong Von Kries"</Link>
            <>L*</>
            <>D50</>
          </TableRow>

        </Table>

        <h5>Pro's and con's of CIELAB</h5>
        <Table 
          splitView
          header={['Known issues & limitations', 'Benefits & strengths']}
        >
          <TableRow>
            <ul>
              <li>Descrepencies between observed &amp; predicted hue and chroma (Hung and Berns 1995).</li>
              <li>Perceptual unique hues (red, green, yellow, blue) do not align directly with a* b* axes, as would be expected of an accurate model of <Link to="color-opponent-process">color opponency</Link>.</li>
              <li>Incorrect chromatic adaptation predicts incorrect colors under various lighting conditions.</li>
              <li>No luminance-level dependency; cannot predict <Link to="hunt-effect">Hunt effect</Link> or <Link to="stevens-power-law">Stevens' law</Link>.</li>
            </ul>
            <ul>
              <li>Device-independant</li>
              <li>Utilized in <Link to="color-difference">Delta E color difference</Link> formulas.</li>
            </ul>
          </TableRow>
        </Table>

      </Section> */}


      <Section title="How it affects UI design" level="4" className="elements">
        <p>Uniform color spaces are linear models of human vision. However, human perception is non-linear. Do not rely on these color spaces alone to create aesthetically balanced colors. </p>
        <p>Browser support is still growing for CIELAB color space. Check <a href="https://www.w3.org/TR/css-color-4/" target="_blank" rel="noopener noreferrer">CSS Module level 4</a> for more detail.</p>
        <p><Link to="/color-scale">Color scales</Link> can be created by <Link to="color-interpolation">interpolation</Link> in various color spaces.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          {/* <li><Link to="/von-kries">Von Kries</Link></li> */}
          <li><Link to="/trichromacy">Trichromacy</Link></li>
          <li><Link to="/color-opponent-process">Color opponent process</Link></li>
          {/* <li><Link to="/color-model">Color model</Link></li>
          <li><Link to="/color-space">Color space</Link></li> */}
          <li><Link to="/hunt-effect">Hunt effect</Link></li>
          <li><Link to="/stevens-power-law">Stevens' power law</Link></li>
          <li><Link to="/color-interpolation">Color interpolation</Link></li>
          <li><Link to="/color-scale">Color scales</Link></li>
          <li><Link to="cieluv">CIELUV</Link></li>
        </ul>
      </Section>

      <CitationList tools>
        <ToolCitation tool="Chroma.js" />
        <ToolCitation tool="Color.js" />
        <ToolCitation tool="D3 color" />
        <ToolCitation tool="Leonardo" />
        
      </CitationList>

      <CitationList>
        <Citation src="CAMS" pages="201-210" />  
        <Citation src="Colorimetry" pages="61-63" />
        <Citation src="Measuring color" pages="55-57" />
        <Citation src="Wikipedia" url="https://en.wikipedia.org/wiki/CIELAB_color_space" title="CIELAB color space" />
      </CitationList>


      <Footer />

    </motion.div>
  )
}
 
export default LAB;