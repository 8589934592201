import React from "react"
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import Figure from '../img/Figure_SpatialColorMixing.png';
import Figure1 from '../img/Figure_SpatialColorMixing1.png';
import Figure2Light from '../img/Figure_SpatialColorMixing2-light.png';
import Figure2Dark from '../img/Figure_SpatialColorMixing2-dark.png';
import PageHeader from '../components/PageHeader';

import ImageSlider from "../components/ImageSlider";
import { Link } from "react-router-dom";
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";

function SpatialColorMixing(props) {
  const mq = window.matchMedia('(prefers-color-scheme: dark)');
  const Figure2 = (mq.matches) ? Figure2Dark : Figure2Light;

  const DiscContrast = <ImageSlider 
  id="SuccessiveContrast" 
  images={ [Figure1, Figure2] } 
  labelLeft="Illusion"
  labelRight="Reveal" 
  height="340px"
  // toggle
  // width='230px'
  />

  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      key="spatial-color-mixing"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Spatial color mixing"
        description="(Optical color mixing) A perceptual illusion created when the visual system combines color information."
      />

      <PageHeader
        category="Color perception"
        title="Spatial color mixing"
        subtitle="(Optical color mixing)"
        />

      <Section
        splitView
        figure={Figure}
        alt="Teal colored square with maginfied loupe showing the color is actually a repeating pattern of white, green and blue smaller squares"
        caption="A teal color appears due to spatial color mixing of white, green and blue."
      >
      <p className="body4">A perceptual illusion created when the visual system combines color information.</p>
      </Section>

      <Section title="Example" level="2">
        <Section level="4" splitView title="Munker-White illusion"
          playground={DiscContrast}
          >
          <p>Move the slider to remove the bands of color from the image and reveal the actual color of the spheres.</p>
        </Section>
      </Section>

      <Section title="How it affects UI design" level="4" className="elements">
        <p>All colors on digital displays are percieved due to spatial color mixing of pixel-level signals of red, green, and blue.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/simultaneous-contrast'> Simultaneous contrast </Link></li>
          <li><Link to='/bezold-effect'> Bezold effect </Link></li>
          <li><Link to="additive">Additive color mixing</Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="Understanding Color" pages="123-124" />
        <Citation url="https://thevirtualinstructor.com/blog/optical-color-mixing" publication="The Virtual Instructor" author="Matt Fussell" />
        <Citation url="https://www.livescience.com/confetti-munker-white-optical-illusion.html" publication="Live Science" author="Nicoletta Lanese" year="2021" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default SpatialColorMixing;