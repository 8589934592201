import { 
  Link, 
  useParams 
} from "react-router-dom";
import SiteData from "../SiteData";
import "./Menu.scss";

const MenuGroup = (props) => {
  const title = props.title;
  const string = props.title.replaceAll(' ', '-').toLowerCase();
  const id = 'menu--' + string;
  const className = (props.defaultOpen) ? "menu-group is-open" : "menu-group" ;

  return (
    <div className={className} id={id}>
      <span className="item--topLevel" onClick={() =>  {toggleView({id})} }>{title}</span>
      {props.children}
    </div>
  );
}

const MenuLink = ({ page, selected, type, setter, status, id }) => {
  // const title = page.charAt(0).toUpperCase() + page.slice(1);
  const title = page;
  let className = selected ? "item selected " : "item ";
  
  if(status) className = className + ' ' + status;
  if(type) className = className + type;

  let linkTo = (page === "Home") ? "" :  page.replaceAll('–', '-').replaceAll(" ", "-").replaceAll("'", "").replaceAll("?", "").replaceAll("ü", "u").replaceAll("&", "and").toLowerCase();
  
  return (
    <Link 
      to={`/${linkTo}`} 
      // key={key}
      id={id}
      className={className} 
      onClick={e => {
        setTimeout(()=> {
          setter(false);
        },150)
      }}
    >
      {title}
    </Link>
  );
};

const toggleView = (id) => {
  const menuGroup = document.getElementById(id.id);
  if(menuGroup.classList.contains('is-open')) menuGroup.classList.remove('is-open');
  else menuGroup.classList.add('is-open')
}

const Menu = (props) => {
  const page = useParams().page || "Home";

  const setter = props.setIsMenuOpen;

  // Get data from SiteData to generate all menu links..
  const categories = SiteData.map(({ category }) => category);
  const unique = (value, index, self) => {
    return self.indexOf(value) === index
  }
  const uniqueCategories = categories.filter(unique)
  
  return (
    <div className="navigation-sub"> 
      <MenuLink key="homePage" setter={setter} type="item--topLevel" page="Home" selected={page === "home" || page === ""} />
      <MenuLink key="IntroPage" setter={setter} type="item--topLevel" page="Introduction" selected={page === "introduction"} />

      {/* {menuGroups} */}
      {uniqueCategories.map((cat, i) => {
        if(cat === 'uncategorized') {
          // do nothing with it.
          return <></>
        } else {
          const filtered = SiteData.filter((item) => item.category.includes(cat))
          return (
            <MenuGroup defaultOpen key={`${cat}-${i}`} title={cat}>
              {filtered.map((item) => {
                return (
                  <MenuLink key={item.path} setter={setter} page={item.title} selected={page === item.path.replaceAll('/', '')} />
                )
              })}
            </MenuGroup>
          )
        }
      })}

      <MenuLink key="ReferencesPage" setter={setter} type="item--topLevel" page="References" selected={page === "references"} id="lastMenuItem"/>

    </div>
  );
};

export default Menu;


