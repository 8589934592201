import React from "react"
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import Figure from '../img/Figure_Clamping.png';
import FigureDark from '../img/Figure_ClampingDark.png';
import PageHeader from '../components/PageHeader';

import { Link } from "react-router-dom";

function Clamping(props) {
  const mq = window.matchMedia('(prefers-color-scheme: dark)');
  const IMG = (mq.matches) ? FigureDark : Figure;

  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      key="clamping"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Clamping"
        description="When a color is outside of a color space's gamut, the closest approximate in-gamut color is returned."
      />

      <PageHeader
        category="Color spaces"
        title="Clamping"
        // subtitle=""
        />

      <Section
        splitView
        figure={IMG}
        // alt="alt text"
        caption="Clamping to sRGB will take a color that is out of gamut and return its closest approximate equivalent."
      >
      <p className="body4">Returning a valid color value for a <Link to="color-space">color space's</Link> when the computed value is either out of <Link to="gamut">gamut</Link> or too precise of a number.</p>
      <p className="body4">When the color is out of gamut, the closest approximate in-gamut color is returned. When the color value is too precise, it is rounded to a supported value.</p>
      </Section>

      <Section title="How it affects UI design" level="4" className="elements">
        <p>Clamping is built into most color libraries.</p>
        <p>Clamping can enable you to design for wide <Link to="gamut">gamut</Link> color spaces, such as <Link to="color-appearance-model">uniform color spaces</Link> while still providing colors in your product's supported <Link to="color-profile">color profile</Link>.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/color-model'> Color model </Link></li>
          <li><Link to='/color-space'> Color space </Link></li>
          <li><Link to='/gamut'> Gamut </Link></li>
          <li><Link to='/color-appearance-model'> Color appearance model </Link></li>
          <li><Link to='/color-profile'> Color profile </Link></li>

        </ul>
      </Section>

      {/* TODO: Add citations 
        TODO: Evaluate inline & related links
      */}


      <Footer />

    </motion.div>
  )
}
 
export default Clamping;