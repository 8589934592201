import React from "react"
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';
import { Link } from "react-router-dom";

import Figure from '../img/Figure_HelmholtzKohlrausch.png';
import PageHeader from '../components/PageHeader';

import CitationList from "../components/CitationList";
import Citation from "../components/Citation";

function HelmholtzKohlrausch(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      exit="out"
      key="chromatic-luminance"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Helmholtz-Kohlrausch effect"
        description="(Chromatic luminance) The illusion where increased saturation of a color is perceived as an increase in the color's lightness."
      />

      <PageHeader
        category="Color perception"
        title="Helmholtz–Kohlrausch effect"
        subtitle="(Chromatic luminance)"
        />

      <Section level="4" splitView 
        figure={Figure}
        alt="alt text"
        caption="Colors of similar lightness shown with different saturation, compared to the same colors shown in grayscale."
        >
        <p className="body4">The illusion where increased saturation (or <Link to="chroma">chroma</Link>) of a color is perceived as an increase in the color's <Link to="lightness">lightness</Link>.</p>
      </Section>

      <Section title="How it affects UI design" level="4" className="elements">
        <p>When designing interfaces, be aware of this illusion.</p>
        <p> <strong>Do not</strong> try to subjectively match colors by their apparent lightness, as this can result in a false alignment. People with a <Link to="color-vision-deficiency">color vision deficiency</Link> will not experience this illusion to the same degree (if at all).</p>
        <p>Use accurate measurements for <Link to="lightness">lightness</Link>, such as with <Link to="color-appearance-model">color appearance models</Link>.</p>
        <p>Use a tool to check <Link to="apca">contrast</Link> in order to ensure appropriate contrast is provided, regardless of the affects of chromatic luminance.</p>
        <p>WCAG <a href="https://www.w3.org/TR/WCAG21/#contrast-minimum" target="_blank" rel="noopener noreferrer">1.4.3 (contrast minimum)</a> provides a general threshold for making text &amp; components percievable to most sighted users.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/color-vision-deficiency'> Color vision deficiency </Link></li>
          <li><Link to='/chroma'> Chroma </Link></li>
          <li><Link to='/chromaticity'> Chromaticity </Link></li>
          <li><Link to='/tone'> Tone </Link></li>
          <li><Link to='/color-appearance-model'> Color appearance model </Link></li>
          <li><Link to='/simultaneous-contrast'> Simultaneous contrast </Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="CAMS" pages="123-124"/>
        <Citation src="Measuring color" pages="440" />
        <Citation src="Colorimetry" pages="276-277" />
        <Citation src="Wikipedia" url="https://en.wikipedia.org/wiki/Helmholtz%E2%80%93Kohlrausch_effect" title="Helmholtz–Kohlrausch effect" />
        <Citation src="WCAG" url="https://www.w3.org/TR/WCAG21/#contrast-minimum" title="Success Criterion 1.4.3 Contrast (Minimum)" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default HelmholtzKohlrausch;