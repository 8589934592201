import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';
import Citation from "../components/Citation";
import CitationList from "../components/CitationList";

// import Figure from '../img/Figure_Pupil.png';
// import FigureDark from '../img/Figure_Pupil_Dark.png';
import PageHeader from '../components/PageHeader';

import Figure from '../img/Figure_pupil.png';
import FigureDark from '../img/Figure_pupil_dark.png';
// import Figure from '../img/Figure_retina.png';
// import Figure from '../img/Figure_cones.png';
// import Figure from '../img/Figure_rods.png';
// import Figure from '../img/Figure_optic-nerve.png';
// import Figure from '../img/Figure_pigment.png';
// import Figure from '../img/Figure_fovea.png';


function Pupil(props) {
  const mq = window.matchMedia('(prefers-color-scheme: dark)');
  const IMG = (mq.matches) ? FigureDark : Figure;

  return (
    <motion.div
      style={pageStyle}
      key="trichromacy"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Pupil &amp; iris"
        description=""
      />

      <PageHeader
        category="Color vision"
        title="Pupil &amp; iris"
        // subtitle="(Anatomy)"
        />

      <Section
        figure={IMG}
        splitView
      >
        <p className="body4">The pupil is the central opening in the iris muscle. The iris controls pupil size in order to regulate the amount of light entering the eye.</p>
        <p className="body4">Pupil size changes during sensory adaptations such as <Link to="light-and-dark-adaptation">light and dark adaptation</Link>.</p>
      </Section>


      <Section title="Related topics" level="4">
        <ul>
          <li><Link to="light-and-dark-adaptation"> Light &amp; dark adaptation </Link></li>
          <li><Link to="light-sensitivity"> Light sensitivity </Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="CAMS" pages="4-5" />
        <Citation src="AAO.org" url="https://www.aao.org/eye-health/anatomy/pupil" title="Pupil"/>
        <Citation src="NIH NEI" url="https://www.nei.nih.gov/learn-about-eye-health/healthy-vision/how-eyes-work" title="How eyes work" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default Pupil;