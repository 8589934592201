import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import Figure from '../img/Figure_AnalogousColors.png';
import PageHeader from '../components/PageHeader';
// import ColorHarmonyPicker from '../components/ColorHarmonyPicker';
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";
import ToolCitation from "../components/ToolCitation";

function AnalogousColors(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      key="analogous-colors"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Analogous colors"
        description="Colors that are in close proximity on a color wheel (similar hue)."
      />

      <PageHeader
        category="Color relationships"
        title="Analogous colors"
        // subtitle=""
        />

      <Section
        figure={Figure}
        alt="Color wheel with orange, yellow-green, and red circles displayed in close proximity"       
      >
        {/* <p className="body4">A color space is the total collection of possible colors within a particular set of primaries. Color spaces are specific implimentations of a <Link to="/color/color-model">color model</Link>.</p> */}
        <p className="body4">Colors that are in close proximity on a color wheel (similar hue).</p>

      </Section>

      {/* <Section title="Try it out">
        <p>Color relationships are abstract ideas. Unique colors are produced when these relationships are applied to specific implementations of a <Link to='/color-model'>color model</Link> (<Link to='/color-space'>color spaces</Link>). </p>
        
        <ColorHarmonyPicker relationship="Analogous" mode="HSL" hideReset hideValue/>
      </Section> */}


      <Section title="How it affects UI design" level="4" className="elements">
        <p>Analogous colors can be used to create dynamic <Link to="/color-scale">color scales</Link> and combat the <Link to="abney-effect">Abney effect</Link> or simulate the <Link to="bezold-brucke-shift">Bezold–Brücke shift</Link>.</p>
        <p>Analogous colors can be used as secondary, supporting colors in a UI color palette for a high degree of harmony and unity.</p>
        <p>Choosing analogous colors at a higher distance on the color wheel can produce greater interest in color scheme.</p>
        <p><Link to="hue">Hue</Link> is treated differently between <Link to="color-space">color spaces</Link>, which can effect which colors are considered to be analogous.</p>
      </Section>


      <CitationList tools>
        <ToolCitation tool="Adobe color" />
      </CitationList>

      <CitationList>
        <Citation src="Understanding Color" pages="73, 221" />
        <Citation src="Launching the Imagination" pages="62" />
        <Citation src="Classical Painting" pages="81" />
        <Citation src="Handprint.com" url="https://www.handprint.com/HP/WCL/tech13.html" title="Color harmony &amp; color design"/>
      </CitationList>


      <Footer />

    </motion.div>
  )
}
 
export default AnalogousColors;