
import React from "react"
import PageMeta from "./PageMeta";
import SiteData from "../SiteData";
// import SocialShareButton from "./SocialShareButton";

class PageHeader extends React.Component {
  constructor(props) {
    super(props)
    this.title = props.title;
    this.description = props.description;
    this._findCategory = () => {
      const thisItem = SiteData.find(item => item.title.includes(props.title))

      return thisItem.category;
    }
    this.category = (this._findCategory() !== 'uncategorized') ? this._findCategory() : "";

    this.url = props.title.replaceAll(" ", "-").replaceAll("'", "").replaceAll("?", "").replaceAll("ü", "u").replaceAll("&", "and").toLowerCase();
    this.img = 'https://colorandcontrast.com/static/media/' + props.img;
    this.alt = props.alt;
    this.hashtags = props.hashtags;
  }
  render() {
    const subtitle = (this.props.subtitle) ? <p className="h3 subtitle">{this.props.subtitle}</p> : '';
    return (
      <>
        <PageMeta
          title={this.title}
          description={this.description}
          img={this.img}
          alt={this.alt}
        />
        <hgroup>
          <p className="h5">{this.category}</p>
          <h1>{this.title}</h1>
          {subtitle}
        </hgroup>

        {/* <SocialShareButton
          description={this.title + ": " + this.description}
          url={this.url}
          hashtags={this.hashtags}
        /> */}
      </>

    )
  }
}
 
export default PageHeader;
