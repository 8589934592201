import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';
import Citation from "../components/Citation";
import CitationList from "../components/CitationList";

// import Figure from '../img/Figure_Lens.png';
// import FigureDark from '../img/Figure_Lens_Dark.png';
import PageHeader from '../components/PageHeader';

import Figure from '../img/Figure_lens.png';
import FigureDark from '../img/Figure_lens_dark.png';
// import Figure from '../img/Figure_pupil.png';
// import Figure from '../img/Figure_retina.png';
// import Figure from '../img/Figure_cones.png';
// import Figure from '../img/Figure_rods.png';
// import Figure from '../img/Figure_optic-nerve.png';
// import Figure from '../img/Figure_pigment.png';
// import Figure from '../img/Figure_fovea.png';


function Lens(props) {
  const mq = window.matchMedia('(prefers-color-scheme: dark)');
  const IMG = (mq.matches) ? FigureDark : Figure;

  return (
    <motion.div
      style={pageStyle}
      key="trichromacy"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Lens"
        description=""
      />

      <PageHeader
        category="Color vision"
        title="Lens"
        // subtitle="(Anatomy)"
        />

      <Section
        figure={IMG}
        splitView
      >
        <p className="body4">The lens serves to assist the <Link to="cornea">cornea</Link> in the refraction of light. It is shaped in a way that helps to reduce <Link to='/chromatic-abberation'>abberations</Link>.</p>
        <p className="body4">The shape of the lens is flexible and gets "flatter" when viewing distant objects, and "thicker" when viewing nearby objects.</p>
        <p className="body4">Lens flexibility is reduced with age, resulting in <Link to="presbyopia">presbyopia</Link>.</p>
      </Section>


      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/cornea'> Cornea </Link></li>
          <li><Link to='/chromatic-abberation'> Chromatic abberation </Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="CAMS" pages="3" />
        <Citation src="AAO.org" url="https://www.aao.org/eye-health/anatomy/lens-9" title="Lens"/>
        <Citation src="NIH NEI" url="https://www.nei.nih.gov/learn-about-eye-health/healthy-vision/how-eyes-work" title="How eyes work" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default Lens;