import React from "react"
import { 
  Route, 
  Switch,
} from 'react-router-dom'; 

import Menu from './Menu';
import Cross from '../svg/Cross';
import SiteData from "../SiteData";
import Search from "./Search";
import './Navigation.scss';

const Navigation = (props) => {
  // const page = useParams().page || 'home';
  const isOpen = props.visible;
  const setter = props.setIsMenuOpen;
  const classNameStyle = (isOpen) ? 'menuWrapper open' : 'menuWrapper';

  return (
    <div className={classNameStyle}>      
      <nav className="menu" aria-label="Main">
        <button type="button" className="menuButton" aria-expanded="true" onClick={e => {
          setter(false);
        }}>
          <Cross
            width="18px"
            height="18px"
            // aria-hidden="true"
            // focusable="false"
          />
        </button>

        <Search details={SiteData} menuSetter={setter}/>

        <Switch>
          <Route exact path='/'>
            <Menu setIsMenuOpen={setter}/>
          </Route>
          <Route path='/:page'>
            <Menu setIsMenuOpen={setter}/>
          </Route>
        </Switch>
      </nav>
      <div id="overlay" onClick={e => {
          setter(false);
        }}></div>
    </div>
    
  );
};

export default Navigation;


