import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';
import LightAdapted from '../img/Figure_HuntEffectLight.png';
import MedAdapted from '../img/Figure_HuntEffectMed.png';
import DarkAdapted from '../img/Figure_HuntEffectDark.png';
import ImageSlider from "../components/ImageSlider";
import Citation from "../components/Citation";
import CitationList from "../components/CitationList";
// import './HuntEffect.scss';

// import Figure from '../img/Figure_HuntEffect.png';
import PageHeader from '../components/PageHeader';


function HuntEffect(props) {
  const CaveSim = <ImageSlider 
    id="HuntEffect" 
    images={ [DarkAdapted, MedAdapted, LightAdapted] } 
    labelLeft="Low light" 
    labelRight="Bright light" 
    defaultValue="100"
    contained
    height="250px"
    // pan
    // width='230px'
    />

  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      key="hunt-effect"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Hunt effect"
        description="The colorfulness (chroma) of a color increases with it's luminance."
      />

      <PageHeader
        category="Color vision"
        title="Hunt effect"
        />

      <Section splitView
          playground={CaveSim}
        >
        <p className="body4">The colorfulness (<Link to="chroma">chroma</Link>) of a color increases as <Link to="luminance">light intensity</Link> is increased.</p>
      </Section>

      <Section title="How it affects UI design" level="4" className="elements">
        <p>Decreasing <Link to="chroma">chroma</Link> of colors in <Link to="dark-mode">dark mode</Link> may give your interface a more natural appearance to colors.</p>
        <p>People viewing your design on a low-lit display will see lower <Link to="chroma">chromatic</Link> colors.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/light-and-dark-adaptation'> Light &amp; dark adaptation </Link></li>
          <li><Link to='/luminance'> Luminance </Link></li>
          <li><Link to='/chroma'> Chroma </Link></li>
          <li><Link to='/chromaticity'> Chromaticity </Link></li>
          <li><Link to='/bezold-brucke-shift'> Bezold–Brücke shift</Link></li>
          <li><Link to='/color-appearance-model'> Color appearance model</Link></li>

        </ul>
      </Section>

      <CitationList>
        <Citation src="Measuring color" pages="440" />
        <Citation author="Michel Berthier, Edoardo Provenzi" title="Hunt’s Colorimetric Effect from a Quantum Measurement Viewpoint" year="2021" publisher="Springer" url="https://link.springer.com/chapter/10.1007/978-3-030-80209-7_20"/>
        <Citation src="CAMS" pages="125-127" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default HuntEffect;