import React from "react"
import Image from "../components/Image";
import Caption from "./Caption";
import './Section.scss';

class Section extends React.Component {
  // constructor(props) {
  //   super(props)
  // }
  render() {
    // const link = `#${this.props.title}`;
    // const title = (this.props.title) ? <h5 id={this.props.title}>{this.props.title} <a className="anchorLink" href={link}>#</a></h5> : '';
    let title;
    if(this.props.title) {
      if(this.props.level === '1') {
        title = <h1 id={this.props.title}>{this.props.title}</h1> 
      }
      else if(this.props.level === '2' ) {
        title = <h2 id={this.props.title}>{this.props.title}</h2> 
      }
      else if(this.props.level === '3' ) {
        title = <h3 id={this.props.title}>{this.props.title}</h3> 
      } 
      else if(this.props.level === '4' ) {
        title = <h4 id={this.props.title}>{this.props.title}</h4> 
      } 
      else if( !this.props.level || this.props.level === '5' ) {
        title = <h5 id={this.props.title}>{this.props.title}</h5> 
      }
    } else {
      title = '';
    }

    let sectionClassName;
    if(this.props.splitView) sectionClassName = 'subSection';
    if(this.props.className) sectionClassName = sectionClassName + ' ' + this.props.className;
    if(this.props.level === '1') sectionClassName = sectionClassName + ' primarySection';

    const overline = (this.props.overline) ? <h6 className="overline">{this.props.overline}</h6> : ' ';

    const image = (this.props.figure) ? <Image 
      src={this.props.figure}
      alt={this.props.alt}
      caption={this.props.caption}
    ></Image> : '';

    const supportingContent = (this.props.figure) ? image : ((this.props.playground) ? this.props.playground: '');
    const playgroundCaption = (this.props.playground && this.props.caption) ? <Caption>{this.props.caption}</Caption> : '';

    const primaryContent = <div>
        {overline}
        {title}
        {this.props.children}
      </div>;

    const secondaryContent = <div>
      {supportingContent}
      {playgroundCaption}
      </div>

    const leftContent = (!this.props.reversed) ? primaryContent : secondaryContent;
    const rightContent = (!this.props.reversed) ? secondaryContent : primaryContent;

    return (
      <section className={sectionClassName}>
        {leftContent}
        {rightContent}
      </section>
    )
  }
}
 
export default Section;
