import React from "react"
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

// import Figure from '../img/Figure_Luminance.png';
// import FigureDark from '../img/Figure_Luminance_Dark.png';
import PageHeader from '../components/PageHeader';

import CitationList from "../components/CitationList";
import Citation from "../components/Citation";
import { Link } from "react-router-dom";

function Luminance(props) {
  // const mq = window.matchMedia('(prefers-color-scheme: dark)');
  // const IMG = (mq.matches) ? FigureDark : Figure;

  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      key="luminance"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Luminance"
        description="A measure of the intensity of emitted light, which describes the amount of light passing through, emitting from, or reflecting off a surface."
      />

      <PageHeader
        category="Measurement &amp; formulas"
        title="Luminance"
        // subtitle="(Delta E)"
        />

      <Section>
        <p className="body4">A measure of the intensity of emitted light, which describes the amount of light passing through, emitting from, or reflecting off a surface. Luminance is not a perceptual measurement.</p>
        <p className="body4">Luminance is distinctly different from color properties such as <Link to="lightness">lightness</Link>, value or brightness.</p>
      </Section>

      {/* <Section title="Try it out">
        <p>Picker here</p>
      </Section> */}

      <Section title="How it affects UI design" level="4" className="elements">
        <p>Luminance relates to a device's unique screen brightness levels, as the amount of light emitted from the screen.</p>
        <p>Highly luminous displays can cause adverse effects for users with <Link to="light-sensitivity">light sensitivity</Link> (such as eye strain or migraines) when viewed in dark lighting conditions. It is recommended to support both <Link to="light-mode">light</Link> and <Link to="dark-mode">dark mode</Link> as well as iOS <Link to="increase-contrast-mode">increase contrast mode</Link> and Windows <Link to="high-contrast-mode">high contrast mode</Link>.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/lightness'> Lightness </Link></li>
          <li><Link to='/light-and-dark-adaptation'> Light &amp; dark adaptation </Link></li>
          <li><Link to='/light-sensitivity'>Light sensitivity</Link></li>
          <li><Link to='/bezold-brucke-shift'>Bezold–Brücke shift</Link></li>
          <li><Link to='/hunt-effect'>Hunt effect</Link></li>
          <li><Link to='/relative-luminance'>Relative luminance</Link></li>
          <li><Link to='/apca'>APCA</Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation url="https://www.sciencedirect.com/topics/biochemistry-genetics-and-molecular-biology/luminance" publisher="ScienceDirect" title="Luminance"/>
        <Citation url="https://www.cell.com/current-biology/pdf/S0960-9822(07)00878-0.pdf" title="Lightness and brightness" author="Alan L Gilchrist" publisher="Current Biology (Cell Press Journal)" />
        <Citation publisher="Wikipedia" title="Luminance" url="https://en.wikipedia.org/wiki/Luminance"/>
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default Luminance;