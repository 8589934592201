import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

// import Figure from '../img/Figure_RGB.png';
import PageHeader from '../components/PageHeader';
import ColorPicker from "../components/ColorPicker";
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";
import ToolCitation from "../components/ToolCitation";
// import { Table, TableRow } from "../components/Table";

function sRGB(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      key="srgb"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="sRGB"
        description="An RGB color model supported in most digital displays. sRGB is the most widely used color space for web browsers."
      />

      <PageHeader
        category="Color spaces"
        title="sRGB"
        // subtitle=""
        />

      <Section>
        <p className="body4">An <Link to="/rgb-model">RGB color model</Link> supported in most digital displays. sRGB is the most widely used color space for web browsers.</p>
      </Section>

      <Section title="Examples" level="2">
        <Section level="5" splitView 
          title="sRGB"
          playground={<ColorPicker mode="sRGB" />}
        >
        <p>The sRGB color space is the web default, and supported by most digital displays. HEX format is a base 16 format for RGB.</p>
        <p>CSS annotation:</p>
        <ul>
          <li> <code>rgb(255, 255, 255)</code> </li>
          <li> <code>rgba(255, 255, 255, 1)</code> with transparency</li>
          <li> <code>#RRGGBBAA</code> </li>
          <li> <code>#RRGGBBAA</code> with transparency</li>
        </ul>
        </Section>

        <Section level="5" splitView 
          title="HSL"
          playground={<ColorPicker mode="HSL" />}

        >
        <p>HSL is a cylindrical transformation of sRGB color space. Properties are Hue, Saturation, and Lightness.</p>
        <p>CSS annotation:</p>
        <ul>
          <li> <code>hsl(360deg, 100%, 100%)</code> </li>
          <li> <code>hsla(360deg, 100%, 100%, 1)</code> with transparency</li>
        </ul>
        </Section>

        <Section level="5" splitView 
          title="HWB"
          playground={<ColorPicker mode="HWB" />}

        >
        <p>HWB is a cylindrical transformation of sRGB color space and is closely related to HSV. Properties are Hue, Whiteness, and Blackness.</p>
        <p>CSS annotation:</p>
        <ul>
          <li> <code>hwb(360 100% 100%)</code> </li>
          <li> <code>hwb(360 100% 100% / 1)</code> with transparency</li>
        </ul>
        </Section>

        <Section level="5" splitView 
          title="HSV"
          playground={<ColorPicker mode="HSV" />}
        >
        <p>HSV is a cylindrical transformation of sRGB color space. Properties are Hue, Saturation, and Value.</p>
        <p>HSV is not available in CSS, but used widely in design tools and color pickers.</p>

        </Section>

      </Section>


      {/* <Section title="Analysis of sRGB" level="2" >
        <p>Knowing which <Link to="color-space">color space</Link> to use as a designer can be confusing. This analysis could help you in determining whether or not sRGB is the right color space to try.</p>

        <h5>Methods and derivation</h5>
        <Table
          header={['Derived from', <Link to="chromatic-adaptation">Chromatic adaptation</Link>, <Link to='lightness'>Lightness</Link>, 'White point']}
        >
          <TableRow>
            <>Red, Green, Blue pixels of device</>
            <>None</>
            <>None</>
            <>N/A</>
          </TableRow>

        </Table>

        <h5>Pro's and con's of sRGB</h5>
        <Table 
          splitView
          header={['Known issues & limitations', 'Benefits & strengths']}
        >
          <TableRow>
            <ul>
              <li>Device-dependant</li>
              <li>Not perceptually accurate</li>
              <li>Cylindrical transformations are not perceptually accurate</li>
            </ul>
            <ul>
              <li>RGB is an accurate model for <Link to="additive">additive</Link> mixtures of light for a person's specific device.</li>
              <li>Widely supported (default) for web and digital applications</li>
            </ul>
          </TableRow>
        </Table>

      </Section> */}


      <Section title="How it affects UI design" level="4" className="elements">
        <p>RGB color spaces are widely supported in most technologies, and are a safe approach to define colors for UI.</p>
        <p>RGB color spaces are not perceptually uniform. Using these to mix <Link to="tint">tints</Link>, <Link to="shade">shades</Link>, or <Link to='tone'>tones</Link> can be very difficult and yield unexpected results.</p>
        <p>When trying to create perceptually uniform colors, use a <Link to="color-appearance-model">color appearance model</Link> instead.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to="color-model">Color model</Link></li>
          <li><Link to="color-space">Color space</Link></li>
          <li><Link to="additive">Additive color mixing</Link></li>
          <li><Link to="tint">Tint</Link></li>
          <li><Link to="shade">Shade</Link></li>
          <li><Link to="tone">Tone</Link></li>
        </ul>
      </Section>

      <CitationList tools>
        <ToolCitation tool="Chroma.js" />
        <ToolCitation tool="Color.js" />
        <ToolCitation tool="D3 color" />
        <ToolCitation tool="Leonardo" />
      </CitationList>

      <CitationList>
        <Citation url="https://www.color.org/chardata/rgb/srgb.xalter" publisher="International Color Consortium (ICC)" title="sRGB" />
        <Citation src="Wikipedia" url="https://en.wikipedia.org/wiki/SRGB" title="sRGB" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default sRGB;