import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import Figure from '../img/Figure_Tone.png';
import FigureDark from '../img/Figure_Tone_Dark.png';
// import Example from '../img/Figure_Tone-Example.png';
// import Example2 from '../img/Figure_ColorModel-Example.png';
import PageHeader from '../components/PageHeader';
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";


function Tone(props) {
  const mq = window.matchMedia('(prefers-color-scheme: dark)');
  const IMG = (mq.matches) ? FigureDark : Figure;


  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      key="tone"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Tone"
        description="A less colorful version of a color, generally equal to adding gray, or adding the color's compliment."
      />

      <PageHeader
        category="Color properties"
        title="Tone"
        // subtitle=""
        />

      <Section
        figure={IMG}
        alt="Orange circle with arrow pointing to a less saturated orange circle, demonstrating its tone"       
      >
        {/* <p className="body4">A color space is the total collection of possible colors within a particular set of primaries. Color spaces are specific implimentations of a <Link to="/color/color-model">color model</Link>.</p> */}
        <p className="body4">A less <Link to="chroma">colorful</Link> version of a color, generally equal to "adding gray," or adding the color's compliment.</p>
        
      </Section>


      <Section title="How it affects UI design" level="4" className="elements">
        <p>A color's appearance can be affected by the lightness or darkness of its backround. For dark mode, less saturated tones may appear more appropriate and can be less strain on a users' eyes.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/chroma'> Chroma </Link></li>
          <li><Link to='/color-model'> Color model </Link></li>
          <li><Link to='/color-space'> Color space </Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="Understanding Color" pages="89"/>
        <Citation src="Launching the Imagination" pages="384" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default Tone;