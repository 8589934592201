import React from "react"
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

// import Figure from '../img/Figure_ColorProfile.png';
import PageHeader from '../components/PageHeader';

import { Link } from "react-router-dom";
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";

function ColorProfile(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      key="color-profile"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Color profile"
        description="Data regarding an input or output color space used for a device or media. International Color Consortium (ICC) Profiles are standard designations to assist in displaying media within its specified format."
      />

      <PageHeader
        category="Color spaces"
        title="Color profile"
        // subtitle=""
        />

      <Section
        // splitView
        // figure={Figure}
        // alt="alt text"
        // caption="ColorProfile."
      >
      <p className="body4">Data regarding an input or output <Link to="color-space">color space</Link> used for a device or media. <a href="https://www.color.org/index.xalter" target="_blank" rel="noopener noreferrer">International Color Consortium (ICC)</a> Profiles are standard designations to assist in displaying media within its specified format.</p>
      <p className="body4">Color management is the controlled conversion of colors, which can be done using ICC color profiles.</p>
      </Section>

      <Section title="How it affects UI design" level="4" className="elements">
        <p>Media displayed on a device may have a unique color profile. Web applications may display colors in <Link to="srgb">sRGB</Link> while still properly displaying an image formatted to a different color profile.</p>
        <p>Color profiles are especially important when creating image assets. An image formatted to <Link to="cmyk-model">CMYK</Link> will appear muddy an inaccurate when displayed in <Link to="rgb-model">RGB</Link>.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/color-model'> Color model </Link></li>
          <li><Link to='/color-space'> Color space </Link></li>
          <li><Link to="/gamut">Gamut</Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation url="https://www.color.org/index.xalter" title="International Color Consortium (ICC)" />
        <Citation url="https://en.wikipedia.org/wiki/ICC_profile" title="ICC Profile" publisher="Wikipedia" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default ColorProfile;