import React from "react"
import { Link } from "react-router-dom";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Pagination from '../components/Pagination';
import Footer from '../components/Footer';

// import Figure from '../img/Figure_TutorialChoosingColorSpace.png';
// import Example from '../img/Figure_TutorialChoosingColorSpaceCIE.png';
// import Example2 from '../img/Figure_ColorModel-Example.png';
import PageHeader from '../components/PageHeader';
// import ImageSlider from "../components/ImageSlider";
// import Image1 from '../img/Figure_ContrastConstancy1.png';
// import Image2 from '../img/Figure_ContrastConstancy2.png';
// import { findByLabelText } from "@testing-library/react";


function TutorialChoosingColorSpace(props) {
  
  return (
    <motion.div
      style={pageStyle}
      key="tutorial-choosing-color-space"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageHeader
        category="Tutorials"
        title="Choosing a color space"
        // subtitle="Secondary, tertiary, &amp; system colors"
        />

      <Section level="2">
        <p className="body4"><Link to="color-space">Color spaces</Link> can be tools for choosing color relationships, creating <Link to="color-scales">color scales</Link> and gradients, and evaluating colors for <Link to="color-appearance-model">perceptual uniformity</Link> and balance.</p>
      </Section>

      <Section 
        overline="Step 1" 
        title="Identify your intent" 
        level="2"
        >
        <p></p>

        <Section 
          level="4" 
          title="Single-hue color scales" 
          splitView 
          className="smallSplitView subSection-compact">
          <p>Single-hue <Link to="color-scale">color scales</Link> should appear to retain a consistent <Link to="hue">hue</Link> appearance for <Link to="tints">tints</Link> and <Link to="shades">shades</Link>. </p>
          <p>These benefit from <strong>cartesian</strong> <Link to="color-space">color spaces</Link> such as:
            <ul>
              <li><Link to="oklab">OKLAB</Link> (recommended)</li>
              <li><Link to="cam02">CAM02</Link></li>
              <li><Link to="srgb">RGB</Link></li>
              <li><Link to="cieluv">HSLuv</Link></li>
            </ul>
          </p>
        </Section>

        <Section 
          level="4" 
          title="Multi-hue color scales" 
          splitView 
          className="smallSplitView subSection-compact">
          <p>Multi-hue <Link to="color-scale">color scales</Link> should appear to retain a consistent <Link to="chroma">chroma</Link> as <Link to="hue">hue</Link> and <Link to="lightness">lightness</Link> change. </p>
          <p>These benefit from <strong>cylindrical</strong> <Link to="color-space">color spaces</Link> such as:
            <ul>
              <li><Link to="oklab">OKLCH</Link> (recommended)</li>
              <li>Cylindrical <Link to="cam02">CAM02</Link></li>
              <li><Link to="lab">LCH</Link></li>
            </ul>
          </p>
        </Section>

      </Section>

      <Section 
        overline="Step 2" 
        title="Favor perceptual uniformity" 
        level="2"
        >
        <p>While there are many options, it is better to leverage <Link to="color-appearance-model">perceptually uniform color spaces</Link>. These color spaces leverage decades of color science, and are approximations of how humans percieve color.</p>
        <p>In some cases, <Link to="RGB">RGB color models</Link> will yeild equally balanced results. This is because RGB color models are based on <Link to="trichromacy">trichromatic color vision</Link>, a fundamental aspect of the visual system.</p>
      </Section>

      <Section 
        overline="Step 3" 
        title="Experiment" 
        level="2"
        >
        <p>Tools are becoming more widely available that support many color spaces. Despite these recommendations, it's always best to test and experiment with colors yourself.</p>
        <p>One tool that helps you test various color spaces and visualize color metrics is Adobe's open-source tool <a href="https://leonardocolor.io" target="_blank" rel="noopener noreferrer">Leonardo</a>.</p>
      </Section>

      <Pagination 
        previous="Choosing a palette"
        next="Creating color scales"
      />

      <Footer />

    </motion.div>
  )
}
 
export default TutorialChoosingColorSpace;