import React from "react"
import Caption from './Caption';
import './Image.scss';

class Image extends React.Component {
  // constructor(props) {
  //   super(props)
  // }
  render() {
    const caption = (this.props.caption) ? <Caption>{this.props.caption}</Caption>: '';
    return (
      <>
        <img 
          className={this.props.className}
          src={this.props.src}
          alt={this.props.alt}
          id={this.props.id}
          style={this.props.style}
        ></img>
        {caption}
      </>

    )
  }
}
 
export default Image;