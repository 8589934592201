import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import Figure from '../img/Figure_ColorVisionDeficiency.png';
import PageHeader from '../components/PageHeader';
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";

function ColorVisionDeficiency(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      key="color-vision-deficiency"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Color vision deficiency"
        description="Color vision deficiencies are anomalies in wavelength sensitivity or absence of photoreceptor cells."
      />

      <PageHeader
        category="Color vision"
        title="Color vision deficiency"
        subtitle='("Color blindness")'
        />

      <Section
        figure={Figure}
        caption="Three plates from the Ishihara color test with hidden numbers 12, 74, and 45"
      >
        <p className="body4">Color vision deficiencies are anomalies in wavelength sensitivity or absence of <Link to="retina">photoreceptor cells</Link>. Approximately 8% of males and 0.5% of females have a color vision deficiency.</p>

      </Section>
      <Section>
        <p className="body4"> Types of color vision deficiency include:</p>
        <ul className="body4">
          <li><Link to='/anomalous-trichromacy'> Anomalous trichromacy </Link></li>
          <li><Link to='/dichromacy'> Dichromacy </Link></li>
          <li><Link to='/monochromacy'> Monochromacy </Link></li>
        </ul>
      </Section>

      <Section title="How it affects UI design" level="4" className="elements">
        <p>Designing with color vision deficiencies in mind is important. Most issues can be resolved by compliance with <a href="https://www.w3.org/TR/WCAG21/#use-of-color" target="_blank" rel="noopener noreferrer">WCAG 1.4.1 (use of color)</a> and <a href="https://www.w3.org/TR/WCAG21/#contrast-minimum" target="_blank" rel="noopener noreferrer">1.4.3 (contrast minimum)</a>.</p>
        <p>When color identification is important to all sighted users, use a color tool to evaluate <Link to="color-difference">color difference</Link> for each color deficiency type.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to="/trichromacy">Trichromacy</Link></li>
          <li><Link to="/color-confusion-lines">Color confusion lines</Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="Psychophysical" pages="268-269"/>
        <Citation src="Color-blindness.com" url="https://www.color-blindness.com/ishiharas-test-for-colour-deficiency-38-plates-edition/"/>
        <Citation src="WCAG" url="https://www.w3.org/TR/WCAG21/#use-of-color" title="Success Criterion 1.4.1 Use of Color" />
        <Citation src="WCAG" url="https://www.w3.org/TR/WCAG21/#contrast-minimum" title="Success Criterion 1.4.3 Contrast (Minimum)" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default ColorVisionDeficiency;