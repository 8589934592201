import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import Figure from '../img/Figure_ColorSpace.png';
import Example from '../img/Figure_ColorSpace-Example.png';
import ExampleDark from '../img/Figure_ColorSpace-Example_Dark.png';
// import Example2 from '../img/Figure_ColorModel-Example.png';
// import Example2Dark from '../img/Figure_ColorModel-Example_Dark.png';
import PageHeader from '../components/PageHeader';
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";
// import {Table, TableRow} from "../components/Table";


function ColorSpace(props) {
  const mq = window.matchMedia('(prefers-color-scheme: dark)');
  // const IMG2 = (mq.matches) ? Example2Dark : Example2;
  const IMG = (mq.matches) ? ExampleDark : Example;
  
  return (
    <motion.div
      style={pageStyle}
      key="color-space"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Color space"
        description="A color space is a collection all colors within a particular specific set of primaries. Color spaces are specific implimentations of a color model."
      />

      <PageHeader
        category="Color spaces"
        title="Color space"
        // subtitle=""
        />

      <Section
        splitView
        figure={Figure}
        caption="RGB cube representation of the sRGB color space"
      >
        {/* <p className="body4">A color space is the total collection of possible colors within a particular set of primaries. Color spaces are specific implimentations of a <Link to="/color-model">color model</Link>.</p> */}
        <p className="body4">A color space is a collection all colors within a particular specific set of primaries (<Link to="gamut">gamut</Link>). </p>
        <p className="body4">Color spaces are specific implimentations of a <Link to="/color-model">color model</Link>.</p>
        
        {/* <p className="body4">Some color spaces have cylindrical transformations, which qualify as unique color spaces despite being bound to the same total collection of possible colors.</p> */}

        {/* <Image 
          src={Figure}
          alt="Geometric forms for color spaces sRGB (cube), HSV (cylinder) and CIELAB (irregular shape)" 
          caption=""
        ></Image> */}
      </Section>

      {/* <Section title="Color spaces are models with context">
        <Section level="4" splitView 
            figure={IMG2}
            alt="alt text"
            caption="What you see here."
          >
          <p>The <Link to="/rgb">RGB color model</Link> is a way of describing color using three primaries: red, green, and blue. When unique values are used for these primaries (and they are implemented mathematically), the results are unique color spaces. </p>
          <p>In this example, specific red, green, and blue colors are used to define sRGB, DCI-P3, and ProPhoto RGB color spaces.</p>
          <p>The resulting area of available colors in a color space are referred to as the <strong>gamut</strong>.</p>
        </Section>
      </Section> */}

      <Section title="Cylindrical transformations" level="2">
        <Section level="4" splitView 
            figure={IMG}
            // alt="alt text"
            caption="An example of cylindrical transformations of sRGB into the HSV and HSL color spaces."
          >
            <p>Some color spaces have cylindrical transformations, which qualify as unique color spaces despite being bound to the same <Link to="gamut">total collection of possible colors</Link>.</p>
          <p>Cylindrical transformations provide a more perceptual-based approach to <strong>defining</strong> color. They are easier to reason about, and in some cases can return more appealing colors when <Link to="color-interpolation">interpolating</Link> between values.</p>
          {/* <p>Both sRGB and DCI-P3 are RGB color models as they describe color by the red, green, and blue channels. However, the actual colors available differ considerably within each color space.</p> */}
        </Section>
      </Section>

      {/* <Section title="Which color space to use?" level="2">
        <p>Choosing the right color space can be a daunting task. There are many options, and conflicting recommendations coming from publications in the design space.</p>
        <p>Here are some differences that can help you evaluate which color space best serves your needs:</p>

        <Table
          header={['Color space', 'Origination', <Link to="chromatic-adaptation">Chromatic adaptation</Link>, 'Lightness', 'White point',  'Known issues & limitations', 'Benefits & strengths']}
        >
            <TableRow> 
              <Link to="srgb">sRGB</Link>
              <>n/a</>
              <>n/a</>
              <>n/a</>
              <>n/a</>
            </TableRow>
            <TableRow> 
              <Link to="display-p3">Display P3</Link>
              <>n/a</>
              <>n/a</>
              <>n/a</>
            </TableRow>
            <TableRow> 
              <Link to="lms">LMS</Link>
              <>TODO</>
              <>TODO</>
              <>TODO</>
            </TableRow>
            <TableRow> 
              <Link to="xyz">XYZ</Link>
              <>TODO</>
              <>TODO</>
              <>TODO</>
            </TableRow>
            <TableRow> 
              <Link to="cielab">CIELAB</Link>
              <Link to="xyz">XYZ</Link>
              <>"Wrong Von Kries"</>
              <>L*</>
              <>TODO</>
              <ul>
                <li>Descrepencies between observed &amp; predicted hue and chroma (Hung and Berns 1995).</li>
                <li>Perceptual unique hues (red, green, yellow, blue) do not align directly with a* b* axes, as would be expected of an accurate <Link to="color-opponent-process">opponent color</Link> space.</li>
                <li>Incorrect chromatic adaptation predicts incorrect colors under various lighting conditions.</li>
                <li>No luminance-level dependency; cannot predict <Link to="hunt-effect">Hunt effect</Link> or <Link to="stevens-power-law">Stevens' law</Link></li>
              </ul>
              <>I'm sure there's some good..</>
            </TableRow>
            <TableRow> 
              <Link to="cieluv">CIELUV</Link>
              <Link to="xyz">XYZ</Link>
              <>Subtractive adaptation transformation</>
              <>L*</>
              <>TODO</>
              <ul>
                <li>Subtractive adaptation is farther from physiological reality that "Wrong Van Kries" resulting in colors being shifted entirely out of <Link to="gamut">gamut</Link> of percievable colors.</li>
                <li>Poor predictor of color difference.</li>
              </ul>
            </TableRow>
            <TableRow> 
              <Link to="cam02">CAM02</Link>
              <Link to="xyz">XYZ</Link>
              <>Von Kries</>
              <>Other stuffs</>
            </TableRow>
            <TableRow> 
              <Link to="cam16">CAM16</Link>
              <Link to="xyz">XYZ</Link>
              <>Von Kries</>
              <>Other stuffs</>
            </TableRow>
            <TableRow> 
              <Link to="ipt">IPT</Link>
              <Link to="lms">LMS</Link>
              <>None</>
              <>I</>
              <>D65</>
              <ul>
                <li>No method for <Link to="chromatic-adaptation">chromatic adaptation</Link></li>
                <li>No luminance-level dependency; cannot predict <Link to="hunt-effect">Hunt effect</Link> or <Link to="stevens-power-law">Stevens' law</Link></li>
              </ul>
              <ul>
                <li>Constant hue predictions</li>
                <li>Intended for digital imaging</li>
                <li>Used for critical gamut-mapping such as HDR and digital photography</li>
              </ul>
            </TableRow>
            <TableRow> 
              <Link to="oklab">OKLAB</Link>
              <><Link to="cam16">CAM16</Link> &amp; <Link to="ipt">IPT</Link></>
              <>TODO</>
              <>TODO</>
            </TableRow>

        </Table>
        
      </Section> */}


      <Section title="How it affects UI design" level="4" className="elements">
        <p>Devices can support different color spaces. For web, the default <code>rgb</code> annotation defines colors in sRGB color space. For wider gamut colors in web applications, use <code>display-P3</code> annotation to define colors.</p>
        <p>Color relationships and properties can vary considerably between different color spaces. When creating <Link to="color-scale">color scales</Link>, try <Link to="tint">lightening</Link> and <Link to="shade">darkening</Link> colors (or <Link to="color-interpolation">interpolating</Link> between colors) in a variety of color spaces to find the best appearance.</p>
      </Section>

      <CitationList>
        <Citation src="CAMS" pages="201-210" />  
        <Citation src="Wikipedia" url="https://en.wikipedia.org/wiki/Color_space" title="Color space" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default ColorSpace;