import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';
import LightAdapted from '../img/cave_lightAdapted.jpg';
import DarkAdapted from '../img/cave_darkAdapted.jpg';
import ImageSlider from "../components/ImageSlider";
import './LightDarkAdaptation.scss';

// import Figure from '../img/Figure_LightDarkAdaptation.png';
import PageHeader from '../components/PageHeader';

import CitationList from "../components/CitationList";
import Citation from "../components/Citation";

function LightDarkAdaptation(props) {
  const CaveSim = <ImageSlider 
    id="LightDarkAdaptation" 
    images={ [DarkAdapted, LightAdapted] } 
    labelLeft="Dark adapted" 
    labelRight="Light adapted" 
    contained
    pan
    // width='230px'
    />

  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      key="light-dark-adaptation"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Light and dark adaptation"
        description="A process of the visual system to increase or decrease light sensitivity in response to adapting light."
      />

      <PageHeader
        category="Color vision"
        title="Light &amp; dark adaptation"
        />

      <Section
        splitView
        playground={CaveSim}
        >
        <p className="body4">A process of the visual system to increase or decrease light sensitivity in response to adapting light.</p>
        <p className="body4">This adaptation occurs by means of the different sensitivities of cones versus rods, a change in pupil size, cone photopigment concentration, and neural responsiveness.</p>
        {/* <p className="body4">Vision in well-lit environments (where cones are responsive) is called <strong>photopic</strong>. Dim-lit environments (where both cones and rods are responsive) is called <strong>mesopic</strong> vision. And low-lit environments (where only rods are responsive) is called <strong>scotopic</strong> vision.</p> */}
        
        {/* <Image 
          src={Figure}
          alt="alt text"
          caption="Center area of checkers (A) appear to have similar Colors due to comparison with outer checkered area, as evidenced when outer checkers are removed (B)."
        /> */}
      </Section>

      {/* <Section title="Example" level="2">
        <Section level="4" splitView
          playground={CaveSim}
        >
          <p>Use the slider to simulate light and dark adaptaion. As you focus on the lighter environment, your eyes slowly adapt to the light (light adaptation). As you focus on the darker environment, your eyes slowly adapt to the lower level of light (dark adaptation).</p>
        </Section>

      </Section> */}

      <Section title="How it affects UI design" level="4" className="elements">
        <p><Link to="light-sensitivity">Light sensitivity</Link> and <Link to="contrast-sensitivity">contrast sensitivity</Link> are affected from this process. Many devices auto-adjust for ambient light, however, consider personalization settings to give users the ability to adjust lightness and contrast to meet their needs.</p>
        <p>Light and dark adaptation naturally follows <Link to="stevens-power-law">Stevens' power law</Link>, affecting <Link to="contrast-discrimination">contrast discrimination</Link> between colors at varying lightnesses.</p>
        <p>Color appearance will change during this process, due to the <Link to="hunt-effect">Hunt effect</Link>, the <Link to="bezold-brucke-shift">Bezold–Brücke shift</Link>, and the <Link to="purkinje-shift">Purkinje effect</Link>. Take these into consideration when creating <Link to="color-scale">color scales</Link> or dark <Link to="color-theme">color themes</Link> if you want colors to emulate properties of the physical world. </p>
        <p>Lights brighter than the adapted <Link to="dynamic-range">dynamic range</Link> create <Link to="optical-glare">optical glare</Link>, which can affect <Link to="contrast-sensitivity">contrast sensitiviy</Link> and <Link to="contrast-discrimination">discrimination</Link> and causes legibility issues with text.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/contrast-sensitivity'> Contrast sensitivity </Link></li>
          <li><Link to='/contrast-discrimination'> Contrast discrimination </Link></li>
          <li><Link to='/bezold-brucke-shift'> Bezold–Brücke shift </Link></li>
          <li><Link to='/purkinje-shift'> Purkinje shift </Link></li>
          <li><Link to='/hunt-effect'> Hunt effect </Link></li>
          <li><Link to='/dynamic-range'> Dynamic range </Link></li>
          <li><Link to='/optical-glare'> Optical glare </Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="Psychophysical" pages="76-77, 103"/>
        <Citation src="Visual Perception" pages="26"/>
        <Citation src="CAMS" pages="24, 128"/>
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default LightDarkAdaptation;