import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import Figure from '../img/Figure_ColorModel.png';
import PageHeader from '../components/PageHeader';
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";

function ColorModel(props) {
  
  return (
    <motion.div
      style={pageStyle}
      key="color-model"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Color models"
        description="A color model is method of describing color. When this method is implemented with precise math, it becomes a color space."
      />

      <PageHeader
        category="Color models"
        title="Color model"
        // subtitle=""
        />

      <Section
        splitView
        figure={Figure}
        caption="Concepts like hue, colorfulness, and lightness provide a model of describing color."
      >
        <p className="body4">A color model is method of describing color. When this method is implemented with precise math, it becomes a <Link to="/color-space">color space</Link>.</p>
        <p className="body4">Some models are descriptive of <strong>color properties</strong>, whereas others are relative to <strong>color mixing</strong> relative to primary colors.</p>

        <p className="body4">A few examples of color models are:
          <ul>
            <li><Link to='/rgb-model'>RGB (Red, Green, Blue)</Link></li>
            <li><Link to='/ryb-model'>RYB (Red, Yellow, Blue)</Link></li>
            <li><Link to='/cmyk-model'>CMYK (Cyan, Magenta, Yellow, Black)</Link></li>
            <li><Link to='/color-appearance-model'>Color appearance models</Link></li>
          </ul>
        </p>
        
      </Section>

      {/* <Section level="4" splitView>
        <p>Some models are descriptive of color properties, whereas others are relative to color mixing relative to primary colors.</p>
        <p>A few examples of color models are:
          <ul>
            <li><Link to='/rgb-model'>RGB (Red, Green, Blue)</Link></li>
            <li><Link to='/ryb-model'>RYB (Red, Yellow, Blue)</Link></li>
            <li><Link to='/cmyk-model'>CMYK (Cyan, Magenta, Yellow, Black)</Link></li>
            <li><Link to='/color-appearance-models'>Color appearance models</Link></li>
          </ul>
        </p>
      </Section> */}

      <CitationList>
        <Citation url="https://acsweb.ucsd.edu/~ncn003/CSE3/Lab2/ColorModels.htm" publication="UC San Diego" title="Color models" />
        <Citation src="Wikipedia" url="https://en.wikipedia.org/wiki/Color_model" title="Color model"/>
      </CitationList>


      <Footer />

    </motion.div>
  )
}
 
export default ColorModel;