import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import Figure from '../img/Figure_SpectralColors.png';
// import Example from '../img/Figure_SpectralColors-Example.png';
// import Example2 from '../img/Figure_ColorModel-Example.png';
import PageHeader from '../components/PageHeader';
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";


function SpectralColors(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      key="Spectral-colors"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Spectral colors"
        description="Colors that can be generated by a single wavelength of light."
      />

      <PageHeader
        category="Color vision"
        title="Spectral colors"
        // subtitle=""
        />

      <Section
        figure={Figure}
        alt="Colors that can be generated by a single wavelength of light" 
      >
        <p className="body4">Colors that can be generated by a single wavelength of light.</p>

      </Section>


      {/* <Section title="How it affects UI design" level="4" className="elements">
        <p>SpectralColorss are utilized in creating color scales. They provide higher or lower contrast colors for various UI applications like text, borders, or backgrounds.</p>
      </Section> */}

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/non-spectral-colors'> Non-spectral colors </Link></li>
          <li><Link to='/trichromacy'> Trichromacy </Link></li>
          <li><Link to='/color-opponent-process'> Color opponent process </Link></li>
          <li><Link to='/hue'> Hue </Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="Psychophysical" pages="286" />
        <Citation src="Vision and art" pages="30-31" />
        <Citation src="Understanding Color" pages="16-17" />
        <Citation src="Wikipedia" url="https://en.wikipedia.org/wiki/Spectral_color" title="Spectral color" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default SpectralColors;