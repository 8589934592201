import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import Figure from '../img/Figure_Opacity.png';
// import Example from '../img/Figure_Opacity-Example.png';
// import Example2 from '../img/Figure_ColorModel-Example.png';
import PageHeader from '../components/PageHeader';
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";


function Opacity(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      key="opacity"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Opacity"
        description="(Alpha, transparency, translucency) Opacity is a property that describes how much you can see through a color."
      />

      <PageHeader
        category="Color properties"
        title="Opacity"
        subtitle="(Alpha, transparency, translucency)"
        />

      <Section
        figure={Figure}
        alt="Orange circle with arrow pointing to a lighter orange circle, demonstrating its Opacity" 
      >
        <p className="body4">Opacity is a property that describes how much you can see through a color.</p>
        <p className="body4"> A color that cannot be seen through is fully <strong>opaque</strong>. A color that be seen through but still perceived is <strong>translucent</strong>. A color that is fully seen through and not perceived is <strong>transparent</strong>. </p>
        <p className="body4">In many implemented <Link to="color-space">color spaces</Link>, opacity is controlled with an alpha channel.</p>

      </Section>


      <Section title="How it affects UI design" level="4" className="elements">
        <p>Opacity can be used to create subtle, or low-contrast colors. Translucent colors adapt well when placed on different surfaces, and retain a consistent WCAG <Link to="relative-luminance">relative luminance contrast</Link>.</p>
        <p>Opacities do not have the same <stong>perceived contrast</stong> between light and dark backgrounds. When supporting <Link to="dark-mode">dark mode</Link> (or dark <Link to="color-theme">color themes</Link>), higher percentages of opacity will be needed.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/hue'> Hue </Link></li>
          <li><Link to='/chroma'> Chroma </Link></li>
          <li><Link to='/tint'> Tint </Link></li>
          <li><Link to='/shade'> Shade </Link></li>
          <li><Link to='/tone'> Tone </Link></li>
          <li><Link to='/color-model'> Color model </Link></li>
          <li><Link to='/color-space'> Color space </Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="Understanding Color" pages="36" />
        <Citation src="Wikipedia" url="https://en.wikipedia.org/wiki/Transparency_and_translucency" title="Transparency and translucency" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default Opacity;