import React from "react"
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import Figure from '../img/Figure_Chromostereopsis.png';
import PageHeader from '../components/PageHeader';

import { Link } from "react-router-dom";
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";

function Chromostereopsis(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      key="chromostereopsis"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Chromostereopsis"
        description="An illusion created of depth or vibration created when certain colors are placed next to one another. Chromosteropsis is generally attributed to chromatic aberration."
      />

      <PageHeader
        category="Color perception"
        title="Chromostereopsis"
        />

        <Section level="4" splitView 
          figure={Figure}
          alt="alt text"
          caption="Certain colors appear closer than others or appear to vibrate when placed next to one another."
        >
        <p className="body4">An illusion created of depth or "vibration" created when certain colors are placed next to one another. Chromosteropsis is generally attributed to <Link to="chromatic-abberation">chromatic abberation</Link>.</p>
        </Section>

      <Section title="How it affects UI design" level="4" className="elements">
        <p>Do not juxtapose high <Link to="chroma">chroma</Link> colors (especially <Link to="spectral-colors">spectral colors</Link>). Exaggerated <Link to="chromatic-abberation">focal depths</Link> from this illusion can be difficult for people to focus on and could cause eye strain.</p>
        <p>Effects of chromostereopsis may be exaggerated by corrective lenses for <Link to="myopia">myopia</Link> or <Link to="hyperopia">hyperopia</Link>.</p>
        <p>Text should never be displayed using a combination of colors that create this illusion, as it will effect legibility.</p>
        <p>This illusion can effect the experience of <Link to="dark-mode">dark mode</Link> or dark <Link to="color-theme">color themes</Link>.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/chromatic-abberation'> Chromatic abberation </Link></li>
          <li><Link to='/color-opponent-process'> Color opponent process </Link></li>
          <li><Link to='/visual-acuity'>Visual acuity</Link></li>
          <li><Link to='/myopia'>Myopia</Link></li>
          <li><Link to='/hyperopia'>Hyperopia</Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="Vision excellence" url="https://www.visionexcellence.com.au/chromostereopsis/" title="Chromostereopsis" />
        <Citation src="Wikipedia" url="https://en.wikipedia.org/wiki/Chromostereopsis" title="Chromostereopsis"/>
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default Chromostereopsis;