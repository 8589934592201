import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import Figure from '../img/Figure_ColorConstancy.png';
import PageHeader from '../components/PageHeader';

import ImageSlider from '../components/ImageSlider';
import ImageA from '../img/a.png';
import ImageB from '../img/b.png';
import ImageC from '../img/c.png';
import FigureChecker1 from '../img/Figure_CheckerShadowIllusion1.png';
import FigureChecker2 from '../img/Figure_CheckerShadowIllusion2.png';
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";

function ColorConstancy(props) {
  const BlueDress = <ImageSlider id="BlueDress" images={ [ImageA, ImageB, ImageC] } labelLeft="Blue &amp; black" labelRight="White &amp; gold" height="375px"/>
  const CheckerShadow = <ImageSlider id="CheckerShadow" images={ [FigureChecker1, FigureChecker2] } labelLeft="Illusion" labelRight="Reveal" width='366px'/>

  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      exit="out"
      key="color-constancy"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Color constancy"
        description="The ability to identify consistent color of objects in varying lighting conditions."
      />

      <PageHeader
        category="Color perception"
        title="Color constancy"
        />

      <Section
        figure={Figure}
        caption="Color constancy is identifying that the cast shadow is a pink surface, despite the actual color being purple."
      >
        <p className="body4">The ability to identify consistent color of objects in varying lighting conditions.</p>
      </Section>

      <Section title="Examples" level="2">
        <Section level="4" splitView 
          // figure={FigureChecker}
          playground={CheckerShadow}
          // alt="alt text"
          title="Checker shadow illusion"
          caption="The gray squares (A) and (B) are the same, but appear to have different lightness."
        >
        <p>This illusion demonstrates an error in identification of a color's lightness due to surrounding colors. Squares A and B appear to be different, however they are the same color.</p>
        </Section>

        <Section level="4" splitView 
            playground={BlueDress}
            title='"The dress"'
            // alt="alt text"
            // caption="Center area of checkers (A) appear to have similar Colors due to comparison with outer checkered area, as evidenced when outer checkers are removed (B)."
          >
          <p>This illusion is result of improper color identification due to <Link to="chromatic-adaptation">chromatic adaptation</Link>, or color constancy, and is known as "chromatic bias". Based on viewing conditions, or surrounding light, the dress may appear to be blue and black or white and gold.</p>
        </Section>

      </Section>

      <Section title="How it affects UI design" level="4" className="elements">
        <p>Colors of different <Link to="hue">hue</Link> can be placed adjacently and appear to be objectively the same. Two-tone coloration of <a href="https://codepen.io/NateBaldwinDesign/full/PoRpVaX" target="_blank" rel="noopener noreferrer">neo-brutalist buttons</a> is a good example of this.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/chromatic-adaptation'> Chromatic adaptation </Link></li>
          <li><Link to='/color-opponent-process'> Color opponent process </Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="CAMS" pages="26-27"/>
        <p>Pages 140 of this book literally say color constancy does not exist.</p>
      </CitationList>


      <Footer />

    </motion.div>
  )
}
 
export default ColorConstancy;