import React from "react"
import './ImageSlider.scss';
import Image from "./Image";

class ImageSlider extends React.Component {
  constructor(props) {
    super(props)
    this.id = props.id;
    this.sliderId = `${this.id}-ImageSlider-control`;

    this.width = (this.props.contained) ? 'auto' : ((this.props.width) ? this.props.width : '100%');
    this.height = (this.props.height) ? this.props.height : '350px';

    this.containerClassname = (this.props.contained) ? "ImageSlider-container contained" : "ImageSlider-container";

    this.posX = '0px';
    document.documentElement.style
      .setProperty(`--${this.id}-posX`, '0px')

      
    this._resetSlider = () => {
      const range = document.getElementById(this.sliderId);
      range.value = this.defaultValue;
      // range.dispatchEvent(new Event("input"));
      this._update();
    }
    
    this._ease = (x) => {
      return x;
    }

    // document.documentElement.style
    //   .setProperty(`--${this.id + '-' + 1}`, 1);
    // document.documentElement.style
    //   .setProperty(`--${this.id + '-' + 2}`, 0);

    this.opacityArray = this.props.images.map((img, i) => {
      if(i <=  this.props.defaultValue/100 * this.props.images.length) return 1
      else return 0;
    })

    this.opacities = (this.props.defaultValue) ?  this.opacityArray : ((this.props.images.length === 3) ? [1, 1, 0] : [1, 0]);
    if(this.props.radio) {
      this.opacities = [1, 0, 0]
    }
    this.defaultValue = (this.props.defaultValue) ? this.props.defaultValue : ((this.props.images.length === 3) ? 50 : 0);

    this.labels = [this.props.labelLeft, this.props.labelCenter, this.props.labelRight]; // Use for radios only

    this.images = (!this.props.radio) ? props.images.map((image, i) => {
      document.documentElement.style
        .setProperty(`--${this.id + '-' + i}`, this.opacities[i]);
      return <Image className="imageSlider-image" id={this.id + '-' + i} key={this.id + '-' + i} src={image} style={{left: `var(--${this.id}-posX)`, height: this.height, opacity: `var(--${this.id + '-' + i})`}}/>
    }) : props.images.map((image, i) => {
      return <Image className={`imageSlider-image imageSlider-image--${this.id}Radio`} id={this.id + '-' + this.labels[i]} key={this.id + '-' + i} src={image} style={{left: 0, height: this.height, opacity: (i === 0) ? 1 : 0 }}/>
    })

    this._update = (e) => {
      let val;
      if(e && this.props.toggle) {
        val = (e.target.checked === true) ? 100 : 0;
      }

      else if (e && this.props.radio) {
        let targetImageId = this.id + '-' + e.target.value;

        let allImages = document.getElementsByClassName(`imageSlider-image--${this.id}Radio`)
        for(let i = 0; i < allImages.length; i++) {
          allImages[i].style.opacity = 0;
        }
        document.getElementById(targetImageId).style.opacity = 1;
      }

      else {
        val = (!e) ? this.defaultValue : +e.target.value;
      }

      if(this.images.length === 3) {
        if (val < 50) {
          document.documentElement.style
            .setProperty(`--${this.id + '-' + 1}`, (val / 50));
          document.documentElement.style
            .setProperty(`--${this.id + '-' + 2}`, 0);
        }
        if (val >= 50) {
          document.documentElement.style
            .setProperty(`--${this.id + '-' + 1}`, 1);
          document.documentElement.style
            .setProperty(`--${this.id + '-' + 2}`, ((val - 50) / 50));
        }
      }
      else if(this.images.length === 2) {
        document.documentElement.style
          .setProperty(`--${this.id + '-' + 1}`, (val/100));
      }
      else {
        console.warn('Unsupported amount of images')
      }
      if(this.props.pan) {
        const val = (!e) ? this.defaultValue : +e.target.value;
        document.documentElement.style
        .setProperty(`--${this.id}-posX`, `-${val/1.5}px`)
      }
      
    }
  }

  render() {
    const label = (this.props.toggle || this.props.radio) 
                  ? ''
                  : <><label id={this.sliderId+'-label1'}>{this.props.labelLeft}</label>
                    <label id={this.sliderId+'-label2'}>{this.props.labelCenter}</label>
                    <label id={this.sliderId+'-label3'}>{this.props.labelRight}</label></> ;
    const control = (this.props.toggle) 
                    ? <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><input type="checkbox" id={this.sliderId} labelledby={this.sliderId+'-label'} onInput={(e) => {this._update(e)}}></input><label id={this.sliderId+'-label'} htmlFor={this.sliderId}>{this.props.label}</label></div>
                    : ( (this.props.radio)
                      ? 
                        <fieldset style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-evenly'}}>
                          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '0'}}>
                            <input defaultChecked type="radio" name={this.sliderId} id={this.props.labelLeft} labelledby={this.sliderId+'-label1'} value={this.props.labelLeft} onInput={(e) => {
                              this._update(e)
                            }}></input>
                            <label id={this.sliderId+'-label1'} htmlFor={this.props.labelLeft}>{this.props.labelLeft}</label>
                          </div>
                          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '0'}}>
                            <input type="radio" name={this.sliderId} id={this.props.labelCenter} labelledby={this.sliderId+'-label2'} value={this.props.labelCenter} onInput={(e) => {
                              this._update(e)
                            }}></input>
                            <label id={this.sliderId+'-label2'} htmlFor={this.props.labelCenter}>{this.props.labelCenter}</label>
                          </div>
                          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '0'}}>
                            <input type="radio" name={this.sliderId} id={this.props.labelRight} labelledby={this.sliderId+'-label3'} value={this.props.labelRight} onInput={(e) => {
                              this._update(e)
                            }}></input>
                            <label id={this.sliderId+'-label3'} htmlFor={this.props.labelRight}>{this.props.labelRight}</label>
                          </div>
                        </fieldset>
                      : <div className="ImageSlider-controls"><input 
                          id={this.sliderId} 
                          className="ImageSlider-control"
                          type="range" 
                          min="0" 
                          max="100" 
                          defaultValue={ this.defaultValue }
                          step="1"
                          // style={{width: this.width}}
                          onInput={(e) => {this._update(e)}} 
                        /> 
                        <button type="button" className="button button--reset" onClick={() => this._resetSlider()}>Reset</button></div>);
    return (
      <div className="ImageSlider">
        <div className={this.containerClassname} style={{height: this.height}}>
          {this.images}
        </div>
        <div className="ImageSlider-labels" 
        // style={{width: this.width}}
        >
          {label}
        </div>
        {control}
      </div>
    )
  }
}
 
export default ImageSlider;