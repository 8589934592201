import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";
import Citation from "../components/Citation";
import CitationList from "../components/CitationList";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import FigureScotopicVision from '../img/Figure_Scotopic.png'
import FigureAchromatopsia from '../img/Figure_Achromatopsia.png'
import FigureCerebralAchromatopsia from '../img/Figure_CerebralAchromatopsia.png'
import PageHeader from '../components/PageHeader';
import ToolCitation from "../components/ToolCitation";

function Monochromacy(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      key="monochromacy"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Monochromacy"
        description="(Monochromatic vision, Achromatopsia) A type of color vision deficiency that can be the result missing cone pigments."
      />

      <PageHeader
        category="Color vision"
        title="Monochromacy"
        subtitle='(Monochromatic vision, Achromatopsia)'
        />

      <Section>
        <p className="body4"> A type of vision where sensory input is provided by only one type of <Link to="retina">photoreceptor</Link> or pigment. Typically used to refer to specific types of <Link to="color-vision-deficiency">color vision deficiencies</Link>.</p>
      </Section>

      <Section
        title="Scotopic vision"
        level="4"
        splitView
        caption="Example image simulating scotopic (rod-only) monochromacy."
        figure={FigureScotopicVision}
      >
        <p>An aspect of normal vision under low-light conditions, where the rods are the only operating photoreceptors.</p>
      </Section>

      <Section
        title="Achromatopsia (rod monochromacy)"
        level="4"
        splitView
        caption="Example image simulating achromatopsia with full loss of color discrimination, lowered visual acuity, and light sensitivity."
        figure={FigureAchromatopsia}
      >
        <p>An inherited <Link to="/color-vision-deficiency">color vision deficiency</Link> that causes extreme <Link to="light-sensitivity">light sensitivity</Link> (day blindness), and a loss of visual acuity and color discrimination. </p>        
        <p>Achromatopsia is very rare and affects 0.00003% - 0.00002% of people.</p>
      </Section>

      <Section
        title="Blue cone monochromacy"
        level="4"
        splitView
        caption="Example image simulating blue cone monochromacy with full loss of color discrimination, lowered visual acuity, and light sensitivity."
        figure={FigureAchromatopsia}
      >
        <p>An inherited <Link to="/color-vision-deficiency">color vision deficiency</Link> where the red (LWS) and green (MWS) cones have loss of functionality. It causes extreme <Link to="light-sensitivity">light sensitivity</Link> (day blindness), and a loss of visual acuity and color discrimination. </p>        
        <p>It is difficult to distinguish blue cone monochromacy from achromatopsia. Blue cone monochromacy is very rare and affects 0.00001% of people.</p>
      </Section>

      <Section
        title="Cerebral achromatopsia"
        level="4"
        splitView
        caption="Example image simulating cerebral achromatopsia, where all cones &amp; rods transmit signals but the brain cannot discern color."
        figure={FigureCerebralAchromatopsia}
      >
        <p>A type of <Link to="/color-vision-deficiency">color vision deficiency</Link> that can be the result of missing cone pigments. Cerebral achromatopsia is a type of monochromacy resulting from stroke or damage to areas of the brain. </p>        
      </Section>


      <Section title="How it affects UI design" level="4" className="elements">
        <p>Designing with color vision deficiencies in mind is important. Most issues can be resolved by compliance with <a href="https://www.w3.org/TR/WCAG21/#use-of-color" target="_blank" rel="noopener noreferrer">WCAG 1.4.1 (use of color)</a> and <a href="https://www.w3.org/TR/WCAG21/#contrast-minimum" target="_blank" rel="noopener noreferrer">1.4.3 (contrast minimum)</a>.</p>
        <p>Most tools improperly simulate types of monochromacy, as they fail to account for increased <Link to="light-sensitivity">light sensitivity</Link> (which effects <Link to="contrast-sensitivity">contrast sensitivity</Link> and <Link to="contrast-discrimination">discrimination</Link>).  Consider personalization settings to give users the ability to adjust contrast to meet their needs.</p>
        <p>Supporting <Link to="high-contrast-mode">Windows high contrast mode</Link> will help users with monochromacy and <Link to="light-sensitivity">light sensitivity</Link>.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to="retina">Retina</Link></li>
          <li><Link to='/color-vision-deficiency'> Color vision deficiency </Link></li>
          <li><Link to='/trichromacy'> Trichromacy </Link></li>
          <li><Link to='/anomalous-trichromacy'> Anomalous trichromacy </Link></li>
          <li><Link to='/dichromacy'> Dichromacy </Link></li>
          <li><Link to='/light-sensitivity'> Light sensitivity </Link></li>
        </ul>
      </Section>

      <CitationList tools>
        <ToolCitation name="Coblis" url="https://www.color-blindness.com/coblis-color-blindness-simulator/" />
        <ToolCitation name="CVD Simulation Web App" url="https://www.myndex.com/CVD/" />
        <ToolCitation name="Colorblindly" url="https://chrome.google.com/webstore/detail/colorblindly/floniaahmccleoclneebhhmnjgdfijgg" />
        <ToolCitation tool="Leonardo" />
      </CitationList>

      <CitationList>
        {/* <Citation src="CVD" pages="202-201" /> */}
        <Citation src="Measuring color" pages="443" />
        <Citation src="Color-blindness.com" url="https://www.color-blindness.com/types-of-color-blindness/" title="Types of color blindness"/>
        <Citation src="Color-blindness.com" url="https://www.color-blindness.com/2007/07/20/monochromacy-complete-color-blindness/" title="Monochromacy: complete color blindness"/>
        <Citation src="Fighting Blindness" url="https://www.fightingblindness.org/diseases/achromatopsia" title="Achromatopsia" />
        <Citation src="Myndex" title="Color vision deficiency simulator" url="https://www.myndex.com/CVD/"/>
        <Citation src="BCM Families Foundation" url="https://www.blueconemonochromacy.org/blueconemonochromacy/" title="BCM" />
        <Citation src="NIH Rare Diseases" url="https://rarediseases.info.nih.gov/diseases/917/blue-cone-monochromatism" title="Blue cone monochromatism" />
        <Citation src="Wikipedia" title="Cerebral achromatopsia" url="https://en.wikipedia.org/wiki/Cerebral_achromatopsia"/>
        <Citation src="Wikipedia" url="https://en.wikipedia.org/wiki/Scotopic_vision" title="Scotopic vision"/>
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default Monochromacy;