import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import Figure from '../img/Figure_ColorScale.png';
import FigureUI from '../img/Figure_UIColorScale.png';
import FigureSequential from '../img/Figure_SequentialScale.png';
import FigureDiverging from '../img/Figure_DivergingScale.png';
import FigureCategorical from '../img/Figure_QualitativeScale.png';

import PageHeader from '../components/PageHeader';
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";


function DivergingColorScale(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      exit="out"
      key="color-scale"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Diverging scale"
        description="A data visualization scale similar to sequential, except with a neutral midpoint to demonstrate polarity."
      />

      <PageHeader
        category="Colors in use"
        title="Diverging scale"
        // subtitle=""
        />


      <Section 
        splitView
        figure={FigureDiverging}
        alt="Gradient from dark cyan to off-white to deep red displayed above a U.S. map. Each state is filled with a unique color from the gradient, sequential scale."
      >
        <p className="body4">A data visualization scale similar to sequential, except with a neutral midpoint to demonstrate polarity.</p>
        <p className="body4">Diverging color scales are <strong>quantitative</strong>.</p>
      </Section>


      <Section title="How it affects UI design" level="4" className="elements">
        <p>When creating color scales, <Link to="color-interpolation">interpolating</Link> in various <Link to="/color-space">color spaces</Link> will result in different colors or appearance. Use a <Link to="/color-appearance-model">color appearance model</Link> if trying to make your color scale perceptually balanced.</p>
        <p>Do not rely on color choice alone to make data visualizations accessible. Additional considerations beyond color and contrast may be necessary. </p>
        <p>Diverging scales may be more prone to color conflicts for people who have a <Link to="color-vision-deficiency">color vision deficiency</Link>. </p>
        {/* <p>Perceptually balanced UI color scales can be created with a variety of tools such as <a href="https://leonardocolor.io/theme.html" target="_blank" rel="noopener noreferrer">Leonardo</a> or the <a href="https://www.figma.com/community/plugin/970405419734718732/Accessible-color-palettes" target="_blank" rel="noopener noreferrer">Accessible color palettes</a> plugin.</p> */}
        {/* <p>Color scales for data visualization can be created with a variety of tools such as <a href="https://leonardocolor.io/scales.html" target="_blank" rel="noopener noreferrer">Leonardo</a>.</p> */}
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to="abney-effect">Abney effect</Link></li>
          <li><Link to="bezold-brucke-shift">Bezold–Brücke shift</Link></li>
          <li><Link to='/contrast-sensitivity'> Contrast sensitivity </Link></li>
          <li><Link to='/color-vision-deficiency'> Color vision deficiency </Link></li>
          <li><Link to="lateral-inhibition">Lateral inhibition</Link></li>
          <li><Link to="contrast-discrimination">Contrast discrimination</Link></li>
          <li><Link to='monochromatic-colors'> Monochromatic colors </Link></li>
          <li><Link to='analogous-colors'> Analogous colors </Link></li>
          <li><Link to='complimentary-colors'> Complimentary colors </Link></li>
          <li><Link to='/hue'> Hue </Link></li>
          <li><Link to='/tint'> Tint </Link></li>
          <li><Link to='/shade'> Shade </Link></li>
          <li><Link to='/color-space'> Color space </Link></li>
          <li><Link to='/color-appearance-model'> Color appearance model </Link></li>
          <li><Link to='/relative-luminance'> Relative luminance </Link></li>
          <li><Link to='/apca'> APCA </Link></li>
          <li><Link to='/color-difference'> Color difference </Link></li>
        </ul>
      </Section>

      <CitationList>
        {/* <Citation src="Color and Meaning" pages="17, 19" /> */}
        <Citation url="https://blog.datawrapper.de/which-color-scale-to-use-in-data-vis/" publisher="Datawrapper" title="Which color scale to use when visualizing data" author="Lisa Charlotte Muth" date="March 16, 2021" />
        <Citation url="https://spectrum.adobe.com/page/color-for-data-visualization/" publisher="Adobe Spectrum" title="Color for Data Visualization"/>
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default DivergingColorScale;