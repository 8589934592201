import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import Figure from '../img/Figure_IncreaseContrastMode.png';
// import Example from '../img/Figure_IncreaseContrastMode-Example.png';
// import Example2 from '../img/Figure_ColorModel-Example.png';
import PageHeader from '../components/PageHeader';
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";


function IncreaseContrastMode(props) {
  
  return (
    <motion.div
      style={pageStyle}
      key="increase-contrast-mode"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Increase contrast mode"
        description="(iOS) Increase contrast mode is an device-specific color mode. It effects the colors of interfaces at an operating system level and displays colors with higher contrast for most applications."
      />

      <PageHeader
        category="Color for design systems"
        title="Increase contrast mode"
        subtitle="(iOS)"
        />

        <Section
          figure={Figure}
          alt="Gradient of color from yellow to orange to dark purple" 
        >
          <p className="body4">Increase contrast mode is an device-specific color mode. It effects the colors of interfaces at an operating system level and displays colors with higher contrast for most applications.</p>

        </Section>

      <Section title="How it affects UI design" level="4" className="elements">
        <p>In web applications, increase contrast mode can be given unique styling using the <a href="https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-contrast" target="_blank" rel="noopener noreferrer"><code>prefers-contrast</code> media query</a>.</p>
        <p>People with low vision rely on this mode to provide better visibility of content.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/contrast-sensitivity'> Contrast sensitivity </Link></li>
          <li><Link to='/light-and-dark-adaptation'> Light and dark adaptation </Link></li>
          <li><Link to='/high-contrast-mode'> High contrast mode </Link></li>
        </ul>
      </Section>

      <CitationList>

        <Citation url="https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-contrast" title="prefers-contrast" publisher="Mozilla" />
      </CitationList>


      <Footer />

    </motion.div>
  )
}
 
export default IncreaseContrastMode;