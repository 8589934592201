import React from "react"
import PageMeta from "../components/PageMeta";

// 
import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';
import { Link } from "react-router-dom";

import Figure from '../img/Figure_OpticalGlare.png';
// import FigureDark from '../img/Figure_OpticalGlareDark.png';
// import ImageSlider from "../components/ImageSlider";
// import Image1 from '../img/VisualAcuity_2020.png';
// import Image2 from '../img/VisualAcuity_Myopia01.png';
// import Image3 from '../img/VisualAcuity_Myopia02.png';
import PageHeader from '../components/PageHeader';

import CitationList from "../components/CitationList";
import Citation from "../components/Citation";

function OpticalGlare(props) {
  // const mq = window.matchMedia('(prefers-color-scheme: dark)');
  // const IMG = (mq.matches) ? FigureDark : Figure;

  // const Slider = <ImageSlider 
  //   id="OpticalGlare" 
  //   images={ [Image1, Image2, Image3] } 
  //   labelLeft="Normal vision" 
  //   labelRight="OpticalGlare" 
  //   height="292px"
  //   defaultValue="0"
  //   />

  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      key="visual-acuity"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Optical glare"
        description='The scattering of light within the eye from bright light.'
      />

      <PageHeader
        title="Optical glare"
        // subtitle="(Nearsightedness)"
        />

      <Section 
        // splitView
        figure={Figure}
        caption="Bright light from the sun scatters in the eye, causing a glare and obstructing or limiting vision."
      >
        <p className="body4">The scattering of light within the eye from bright light.</p>
        <p className="body4">Optical glare can be caused by lights brighter than the <Link to="dynamic-range">dynamic range</Link> of luminosity vision during <Link to="light-and-dark-adaptation">light and dark adaptation</Link>.</p>
        <p className="body4">Screen glare is a form of optical glare caused by a device's brightness or reflections of light on the screen.</p>
      </Section>

      {/* <Section 
        title="See how it looks" 
        // splitLevel 
        level="2"
        >
          <Section
            playground={Slider}
            splitView
          >
          <p>Use the slider to see how varying degrees of myopia can affect a person's vision.</p>
          <p>Most conditions can be corrected with eyeglasses, contacts, or eye surgery.</p>

          </Section>
      </Section> */}


      <Section title="How it affects UI design" level="4" className="elements">
        <p>Optical glare can affect <Link to="contrast-sensitivity">contrast sensitivity</Link> and <Link to="contrast-discrimination">discrimination</Link> and causes legibility issues with text.</p>
        <p>Supporting <Link to="increase-contrast-mode">iOS increase contrast mode</Link> and <Link to="high-contrast-mode">Windows high contrast mode</Link> will help users who are experiencing optical glare.</p>
        <p>In order to combat optical glare, users may need higher or lower contrast, as well as a lighter or darker colored interface. Consider <Link to="adaptive-theme">personalization settings</Link> to give users the ability to adjust contrast and lightness to meet their needs.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/light-and-dark-adaptation'> Light &amp; dark adaptation </Link></li>
          <li><Link to='/dynamic-range'> Dynamic range </Link></li>
          <li><Link to='/contrast-sensitivity'> Contrast sensitivity </Link></li>
          <li><Link to='/astigmatism'> Astigmatism </Link></li>
          <li><Link to='/cataracts'> Cataracts </Link></li>
          <li><Link to='/increase-contrast-mode'> Increase contrast mode </Link></li>
          <li><Link to='/high-contrast-mode'> High contrast mode </Link></li>
          <li><Link to='/adaptive-color-theme'> Adaptive color theme </Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="WebMD" url="https://www.webmd.com/eye-health/halos-and-glare-causes-prevention-treatment" title="Halos and Glare: Why Can't I See Well at Night?" author="By Eric Metcalf, MPH"/>
        <Citation src="Wikipedia" url="https://en.wikipedia.org/wiki/Glare_(vision)" title="Glare (vision)" />
        <Citation src="Wikipedia" url="https://en.wikipedia.org/wiki/Lens_flare" title="Lens flare" />
        <Citation src="AAO.org" url="https://www.aao.org/image/glare-2" />
      </CitationList>
      
      <Footer />

    </motion.div>
  )
}
 
export default OpticalGlare;