import React from "react"
import PageMeta from "../components/PageMeta";

// 
import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';
import { Link } from "react-router-dom";

import Figure from '../img/Figure_BezoldBruckeShift.png';
import FigureDark from '../img/Figure_BezoldBruckeShift_Dark.png';
import PageHeader from '../components/PageHeader';

import CitationList from "../components/CitationList";
import Citation from "../components/Citation";

function BezoldBruckeShift(props) {
  const mq = window.matchMedia('(prefers-color-scheme: dark)');
  const IMG = (mq.matches) ? FigureDark : Figure;

  return (
    <motion.div
      style={pageStyle}
      key="bezold-brucke-shift"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Bezold–Brücke shift"
        description="The appearance of a color's temperature (hue) may change as the intensity of light changes."
      />

      <PageHeader
        category="Color perception"
        title="Bezold–Brücke shift"
        subtitle="(Luminance on hue effect)"
        />

      <Section level="4" splitView 
          figure={IMG}
          // alt="alt text"
          caption="Hues are relatively warmer for lighter values and coolor for darker values of an object of uniform color due to light intensity."
        >
        <p className="body4">The appearance of a color's <Link to="color-temperature">temperature</Link> or <Link to="hue">hue</Link> changes as the <Link to="luminance">intensity of light</Link> changes.</p>
        </Section>

      <Section title="How it affects UI design" level="4" className="elements">
        <p>When creating a <Link to="color-scale">color scale</Link>, or making <Link to="tints">tints</Link>, cool colors should shift towards <Link to="warm-and-cool-colors">warmer hues</Link> as they get <Link to="lightness">lighter</Link> if you want colors to emulate properties of the physical world.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/hue'> Hue </Link></li>
          <li><Link to='/purkinje-shift'> Purkinje shift </Link></li>
          <li><Link to='/warm-and-cool-colors'> Warm &amp; cool colors </Link></li>
          <li><Link to="/color-temperature">Color temperature</Link></li>
          <li><Link to="/color-scale">Color scale</Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="Psychophysical" pages="260-261, 283" />
        <Citation src="CAMS" pages="120-123" />
        <Citation src="Wikipedia" url="https://en.wikipedia.org/wiki/Bezold%E2%80%93Br%C3%BCcke_shift" title="Bezold–Brücke shift" />
      </CitationList>
      
      <Footer />

    </motion.div>
  )
}
 
export default BezoldBruckeShift;