import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import Figure from '../img/Figure_ColorScale.png';
import FigureUI from '../img/Figure_UIColorScale.png';
import FigureSequential from '../img/Figure_SequentialScale.png';
import FigureDiverging from '../img/Figure_DivergingScale.png';
import FigureCategorical from '../img/Figure_QualitativeScale.png';

import PageHeader from '../components/PageHeader';
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";


function ColorScale(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      exit="out"
      key="color-scale"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Color scale"
        description="A sequence of interrelated colors. Continuous color scales are visualized as a gradient."
      />

      <PageHeader
        category="Colors in use"
        title="Color scale"
        // subtitle=""
        />

      <Section
        figure={Figure}
        alt="Gradient of color from yellow to orange to dark purple" 
      >
        {/* <p className="body4">A color space is the total collection of possible colors within a particular set of primaries. Color spaces are specific implimentations of a <Link to="/color-model">color model</Link>.</p> */}
        <p className="body4">A sequence of interrelated colors. Continuous color scales are visualized as a gradient.</p>
        
      </Section>
      <Section>
        <p className="body4"> Types of color scales include:</p>
        <ul className="body4">
          <li><Link to='/ui-color-scale'> UI color scale </Link></li>
          <li><Link to='/sequential-scale'> Sequential scale </Link></li>
          <li><Link to='/diverging-scale'> Diverging scale </Link></li>
          <li><Link to='/categorical-scale'> Categoriacl scale </Link></li>
        </ul>
      </Section>


      {/* <Section title="How it affects UI design" level="4" className="elements">
        <p>UI color scales need to provide options that meet or exceed <a href="https://www.w3.org/TR/WCAG21/#contrast-minimum" target="_blank" rel="noopener noreferrer">WCAG 1.4.3 (contrast minimum)</a>.</p>
        <p>When creating color scales, <Link to="color-interpolation">interpolating</Link> in various <Link to="/color-space">color spaces</Link> will result in different colors or appearance. Use a <Link to="/color-appearance-model">color appearance model</Link> if trying to make your color scale perceptually balanced.</p>
        <p>Do not rely on color choice alone to make data visualizations accessible. Additional considerations beyond color and contrast may be necessary. </p>
        <p>Diverging and categorical scales may be more prone to color conflicts for people who have a <Link to="color-vision-deficiency">color vision deficiency</Link>. </p>
      </Section> */}

      {/* <Section title="Related topics" level="4">
        <ul>
          <li><Link to="abney-effect">Abney effect</Link></li>
          <li><Link to="bezold-brucke-shift">Bezold–Brücke shift</Link></li>
          <li><Link to='/contrast-sensitivity'> Contrast sensitivity </Link></li>
          <li><Link to='/color-vision-deficiency'> Color vision deficiency </Link></li>
          <li><Link to="lateral-inhibition">Lateral inhibition</Link></li>
          <li><Link to="contrast-discrimination">Contrast discrimination</Link></li>
          <li><Link to='monochromatic-colors'> Monochromatic colors </Link></li>
          <li><Link to='analogous-colors'> Analogous colors </Link></li>
          <li><Link to='complimentary-colors'> Complimentary colors </Link></li>
          <li><Link to='/hue'> Hue </Link></li>
          <li><Link to='/tint'> Tint </Link></li>
          <li><Link to='/shade'> Shade </Link></li>
          <li><Link to='/color-space'> Color space </Link></li>
          <li><Link to='/color-appearance-model'> Color appearance model </Link></li>
          <li><Link to='/relative-luminance'> Relative luminance </Link></li>
          <li><Link to='/apca'> APCA </Link></li>
          <li><Link to='/color-difference'> Color difference </Link></li>
        </ul>
      </Section> */}

      <CitationList>
        <Citation src="Color and Meaning" pages="17, 19" />
        <Citation url="https://blog.datawrapper.de/which-color-scale-to-use-in-data-vis/" publisher="Datawrapper" title="Which color scale to use when visualizing data" author="Lisa Charlotte Muth" date="March 16, 2021" />
        <Citation url="https://spectrum.adobe.com/page/color-for-data-visualization/" publisher="Adobe Spectrum" title="Color for Data Visualization"/>
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default ColorScale;