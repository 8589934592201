import React from "react"
import PageMeta from "../components/PageMeta";

// 
import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';
import { Link } from "react-router-dom";

import Figure from '../img/Figure_ContrastPolarity.png';
import PageHeader from '../components/PageHeader';

import CitationList from "../components/CitationList";
import Citation from "../components/Citation";

function ContrastPolarity(props) {

  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      key="visual-acuity"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Contrast polarity"
        description='The contrast relationship between figure and background.'
      />

      <PageHeader
        title="Contrast polarity"
        // subtitle="(Nearsightedness)"
        />

      <Section 
        splitView
        figure={Figure}
        caption="Letterforms demonstrating negative (dark on light) and positive (light on dark) contrast polarity."
      >
        <p className="body4">The contrast relationship between the figure and its background.</p>
        <p className="body4">Dark objects on a light background have <strong>negative</strong> contrast polarity.</p>
        <p className="body4">Light objects on a dark background have <strong>positive</strong> contrast polarity.</p>
        {/* <p className="body4">In digital imaging, high dynamic range means the camera captures a wide range of bright and dark areas that would otherwise need multiple exposures (bracketing).</p> */}
      </Section>

      {/* <Section 
        title="See how it looks" 
        // splitLevel 
        level="2"
        >
          <Section
            playground={Slider}
            splitView
          >
          <p>Use the slider to see how varying degrees of myopia can affect a person's vision.</p>
          <p>Most conditions can be corrected with eyeglasses, contacts, or eye surgery.</p>

          </Section>
      </Section> */}


      <Section title="How it affects UI design" level="4" className="elements">
        <p><Link to="/contrast-sensitivity">Contrast sensitivity</Link> can differ between positive and negative contrast environments. <Link to="dark-mode">Dark mode</Link> may need higher <Link to="relative-luminance">relative luminance</Link> contrast than <Link to="/light-mode">light mode</Link>.</p>
        <p>The <Link to="apca">APCA</Link> contrast formula accounts for the perceptual differences of contrast polarity.</p>
        <p>Positive contrast polarity (<Link to="/dark-mode">dark mode</Link>) in high contrast colors can result in <Link to="/optical-glare">optical glare</Link>.</p>
        <p>Note: there may be some confusion when discussing <strong>display polarity</strong>, which is defined in opposite terms. For example, <Link to="/light-mode">light mode</Link> has <strong>negative</strong> contrast polarity, but <strong>positive</strong> device polarity.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/contrast-sensitivity'> Contrast sensitivity </Link></li>
          <li><Link to='/optical-glare'> Optical glare </Link></li>
          <li><Link to='/relative-luminance'> Relative luminance </Link></li>
          <li><Link to='/apca'> APCA </Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="Psychology Dictionary" url="https://psychologydictionary.org/contrast-polarity/" title="Contrast polarity" author="N., Sam M.S"/>
        <Citation src="American Psychological Association: Dictionary of Psychology" url="https://dictionary.apa.org/contrast-polarity" title="Contrast polarity"/>
        <Citation src="ScienceDirect" url="https://www.sciencedirect.com/science/article/abs/pii/004269899390129K?via%3Dihub" title="The effect of contrast polarity on letter identification" author="Kenneth R. Alexander, Wei Xie, Deborah J. Derlacki"/>
        <Citation src="ScienceDirect" url="https://www.sciencedirect.com/science/article/pii/S1888429618300256" title="Influence of contrast polarity on the accommodative response" author="Paula Bernal-Molina, José J. Esteve-Taboada, Teresa Ferrer-Blasco, Robert Montés-Micó"/>
        <Citation src="The Journal of Neuroscience" url="https://www.jneurosci.org/content/43/6/993" title="Luminance Contrast Shifts Dominance Balance between ON and OFF Pathways in Human Vision" author="Hamed Rahimi-Nasrabadi, Veronica Moore-Stoll, Jia Tan, Stephen Dellostritto, JianZhong Jin, Mitchell W. Dul and Jose-Manuel Alonso"/>
        <Citation src="Scientific Reports" url="https://www.nature.com/articles/s41598-018-28904-x" title="Reading and Myopia: Contrast Polarity Matters" author="Andrea C. Aleman, Min Wang & Frank Schaeffel"/>
        <Citation src="NIH NCBI" url="https://pubmed.ncbi.nlm.nih.gov/22676836/" title="Effect of display polarity and luminance contrast on visual lobe shape characteristics" author="Steve N H Tsang, Alan H S Chan, R F Yu"/>
      </CitationList>
      
      <Footer />

    </motion.div>
  )
}
 
export default ContrastPolarity;