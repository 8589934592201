import React from "react"
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';
import { Link } from "react-router-dom";

import Figure from '../img/Figure_VisualAcuity.png';
import FigureDark from '../img/Figure_VisualAcuityDark.png';
// import ImageSlider from "../components/ImageSlider";
// import Image1 from '../img/Figure_ContrastConstancy1.png';
// import Image2 from '../img/Figure_ContrastConstancy2.png';
import PageHeader from '../components/PageHeader';

import CitationList from "../components/CitationList";
import Citation from "../components/Citation";

function VisualAcuity(props) {
  const mq = window.matchMedia('(prefers-color-scheme: dark)');
  const IMG = (mq.matches) ? FigureDark : Figure;

  // const Slider = <ImageSlider 
  //   id="VisualAcuity" 
  //   images={ [Image1, Image2,] } 
  //   labelLeft="Illusion" 
  //   labelRight="Reveal" 
  //   height="172px"
  //   />

  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      key="visual-acuity"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Visual acuity"
        description='The ability to detect small spatial details with precision (clarity or sharpness of vision)'
      />

      <PageHeader
        category="Contrast perception"
        title="Visual acuity"
        // subtitle="(Contrast constancy)"
        />

      <Section 
        figure={Figure}
        caption="Example of letter target acuity chart using LogMAR scale. These charts are used for visual acuity testing."
      >
        <p className="body4">The clarity or sharpness of vision, relative to optical blur. It is the measure of how well an image is in focus on the retina.</p>
      </Section>

      <Section>
        <p className="body4">Visual acuity can be affected by:</p>
        <ul className="body4">
          <li><Link to="/myopia" >Myopia</Link> </li>
          <li><Link to="/hyperopia" >Hyperopia</Link> </li>
          <li><Link to='/contrast-sensitivity'> Low contrast sensitivity</Link></li>
          <li><Link to='/color-vision-deficiency'> Color vision deficiencies</Link></li>
          <li><Link to="/presbyopia">Age (presbyopia)</Link></li>
          <li>And <a href="https://www.w3.org/WAI/GL/low-vision-a11y-tf/wiki/Types_of_Low_Vision" target="_blank" rel="noopener noreferrer">many more...</a></li>

        </ul>

      </Section>

      {/* <Section title="Example" level="2">

        <Section level="4" splitView 
          // playground={Slider}
          title="Chubb illusion"
          // alt="alt text"
          // caption="The highlighted checkerboards appear to be the same (having the same contrast between light and dark squares). Move the slider to reveal how different the two highlighted checkers are. The internal checkers do not change."
        >
          <p>The central, hightlighted checkerboard on both sides of the image appear to have the same contrast for their internal squares.</p>
          <p>Move the slider to reveal how different the two highlighted checkers are. The internal checkers do not change.</p>
          <p>This illusion demonstrates how percieved contrast and actual contrast can be different based on the surrounding elements.</p>
        </Section>

      </Section> */}

      <Section title="How it affects UI design" level="4" className="elements">
        <p>Visual acuity is a key factor in how sighted users will experience your designs. Edge detection, identifying components, and the legibility and readibility of text are directly affected by a users' visual acuity.</p>
        <p>Not all people have the same visual acuity. WCAG <a href="https://www.w3.org/TR/WCAG21/#contrast-minimum" target="_blank" rel="noopener noreferrer">1.4.3 (contrast minimum)</a> provides a general threshold for making text &amp; components percievable to most sighted users.</p>
        <p>Typographic sizes and font weights <Link to="/contrast-sensitivity">affect the perception of contrast</Link> in text. Lighter font weights (or smaller text) should use higher contrast colors.</p>
        <p>In some cases, users may benefit from higher contrast than recommended minimums, whereas other users <strong>may be negatively impacted by high contrast interfaces</strong>.  Consider <Link to="adaptive-theme">personalization settings</Link> to give users the ability to adjust contrast to meet their needs.</p>
        <p> Supporting <Link to="increase-contrast-mode">iOS increase contrast mode</Link> and <Link to="high-contrast-mode">Windows high contrast mode</Link> will help users with low contrast sensitivity.</p>
        <p> Additional factors need to be considered from related sources below.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/contrast-sensitivity'> Contrast sensitivity </Link></li>
          <li><Link to='/contrast-discrimination'> Contrast discrimination </Link></li>
          <li><Link to='/lateral-inhibition'> Lateral inhibition </Link></li>
          <li><Link to='/relative-luminance'> Relative luminance </Link></li>
          <li><Link to='/apca'> APCA </Link></li>
          <li><Link to='/color-difference'> Color difference </Link></li>
          <li><Link to='/simultaneous-contrast'> Simultaneous contrast </Link></li>
          <li><Link to='/bezold-effect'> Bezold effect </Link></li>
          <li><Link to='/stevens-power-law'> Stevens' power law </Link></li>
          <li><Link to='/light-and-dark-adaptation'> Light &amp; dark adaptation </Link></li>
          <li><Link to='/myopia'> Myopia </Link></li>
          <li><Link to='/hyperopia'> Hyperopia </Link></li>
          <li><Link to="/presbyopia">Presbyopia</Link></li>
          <li><Link to='/light-sensitivity'> Light sensitivity </Link></li>
          <li><Link to='/color-vision-deficiency'> Color vision deficiency </Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="Visual Perception" pages="15, 17" />
        <Citation src="Understanding Color" pages="43-45" />
        <Citation src="AOA.org" url="https://www.aoa.org/healthy-eyes/vision-and-vision-correction/visual-acuity" title="Visual Acuity" />
        <Citation src="WCAG" url="https://www.w3.org/WAI/GL/low-vision-a11y-tf/wiki/Types_of_Low_Vision" title="Types of low vision" />
        <Citation src="Wikipedia" url="https://en.wikipedia.org/wiki/Visual_acuity" title="Visual acuity" />
        <Citation url="https://vectorvision.com/contrast-sensitivity-background/" publisher="Vector Vision" />
      </CitationList>


      <Footer />

    </motion.div>
  )
}
 
export default VisualAcuity;