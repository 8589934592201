import React from "react"
import { Link } from "react-router-dom";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Pagination from '../components/Pagination';
import Footer from '../components/Footer';

import PageHeader from '../components/PageHeader';


function TutorialChoosingPalette(props) {
  
  return (
    <motion.div
      style={pageStyle}
      key="tutorial-choosing-palette"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageHeader
        category="Tutorials"
        title="Choosing a palette"
        subtitle="Secondary, tertiary, &amp; system colors"
        />

      <Section level="2">
        <p className="body4">Chosing a color for your product or brand identity can reinforce its purpose.</p>
      </Section>

      <Section 
        overline="Step 1" 
        title="Identify semantic colors" 
        level="2"
        >
        <p>It's important to begin by identifying all of the ways you wish to communicate with color before choosing additional <Link to="hue">hues</Link>. The semantics that you support will have an impact on which colors you can choose for secondary or tertiary accent colors.</p>

        <p>If your product will display errors, you will likely need a <strong>red</strong> hue in your palette.</p>
        <p>If your product displays validation or success messaging, you will likely need a <strong>green</strong> hue in your palette.</p>
        <p>When supporting red and green, be mindful of <Link to="color-vision-deficiency">color vision deficiencies</Link>.</p>
      </Section>

      <Section 
        overline="Step 2" 
        title="Choose color relationships" 
        level="2"
        >
        <p>Identify what type of color scheme you want to support for your product.</p>

        <p>TODO: Replace sections with a picker. Select a type and it will suggest colors + display description.</p>

        <Section 
          level="4" 
          title="Monochromatic palette" 
          splitView 
          className="smallSplitView subSection-compact">
          <p>A <Link to="monochromatic">monochromatic</Link> color palette is minimalistic &amp; utilitarian.</p>
        </Section>

        <Section 
          level="4" 
          title="Analogous palette" 
          splitView 
          className="smallSplitView subSection-compact">
          <p>An <Link to="analogous">analogous</Link> color palette is simple and elegant.</p>
        </Section>

        <Section 
          level="4" 
          title="Complimentary palette" 
          splitView 
          className="smallSplitView subSection-compact">
          <p>A <Link to="complimentary">complimentary</Link> color palette is dynamic.</p>
        </Section>

        <Section 
          level="4" 
          title="Split complimentary palette" 
          splitView 
          className="smallSplitView subSection-compact">
          <p>A <Link to="split-complimentary">split complimentary</Link> color palette is dynamic with additional variety of color.</p>
        </Section>

      </Section>

      <Section 
        overline="Step 3" 
        title="Data visualization &amp; taxonomical colors" 
        level="2"
        >
        <p>If your product will display data visualizations, or use color for taxonomy (such as categorization), additional <Link to="hue">hues</Link> may be needed.</p>
        <p>For every <Link to="hue">hue</Link> that is added to your palette, ensure the <Link to="chroma">chroma (saturation)</Link> is consistent.</p>
        <p>In some cases, <Link to="chroma">chroma</Link> cannot be consistent for every color. In these cases, try to progressively increase or decrease <Link to="chroma">chroma</Link> across your colors along a color wheel.</p>
      </Section>


      <Pagination 
        previous="Choosing a color"
        next="Choosing a color space"
      />

      <Footer />

    </motion.div>
  )
}
 
export default TutorialChoosingPalette;