import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

// import Figure from '../img/Figure_BezoldEffect.png';
import PageHeader from '../components/PageHeader';

import './WhitesIllusion.scss';

import CitationList from "../components/CitationList";
import Citation from "../components/Citation";


class BezoldEffectSlider extends React.Component {
  constructor(props) {
    super(props)
    this.id = props.id;
    this.sliderId = `${this.id}-ImageSlider-control`;

    this.width = (this.props.width) ? this.props.width : '100%';
    this.height = (this.props.height) ? this.props.height : '350px';
    this.defaultValue = 0;
    this.color = '#38ffde'
    this.maxValue = 100; // 40 = half of gray rectangle width

    document.documentElement.style
      .setProperty(`--BezoldOpacity`, `1`);
    document.documentElement.style
      .setProperty(`--BezoldCustomColor`, `${this.color}`);

    this._resetSlider = () => {
      const range = document.getElementById(this.sliderId);
      const colorPicker = document.getElementById('BezoldColorPicker');
      const colorSwatch = document.getElementById(this.id+'colorSwatch');

      range.value = this.defaultValue;
      colorPicker.value = this.color;
      colorSwatch.style.backgroundColor = this.color;

      this._updateSlider();
      this._updateColor();
    }
    
    this._ease = (x) => {
      return x;
    }
    this._updateSlider = (e) => {
      let val = (!e) ? 1 : (100 - +e.target.value)/100;

      document.documentElement.style
        .setProperty(`--BezoldOpacity`, val);
    }

    this._updateColor = (e) => {
      let val = (!e) ? this.color : e.target.value;

      document.documentElement.style
        .setProperty(`--BezoldCustomColor`, `${val}`);
    }

  }

  render() {
    return (
      <div className="ImageSlider">
        <div className="ImageSlider-container BlockRow--bezoldCustom" style={{height: this.height}}>
          <div className="BlockRow BlockRow--bezoldBackground"></div>
          <div className="BlockRow BlockRow--bezoldBackground"></div>
          <div className="BlockRow BlockRow--bezoldBackground"></div>
          <div className="BlockRow BlockRow--bezoldBackground"></div>
          <div className="BlockRow BlockRow--bezoldBackground"></div>
          <div className="BlockRow BlockRow--bezoldBackground"></div>
          <div className="BlockRow BlockRow--bezoldBackground"></div>
          <div className="BlockRow BlockRow--bezoldBackground"></div>
          <div className="BlockRow BlockRow--bezoldBackground"></div>
          <div className="BlockRow BlockRow--bezoldBackground"></div>
          <div className="BlockRow BlockRow--bezoldBackground"></div>
        </div>

        <div className="ImageSlider-labels" style={{width: this.width}}>
          <label>{this.props.labelLeft}</label>
          <label>{this.props.labelRight}</label>
        </div>
        <div className="ImageSlider-controls">
          <input 
            id={this.sliderId} 
            className="ImageSlider-control"
            type="range" 
            min="0" 
            max="100" 
            defaultValue={ this.defaultValue }
            step="1"
            style={{width: this.width}}
            onInput={(e) => {this._updateSlider(e)}} 
          /> 
        </div>
          <label className="ColorPicker-label" for="BezoldColorPicker">Stripe color:</label>
          <div className="ColorPicker">
            <div className="ColorPicker_swatch ColorPicker_swatch--tiny" id={this.id+'colorSwatch'} style={{backgroundColor: this.color}}>
              <input type="color" id="BezoldColorPicker" defaultValue={this.color} onInput={ (e) => {document.getElementById(this.id+'colorSwatch').style.backgroundColor = e.target.value; this.color1 = e.target.value; this._updateColor(e)}}></input>
            </div>
          </div>
        <div className="ImageSlider-controls">
          <button type="button" className="button button--reset" onClick={() => this._resetSlider()}>Reset</button>
        </div>
        
      </div>
    )
  }
}


function BezoldEffect(props) {
  const Slider = <BezoldEffectSlider 
    id="BezoldEffectSlider" 
    // images={ [Image1, Image2,] } 
    labelLeft="Illusion" 
    labelRight="Reveal" 
    height="184px"
  />

  return (
    <motion.div
      style={pageStyle}
      key="bezold-effect"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Bezold effect"
        description="A color's hue, chroma, and lightness may appear different when surrounded by different colors."
      />

      <PageHeader
        category="Color perception"
        title="Bezold effect"
        />

        <Section 
          splitView 
          playground={Slider}
          >
          <p className="body4">A color's <Link to="hue">hue</Link>, <Link to="chroma">chroma</Link>, and <Link to="lightness">lightness</Link> may appear different when surrounded by different colors.</p>
          <p className="body4">This effect is similar to <Link to="simultaneous-contrast">simultaneous contrast</Link> and affected by spatial color mixing.</p>

        </Section>


      <Section title="How it affects UI design" level="4" className="elements">
        <p>Color appearance will change based on adjacent colors.</p>
        <p>Colors will appear different in dark mode. In some cases, unique color values may be recommended in dark mode.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/contrast-sensitivity'> Contrast sensitivity </Link></li>
          <li><Link to='/apca'> APCA </Link></li>
          <li><Link to='/simultaneous-contrast'> Simultaneous contrast </Link></li>
          <li><Link to='/spatial-color-mixing'> Spatial color mixing </Link></li>
          <li><Link to='/color-constancy'> Color constancy </Link></li>
          <li><Link to='/light-and-dark-adaptation'> Light &amp; dark adaptation </Link></li>
          <li><Link to='/lateral-inhibition'> Lateral inhibition </Link></li>
          <li><Link to='/chromatic-luminance'> Chromatic luminance </Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="Launching the Imagination" pages="55-56"/>
        <Citation src="Understanding Color" pages="122-123" />
        <Citation src="Wikipedia" url="https://en.wikipedia.org/wiki/Bezold_effect" title="Bezold effect" />

      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default BezoldEffect;