import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import PageHeader from '../components/PageHeader';
import ColorPicker from "../components/ColorPicker";
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";
import ToolCitation from "../components/ToolCitation";
// import { Table, TableRow } from "../components/Table";

function DisplayP3(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      key="display-p3"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Display P3"
        description="A wide gamut implementation of the RGB color model used by modern retina displays."
      />

      <PageHeader
        category="Color spaces"
        title="Display P3"
        // subtitle=""
        />

      <Section>
        <p className="body4">A wide <Link to="gamut">gamut</Link> implementation of the <Link to="rgb-model">RGB color model</Link> used by modern retina displays.</p>
      </Section>

      <Section title="Example" level="2">
        <Section level="4" splitView 
          // title="Display-P3"
          playground={<ColorPicker mode="Display-P3" />}
        >
        <p>Modern devices like smartphones, tablets, and retina screens use Display-P3. It provides a much wider collection of color, which are more colorful than sRGB.</p>
        <p><strong> Not supported in all browsers or displays</strong>. Unsupported browsers will display gray circle. If color appears the same, you may not be using a <Link to="gamut">wide-gamut</Link> display.</p>
        <p>CSS annotation:</p>
        <ul>
          <li> <code>color(display-p3 1 1 1)</code> </li>
        </ul>
        </Section>

        <Section level="5" splitView 
          title="sRGB fallback"
          playground={<ColorPicker mode="sRGB" />}
        >
        <p>Try to match the Display-P3 color as close as you can for an optically enhanced fallback for your original color.</p>

        </Section>

      </Section>


      {/* <Section title="Analysis of Display P3" level="2" >
        <p>Knowing which <Link to="color-space">color space</Link> to use as a designer can be confusing. This analysis could help you in determining whether or not Display P3 is the right color space to try.</p>

        <h5>Methods and derivation</h5>
        <Table
          header={['Derived from', <Link to="chromatic-adaptation">Chromatic adaptation</Link>, <Link to='lightness'>Lightness</Link>, 'White point']}
        >
          <TableRow>
            <>Red, Green, Blue pixels of device</>
            <>None</>
            <>None</>
            <>N/A</>
          </TableRow>

        </Table>

        <h5>Pro's and con's of Display P3</h5>
        <Table 
          splitView
          header={['Known issues & limitations', 'Benefits & strengths']}
        >
          <TableRow>
            <ul>
              <li>Device-dependant</li>
              <li>Not perceptually accurate</li>
              <li>Cylindrical transformations are not perceptually accurate</li>
            </ul>
            <ul>
              <li>RGB is an accurate model for <Link to="additive">additive</Link> mixtures of light for a person's specific device.</li>
              <li>Widely supported (default) for web and digital applications</li>
              <li>Wider <Link to="gamut">gamut</Link> makes Display P3 more favorable to <Link to="srgb">sRGB</Link> when supported.</li>
            </ul>
          </TableRow>
        </Table>

      </Section> */}

      <Section title="How it affects UI design" level="4" className="elements">
        <p>Display-P3 is not supported by all browsers. Always define a fallback sRGB color value.</p>
        <p>Display-P3 has much more vibrant (chromatic) colors than sRGB. Do not directly convert Display-P3 values to sRGB for <Link to="tone">tones</Link> of a color, as they may appear much less chromatic than intended. Direct conversion is done by multiplying each Display-P3 channel by 255.</p>
        <p>RGB color spaces are not perceptually uniform. Using these to mix <Link to="tint">tints</Link>, <Link to="shade">shades</Link>, or <Link to='tone'>tones</Link> can be very difficult and yield unexpected results.</p>
        <p>When trying to create perceptually uniform colors, use a <Link to="color-appearance-model">color appearance model</Link> instead.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to="color-model">Color model</Link></li>
          <li><Link to="color-space">Color space</Link></li>
          <li><Link to="additive">Additive color mixing</Link></li>
          <li><Link to="tint">Tint</Link></li>
          <li><Link to="shade">Shade</Link></li>
          <li><Link to="tone">Tone</Link></li>
        </ul>
      </Section>

      <CitationList tools>
        <ToolCitation tool="Color.js" />
      </CitationList>

      <CitationList>
        <Citation publisher="International Color Consortium (ICC)" url="https://www.color.org/chardata/rgb/DisplayP3.xalter" title="Display P3" />
        <Citation src="Wikipedia" url="https://en.wikipedia.org/wiki/DCI-P3" title="DCI-P3"/>
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default DisplayP3;