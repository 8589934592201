import React from "react"
import PageMeta from "../components/PageMeta";

// 
import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';
import { Link } from "react-router-dom";

import Figure from '../img/Figure_Astigmatism.png';
import FigureDark from '../img/Figure_AstigmatismDark.png';
// import ImageSlider from "../components/ImageSlider";
// import Image1 from '../img/VisualAcuity_2020.png';
// import Image2 from '../img/VisualAcuity_Myopia01.png';
// import Image3 from '../img/VisualAcuity_Myopia02.png';
import PageHeader from '../components/PageHeader';

import CitationList from "../components/CitationList";
import Citation from "../components/Citation";

function Astigmatism(props) {
  const mq = window.matchMedia('(prefers-color-scheme: dark)');
  const IMG = (mq.matches) ? FigureDark : Figure;

  // const Slider = <ImageSlider 
  //   id="Astigmatism" 
  //   images={ [Image1, Image2, Image3] } 
  //   labelLeft="Normal vision" 
  //   labelRight="Astigmatism" 
  //   height="292px"
  //   defaultValue="0"
  //   />

  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      key="visual-acuity"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Astigmatism"
        description='An irregularity in the curvature in either the cornea or the inner lens of the eye. Astigmatism prevents light from properly focusing on the retina.'
      />

      <PageHeader
        title="Astigmatism"
        // subtitle="(Nearsightedness)"
        />

      <Section 
        splitView
        figure={IMG}
        caption="The irregular shape of the cornea causes refracted light to have focal points inset and behind the retina."
      >
        <p className="body4">An irregularity in the curvature in either the cornea or the inner lens of the eye.</p>
        <p className="body4">Astigmatism prevents light from properly focusing on the retina. Astigmatism can exaggerate <Link to="optical-glare">optical glare</Link>.</p>
      </Section>

      {/* <Section 
        title="See how it looks" 
        // splitLevel 
        level="2"
        >
          <Section
            playground={Slider}
            splitView
          >
          <p>Use the slider to see how varying degrees of myopia can affect a person's vision.</p>
          <p>Most conditions can be corrected with eyeglasses, contacts, or eye surgery.</p>

          </Section>
      </Section> */}


      <Section title="How it affects UI design" level="4" className="elements">
        <p>Astigmatism can cause or exaggerate <Link to="chromatic-abberation">chromatic abberation</Link>. Be sensitive to juxtapositioning highly <Link to="chroma">saturated</Link> reds and blues, as they will create a "vibration" appearance called <Link to="chromostereopsis">chromostereopsis</Link>. This can be unpleasant and causes text legibility issues. </p>
        <p>Astigmatism causes lowered <Link to="visual-acuity">visual acuity (blurring)</Link> and is typically accompanied with <Link to="myopia">myopia</Link> or <Link to="hyperopia">hyperopia</Link>.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/visual-acuity'> Visual acuity </Link></li>
          <li><Link to='/chromatic-abberation'> Chromatic abberation </Link></li>
          <li><Link to='/chromostereopsis'> Chromostereopsis </Link></li>
          <li><Link to='/hyperopia'> Hyperopia </Link></li>
          <li><Link to='/myopia'> Myopia </Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="AAO.org" url="https://www.aao.org/eye-health/diseases/what-is-astigmatism" title="What is astigmatism"/>
        <Citation src="NEI NIH" url="https://www.nei.nih.gov/learn-about-eye-health/eye-conditions-and-diseases/astigmatism" title="Astigmatism"/>
        <Citation src="AOA.org" url="https://www.aoa.org/healthy-eyes/eye-and-vision-conditions/astigmatism" title="Astigmatism" />
        <Citation src="Mayo Clinic" url="https://www.mayoclinic.org/diseases-conditions/astigmatism/symptoms-causes/syc-20353835" title="Astigmatism" /> 
        <Citation src="Wikipedia" url="https://en.wikipedia.org/wiki/Astigmatism" title="Astigmatism" />
      </CitationList>
      
      <Footer />

    </motion.div>
  )
}
 
export default Astigmatism;