import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';
import Citation from "../components/Citation";
import CitationList from "../components/CitationList";

import PageHeader from '../components/PageHeader';

import Figure from '../img/Figure_cornea.png';
import FigureDark from '../img/Figure_cornea_dark.png';
// import Lens from '../img/Figure_lens.png';
// import Pupil from '../img/Figure_pupil.png';
// import Retina from '../img/Figure_retina.png';
// import Cones from '../img/Figure_cones.png';
// import Rods from '../img/Figure_rods.png';
// import OpticNerve from '../img/Figure_optic-nerve.png';
// import Pigment from '../img/Figure_pigment.png';
// import Fovea from '../img/Figure_fovea.png';


function Cornea(props) {
  const mq = window.matchMedia('(prefers-color-scheme: dark)');
  const IMG = (mq.matches) ? FigureDark : Figure;

  return (
    <motion.div
      style={pageStyle}
      key="trichromacy"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Cornea"
        description=""
      />

      <PageHeader
        category="Color vision"
        title="Cornea"
        // subtitle="(Anatomy)"
        />

      <Section
        figure={IMG}
        splitView
      >
        <p className="body4">The transparent dome-shaped surface in front of the eye that focuses light in the eye.</p>
        <p className="body4">Variations in shape of the cornea relative to the  <Link to='retina'>retina</Link> can attribute to <Link to='astigmatism'>astigmatism</Link>, <Link to="myopia">myopia</Link>, and <Link to="hyperopia">hyperopia</Link>.</p>
      </Section>


      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/retina'> Retina </Link></li>
          <li><Link to='/astigmatism'> Astigmatism </Link></li>
          <li><Link to='/myopia'> Myopia </Link></li>
          <li><Link to='/hyperopia'> Hyperopia </Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="CAMS" pages="3" />
        <Citation src="AAO.org" url="https://www.aao.org/eye-health/anatomy/cornea-103" title="Cornea"/>
        <Citation src="Wikipedia" url="https://en.wikipedia.org/wiki/Cornea" title="Cornea" />
        <Citation src="NIH NEI" url="https://www.nei.nih.gov/learn-about-eye-health/healthy-vision/how-eyes-work" title="How eyes work" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default Cornea;