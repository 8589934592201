import React from "react"
import { Link } from "react-router-dom";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Pagination from '../components/Pagination';
import Footer from '../components/Footer';

// import Figure from '../img/Figure_TutorialPersonalization.png';
// import Example from '../img/Figure_TutorialPersonalizationCIE.png';
// import Example2 from '../img/Figure_ColorModel-Example.png';
import PageHeader from '../components/PageHeader';
// import ImageSlider from "../components/ImageSlider";
// import Image1 from '../img/Figure_ContrastConstancy1.png';
// import Image2 from '../img/Figure_ContrastConstancy2.png';
// import { findByLabelText } from "@testing-library/react";


function TutorialPersonalization(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      key="tutorial-personalization"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageHeader
        category="Tutorials"
        title="Personalization"
        subtitle="Elevating inclusivity &amp; accessibility"
        />

      <Section level="2">
        <p className="body4">Not all users will experience <Link to="color-vision-deficiency">color</Link> and <Link to="contrast-sensitivity">contrast</Link> the same. WCAG criteria can help, however personalization will give more sighted users an improved overall experience.</p>
      </Section>

      <Section 
        overline="Step 1" 
        title="Emotional meaning" 
        level="2"
        >
        <p>Colors can convey certain emotions. Choose a color that has emotional qualities that align with your brand identity, or the intended feeling of our product.</p>
        <p>Color picker that displays emotions from database?</p>
      </Section>


      <Pagination 
        previous="Creating a theme"
        // next="Personalization"
      />

      <Footer />

    </motion.div>
  )
}
 
export default TutorialPersonalization;