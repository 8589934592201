import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

// import Figure from '../img/Figure_Chromaticity.png';
import Example from '../img/Figure_ChromaticityCIE.png';
// import Example2 from '../img/Figure_ColorModel-Example.png';
import PageHeader from '../components/PageHeader';
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";


function Chromaticity(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      key="chromaticity"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Chromaticity"
        description="The hue and colorfulness of a color, independant from a color's lightness."
      />

      <PageHeader
        category="Color properties"
        title="Chromaticity"
        // subtitle=""
        />

      <Section>
        <p className="body4">The <Link to="hue">hue</Link> and <Link to="chroma">colorfulness</Link> of a color, independant from a color's <Link to="lightness">lightness</Link>. "Chromaticity" is a term primarily used in reference to the CIE chromaticity diagram.</p>
        
        {/* <Image 
          src={Figure}
          alt="" 
          caption="Conceptual diagram of chromaticity along a color wheel"
        ></Image> */}
      </Section>

      <Section title="CIE chromaticity diagram">
        <Section level="4" splitView 
          figure={Example}
          // caption="Diagram of chromaticity (hue and chroma)"
        >
          <p>The International Commission on Illumination (CIE) <strong>chromaticity diagram</strong> is a cross-section of <Link to="xyz">XYZ color space</Link> and maps the <Link to="trichromacy">visible spectrum</Link>.</p>
          <p>This chromaticity diagram is a standard for illustrating many important aspects of color vision such as the <Link to="abney-effect">Abney effect</Link>, <Link to="bezold-brucke-shift">Bezold–Brücke shift</Link>, <Link to="color-confusion-lines">color confusion lines</Link> of <Link to="color-vision-deficiency">color vision deficiencies</Link>, <Link to="color-temperature">color temperature</Link> and color space <Link to="gamut">gamuts</Link> (among others).</p>
        </Section>
      </Section>


      <Section title="How it affects UI design" level="4" className="elements">
        <p>The chroma of a color can be perceived differently between light and dark modes as a result of the <Link to="/color/bezold-effect">Bezold effect</Link>.</p>
        <p>The chroma of a color can be perceived differently when adjacent to other colors of varying chroma due to <Link to="/color/simultaneous-contrast">simultaneous contrast</Link>.</p>
        <p>Lower chroma colors can be more difficult to distinguish for people with <Link to='color-vision-deficiency'>color vision deficiencies</Link>.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/hue'> Hue </Link></li>
          <li><Link to='/tone'> Tone </Link></li>
          <li><Link to='/color-model'> Color model </Link></li>
          <li><Link to='/color-space'> Color space </Link></li>
          <li><Link to="/color-confusion-lines">Color confusion lines</Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="Psychophysical" pages="283" />
        <Citation src="Wikipedia" url="https://en.wikipedia.org/wiki/Chromaticity" title="Chromaticity" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default Chromaticity;