import React from "react"
import { useState } from "react";
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";
import Color from "colorjs.io";
import chroma from 'chroma-js';
import extendChroma from "../utils/chroma-plus";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import Figure from '../img/Figure_ColorInterpolation.png';
import FigureDark from '../img/Figure_ColorInterpolationDark.png';
// import Example from '../img/Figure_ColorInterpolation-Example.png';
// import Example2 from '../img/Figure_ColorModel-Example.png';
import PageHeader from '../components/PageHeader';
import './ColorInterpolation.scss';
import CitationList from "../components/CitationList";
import Citation from "../components/Citation";
import ToolCitation from "../components/ToolCitation";

extendChroma(chroma);

class ColorRamps extends React.Component {
  constructor(props) {
    super(props)
    this.steps = props.steps;
    this.colorSpaces = [
      'lab',
      'jab',
      'oklab',
      'rgb',
      'lch',
      'jch',
      'oklch',
      'hsluv',
      'hsl',
      'hsv'
    ];
    this.colorSpacesJS = [
      'xyz',
      'p3',
      'hwb',
      'ictcp',
      'jzazbz',
      'jzczhz',
      'rec2020'
    ];

    this.colors = props.colors;
    this.id = `gradient-${props.space}`;
    this.color1 = props.color1;
    this.color2 = props.color2;
    this.chromaRamps = this.colorSpaces.map((space, i) => {
      /** Using Chroma.js (extended) */
      // const color1 = new chroma(this.color1)
      // const color2 = new chroma(this.color2)

      let colors = chroma.scale([this.color1, this.color2]).mode(space).colors(this.steps);

      // let gradient = <div className="gradientRamp" id={`gradient-${space}`} style={{backgroundImage: `linear-gradient(90deg, ${colors})`}}></div>
      // return gradient;
      return (
        <div className="gradientRamp-Wrapper">
          <label htmlFor={`gradient-${space}`} id={`gradientLabel-${space}`}>{space}</label>
          <div key={colors} className="gradientRamp" id={`gradient-${space}`} style={{backgroundImage: `linear-gradient(90deg, ${colors})`}}></div>
        </div>
      )
    })

    this.colorjsRamps = this.colorSpacesJS.map((space, i) => {
      console.log(space)
      let color1 = new Color(this.color1)
      let color2 = new Color(this.color2)
      let colorObjs = color1.steps(color2, {space: space, steps: this.steps})

      let colors = colorObjs.map((object) => {
        // Find the inaccessible sRGB coordinages
        let coords = [object.srgb[0], object.srgb[1], object.srgb[2]];
        // Then convert them to a usable format, because why would I ever need that?
        let rgbCoords = coords.map((cord) => {
          return Math.floor(255 * cord)
        })
        let rgb = `rgb(${rgbCoords})`;
        
        return rgb
      })

      return (
        <div className="gradientRamp-Wrapper">
          <label htmlFor={`gradient-${space}`} id={`gradientLabel-${space}`}>{space}</label>
          <div key={colors} className="gradientRamp" id={`gradient-${space}`} style={{backgroundImage: `linear-gradient(90deg, ${colors})`}}></div>
        </div>
      )
    })
  }

  render() { 
    return (
      <>
      {this.chromaRamps}
      {this.colorjsRamps}
      </>
    );
  }
}

const InterpolationPlayground = (props) => {
    const [color1, setColor1] = useState('#ff8002');
    const [color2, setColor2] = useState('#1ca9e6');
    const [steps, setSteps] = useState(100);

    return (
      <div>
        <div className="ColorPicker colorPicker--row" style={{display: 'flex', width: '100%', justifyContent: 'flex-start', marginLeft: '7ch'}}>
          <div className="ColorPicker_swatch ColorPicker_swatch--small" id="interpColor1Swatch" style={{backgroundColor: color1}}>
            <input type="color" id="interpColor1" defaultValue={color1} onInput={ (e) => {document.getElementById('interpColor1Swatch').style.backgroundColor = e.target.value; setColor1(e.target.value); }}></input>
          </div>
          <div className="ColorPicker_swatch ColorPicker_swatch--small" id="interpColor2Swatch" style={{backgroundColor: color2}}>
            <input type="color" id="interpColor2" defaultValue={color2} onInput={ (e) => {document.getElementById('interpColor2Swatch').style.backgroundColor = e.target.value; setColor2(e.target.value); }}></input>
          </div>
          {/* <div>
            <label htmlFor="interpSteps" id="interpStepsLabel">Steps</label>
            <input id="interpSteps" labelledBy="interpStepsLabel" type="range" min='2' max='100' step="1" defaultValue={steps} onInput={(e) => { setSteps(e.target.value) }} />
          </div> */}
        </div>
        <div id="interpolationRamps" >
          <ColorRamps key={color1+color2+steps} color1={color1} color2={color2} steps={steps}/>
        </div>
      </div>
    )
}



function ColorInterpolation(props) {
  const Playground = <InterpolationPlayground />
  const mq = window.matchMedia('(prefers-color-scheme: dark)');
  const IMG = (mq.matches) ? FigureDark : Figure;

  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      key="color-interpolation"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Color interpolation"
        description="Functionally calculating a color value inbetween two known colors."
      />

      <PageHeader
        category="Colors in use"
        title="Color interpolation"
        // subtitle=""
        />

      <Section
        figure={IMG}
      >
        {/* <p className="body4">A color space is the total collection of possible colors within a particular set of primaries. Color spaces are specific implimentations of a <Link to="/color/color-model">color model</Link>.</p> */}
        <p className="body4">Functionally calculating a color value inbetween two known colors. Color interpolation is included in most color libraries, and is linear by default.</p>
        <p className="body4">The known colors, desired result, choice of <Link to="color-space">color space</Link>, and intended use for the color are important factors in color interpolation.</p>
        <p className="body4">Color interpolation is conceptually similar to color <strong>mixing</strong>.</p>
      </Section>

      <Section title="Example" level="2">
        <Section
          // splitView
          playground={Playground}
        >
          <p>Choose two colors to interpolate between. Gradients will display all colors interpolated between them (within the <Link to="srgb">sRGB</Link> gamut).</p>
        </Section>
      </Section>


      <Section title="How it affects UI design" level="4" className="elements">
        <p>In most cases, use cartesian <Link to="color-space">color spaces</Link> for interpolating between <Link to="monochromatic-colors">monochromatic colors</Link>.</p>
        <p>In most cases, use cylindrical transformations of a <Link to="color-space">color space</Link> for interpolating between multi-hue, or <Link to="analogous-colors">analogous colors</Link>.</p>
        <p>Interpolation in an <Link to="color-appearance-model">uniform color space</Link> will provide colors that are mathematically based on models of human color perception.</p>
        <p>Scientific  <Link to="color-appearance-model">models of human perception of color</Link> is not necessarily reflective of <strong>aesthetic</strong>. Finding proper interpolation paths and color spaces is equal part art and science. <strong>Don't rely on color space interpolation to create aesthetic and perceptually balanced color out-of-the-box.</strong></p>
        <p>Linear interpolation does not always provide the best colors. Use color tools that provide curves or smoothing to refine interpolation paths.</p>
        <p><strong>There is no single <Link to="color-space">color space</Link> that is best</strong>. Experiment to find the best result with your colors.</p>
      </Section>

      <Section title="Related topics" level="4">
        <ul>
          <li><Link to="color-space">Color space</Link></li>
          <li><Link to="color-scale">Color scale</Link></li>
        </ul>
      </Section>

      <CitationList tools>
        <ToolCitation tool="Chroma.js" />
        <ToolCitation tool="Color.js" />
        <ToolCitation tool="D3 color" />
        <ToolCitation tool="Leonardo" />
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default ColorInterpolation;