// import react from "react";
import {Helmet} from 'react-helmet';

const PageMeta = (props) => {
  const SiteTitle = 'Color and Contrast.com';
  const PageTitle = ( props.title === 'Home' || props.title === '' ) ? SiteTitle : SiteTitle + ": " + props.title;
  const SocialPageTitle =  ( props.title === 'Home' || props.title === '' ) ? SiteTitle : SiteTitle + ": " + props.title; // no prefix
  const canonicalUrl = 'https://colorandcontrast.com/';
  const pageUrl = props.url;
  const URL = (props.title === 'Home' || props.title === '') ? canonicalUrl : canonicalUrl + '/#/' + pageUrl;
  // const img = props.img;
  const imgAlt = props.alt;

  return (
    <Helmet>
      <title>{PageTitle}</title>
      <meta name="description" content={props.description} />
      {/* <!--  Essential META Tags --> */}
      <meta property="og:title" content={SocialPageTitle}/>
      <meta property="twitter:title" content={SocialPageTitle}/>
      <meta property="og:type" content="article" />
      <meta property="og:image" content={canonicalUrl + 'SocialImage.png'}/>
      <meta property="twitter:image" content={canonicalUrl + 'SocialImage.png'}/>
      <meta property="og:url" content={URL}/>
      <meta name="twitter:card" content="summary_large_image"/>

      {/* <!--  Non-Essential, But Recommended --> */}
      <meta property="og:description" content={props.description}/>
      <meta property="og:site_name" content={SiteTitle}/>
      <meta name="twitter:image:alt" content={imgAlt}/>

      {/* <!--  Non-Essential, But Required for Analytics --> */}
      {/* <meta property="fb:app_id" content="your_app_id" /> */}
      {/* <meta name="twitter:site:id" content="@NateBaldwinArt"/> */}
      <meta name="twitter:creator" content="@NateBaldwinArt"/>

      <script data-name="BMC-Widget" data-cfasync="false" src="https://cdnjs.buymeacoffee.com/1.0.0/widget.prod.min.js" data-id="natebaldwiw" data-description="Support me on Buy me a coffee!" data-message="" data-color="#26B0A1" data-position="Right" data-x_margin="18" data-y_margin="18"></script>
      
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2895170450249149"
     crossorigin="anonymous"></script>
    </Helmet>
  )
}

export default PageMeta;