// const chroma = require('chroma-js');
// const { extendChroma } = require('./chroma-plus');
import chroma from 'chroma-js';
import extendChroma from "../utils/chroma-plus";
import DeltaE from 'delta-e'
// const DeltaE = require('delta-e');

extendChroma(chroma);

function getColorDifference(color1, color2) {
  // pre-formatting and running through specific deltaE formula
  let c1 = chroma(color1).lab();
  let c2 = chroma(color2).lab();
  let c1Lab = { L: c1[0], A: c1[1] , B: c1[2] }
  let c2Lab = { L: c2[0], A: c2[1] , B: c2[2] }
  // Use DeltaE 2000 formula (latest)
  return DeltaE.getDeltaE00(c1Lab, c2Lab);
}

export default getColorDifference;