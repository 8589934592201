import React from "react"
import { Link } from "react-router-dom";
import PageMeta from "../components/PageMeta";
import Citation from "../components/Citation";
import CitationList from "../components/CitationList";


import Section from "../components/Section";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { motion } from "framer-motion";

import Footer from '../components/Footer';

import Figure from '../img/Figure_ColorOpponentProcess.png';
import FigureDark from '../img/Figure_ColorOpponentProcess_Dark.png';
import PageHeader from '../components/PageHeader';
import OpponentSlider from "../components/OpponentSlider";

function ColorOpponentProcess(props) {
  const mq = window.matchMedia('(prefers-color-scheme: dark)');
  const IMG = (mq.matches) ? FigureDark : Figure;

  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      key="color-opponent-process"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <PageMeta 
        title="Color opponent process"
        description="(Color opponency) An aspect of color vision where sensory signals are linked between different cones. These links inhibit signals sent to the visual system, cancelling out the opponent color."
      />

      <PageHeader
        category="Color vision"
        title="Color opponent process"
        subtitle="(Color opponency)"
        />

      <Section
        figure={IMG}
        caption="Simplified diagram of sensory signals that combine (excite) or cancel (inhibit) the opponent signal(s) to create three distinct color channels. Rods (R) also combine into the luminance channel."      
      >
        <p className="body4">An aspect of color vision where sensory signals are linked between different cones. These links inhibit signals sent to the visual system, cancelling out the opponent color.</p>
        <p className="body4">This secondary process creates the red-green, blue-yellow, and luminance channels of vision (used in <Link to="color-appearance-model">color appearance models</Link>).</p>
      </Section>

      <Section title="See how it works*" level="2">
        <p>Use the slider to adjust the wavelength of light to see how short (SWS / red) cones, medium (MWS / green), and long wavelength sensitive cones (LWS / blue) react to create the opponent channels and inform the perception of color.</p>
        <OpponentSlider />
        <p className="body6"><strong>* Note:</strong> <em>This simulation is intended to assist in demonstrating the concept behind the color opponent process. It is not scientifically accurate and does not account for color compositions or the luminosity channel (cones + rods).</em> </p>
      </Section>


      <Section title="Related topics" level="4">
        <ul>
          <li><Link to='/trichromacy'> Trichromacy </Link></li>
          <li><Link to='/color-appearance-model'> Color appearance model </Link></li>
          <li><Link to='/color-vision-deficiency'> Color vision deficiency </Link></li>
          <li><Link to='/abney-effect'> Abney effect </Link></li>
        </ul>
      </Section>

      <CitationList>
        <Citation src="Psychophysical" pages="256-259"/>
        <Citation src="Visual Intelligence" pages="133-134"/>
        <Citation url="https://en.wikipedia.org/wiki/Opponent_process" publisher="Wikipedia" title="Opponent process"/>
      </CitationList>

      <Footer />

    </motion.div>
  )
}
 
export default ColorOpponentProcess;